import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { RootState } from '../../redux/store';
import { paginationComponentOptions } from '../../utils/list-default';
import { getNome } from '../shared/dropdown/certification/OggettoTipologia';
// import { getOggetto, getOggettoTipologia } from '../shared/dropdown/certification/OggettoTipologia';
import { getStatoCertificazione } from '../shared/dropdown/certification/StatoCertificazione';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';

interface IProps {
  certifications: Array<ICertificationEntity>;
}

const ReplaceCfProfessionalCertifications: FC<IProps> = (props) => {
  
  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggetti: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
  // const tipologie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipologia_certificazione);
  const accrediti: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);

  const stati: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);

  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      omit: true
    },
    {
      name: 'Oggetto',
      selector: (row: ICertificationEntity) => getNome(row.idOggetto, oggetti),
    },
    {
      name: 'Tipologia',
      selector: (row: ICertificationEntity) => (row.idOggetto == 1 || row.idOggetto == 5) ? getNome(row.idTipologia, accrediti) : getNome(row.idTipologia, esoneri),
      // selector: (row: ICertificationEntity) => getOggettoTipologia(row.idOggetto, row.idTipologia, tipologie),
    },
    {
      name: 'Stato',
      selector: (row: ICertificationEntity) => getStatoCertificazione(row.idStatoCertificazione, stati),
    },
    {
      name: 'CFP dichiarati / assegnati',
      selector: (row: ICertificationEntity) => row.cfpDichiarati + " / " + row.cfpAssegnati,
    },
    {
      name: 'Periodo rif.',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'dd/MM/yyyy') : "") + " - " + (row.dataRiferimentoA ? format(new Date(row.dataRiferimentoA), 'dd/MM/yyyy') : ""),
    },
    {
      name: 'Ordine provinciale',
      selector: (row: ICertificationEntity) => getOrdineProvinciale(row.idAzienda, companiesOrders),
    },
  ] as TableColumn<ICertificationEntity>[];

  return (
    <Fragment>
      
            <DataTable columns={columns} data={props.certifications}
              pagination striped dense
              noDataComponent="Nessun risultato"
              paginationComponentOptions={paginationComponentOptions} />
          
    </Fragment>
  );
};

export default ReplaceCfProfessionalCertifications;