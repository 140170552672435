import { FC, Fragment, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import PageSubtitle from '../../components/PageSubtitle';
import { IUser } from '../../entities/user.entity';
import { deleteSubscriptionAndPendingPaymentWithTiming } from '../../redux/slices/course.slice';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { IAttemptPayment } from '../../redux/slices/ui.slice';

const waitingText: string[] = ["Attendere", "Stai per atterrare sulla piattaforma di pagamento", "Ops! Non hai completato l'operazione", "Elaborazione in corso"]

const UserCourseAttemptPayment: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [goToExt, setGoToExt] = useState<boolean | null>(null);
    const [goBack, setGoBack] = useState<boolean | null>(false);
    const [waitingMsg, setWaitingMsg] = useState<string>(waitingText[0]);
    
    const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);
    const attemptpayment: IAttemptPayment = useSelector((s: RootState) => s.ui.payment);

    const handleGoToExt = (localidc: number, idc: null | number, url: null | string, goto: boolean | null, goback: boolean | null, u: IUser | null) => {
        if(idc !== null && url !== null && goto === null) {
            if(localidc > 0 && idc > 0 && localidc == idc && url.length > 0) {
                setGoToExt(true);
                setWaitingMsg(waitingText[1]);
                setTimeout(() => {
                        const div = document.getElementById("aHrefPaymentLink");
                        if (div) {
                            const link = document.createElement('a');
                            link.href = url;
                            div.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    }, 2000);
            }
            else {
                setGoToExt(false);
                setGoBack(null);
            }
        }
        else if(idc !== null && url !== null && goto === false && goback == null) {
            setGoBack(true);
            if(localidc > 0 && idc === 0 && u !== null) {
                setWaitingMsg(waitingText[2]);
                setTimeout(() => {
                        dispatch(deleteSubscriptionAndPendingPaymentWithTiming({ idCorso: localidc, codiceFiscale: u.fiscalCode }))
                            .finally(() => {
                                    setWaitingMsg(waitingText[3]);
                                    setTimeout(() => {
                                            const urlwithparam = AuthenticatedRoutesUser.ScheduledCourses.toString() + "?esitoPagamento=KO";
                                            navigate(urlwithparam, { replace: true });
                                        }, 1000);
                                });
                    }, 1000);
            }
        }
    }

    useEffect(() => {
            const localidc: number = Number(id);
            const goto: boolean | null = goToExt;
            const goback: boolean | null = goBack;
            const idc: number | null = attemptpayment?.idCorso;
            const url: string | null = attemptpayment?.attemptUrl;
            const us: IUser | null = user;
            handleGoToExt(localidc, idc, url, goto, goback, us);
        }, [attemptpayment, goToExt]);
    
    return (
        <Fragment>
            <PageTitle content="Corso" />
            <PageSubtitle content="Pagamento ..." />
            <Modal backdrop="static" show={true}>
                <Modal.Body>
                    <Spinner animation="grow" size="sm" /> &nbsp; {waitingMsg} ...
                </Modal.Body>
            </Modal>
            <div id="aHrefPaymentLink" className="d-none"></div>
        </Fragment>
    );
};

export default UserCourseAttemptPayment;