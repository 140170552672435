import { FC, Fragment, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import FavoritesCourses from '../../components/user/FavoritesCourses';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';

const UserFavoritesCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

   return (
    <Fragment>
      <PageTitle content="Corsi" />
      <PageSubtitle content="Lista corsi d'interesse" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <FavoritesCourses />
      </Container>      
    </Fragment>
  );
};

export default UserFavoritesCourses;