import { createSlice } from "@reduxjs/toolkit";
import { ICoursesPublishedFilter } from "./courses.slice";

export interface IAttemptPayment {
  idCorso: number;
  attemptUrl: string;
}

export interface IUIState {
  sidebar: { expanded: boolean; };
  list: { filter: ICoursesPublishedFilter; };
  payment: IAttemptPayment;
}

export const defaultFilter: ICoursesPublishedFilter = {
  company: 0,
  partner: 0,
  erogazione: 0,
  tipologia: 0,
  categoria: 0,
  text: "",
  eventoGratuito: 0,
  tipoAzienda: 0
};

const initialState: IUIState = {
  sidebar: { expanded: false },
  list: { filter: defaultFilter },
  payment: { attemptUrl: "", idCorso: 0 }
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.sidebar.expanded = !state.sidebar.expanded;
    },
    openSidebar(state) {
      state.sidebar = { expanded: true };
    },
    closeSidebar(state) {
      state.sidebar = { expanded: false };
    },
    setListFilter(state, action) {
      const payload = action.payload as unknown as ICoursesPublishedFilter;
      state.list.filter = payload;
    },
    setAttemptPayment(state, action) {
      const payload = action.payload as unknown as IAttemptPayment;
      state.payment = payload;
    }
  },
});

export const {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  setListFilter,
  setAttemptPayment,
} = uiSlice.actions;

export default uiSlice.reducer;
