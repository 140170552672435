import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Card, Nav, Spinner, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { getProfessionalReplaceCfNewCertifications, getProfessionalReplaceCfNewCourses, getProfessionalReplaceCfOldCertifications, getProfessionalReplaceCfOldCourses, ICertificationsProfessionalThunkPayload, ICoursesProfessionalThunkPayload, IProfessionalReplaceCfThunkPayload, postProfessionalReplaceCf } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import ReplaceCfProfessionalCourses from './ReplaceCfProfessionalCourses';
import ReplaceCfProfessionalPersonalData from './ReplaceCfProfessionalPersonalData';
import ReplaceCfProfessionalCertifications from './ReplaceCfProfessionalCertifications';
import { IRejectResponse } from '../../api/api.interface';

interface IProps {
    professionalOld: IProfessionalEntity;
    professionalNew: IProfessionalEntity;
    onSubmitSuccess: () => void;
}

const ReplaceCf: FC<IProps> = (props) => {

    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<IRejectResponse>();

    useEffect(() => {
        setLoading(true);
        dispatch(getProfessionalReplaceCfOldCourses({ codiceFiscale: props.professionalOld.codiceFiscale, filter: { confermato: (-1), anno: 0, deontologico: (-1), statoIscrizione: 0, triennio: 0 } } as ICoursesProfessionalThunkPayload))
            .unwrap().finally(() => setLoading(false));
        dispatch(getProfessionalReplaceCfNewCourses({ codiceFiscale: props.professionalNew.codiceFiscale, filter: { confermato: (-1), anno: 0, deontologico: (-1), statoIscrizione: 0, triennio: 0 } } as ICoursesProfessionalThunkPayload))
            .unwrap().finally(() => setLoading(false));
        dispatch(getProfessionalReplaceCfOldCertifications({ codiceFiscale: props.professionalOld.codiceFiscale } as ICertificationsProfessionalThunkPayload))
            .unwrap().finally(() => setLoading(false));
        dispatch(getProfessionalReplaceCfNewCertifications({ codiceFiscale: props.professionalNew.codiceFiscale } as ICertificationsProfessionalThunkPayload))
            .unwrap().finally(() => setLoading(false));
    }, [dispatch, props.professionalOld, props.professionalNew]);

    const coursesOld: Array<ICourseEntityRaw> = useSelector((s: RootState) => s.professional.replaceCf.cfOld.courses);
    const certificationsOld: Array<ICertificationEntity> = useSelector((s: RootState) => s.professional.replaceCf.cfOld.certifications);
    const coursesNew: Array<ICourseEntityRaw> = useSelector((s: RootState) => s.professional.replaceCf.cfNew.courses);
    const certificationsNew: Array<ICertificationEntity> = useSelector((s: RootState) => s.professional.replaceCf.cfNew.certifications);
    
    const handleReplaceCf = () => {
        setLoading(true);
        dispatch(postProfessionalReplaceCf({ codiceFiscaleOld: props.professionalOld.codiceFiscale, codiceFiscaleNew: props.professionalNew.codiceFiscale } as IProfessionalReplaceCfThunkPayload))
            .unwrap()
            .then(() => {
                setError(undefined);
            })
            .catch((err) => {
                const erro = err as unknown as IRejectResponse;
                setError(erro);
            })
            .finally(() => {
                setSubmitted(true);
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <div className="d-flex mb-3 justify-content-between">
                <div>
                    { 
                        submitted ? (
                            error ? (
                                <Alert className="m-0" variant="danger">{error.error}</Alert>
                            ) : (
                                <Alert className="m-0" variant="success">
                                    <b>Unione completata con successo!</b> &nbsp;
                                    <Button onClick={props.onSubmitSuccess} variant="link" className="m-0 p-0 text-dark">CLICCA QUI</Button> per ripetere questa operazione su altri codici fiscali.
                                </Alert>
                            )
                        ) : (
                            <span className="m-0"><i>Clicca sul bottone UNISCI per completare l'operazione.</i></span>
                        )
                    }
                </div>
                <Button onClick={handleReplaceCf} variant="primary" disabled={loading || submitted} className="align-self-center w-25" size="lg"> 
                    { 
                        loading ? (
                            <span><Spinner as="span" animation="border" size="sm" role="status" /> <span className="ms-1">Attendere...</span></span>
                        ) : (
                            <span>UNISCI</span>
                        )
                    }
                </Button>
            </div>
            <Tab.Container defaultActiveKey="personalDataOld">
                <Nav fill variant="tabs" defaultActiveKey="personalDataOld">
                    <Nav.Item>
                        <Nav.Link eventKey="personalDataOld" className="text-dark"><b>CF VECCHIO</b><br />Anagrafica</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="coursesOld" className="text-dark"><b>CF VECCHIO</b><br />Corsi</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="certificationsOld" className="text-dark"><b>CF VECCHIO</b><br />Certificazioni / Esoneri</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="personalDataNew" className="text-success"><b>CF NUOVO</b><br />Anagrafica</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="coursesNew" className="text-success"><b>CF NUOVO</b><br />Corsi</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="certificationsNew" className="text-success"><b>CF NUOVO</b><br />Certificazioni / Esoneri</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="personalDataOld">
                        <ReplaceCfProfessionalPersonalData professional={props.professionalOld} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="coursesOld">
                        <ReplaceCfProfessionalCourses courses={coursesOld} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="certificationsOld">
                        <ReplaceCfProfessionalCertifications certifications={certificationsOld} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="personalDataNew">
                        <ReplaceCfProfessionalPersonalData professional={props.professionalNew} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="coursesNew">
                        <ReplaceCfProfessionalCourses courses={coursesNew} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="certificationsNew">
                        <ReplaceCfProfessionalCertifications certifications={certificationsNew} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Fragment>
    );
};

export default ReplaceCf;