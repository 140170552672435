import { IForgotPasswordThunkPayload, IForgotUsernameThunkPayload, IGetProfileThunkPayload, IInspectDocumentThunkPayload, ILoginMockSsoThunkPayload, ILoginSsoThunkPayload, ILoginThunkPayload, IPutProfileThunkPayload, IRegisterThunkPayload, IUpdatePasswordThunkPayload, IVerifyThunkPayload } from '../redux/slices/auth.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const inspectDocument = (payload: IInspectDocumentThunkPayload) => {
  const endpoint = ApiEndpoints.InspectDoc.toString();
  let querystring = Array<string>();
  if (payload.codiceFiscale != null && payload.codiceFiscale.length > 0) {
    querystring.push('codiceFiscale=' + payload.codiceFiscale);
  }
  if (payload.token != null && payload.token.length > 0) {
    querystring.push('token=' + payload.token);
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const register = (payload: IRegisterThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Register, {
      email: payload.email,
      password: payload.password,
    });
};

export const verify = (payload: IVerifyThunkPayload,) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.VerifyAccount, {
      token: payload.token
    });
};

export const forgotUsername = (payload: IForgotUsernameThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ForgotUsername, {
      email: payload.email,
    });
};

export const forgotPassword = (payload: IForgotPasswordThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ForgotPassword, {
      username: payload.username,
    });
};

export const resetPassword = (payload: IUpdatePasswordThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ResetPassword, {
      token: payload.token,
      password: payload.password,
    });
};

export const changePassword = (payload: IUpdatePasswordThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ChangePassword, {
      token: payload.token,
      password: payload.password,
    });
};

export const login = (payload: ILoginThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Login, {
      username: payload.username,
      password: payload.password,
    });
};

export const loginSso = (_payload: ILoginSsoThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.LoginSso);
};

export const loginMockSso = (_payload: ILoginMockSsoThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.LoginMockSso);
};

export const logout = () => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Logout);
};

export const policyConsent = () => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.PolicyConsent);
};

export const getProfile = (_payload: IGetProfileThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.UserMe);
};

export const putProfile = (payload: IPutProfileThunkPayload) => {
  return AxiosService.instance.axios.put(ApiEndpoints.EditUserMe, payload);
};

export const AuthAPI = {
  register,
  verify,
  forgotPassword,
  forgotUsername,
  resetPassword,
  changePassword,
  login,
  logout,
  policyConsent,
  loginSso,
  loginMockSso,
  getProfile,
  putProfile,
  inspectDocument,
};

export default AuthAPI;
