import { format } from 'date-fns';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Container, FormControl, InputGroup, Modal, Row, Col } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { paginationComponentOptions } from '../../utils/list-default';
import { IAdviceEntity } from '../../entities/advice.entity';
import { getAdvices } from '../../redux/slices/advices.slice';
import { Search, X } from 'react-bootstrap-icons';
import Advice from './Advice';
import { AiOutlineEdit } from 'react-icons/ai';
import { AuthenticatedRoutesSupervisor } from '../../enums/routes.enum';
import { useNavigate } from 'react-router-dom';
import { isVisibleNow, whichScope, whichStatus } from '../../utils/advice-default';

const Advices: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textSearch, setTextSearch] = useState<string>("");
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const handleOpenDetailModal = () => setShowDetailModal(true);
    const handleCloseDetailModal = () => {
        setShowDetailModal(false);
        setAdviceItem(null);
    }

    const [adviceItem, setAdviceItem] = useState<IAdviceEntity | null>(null);
    const handleSelectItem = (a: IAdviceEntity) => {
        setAdviceItem(a);
        handleOpenDetailModal();
    };

    const handleEditItem = (b: IAdviceEntity) => {
        navigate(AuthenticatedRoutesSupervisor.Advice.replace(':id', b.id.toString()));
    };

    const advices: Array<IAdviceEntity> = useSelector((s: RootState) => s.advices.value
        .filter((item: IAdviceEntity) => {
            const search = textSearch;
            if (!search || !search.length) return true;
            if (item.titolo.toUpperCase().includes(search.trim().toUpperCase())) return true;
            if (item.testo.toLowerCase().includes(search.trim().toLowerCase())) return true;
            return false;
        }));

    useEffect(() => {
        setIsLoading(true);
        dispatch(getAdvices({ }))
            .unwrap()
            .finally(() => setIsLoading(false));
    }, [dispatch]);

    const columns: Array<TableColumn<IAdviceEntity>> = [
        {
            name: 'Titolo',
            sortable: true,
            selector: (row: IAdviceEntity) => row.titolo,
            grow: 3,
        },
        {
            name: 'Inizio',
            sortable: true,
            selector: (row: IAdviceEntity) => {
                if (!row.dataInizioValidita) return '';
                return format(new Date(row.dataInizioValidita), 'dd/MM/yyyy');
            },
            sortFunction: (a: IAdviceEntity, b: IAdviceEntity) => {
                return new Date(b.dataInizioValidita).getTime() - new Date(a.dataInizioValidita).getTime();
            },
            grow: 1,
        },
        {
            name: 'Fine',
            sortable: true,
            selector: (row: IAdviceEntity) => {
                if (!row.dataFineValidita) return '';
                return format(new Date(row.dataFineValidita), 'dd/MM/yyyy');
            },
            sortFunction: (a: IAdviceEntity, b: IAdviceEntity) => {
                return new Date(b.dataFineValidita).getTime() - new Date(a.dataFineValidita).getTime();
            },
            grow: 1,
        },
        {
            name: 'Ambito',
            selector: (row: IAdviceEntity) => whichScope(row),
            grow: 1,
        },
        {
            name: 'Stato',
            sortable: true,
            selector: (row: IAdviceEntity) => isVisibleNow(row) ? 1 : 0,
            cell: (row, index, column, id) => {
                const intime: number = new Date(row.dataInizioValidita).getTime();
                const fitime: number = new Date(row.dataFineValidita).getTime();
                if(fitime < intime) return "";

                if (row.flagOnline) {
                    return (<Badge pill bg="light" text="dark" className="border-dark">{whichStatus(row)}</Badge>);
                }
                else {
                    return (<Badge pill bg="secondary">{whichStatus(row)}</Badge>);
                }
            },
            grow: 1,
        },
        {
            cell: (row, index, column, id) => (
                <div className="d-flex justify-content-end">
                    <AiOutlineEdit className="text-dark c-pointer mx-2 my-1" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleEditItem(row);
                        }} size={20} />
                    <IoEllipsisVerticalCircle className="text-dark c-pointer mx-2 my-1" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleSelectItem(row);
                        }} size={20} />
                </div>
            ),
            ignoreRowClick: true,
            grow: 0,
        },
    ] as Array<TableColumn<IAdviceEntity>>;

    return (
        <Fragment>
            <Container fluid as="section">
                <Row>
                    <Col>
                        <InputGroup className="w-100">
                            <FormControl placeholder="Cerca digitando: titolo e premi INVIO..." id="idTextSearch"
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setTextSearch(event.currentTarget.value);
                                    }
                                }} />
                            <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        element.value = '';
                                    }
                                    setTextSearch('');
                                }}><X size={20} /></InputGroup.Text>
                            <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        setTextSearch(element.value);
                                    }
                                }}><Search size={20} /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={advices}
                            pagination
                            responsive
                            striped
                            dense
                            noDataComponent="Nessun risultato"
                            paginationComponentOptions={paginationComponentOptions}
                            progressPending={isLoading} />
                    </Col>
                </Row>
            </Container>
            
            <Modal show={showDetailModal} onHide={handleCloseDetailModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h3>Dettaglio</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { adviceItem && <Advice advice={adviceItem} /> }
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default Advices;