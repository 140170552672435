import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import AdviceAPI from "../../api/advice.api";
import { IAdvice, IAdviceEntity } from "../../entities/advice.entity";
import { TheAdviceDefaultLevelId, TheAdviceLevels } from "../../utils/advice-default";

export interface IGetAdvicesReadonlyThunkPayload { }

export interface IGetAdvicesReadonlyFulfilledPayload {
  advices: Array<IAdvice>;
}

export const getAdvicesReadonly = createAsyncThunk(
  'advices/readonly/get',
  async (thunkPayload: IGetAdvicesReadonlyThunkPayload, thunkAPI) => {
    try {
      const response = await AdviceAPI.getAdvicesRedonly(thunkPayload);
      const successPayload = response.data as unknown as Array<IAdvice>;
      const fulfillValue = {
        advices: successPayload,
      } as unknown as IGetAdvicesReadonlyFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetAdvicesThunkPayload { }

export interface IGetAdvicesFulfilledPayload {
  advices: Array<IAdviceEntity>;
}

export const getAdvices = createAsyncThunk(
    'advices/get',
    async (thunkPayload: IGetAdvicesThunkPayload, thunkAPI) => {
      try {
        const response = await AdviceAPI.getAdvices(thunkPayload);
        const successPayload = response.data as unknown as Array<IAdviceEntity>;
        const fulfillValue = {
          advices: successPayload,
        } as unknown as IGetAdvicesFulfilledPayload;
        return thunkAPI.fulfillWithValue(fulfillValue);
      } catch (e) {
        const error = e as AxiosError;
        if (!error.isAxiosError) throw e;
        const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
        return thunkAPI.rejectWithValue(rejectValue);
      }
    }
  );

export interface IAdvicesState {
  error: IRejectResponse | null;
  value: Array<IAdviceEntity>;
  readonlyerror: IRejectResponse | null;
  readonlyvalue: Array<IAdvice>;
}

const initialState: IAdvicesState = {
  error: null,
  value: new Array<IAdviceEntity>(),
  readonlyerror: null,
  readonlyvalue: new Array<IAdvice>(),
};

const advicesSlice = createSlice({
  name: "advices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdvicesReadonly.pending, (state) => {
      state.readonlyerror = null;
      state.readonlyvalue = new Array<IAdvice>();
    });
    builder.addCase(getAdvicesReadonly.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetAdvicesReadonlyFulfilledPayload;
      state.readonlyvalue = payload.advices.sort((a: IAdvice, b: IAdvice) => {
        const aadvice = TheAdviceLevels.find(item => item.nome == a.livelloNotifica);
        const aadviceid = aadvice ? aadvice.id : TheAdviceDefaultLevelId;
        const badvice = TheAdviceLevels.find(item => item.nome == b.livelloNotifica);
        const badviceid = badvice ? badvice.id : TheAdviceDefaultLevelId;
        return aadviceid > badviceid ? (1) : (aadviceid < badviceid ? (-1) : (0));
      });
    });
    builder.addCase(getAdvicesReadonly.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.readonlyerror = payload;
    });

    builder.addCase(getAdvices.pending, (state) => {
      state.error = null;
      state.value = new Array<IAdviceEntity>();
    });
    builder.addCase(getAdvices.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetAdvicesFulfilledPayload;
      state.value = payload.advices;
    });
    builder.addCase(getAdvices.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });
  },
});

export default advicesSlice.reducer;