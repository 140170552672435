import { ChangeEvent, FC, Fragment, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IAttachment, IAttachmentDetail } from '../../entities/attachment.entity';
import { AppDispatch } from '../../redux/store';
import { IPostCertificationAttachmentThunkPayload, postCertificationAttachment } from '../../redux/slices/certification.slice';

interface IProps {
    idCertification: number;    
    allegati: Array<IAttachmentDetail> | [];
    onTestChange?: (newType: IAttachmentDetail[] | []) => void;
}

const AddAttachForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [numAllegati, setNumAllegati] = useState<number>(props?.allegati?.length ? props.allegati.length : 0);
    const [initialValues, setInitialValues] = useState<IAttachmentDetail[]>(props.allegati ? props.allegati : []);
    const [tipoFileErrato, setTipoFileErrato] = useState<string>('');
    const [uploadAttachments, setUploadAttachments] = useState<IAttachment[]>([]);

    const readFileContents = async (file: File) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(fileReader.result);
                if (e.target?.result) {
                    const res = e.target.result;    
                    setInitialValues(oa => [...oa, {
                        filename: file.name,
                        contentType: file.type,
                        contentRaw: res.toString(),
                        idAllegato: 0
                    }]);
                    setUploadAttachments(oa => [...oa, {
                        filename: file.name,
                        contentType: file.type,
                        contentRaw: res.toString(),
                    }]);
                }
            };           
            fileReader.onerror = reject;
            fileReader.readAsDataURL(file);
        });
    };
    const readAllFiles = async (AllFiles: Array<File>) => {
        const results = await Promise.all(
            AllFiles.map(async file => {
                const fileContents = await readFileContents(file);
                return fileContents;
            })
        );
        return results;
    };

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        //const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;
        let AllFiles = [];
        setTipoFileErrato("");

        if (files.length > 0 && files.length < 5-numAllegati) {
            // console.log("tot files: " + files.length);

            let tipiValidi = true;
            for (var i = 0; i < files.length; i++) {
                AllFiles.push(files[i]);

                if (!((files[i].type === "application/pdf") || (files[i].type === "application/x-zip-compressed"))) {
                    tipiValidi = false;
                }
            }

            if (tipiValidi) {
                // console.log("file caricati");                
                readAllFiles(AllFiles)
                    .then(result => {
                        result.map(r => {
                            console.log(r);
                        });
                    });
            } else {
                setTipoFileErrato('tipo file errato, consentiti file zip e pdf');
                setUploadAttachments([]);
                event.currentTarget.value = "";
            }
        } else {
            setTipoFileErrato('il numero massimo di file consentito è 4');
            setUploadAttachments([]);
            event.currentTarget.value = "";
        }
    };

    const [showError, setShowError] = useState(false);

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSubmitted(false);
        setShowError(false);
        setLoading(true);
        
        dispatch(postCertificationAttachment({ idCertification: props.idCertification, attachment: uploadAttachments } as IPostCertificationAttachmentThunkPayload))
            .unwrap()
            .then(() => {
                setSubmitted(true)
                props.onTestChange?.(initialValues)})
            .catch(() => {
                setShowError(true)
                props.onTestChange?.([])})
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            {submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={94} className="text-success mb-5" />
                    <h3 className="text-success">Operazione completata!</h3>
                </div>
            ) : (
                <Form noValidate onSubmit={handleSubmit}>

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>File da allegare (numero di file attualmente inseriti: {numAllegati})</Form.Label>
                        <Form.Control type="file" multiple name="file" onInput={handleFileUpload} disabled={isLoading} />
                        { tipoFileErrato.length > 0 ? (<p>{tipoFileErrato}</p>) : (<p />) }
                    </Form.Group>

                    <Button type="submit" className="w-100" disabled={isLoading}>
                        {isLoading ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendere</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <span>Salva</span>
                        )}
                    </Button>

                    <Alert variant="danger" show={showError} className="mt-3">
                        Salvataggio non riuscito!
                    </Alert>
                </Form>
            )}
        </Fragment>
    );
};

export default AddAttachForm;