import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Col, Container, Row, Spinner } from 'react-bootstrap';
import { GoAlert } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { getProfessional, IGetProfessionalThunkPayload } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';

interface IProps {
    codiceFiscale: string;
}

const ProfessionalPersonalData: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProfessional({ codiceFiscale: props.codiceFiscale } as IGetProfessionalThunkPayload))
            .finally(() => setIsLoading(false));
    }, [dispatch, props.codiceFiscale]);

    const companies: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
    const professional: IProfessionalEntity | null = useSelector((s: RootState) => (s.professional.value));

    return (
        <Fragment>
            {
                (isLoading || professional == null) ? (
                    <Container as="section" fluid className="bg-light p-2">
                        <Row>
                            <Col>
                                <Spinner animation='border' />
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container as="section" fluid className="bg-light p-2">
                        <Row className="m-2">
                            <Col md={3}><b>Codice fiscale</b></Col>
                            <Col md={9}>{professional.codiceFiscale}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Cognome</b></Col>
                            <Col md={9}>{professional.cognome}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Nome</b></Col>
                            <Col md={9}>{professional.nome}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Email</b></Col>
                            <Col md={9}>{professional.email}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>PEC</b></Col>
                            <Col md={9}>{professional.pec ? professional.pec : (<i className="text-muted">dato non disponibile</i>)}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>PEO</b></Col>
                            <Col md={9}>{professional.peo ? professional.peo : (<i className="text-muted">dato non disponibile</i>)}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Data nascita</b></Col>
                            <Col md={9}>{professional.dataNascita ? format(new Date(professional.dataNascita), 'dd/MM/yyyy') : (<span className="text-danger"><GoAlert className="text-danger" size={25} /> <i>dato mancante</i></span>) }</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Data prima iscrizione</b></Col>
                            <Col md={9}>{professional.dataPrimaIscrizione ? format(new Date(professional.dataPrimaIscrizione), 'dd/MM/yyyy') : (<span className="text-danger"><GoAlert className="text-danger" size={25} /> <i>dato mancante</i></span>) }</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Stato</b></Col>
                            <Col md={9}><h4 className="m-0 p-0">
                                {professional.status.toLowerCase() === "iscritto" ? (
                                    <Badge bg="success">{professional.status}</Badge>
                                ) : (
                                    professional.status.toLowerCase() === "sospeso" ? (
                                        <Badge bg="warning">{professional.status}</Badge>
                                    ) : (
                                        <Badge bg="danger">{professional.status}</Badge>
                                    )
                                )}</h4>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Ordine</b></Col>
                            <Col md={9}>{getOrdineProvinciale(professional.ordine, companies)}</Col>
                        </Row>
                        <hr />
                        <Row className="m-2">
                            <Col md={3}><b>Settore</b></Col>
                            <Col md={9}>{professional.settore}</Col>
                        </Row>
                    </Container>
                )
            }
        </Fragment>
    );
};

export default ProfessionalPersonalData;