import { FC, Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import InspectDocumentForm from '../components/InspectDocumentForm';
import Footer from '../layout/parts/Footer';

const InspectDocumentNoCode: FC = () => {
  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card className="m-auto">
              <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                <h2>Verifica documento</h2>
                <InspectDocumentForm token="" codiceFiscale=""/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer variant={"dark"} />
    </Fragment>
  );
};

export default InspectDocumentNoCode;