import { FC, useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { MdLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Routes } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch } from '../../redux/store';

interface IProps {
  isMaintenanceMode: boolean;
}

const MaintenanceAlert: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(props.isMaintenanceMode);
  }, [props.isMaintenanceMode]);


  const handleClose = () => {
    setShow(false);
    handleLogout();
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = Routes.Root));
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom" className="bg-warning text-light" backdrop="static">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title><h1><span className="me-3">Sito in manutenzione! </span><Button onClick={handleClose} variant="dark"><MdLogout size={20} /> Esci</Button></h1></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h5>Uscire dall'applicazione ed effettuare nuovamente l'accesso più tardi.</h5>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

export default MaintenanceAlert;