import { IGetAdvicesReadonlyThunkPayload, IGetAdvicesThunkPayload } from '../redux/slices/advices.slice';
import { IGetAdviceThunkPayload, IPostAdviceThunkPayload, IPutAdviceThunkPayload, IDeleteAdviceThunkPayload } from '../redux/slices/advice.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getAdvicesRedonly = (_payload: IGetAdvicesReadonlyThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.AdvicesReadonly);
};

export const getAdvices = (_payload: IGetAdvicesThunkPayload) => {
    return AxiosService.instance.axios.get(ApiEndpoints.Advices);
};

export const getAdvice = (payload: IGetAdviceThunkPayload) => {
    const url = ApiEndpoints.Advice.replace(':id', payload.id.toString());
    return AxiosService.instance.axios.get(url);
};

export const postAdvice = (payload: IPostAdviceThunkPayload) => {
    return AxiosService.instance.axios.post(ApiEndpoints.AddAdvice, payload.advice);
};

export const putAdvice = (payload: IPutAdviceThunkPayload) => {
    const url = ApiEndpoints.EditAdvice.replace(':id', payload.advice.id.toString());
    return AxiosService.instance.axios.put(url, payload.advice);
};

export const deleteAdvice = (payload: IDeleteAdviceThunkPayload) => {
    const url = ApiEndpoints.DeleteAdvice.replace(':id', payload.id.toString());
    return AxiosService.instance.axios.delete(url);
};

const AdviceAPI = {
    getAdvicesRedonly,
    getAdvices,
    getAdvice,
    postAdvice,
    putAdvice,
    deleteAdvice,
};
 
export default AdviceAPI;