import { IMetadataOption } from "../entities/metadata.entity";

export const getMetadataNomeById = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}