import { FC, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getTrainingType } from './dropdown/course/TipoFormazione';
import TrainingCreditsBar from './TrainingCreditsBar';

export const getCfpThreeYearsByTrainingType = (initialTrainingType: boolean): number => {
    if (initialTrainingType === false) return 48; // Formazione ordinaria
    if (initialTrainingType === true) return 12; // Formazione deontologica
    return 0;
};

interface IProps {
    cfpDecurtati: number; // cfp triennio precedente in negativo (riportali con segno positivo)
    cfpObbligatori: number; // cfpObbligatori ovvero 12 oppure 48 (se sono deontologici o ordinari) sse 0 allora viene calcolato automaticamente in base al tipo formazione
    cfp: number; //cfp raggiunti nel triennio selezionato (solo valori positivi)
    trainingType: boolean; // true: deontologici oppure false: ordinari
    appendText: string; // eventuale testo che verrà inserito in coda alle altre diciture nel titolo
    cfpAcquisitiSoloEsoneri: number; //parte di cfp deidicati solo ad esoneri
}

const TrainingCreditsBarBuilder: FC<IProps> = (props) => {

    const [cfp1, setCfp1] = useState<number>(0);
    const [cfp2, setCfp2] = useState<number>(0);
    const [cfp3, setCfp3] = useState<number>(0);

    const [cfp1TOT, setCfp1TOT] = useState<number>(0);
    const [cfp2TOT, setCfp2TOT] = useState<number>(0);
    const [cfp3TOT, setCfp3TOT] = useState<number>(0);

    useEffect(() => {
        const cfps = props.cfp; // cfp finora acquisiti
        const cfpsEvtCert = props.cfp - props.cfpAcquisitiSoloEsoneri; // cfp finora acquisiti SOLO EVENTI E CERTIFICAZIONI
        const cfpsEso = props.cfpAcquisitiSoloEsoneri; // cfp finora acquisiti SOLO ESONERI

        const cfps1Total = props.cfpDecurtati;
        const cfps2Total = props.cfpObbligatori > 0 ? props.cfpObbligatori : getCfpThreeYearsByTrainingType(props.trainingType);
        
        const cfps1 = cfpsEvtCert > cfps1Total ? cfps1Total : cfpsEvtCert;
        //const cfps1Part = cfps1Total - (cfpsEvtCert + cfpsEso);
        const cfps2 = (cfpsEvtCert > cfps1Total) ? 
            (((cfpsEvtCert + cfpsEso - cfps1Total) > cfps2Total) ? cfps2Total : (cfpsEvtCert + cfpsEso - cfps1Total)) :
            ((cfpsEso > cfps2Total) ? cfps2Total : cfpsEso);
        const cfps3 = (cfpsEvtCert > cfps1Total) ? (((cfpsEvtCert + cfpsEso - cfps1Total) > cfps2Total) ? (cfpsEvtCert + cfpsEso - cfps1Total - cfps2Total) : 0) : 0;

        const cfps3Total = cfps3;

        setCfp1(cfps1);
        setCfp2(cfps2);
        setCfp3(cfps3);
        setCfp1TOT(cfps1Total);
        setCfp2TOT(cfps2Total);
        setCfp3TOT(cfps3Total);
    }, [props.cfp, props.cfpDecurtati, props.cfpObbligatori, props.trainingType, props.cfpAcquisitiSoloEsoneri]);

    return (
        <Container fluid as="section" className="p-0 m-0">
            <Row className="mb-3">
                <Col md={3}>
                    <TrainingCreditsBar
                        cfp={cfp1} cfpTOT={cfp1TOT} hideIfEmpty={false} 
                        variant100={"success"} variant50={"danger"} 
                        textTitle={getTrainingType(props.trainingType)}
                        textSubtitle={"debito triennio precedente "}
                    />
                </Col>
                <Col md={6}>
                    <TrainingCreditsBar
                        cfp={cfp2} cfpTOT={cfp2TOT} hideIfEmpty={false} 
                        variant100={"success"} variant50={"warning"} 
                        textTitle={getTrainingType(props.trainingType)}
                        textSubtitle={"triennio selezionato " + props.appendText}
                    />
                </Col>
                <Col md={3}>
                    <TrainingCreditsBar
                        cfp={cfp3} cfpTOT={cfp3TOT} hideIfEmpty={true} 
                        variant100={"primary"} variant50={"secondary"} 
                        textTitle={""}
                        textSubtitle={""}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TrainingCreditsBarBuilder;