import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import DocumentAPI from "../../api/document.api";
import { IAttachment } from "../../entities/attachment.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IPostExportCourseProfessionalCertificateThunkPayload {
    codiceFiscale: string;
    idCorso: number;
}
  
export interface IPostExportCourseProfessionalCertificateFulfilledPayload {
    attachment: IAttachment;
}
  
export const postExportCourseProfessionalCertificate = createAsyncThunk(
    'documents/export/course-professional-certificate/post',
    async (thunkPayload: IPostExportCourseProfessionalCertificateThunkPayload, thunkAPI) => {
        try {
            const response = await DocumentAPI.postExportCourseProfessionalCertificate(thunkPayload);
            const successPayload = response.data as unknown as IAttachment;
            const fulfillValue = {
                attachment: successPayload
            } as IPostExportCourseProfessionalCertificateFulfilledPayload;
            return thunkAPI.fulfillWithValue(fulfillValue);
        } catch (e) {
            const error = e as AxiosError;
            if (!error.isAxiosError) throw e;
            const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
            return thunkAPI.rejectWithValue(rejectValue);
        }
    }
);

export interface IPostExportProfessionalCvThunkPayload {
    codiceFiscale: string;
    idTriennio: number;
    tipoAttestato: number;
}
  
export interface IPostExportProfessionalCvFulfilledPayload {
    attachment: IAttachment;
}
  
export const postExportProfessionalCv = createAsyncThunk(
    'documents/export/course-professional-cv/post',
    async (thunkPayload: IPostExportProfessionalCvThunkPayload, thunkAPI) => {
        try {
            const response = await DocumentAPI.postExportProfessionalCv(thunkPayload);
            const successPayload = response.data as unknown as IAttachment;
            const fulfillValue = {
                attachment: successPayload
            } as IPostExportProfessionalCvFulfilledPayload;
            return thunkAPI.fulfillWithValue(fulfillValue);
        } catch (e) {
            const error = e as AxiosError;
            if (!error.isAxiosError) throw e;
            const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
            return thunkAPI.rejectWithValue(rejectValue);
        }
    }
);

export interface IDocumentsState {
    value: null;
    error: IRejectResponse | null;
}
  
const initialState: IDocumentsState = {
    value: null,
    error: null,
};
  
const documentsSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(postExportCourseProfessionalCertificate.pending, (state) => { });
      builder.addCase(postExportCourseProfessionalCertificate.fulfilled, (state, action) => { });
      builder.addCase(postExportCourseProfessionalCertificate.rejected, (state, action) => { });

      builder.addCase(postExportProfessionalCv.pending, (state) => { });
      builder.addCase(postExportProfessionalCv.fulfilled, (state, action) => { });
      builder.addCase(postExportProfessionalCv.rejected, (state, action) => { });
    },
});

export default documentsSlice.reducer;