import { FC, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { UserRole } from '../../enums/user-roles.enum';
import ProfessionalCertifications from '../shared/ProfessionalCertifications';
import ProfessionalCourses from '../shared/ProfessionalCourses';
import ProfessionalPersonalData from '../shared/ProfessionalPersonalData';
import ProfessionalTrainingCredits from '../shared/ProfessionalTrainingCredits';
import ProfessionalTrainingCreditsImateria from '../shared/ProfessionalTrainingCreditsImateria';
import SupportOtpBuilder from './SupportOtpBuilder';

interface IProps {
    codiceFiscale: string;
}

const Professional: FC<IProps> = (props) => {
    return (
        <Fragment>
            <Tabs defaultActiveKey="personalData">
                <Tab eventKey="personalData" title="Anagrafica">
                    <ProfessionalPersonalData codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="trainingCredits" title="Status CFP">
                    <ProfessionalTrainingCredits codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="trainingCreditsImateria" title="Status CFP Im@teria - Aggiornato al 21/12/21">
                    <ProfessionalTrainingCreditsImateria codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="courses" title="Corsi">
                    <ProfessionalCourses codiceFiscale={props.codiceFiscale} disableButtonExportPdf={true} disableButtonMoodle={false} />
                </Tab>
                <Tab eventKey="certifications" title="Certificazioni / Esoneri">
                    <ProfessionalCertifications codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="support" title="Assistenza">
                    <SupportOtpBuilder codiceFiscale={props.codiceFiscale} idAzienda={0} role={Number(UserRole.Guest.toString())} />
                </Tab>
            </Tabs>
        </Fragment>
    );
};

export default Professional;