import { FC } from 'react';

const SuspenseFallback: FC = () => (
  <main className="suspense">
    <section className="position-fixed min-vw-100 min-vh-100 login-background d-none d-lg-block">
      <div className="logo position-relative">
        <img className="position-absolute top-100 start-50 translate-middle-x mt-5" src="/assets/logo.svg" alt="logo" />
      </div>
      <div className="d-flex align-items-end fixed-bottom justify-content-between">
        <img src="/assets/buildings-left.svg" height={350} alt="buildings" />
        <div></div>
        <img src="/assets/buildings-right.svg" height={350} alt="buildings" />
      </div>
    </section>
  </main>
);

export default SuspenseFallback;
