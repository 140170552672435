import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

interface IProps {
    defaultSelectedId?: number | null;
    onSelect?: (option: number | null) => void;
}

const TelematicReceipt: FC<IProps> = (props) => {

    const defaultSelected = (): number | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filter, setFilter] = useState<number | null>(defaultSelected());

    const handleFilter = (option: number | null) => {
        setFilter(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {`R.T. (ricevuta telematica) ${filter === 1 ? 'mancante' : ''} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownAllRT"} disabled={filter == null} onClick={() => handleFilter(null)}>Tutte</Dropdown.Item>
                    <Dropdown.Item key={"dropdownNoRT"} disabled={filter === 1} onClick={() => handleFilter(1)}>RT mancante</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default TelematicReceipt;