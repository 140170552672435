import { FC, Fragment } from 'react';
import { useParams } from 'react-router';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import UserCourseDetail from '../../components/shared/UserCourseDetail';
import { FaInfoCircle } from 'react-icons/fa';

const UserCourse: FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Fragment>
      <PageTitle content="Corso">
        <a href="/assets/tutorial/U_DettaglioCorso.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="" />
      <UserCourseDetail idCorso={Number(id)} />
    </Fragment>
  );
};

export default UserCourse;