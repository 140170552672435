import { Formik } from 'formik';
import { FC, Fragment } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import * as yup from 'yup';
import MyRichTextEditor from '../shared/MyRichTextEditor';
import { IAdviceEntity, IMetadataOptionAdvice } from '../../entities/advice.entity';
import { TheAdviceLevels } from '../../utils/advice-default';

interface IProps {
    advice: IAdviceEntity;
    isLoading: boolean;
    submitted: boolean;
    onSave: (values: IAdviceEntity) => void;
}

const AdviceForm: FC<IProps> = (props) => {
    const schema = yup.object().shape({
        titolo: yup.string().required("Campo obbligatorio").min(1, "Campo obbligatorio").max(200, ({ max }) => `Lunghezza massima consentita ${max} caratteri`),
        livelloNotifica: yup.string().required("Campo obbligatorio"),
        dataInizioValidita: yup.string().required("Campo obbligatorio"),
        dataFineValidita: yup.string().required("Campo obbligatorio"),
        flagAmbitoGlobale: yup.bool(),
        flagAmbitoOrdini: yup.bool().when("flagAmbitoGlobale", {
            is: true, then: yup.bool().isFalse("Questa opzione non può essere abilitata contemporaneamente ad AMBITO GLOBALE.")
        }),
        flagAmbitoEntiTerzi: yup.bool().when("flagAmbitoGlobale", {
            is: true, then: yup.bool().isFalse("Questa opzione non può essere abilitata contemporaneamente ad AMBITO GLOBALE.")
        }),
        flagAmbitoProfessionisti: yup.bool().when("flagAmbitoGlobale", {
            is: true, then: yup.bool().isFalse("Questa opzione non può essere abilitata contemporaneamente ad AMBITO GLOBALE.")
        })
    });

    const handleSubmit = (value: IAdviceEntity) => {
        props.onSave(value);
    }

    return (
        <Fragment>
            {props.submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={100} className="text-success mb-5" />
                    <h3 className="text-success">Ben fatto!</h3>
                    <p className="mt-3">Operazione completata.</p>
                </div>
            ) : (
                <Formik initialValues={props.advice} validationSchema={schema} onSubmit={handleSubmit}>
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3" controlId="titoloValidation">
                                <Form.Label as={Col} lg={2} md={4} sm={12}>Titolo <Asterisk size={8} className="mb-3" /></Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Control as="input" type="text" onChange={handleChange}
                                        name="titolo" value={values.titolo || ""}
                                        isInvalid={!!touched.titolo && !!errors.titolo}
                                        disabled={props.isLoading} />
                                    <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="livelloNotificaValidation">
                                <Form.Label as={Col} lg={2} md={4} sm={12}>Livello notifica <Asterisk size={8} className="mb-3" /></Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Control as="select" onChange={handleChange} disabled={props.isLoading}
                                        name="livelloNotifica" value={values.livelloNotifica}
                                        isInvalid={!!touched.livelloNotifica && !!errors.livelloNotifica}>
                                            <option key={"livelloNotifica0"} value={""}></option>
                                            {   
                                                TheAdviceLevels.filter((itm: IMetadataOptionAdvice) => itm.hide === false).map((item: IMetadataOptionAdvice, index: number) => {
                                                    return (<option key={"livelloNotifica" + index} value={item.nome}>{item.descrizione}</option>);
                                                })
                                            }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.livelloNotifica}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="TestoValidation">
                                <Form.Label as={Col} lg={2} md={4} sm={12}>Testo</Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <MyRichTextEditor name="testo" value={values.testo} onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="periodoValiditaValidation">
                                <Form.Label as={Col} lg={2} md={4} sm={12}>Periodo validità <Asterisk size={8} className="mb-3" /></Form.Label>
                                <Col lg={2} md={3} sm={12}>
                                    <Form.Control type="date" onChange={handleChange}
                                        name="dataInizioValidita" value={values.dataInizioValidita || ""}
                                        isInvalid={!!touched.dataInizioValidita && !!errors.dataInizioValidita}
                                        placeholder="Inizio" disabled={props.isLoading} />
                                    <Form.Text>Inizio validità</Form.Text>
                                    <Form.Control.Feedback type="invalid">{errors.dataInizioValidita}</Form.Control.Feedback>
                                </Col>
                                <Col lg={2} md={3} sm={12}>
                                    <Form.Control type="date" onChange={handleChange}
                                        name="dataFineValidita" value={values.dataFineValidita || ""}
                                        isInvalid={!!touched.dataFineValidita && !!errors.dataFineValidita}
                                        placeholder="Fine" disabled={props.isLoading} />
                                    <Form.Text>Fine validità</Form.Text>
                                    <Form.Control.Feedback type="invalid">{errors.dataFineValidita}</Form.Control.Feedback>
                                </Col>
                                <Col lg={2} md={2} sm={12}>
                                    <Form.Check type="switch">
                                        <Form.Check.Input disabled={props.isLoading} 
                                            onChange={(e) => setFieldValue('flagOnline', e.target.checked)}
                                            name="flagOnline" defaultChecked={values.flagOnline}
                                            isInvalid={!!touched.flagOnline && !!errors.flagOnline} />
                                        <Form.Check.Label>On-line</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.flagOnline}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="ilFlagAmbitoGlobale">
                                <Form.Label as={Col} lg={2} md={4} sm={12}>Ambito visualizzazione</Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Check type="switch">
                                        <Form.Check.Input disabled={props.isLoading}
                                            onChange={(e) => setFieldValue('flagAmbitoGlobale', e.target.checked)}
                                            name="flagAmbitoGlobale" defaultChecked={values.flagAmbitoGlobale}
                                            isInvalid={!!touched.flagAmbitoGlobale && !!errors.flagAmbitoGlobale} 
                                        />
                                        <Form.Check.Label>globale (tutti gli utenti di piattaforma)</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.flagAmbitoGlobale}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="ilFlagAmbitoOrdini">
                                <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Check type="switch">
                                        <Form.Check.Input disabled={props.isLoading}
                                            onChange={(e) => setFieldValue('flagAmbitoOrdini', e.target.checked)}
                                            name="flagAmbitoOrdini" defaultChecked={values.flagAmbitoOrdini}
                                            isInvalid={!!touched.flagAmbitoOrdini && !!errors.flagAmbitoOrdini} 
                                        />
                                        <Form.Check.Label>ordini</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.flagAmbitoOrdini}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="ilFlagAmbitoEntiTerzi">
                                <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Check type="switch">
                                        <Form.Check.Input disabled={props.isLoading}
                                            onChange={(e) => setFieldValue('flagAmbitoEntiTerzi', e.target.checked)}
                                            name="flagAmbitoEntiTerzi" defaultChecked={values.flagAmbitoEntiTerzi}
                                            isInvalid={!!touched.flagAmbitoEntiTerzi && !!errors.flagAmbitoEntiTerzi} 
                                        />
                                        <Form.Check.Label>enti terzi</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.flagAmbitoEntiTerzi}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="ilFlagAmbitoProfessionisti">
                                <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                <Col lg={6} md={8} sm={12}>
                                    <Form.Check type="switch">
                                        <Form.Check.Input disabled={props.isLoading}
                                            onChange={(e) => setFieldValue('flagAmbitoProfessionisti', e.target.checked)}
                                            name="flagAmbitoProfessionisti" defaultChecked={values.flagAmbitoProfessionisti}
                                            isInvalid={!!touched.flagAmbitoProfessionisti && !!errors.flagAmbitoProfessionisti} 
                                        />
                                        <Form.Check.Label>professionisti</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{errors.flagAmbitoProfessionisti}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Row className="mb-3">
                                <Col>
                                    {
                                        ((!!touched.titolo && !!errors.titolo) || 
                                            (!!touched.livelloNotifica && !!errors.livelloNotifica) || 
                                            (!!touched.dataInizioValidita && !!errors.dataInizioValidita) || 
                                            (!!touched.dataFineValidita && !!errors.dataFineValidita) || 
                                            (!!touched.flagAmbitoOrdini && !!errors.flagAmbitoOrdini) ||
                                            (!!touched.flagAmbitoEntiTerzi && !!errors.flagAmbitoEntiTerzi) ||
                                            (!!touched.flagAmbitoProfessionisti && !!errors.flagAmbitoProfessionisti)
                                            ) ? (
                                            <Alert variant="danger">
                                                <h6>Validazione fallita! Ricontrollare:</h6>
                                                <ul>
                                                    {!!touched.titolo && !!errors.titolo ? (<li>Titolo: {errors.titolo}</li>) : ""}
                                                    {!!touched.livelloNotifica && !!errors.livelloNotifica ? (<li>Livello notifica: {errors.livelloNotifica}</li>) : ""}
                                                    {!!touched.dataInizioValidita && !!errors.dataInizioValidita ? (<li>Data inizio validità: {errors.dataInizioValidita}</li>) : ""}
                                                    {!!touched.dataFineValidita && !!errors.dataFineValidita ? (<li>Data fine validità: {errors.dataFineValidita}</li>) : ""}
                                                    {!!touched.flagAmbitoOrdini && !!errors.flagAmbitoOrdini ? (<li>Ambito ordini: {errors.flagAmbitoOrdini}</li>) : ""}
                                                    {!!touched.flagAmbitoEntiTerzi && !!errors.flagAmbitoEntiTerzi ? (<li>Ambito enti terzi: {errors.flagAmbitoEntiTerzi}</li>) : ""}
                                                    {!!touched.flagAmbitoProfessionisti && !!errors.flagAmbitoProfessionisti ? (<li>Ambito professionisti: {errors.flagAmbitoProfessionisti}</li>) : ""}
                                                </ul>
                                            </Alert>
                                        ) : (
                                            <Fragment />
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Button type="submit" disabled={props.isLoading}>
                                        {props.isLoading ? (
                                            <Fragment>
                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                <span className="ms-1">Attendere</span>
                                                <span>...</span>
                                            </Fragment>
                                        ) : (
                                            <span>Salva</span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )}
        </Fragment>
    );
};

export default AdviceForm;