import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from "react-icons/bs";


export const getStatoIscrizione = (id: string): string => {
    if (id === "iscritto") return "Iscritti";
    if (id === "sospeso") return "Sospesi";
    if (id === "cancellato") return "Cancellati";
    return "";
}

interface IProps {
    defaultSelectedId?: string | null;
    onSelect?: (option: string | null) => void;
}

const StatoIscrizione: FC<IProps> = (props) => {

    const defaultSelected = (): string | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByStatoIscrizione, setFilterByStatoIscrizione] = useState<string | null>(defaultSelected());

    const handleFilterByStatoIscrizione = (option: string | null) => {
        setFilterByStatoIscrizione(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {filterByStatoIscrizione != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByStatoIscrizione == null ? 'Stato iscrizione' : getStatoIscrizione(filterByStatoIscrizione)} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownStatoIscrizioneNone"} disabled={filterByStatoIscrizione == null} onClick={() => handleFilterByStatoIscrizione(null)}>Tutti</Dropdown.Item>
                    <Dropdown.Item key={"dropdownStatoIscrizioneIscritto"} disabled={filterByStatoIscrizione === 'iscritto'} onClick={() => handleFilterByStatoIscrizione('iscritto')}>{getStatoIscrizione('iscritto')}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownStatoIscrizioneSospeso"} disabled={filterByStatoIscrizione === 'sospeso'} onClick={() => handleFilterByStatoIscrizione('sospeso')}>{getStatoIscrizione('sospeso')}</Dropdown.Item>
                    {/* <Dropdown.Item key={"dropdownStatoIscrizioneCancellato"} disabled={filterByStatoIscrizione === 'cancellato'} onClick={() => handleFilterByStatoIscrizione('cancellato')}>{getStatoIscrizione('cancellato')}</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default StatoIscrizione;