import { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Col, FormControl, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ICourseFavoritesEntityRaw } from '../../entities/course.entity';
import { AppDispatch, RootState } from '../../redux/store';
import CourseDetail from '../shared/CourseDetail';
import FavoritesCoursesItem from './FavoritesCoursesItem';
import { InterestCourseState } from '../../enums/subscription-state.enum';
import { getFavorites } from '../../redux/slices/favorites.slice';
import StatusFavorites from '../shared/dropdown/course/StatusFavorites';
import { IMetadataOption } from '../../entities/metadata.entity';
import Sorting from '../shared/dropdown/list/Sorting';
import { SharedFavoritesListOrderBy } from '../../utils/course-default';
import { IUser } from '../../entities/user.entity';
import { IPutUnsubscribeCourseThunkPayload, putUnsubscribeCourse } from '../../redux/slices/course.slice';

const FavoritesCourses: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    
    const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);
    
    const [countRefresh, setCountRefresh] = useState<number>(0);
    const [idCorsoSelected, setIdCorsoSelected] = useState<number>(0);
    const [isLoading, setLoading] = useState(true);

    const [filterByFavoriteStatus, setFilterByFavoriteStatus] = useState<InterestCourseState | null>(null);
    const [filterByFreeText, setFilterByFreeText] = useState<string | null>("");
    const [filterSortOrderBy, setFilterSortOrderBy] = useState<number | null>(SharedFavoritesListOrderBy[1].id);
    const [filterSortOrderType, setFilterSortOrderType] = useState<number>(1);

    const favorites: Array<ICourseFavoritesEntityRaw> = useSelector((s: RootState) => s.favorites.value.filter(fstatus => { 
        if (filterByFavoriteStatus == null) {
            return true;
        } else {
            return fstatus.statoInteresse == filterByFavoriteStatus;
        }
    }).filter(ftext => {
        return ftext.titolo.toLowerCase().includes(filterByFreeText? filterByFreeText.toLowerCase() : "");
    }).sort((a,b) => {
        if (filterSortOrderBy != null && filterSortOrderBy === 2) {
            if (a.statoInteresse.toString() > b.statoInteresse.toString()) {
                return -1 * filterSortOrderType;
            } else if (a.statoInteresse.toString() == b.statoInteresse.toString()) {
                return 0;
            } else {
                return 1 * filterSortOrderType;
            }
        } else {
            return 0;
        }
    }));
    
    const handleFreeTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterByFreeText(event.target.value);
    };

    const handleClickSortOrderBy = (option: IMetadataOption[] | null) => {
        setFilterSortOrderBy((option == null || option?.length == 0) ? null : option[0].id);
    };

    const handleClickSortOrderType = (option: boolean) => {
        setFilterSortOrderType(option ? -1 : 1);
    };

    const handleSatusFavoritesClick = (option: InterestCourseState | null) => {
        setFilterByFavoriteStatus(option);
    };
 
    useEffect(() => {
        setLoading(true);
        dispatch(getFavorites({}))
            .unwrap()
            .finally(() => setLoading(false));
    }, [dispatch, countRefresh]);

    const [showCourseDetailModal, setShowCourseDetailModal] = useState(false);
    const handleOpenCourseDetailModal = () => setShowCourseDetailModal(true);
    const handleCloseCourseDetailModal = () => {
        setShowCourseDetailModal(false);
        setIdCorsoSelected(0);
    }

    const handleClickFavoriteItem = (idCorso: number) => {
        setIdCorsoSelected(idCorso);
        handleOpenCourseDetailModal();
    };

    const handleUnsubscribe = (idCorso: number) => {
        if (user != null) {
            setLoading(true);
            dispatch(putUnsubscribeCourse({ idCorso: idCorso, codiceFiscale: user.fiscalCode } as IPutUnsubscribeCourseThunkPayload))
                .unwrap()
                .finally(() => setCountRefresh(countRefresh + 1));
        }
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <section className="d-flex my-2">
                        <div className="flex-grow-1">
                            <InputGroup>
                                <FormControl placeholder="Cerca" onChange={handleFreeTextChange} />
                                <InputGroup.Text><Search /></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="flex-shrink-1 d-flex">
                            <InputGroup className="ms-2">
                                <StatusFavorites onSelect={handleSatusFavoritesClick} />
                                <Sorting initSortingOptions={SharedFavoritesListOrderBy} onSelectOrderBy={handleClickSortOrderBy} onSelectOrderType={handleClickSortOrderType} defaultOrderBy={[SharedFavoritesListOrderBy[1]]} />
                            </InputGroup>
                        </div>
                    </section>
                </Col>
            </Row>
            <Row>
                <Col>
                    <section className="d-flex my-2">
                        <div className="flex-grow-1 d-flex">
                            <p>{(favorites.length > 0 ? "corsi in elenco: " + favorites.length : "0 corsi TOTALI")}</p>
                        </div>
                        <div className="flex-shrink-1 d-flex">
                            <p><Badge bg="warning">corsi arancione</Badge> necessitano del pagamento (pre-iscrizione); <Badge bg="secondary">corsi in grigio</Badge> sono in lista d'attesa</p>
                        </div>
                    </section>
                </Col>
            </Row>
            <Row>
                <Col>
                    <section className="d-flex flex-column mt-3">
                        {
                            isLoading ? (
                                <p className="text-center">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                    <p><span>Attendere</span><span>...</span></p>
                                </p>
                            ) : (
                                favorites.length ? (
                                    favorites.map((fav: ICourseFavoritesEntityRaw, index: number) => (
                                            <FavoritesCoursesItem favorite={fav} onClickItem={handleClickFavoriteItem} onClickButton={handleUnsubscribe} />
                                        )
                                    )
                                ) : (
                                    <p className="text-center text-dark m-3">Nessun corso nella lista</p>
                                )
                            )
                        }
                    </section>
                </Col>
            </Row>

            <Modal size="lg" show={showCourseDetailModal} onHide={handleCloseCourseDetailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Dettaglio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {   
                        idCorsoSelected > 0 ? (
                            <CourseDetail idCorso={idCorsoSelected} />
                        ) : (
                            <Fragment />
                        )
                    }
                </Modal.Body>
            </Modal>           
        </Fragment>
    );
};

export default FavoritesCourses;