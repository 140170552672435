import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import { INotifyEntity } from "../../entities/notify.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import NotificationAPI from "../../api/notification.api";

export interface IGetNotificationsThunkPayload {
}

export interface IGetNotificationsFulfilledPayload {
  notifications: Array<INotifyEntity>;
}

export const getNotifications = createAsyncThunk(
  'notifications/get',
  async (thunkPayload: IGetNotificationsThunkPayload, thunkAPI) => {
    try {
      const response = await NotificationAPI.getNotifications(thunkPayload);
      const successPayload = response.data as unknown as Array<INotifyEntity>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalCoursesList = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        notifications: successPayload,
        totalList: countTotalCoursesList,
      } as unknown as IGetNotificationsFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface INotificationsState {
  error: IRejectResponse | null; //error
  value: Array<INotifyEntity>; //favoriti
  // totalList: number;
}

const initialState: INotificationsState = {
  error: null,
  value: [],
  // totalList: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetNotificationsFulfilledPayload;
      state.value = payload.notifications;
      // state.totalList = payload.totalList;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = [];
    });
  },
});

export default notificationsSlice.reducer;