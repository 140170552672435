import { FC, Fragment } from 'react';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const SupervisorDashboard: FC = () => {
  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto coordinatore!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
    </Fragment>
  );
};

export default SupervisorDashboard;
