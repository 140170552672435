import { FC, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import AttachmentList from '../../components/shared/AttachmentList';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';

const ManagerAttachments: FC = () => {
  const dispatch = useDispatch<AppDispatch>();


  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    //dispatch(getMetadataTeacher({}));
    //dispatch(getMetadataCertification({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Allegati" />
      <PageSubtitle content="Lista completa allegati" />
      <PageParagraph content="" />
      <AttachmentList readOnly={true} />
    </Fragment>
  );
};

export default ManagerAttachments;