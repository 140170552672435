import React, { FC, Fragment, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../../entities/company.entity';
import { RootState } from '../../../redux/store';

export const getEnteTerzo = (idAzienda: number, list: Array<ICompanyEntity>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getEnteTerzoItem = (idAzienda: number, list: Array<ICompanyEntity>): ICompanyEntity | null => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: ICompanyEntity | null) => void;
}

const EnteTerzo: FC<IProps> = (props) => {

  const partners: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.partners);

  const defaultSelectedItem = (): ICompanyEntity | null => {
    if (props?.defaultSelectedId) {
      return getEnteTerzoItem(props.defaultSelectedId, partners);
    }
    return null;
  };

  const [filterByEnteTerzo, setFilterByEnteTerzo] = useState<ICompanyEntity | null>(defaultSelectedItem());
  const [searchItemsByText, setSearchItemsByText] = useState<string>('');

  const handleFilterByEnteTerzo = (item: ICompanyEntity | null) => {
    setFilterByEnteTerzo(item);
    if (props.onSelect) {
      props.onSelect(item);
    }
  };

  const handleSearchItemsByTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchItemsByText(text);
  };

  return (
    <Fragment>
      <Dropdown className="mx-2 my-2">
        <Dropdown.Toggle variant="outline-primary">
          {filterByEnteTerzo != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`${filterByEnteTerzo ? filterByEnteTerzo.nome.substring(0, 10) : 'Ente terzo'} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <FormControl placeholder="Cerca" aria-label="Cerca" onChange={handleSearchItemsByTextChange} aria-describedby="basic-search-option-item" />
          <Dropdown.Item key={"dropdownEnteTerzoNone"} disabled={filterByEnteTerzo === null} onClick={() => handleFilterByEnteTerzo(null)}>Tutti</Dropdown.Item>
          {
            partners.length ? (
              partners.filter((itm: ICompanyEntity) => {
                if (searchItemsByText.length === 0) return true;
                return itm.nome.toLowerCase().startsWith(searchItemsByText.toLowerCase());
              }).map((item: ICompanyEntity, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownEnteTerzo" + item.idAzienda} disabled={filterByEnteTerzo !== null && item.idAzienda === filterByEnteTerzo.idAzienda} onClick={() => handleFilterByEnteTerzo(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default EnteTerzo;