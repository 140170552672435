import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMetadataOption } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";

export const getStatoCertificazione = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getStatoCertificazioneOption = (id: number, list: Array<IMetadataOption>): IMetadataOption | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: IMetadataOption | null) => void;
}

const StatoCertificazione: FC<IProps> = (props) => {

  const staticertificazione: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);


  const defaultSelectedOption = (): IMetadataOption | null => {
    if (props?.defaultSelectedId) {
      return getStatoCertificazioneOption(props.defaultSelectedId, staticertificazione);
    }
    return null;
  };

  const [filterByStatoCertificazione, setFilterByStatoCertificazione] = useState<IMetadataOption | null>(defaultSelectedOption());

  const handleFilterByStatoCertificazione = (option: IMetadataOption | null) => {
    setFilterByStatoCertificazione(option);
    if (props?.onSelect) {
      props.onSelect(option);
    }

  };

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary">
          {filterByStatoCertificazione != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Stato ${filterByStatoCertificazione ? filterByStatoCertificazione.nome : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"dropdownStatoCertificazioneNone"} disabled={filterByStatoCertificazione === null} onClick={() => handleFilterByStatoCertificazione(null)}>Tutti</Dropdown.Item>
          {
            staticertificazione.length ? (
              staticertificazione.map((item: IMetadataOption, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownStatoCertificazione" + item.id} disabled={filterByStatoCertificazione !== null && item.id === filterByStatoCertificazione.id} onClick={() => handleFilterByStatoCertificazione(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default StatoCertificazione;