import { FC, useState } from 'react';
import PrivacyPolicyView from '../../components/shared/PrivacyPolicyView';
import packageJson from '../../../package.json';
import { DOMAIN } from '../../api/api.config';

interface IProps {
    variant?: string;
}

const Footer: FC<IProps> = (props) => {

    const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

    const handleClose = () => setShowPrivacy(false);

    const handleCurrentEnv = () : string => {
        if(DOMAIN.includes("localhost")) {
            return "dev";
        }
        else if(DOMAIN.includes("collaudo")) {
            return "coll";
        }
        else if(DOMAIN.includes("certificazione")) {
            return "cert";
        }
        return "prod";
    }

    return (
        <div className={`fixed-bottom small text-light text-center bg-${props?.variant ? props.variant : 'secondary'}`}>
            <small><small>Copyright 2022 © Tutti i diritti riservati. Creato da Didanet Srl &mdash; <span title={`${handleCurrentEnv()}.${packageJson.version}.${packageJson.versionTag}`}>v{packageJson.version}</span> &mdash; <a href={void (0)} onClick={() => setShowPrivacy(true)} title="Privacy e policy" className="text-light c-pointer">Privacy e policy</a> - <a href="https://awn.it" target="_blank" rel="noreferrer" className="text-light" title="vai al sito awn.it">awn.it</a></small></small>
            <PrivacyPolicyView show={showPrivacy} handleClose={handleClose} />
        </div>
    );
};

export default Footer;