import { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import ProfessionalCourses from '../../components/shared/ProfessionalCourses';
import { IUser } from '../../entities/user.entity';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import { b64toBlob } from '../../utils/attachment-parser';
import { Button, Spinner } from 'react-bootstrap';
import { getMyCoursesExport, ICoursesExportFulfilledPayload } from '../../redux/slices/courses.slice';

const UserMyCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value ? s.auth.user.value : null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleExport = () => {
    setIsLoading(true);
    if (user!=null){
      dispatch(getMyCoursesExport({ codice_fiscale: user.fiscalCode})) /* da cambiare endpoint */
        .unwrap()
        .then((e) => {
          const payload = e as unknown as ICoursesExportFulfilledPayload;
          const div = document.getElementById("divAdminExportMieiCorsiCSV");
          if (div) {
            b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
              const url = window.URL.createObjectURL(blobresult);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
              div.appendChild(link);
              link.click();
              link.remove();
            });
          }
        })
        .catch((e) => {
          // console.log("KO", e);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Corsi">
        <a href="/assets/tutorial/U_MieiCorsi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista corsi a cui sei iscritto">
        <Button onClick={handleExport}>
          {isLoading ? (
            <Fragment>
              <Spinner as="span" animation="border" size="sm" role="status" />
              <span className="ms-1">Attendere</span>
              <span>...</span>
            </Fragment>
          ) : (
            <span>Esporta in .CSV</span>
          )}
        </Button>
        <div id="divAdminExportMieiCorsiCSV" className="d-none"></div> 
      </PageSubtitle>
      <PageParagraph content="" />
      {
        user ? (
          <ProfessionalCourses codiceFiscale={user.fiscalCode} disableButtonExportPdf={false} disableButtonMoodle={false} />
        ) : (
          <Fragment />
        )
      }
    </Fragment>
  );
};

export default UserMyCourses;