import { FC, Fragment } from 'react';
import { IUser } from '../entities/user.entity';
import ExpireAlert from './parts/ExpireAlert';
import Footer from './parts/Footer';
import Header from './parts/Header';
import SidebarUser from './parts/SidebarUser';
import MaintenanceAlert from './parts/MaintenanceAlert';

interface IProp {
  expire: string | null;
  user: IUser;
  isMaintenanceMode: boolean;
}

const LayoutUser: FC<IProp> = (props) => {
  return (
    <Fragment>
      <Header  user={props.user} />
      <SidebarUser user={props.user} />
      <main>{props.children}</main>
      <ExpireAlert expire={props.expire} />
      <MaintenanceAlert isMaintenanceMode={props.isMaintenanceMode} />
      <Footer />
    </Fragment>
  );
};

export default LayoutUser;
