import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Modal, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IRejectResponse } from '../../api/api.interface';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseRaw } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCoursesPublished, ICoursesPublishedFilter, ICoursesPublishedPagination, ICoursesPublishedSorting, ICoursesPublishedThunkPayload } from '../../redux/slices/courses.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { defaultFilter, setListFilter } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { AdminCourseListOrderBy } from '../../utils/course-default';
import { parseRawToICourse } from '../../utils/course-parser';
import CourseDetail from './CourseDetail';
import CourseListItem from './CourseListItem';
import AreaFormativa from './dropdown/course/AreaFormativa';
import Erogazione from './dropdown/course/Erogazione';
import EventoGratuito from './dropdown/course/EventoGratuito';
import Organizzatore from './dropdown/course/Organizzatore';
import Tipologia from './dropdown/course/Tipologia';
import EnteTerzo from './dropdown/EnteTerzo';
import PaginationLimit from './dropdown/list/PaginationLimit';
import Sorting from './dropdown/list/Sorting';
import OrdineProvinciale from './dropdown/OrdineProvinciale';
import PageSelect from '../shared/dropdown/list/PageSelect';

interface IPagination extends ICoursesPublishedPagination { }
interface IFilter extends ICoursesPublishedFilter { }
interface ISorting extends ICoursesPublishedSorting { }

const CourseList: FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const filtri: IFilter = useSelector((s: RootState) => s.ui.list.filter);
  const error = useSelector((s: RootState) => s.courses.error as IRejectResponse);
  const courses: Array<ICourseRaw> = useSelector((s: RootState) => s.courses.list);
  const countTotalCoursesList: number = useSelector((s: RootState) => s.courses.totalList);

  const [isLoading, setLoading] = useState(true);

  const [showCourseDetailModal, setShowCourseDetailModal] = useState(false);
  const handleOpenCourseDetailModal = () => setShowCourseDetailModal(true);
  const handleCloseCourseDetailModal = () => setShowCourseDetailModal(false);

  //const [filterByCompany, setFilterByCompany] = useState<ICompanyEntity | null>(null);
  //const [filterByPartner, setFilterByPartner] = useState<ICompanyEntity | null>(null);
  //const [filterByErogazione, setFilterByErogazione] = useState<IMetadataOption | null>(null);
  //const [filterByTipologia, setFilterByTipologia] = useState<IMetadataOption | null>(null);
  //const [filterByCategoria, setFilterByCategoria] = useState<IMetadataOption | null>(null);
  //const [filterByText, setFilterByText] = useState<string | null>(null);
  const [filter, setFilter] = useState<IFilter>(filtri);

  const [pagination, setPagination] = useState<IPagination>({ page: 1, limit: 25 });

  const [orderBy, setOrderBy] = useState<Array<IMetadataOption>>([AdminCourseListOrderBy[0]]);
  const [sorting, setSorting] = useState<ISorting>({ orderby: [AdminCourseListOrderBy[0].nome], ordertype: true });

  const [idCorsoSelected, setIdCorsoSelected] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    dispatch(setListFilter(filter));
    dispatch(getCoursesPublished({ filter: filter, pagination: pagination, sorting: sorting } as ICoursesPublishedThunkPayload))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch, filter, pagination, sorting]);

  useEffect(() => {
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, []);

  const handleFilterByText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    //setFilterByText(text);
    setFilter({ ...filter, text: text });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByErogazione = (option: IMetadataOption | null) => {
    //setFilterByErogazione(option);
    setFilter({ ...filter, erogazione: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTipologia = (option: IMetadataOption | null) => {
    //setFilterByTipologia(option);
    setFilter({ ...filter, tipologia: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByCategoria = (option: IMetadataOption | null) => {
    //setFilterByCategoria(option);
    setFilter({ ...filter, categoria: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByCompany = (company: ICompanyEntity | null) => {
    //setFilterByCompany(company);
    setFilter({ ...filter, company: company ? company.idAzienda : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByPartner = (partner: ICompanyEntity | null) => {
    //setFilterByPartner(partner);
    setFilter({ ...filter, partner: partner ? partner.idAzienda : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByOrganizzatore = (org: number | null) => {
    setFilter({ ...filter, tipoAzienda: org ? org : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByEventoGratuito = (gratis: number | null) => {
    setFilter({ ...filter, eventoGratuito: gratis ? gratis : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
    setOrderBy(ordby ? ordby : []);
    setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderType = (type: boolean) => {
    setSorting({ ...sorting, ordertype: type });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handlePaginationLoad = (pag: number) => {
    setPagination({ ...pagination, page: pag });
  };
  
  const handlePaginationLoadFirst = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const handlePaginationLoadLast = () => {
    setPagination({ ...pagination, page: Math.ceil(countTotalCoursesList / pagination.limit) });
  };

  const handlePaginationLimit = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handlePaginationLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePaginationLoadMinus = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleClickCourseItemDetailPage = (idCorso: number) => {
    setIdCorsoSelected(idCorso);
    let route = AuthenticatedRoutesUser.Course.toString();
    route = route.replace(':id', idCorso.toString());
    navigate(route);
  };

  const [showFilterList, setShowFilterList] = useState(false);

  const handleClose = () => {
    setShowFilterList(false);
  };
  const handleShowFilterList = () => setShowFilterList(true);

  const handleClear = () => {
    setFilter(defaultFilter);
    setShowFilterList(false);
  };

  return (
    <Fragment>
      <Row className="mb-2">
        <Col>
          <InputGroup className="d-flex justify-content-start">
            <FormControl placeholder="Cerca per titolo o codice corso" value={filtri.text} onChange={handleFilterByText} />
            <InputGroup.Text><Search /></InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="d-flex justify-content-between">
          <Button variant="outline-primary" onClick={handleShowFilterList}>
          <span className="px-1">Ricerca per FILTRI</span>
            { 
              filtri.text.length > 0 ? (
                <span className="badge rounded-pill px-2 py-1 bg-secondary">non attiva</span>
              ) : (
                filtri.tipoAzienda > 0 || filtri.eventoGratuito > 0 || filtri.categoria > 0 || filtri.company > 0 || filtri.erogazione > 0 || filtri.partner > 0 || filtri.tipologia > 0 ? (
                  <span className="badge rounded-pill px-2 py-1 bg-success">attiva</span>
                ) : (<span />)
              )
            }
          </Button>
          <div className="px-1 mt-1">
          {(courses.length > 0 ? "corsi " + (1 + pagination.limit * (pagination.page-1)) + "-" + (courses.length + pagination.limit * (pagination.page-1)) + 
                                (courses.length >= countTotalCoursesList ? "" : " di " + countTotalCoursesList) : "0 corsi TOTALI")}
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <InputGroup className="d-flex justify-content-start">
            <Sorting
              initSortingOptions={AdminCourseListOrderBy}
              onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType}
              defaultOrderBy={[AdminCourseListOrderBy[0]]} defaultOrderType={true} />
            <PaginationLimit enableUnlimit={false} onSelect={handlePaginationLimit} />
            <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCoursesList / pagination.limit)} onSelect={handlePaginationLoad} />
          </InputGroup>
        </Col>
      </Row>

      {
        error ? (
          <Row>
            <Col className="text-center">
              <p className="text-danger">{error.error}</p>
            </Col>
          </Row>
        ) : (
          courses.length ? (
            <section className={`d-flex flex-wrap align-content-stretch`}>
              {courses.map((course: ICourseRaw, index: number) => (
                <div key={index + '_' + course.id} className="m-1 d-flex">
                  <CourseListItem course={parseRawToICourse(course)} isGridView={true} onClick={handleClickCourseItemDetailPage} />
                </div>
              ))}
            </section>
          ) : (
            <Fragment>
              {(pagination.page === 1) ? (
                <Row className="py-3">
                  <Col className="text-center">
                    <p className="text-dark">Nessun corso presente</p>
                  </Col>
                </Row>
              ) : (
                <Row className="py-3">
                  <Col className="text-center">
                    <p className="text-dark">Non ci sono altri corsi da consultare</p>
                  </Col>
                </Row>
              )}
            </Fragment>
          )
        )
      }

      {
        isLoading ? (
          <Row>
            <Col className="text-center">
              <Spinner as="span" animation="border" size="sm" role="status" />
              <p>
                <span>Attendere</span>
                <span>...</span>
              </p>
            </Col>
          </Row>
        ) : (
          <Row className="py-2">
            <Col className="text-center">
            <Button variant="primary" onClick={handlePaginationLoadFirst} className="mx-2" disabled={isLoading || (pagination.page - 1) === 0}>
              Pagina iniziale
            </Button>
              <Button variant="primary" onClick={handlePaginationLoadMinus} className="mx-2" disabled={(pagination.page - 1) === 0}>
                Precedente
              </Button>
              <span>pagina {pagination.page}</span>
              <Button variant="primary" onClick={handlePaginationLoadMore} className="mx-2" disabled={pagination.limit !== courses.length}>
                Successiva
              </Button>
              <Button variant="primary" onClick={handlePaginationLoadLast} className="mx-2" disabled={isLoading || pagination.limit != courses.length}>
                Pagina finale
            </Button>
            </Col>
          </Row>
        )
      }

      <Offcanvas backdrop="static" placement="end" show={showFilterList} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ricerca per filtri</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div><Organizzatore defaultSelectedId={filtri.tipoAzienda} onSelect={handleFilterByOrganizzatore} /></div>
          <div className={`${filtri.tipoAzienda === 1 ? 'invisible' : 'visible'}`}><OrdineProvinciale defaultSelectedId={filtri.company} onSelect={handleFilterByCompany} /></div>
          <div className={`${filtri.tipoAzienda === 2 ? 'invisible' : 'visible'}`}><EnteTerzo defaultSelectedId={filtri.partner} onSelect={handleFilterByPartner} /></div>
          <div><EventoGratuito defaultSelectedId={filtri.eventoGratuito} onSelect={handleFilterByEventoGratuito} /></div>
          <div><Erogazione defaultSelectedId={filtri.erogazione} onSelect={handleFilterByErogazione} /></div>
          <div><Tipologia defaultSelectedId={filtri.tipologia} onSelect={handleFilterByTipologia} /></div>
          <div><AreaFormativa defaultSelectedId={filtri.categoria} onSelect={handleFilterByCategoria} /></div>
          <Button className="my-2 w-50 mx-1" onClick={handleClose}>Applica</Button>
          <Button className="my-2 w-25 mx-1" variant="outline-danger" onClick={handleClear}>Reset</Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal size="lg" show={showCourseDetailModal} onHide={handleCloseCourseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dettaglio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {idCorsoSelected ? (
            <CourseDetail idCorso={idCorsoSelected} />
          ) : (
            <Fragment></Fragment>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CourseList;