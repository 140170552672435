import { FC, Fragment, useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { ICompanyEntity } from '../../entities/company.entity';
import { UserRole } from '../../enums/user-roles.enum';
import EnteTerzo from '../shared/dropdown/EnteTerzo';
import OrdineProvinciale from '../shared/dropdown/OrdineProvinciale';
import SupportOtpBuilder from './SupportOtpBuilder';

const SupportOtp: FC = () => {
    const [role, setRole] = useState<UserRole | null>(null);
    const [azienda, setAzienda] = useState<ICompanyEntity | null>(null);

    const handleCompanyClick = (company: ICompanyEntity | null) => {
        setAzienda(company);
        setRole(UserRole.Admin);
    };

    const handlePartnerClick = (company: ICompanyEntity | null) => {
        setAzienda(company);
        setRole(UserRole.Partner);
    };

    const handleClear = () => {
        setRole(null);
        setAzienda(null);
    };

    return (
        <Container as="section" fluid className="m-0">
            <Row>
                <Col>
                    {
                        azienda == null || role == null ? (
                            <Tabs defaultActiveKey="ordini">
                                <Tab eventKey="ordini" title="Ordini" className="bg-light p-3">
                                    <OrdineProvinciale onSelect={handleCompanyClick} />
                                </Tab>
                                <Tab eventKey="enti" title="Enti Terzi" className="bg-light p-3">
                                    <EnteTerzo onSelect={handlePartnerClick} />
                                </Tab>
                            </Tabs>
                        ) : (
                            <Fragment>
                                <p className="bg-light m-0"><Button onClick={handleClear} variant="link" className="m-1">Seleziona un altro ordine/ente</Button></p>
                                <SupportOtpBuilder idAzienda={azienda.idAzienda} codiceFiscale={azienda?.siglaAzienda ? azienda.siglaAzienda : ''} role={role == null ? 0 : Number(role.toString())} />
                            </Fragment>
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default SupportOtp;