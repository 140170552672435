import { format } from 'date-fns';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { Search, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { ICompanyEntity } from "../../../entities/company.entity";
import { IProfessionalEntity } from "../../../entities/professional.entity";
import { getProfessionalSearch, IProfessionalSearchThunkPayload } from "../../../redux/slices/professionals.slice";
import { AppDispatch, RootState } from "../../../redux/store";
import { getOrdineProvinciale } from "../dropdown/OrdineProvinciale";

interface IProps {
    onSelect: (professional: IProfessionalEntity) => void;
    searchInActiveProfessionalList: number; // 0 = tutti; 1 = solo attivi; 2 = solo disabilitati
    disableManualInput: boolean;
};

const SearchProfessional: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [prof, setProf] = useState<IProfessionalEntity>({ 
        codiceFiscale: "", 
        nome: "", 
        cognome: "",
        dataIscrizione: (new Date()).toISOString(),
        dataNascita: "",
        dataPrimaIscrizione: "",
        email: "",
        idProfessionista: 0,
        note: "",
        ordine: 0,
        pec: "",
        peo: "",
        settore: "",
        sezione: "",
        status: "",
        titolo: "",
    } as IProfessionalEntity);

    const professionals: Array<IProfessionalEntity> = useSelector((s: RootState) => s.professionals.searchValue);
    const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
    
    const [filter, setFilter] = useState<IProfessionalSearchThunkPayload>({ text: '', activetoday: props.searchInActiveProfessionalList });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    const columns: TableColumn<IProfessionalEntity>[] = [
        {
            cell: (row, index, column, id) => {
                return (<Button variant="primary" size="sm" className="my-1" onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    props.onSelect(row);
                }} >seleziona</Button>);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Professionista',
            selector: (row: IProfessionalEntity) => (row.cognome ? row.cognome.toUpperCase() : '') + ' ' + row.nome,
        },
        {
            name: 'CF',
            selector: (row: IProfessionalEntity) => row.codiceFiscale,
        },
        {
            id: 'ordine',
            name: 'Ordine',
            selector: (row: IProfessionalEntity) => getOrdineProvinciale(row.ordine, companiesOrders),
        },
    ] as TableColumn<IProfessionalEntity>[];

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProfessionalSearch(filter))
            .unwrap()
            .finally(() => setIsLoading(false));
    }, [dispatch, filter]);

    const handleFilterByText = () => {
        if(text != null && text.length > 2) {
            setFilter({ ...filter, text: text.toUpperCase() });
        } else {
            setFilter({ ...filter, text: "" });
        }
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setText(text);
    };

    const handleClearFilterByText = () => {
        setText('');
        setFilter({ ...filter, text: '' });
    };

    return (
        <Fragment>
            <Container fluid as="section" className="m-0 p-0">
                <Row className="p-2 bg-light">
                    <Col>
                        <InputGroup>
                            <FormControl placeholder="Cerca digitando: codice fiscale, cognome e/o nome, email e premi INVIO..." id="idTextSearch"
                                onChange={handleChangeText}
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleFilterByText();
                                    }
                                }} />
                            <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        element.value = '';
                                    }
                                    handleClearFilterByText();
                                }}><X size={20} /></InputGroup.Text>
                            <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    handleFilterByText();
                                }}><Search size={20} /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable columns={columns} data={professionals}
                            responsive striped dense
                            noDataComponent={(
                                <p className="text-center">
                                    {
                                        filter.text.length < 3 ? (<p>Digitare almeno 3 caratteri nella casella di ricerca.</p>) : (
                                            <span>
                                                <p>Nessun risultato</p>
                                                {
                                                    props.disableManualInput === false ? (
                                                        <Button variant="primary" size="sm" className={`${props.disableManualInput === false && text.includes(" ") ? 'd-none': ''}`} onClick={(e: React.MouseEvent) => {
                                                            e.preventDefault();
                                                            const professional = {
                                                                ...prof,
                                                                codiceFiscale: filter.text.toUpperCase(),
                                                            } as IProfessionalEntity;
                                                            props.onSelect(professional);
                                                        }}>seleziona ugualmente <b>{filter.text.toUpperCase()}</b> come codice fiscale</Button>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </span>
                                        )
                                    }
                                </p>
                            )}
                            progressPending={isLoading} 
                            pagination />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default SearchProfessional;