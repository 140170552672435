import { FC, useState } from "react";
import { IAdvice } from "../../entities/advice.entity";
import { Alert } from "react-bootstrap";
import { TheAdviceLevels } from "../../utils/advice-default";

interface IProps {
    advice: IAdvice;
}

const AdviceItem: FC<IProps> = (props) => {

    const handleVariant = (livelloNotifica: string) : string => {
      const adv = TheAdviceLevels.find(item => item.nome == livelloNotifica);
      return adv ? adv.variant : "light";
    }

    const [variant, ] = useState<string>(handleVariant(props.advice.livelloNotifica));

    return (
      <Alert key={"adviceIdx" + props.advice.id} className="mb-3 text-dark border-1 border-dark" variant={variant}>
        <Alert.Heading>{props.advice.titolo}</Alert.Heading>
          { props.advice.testo && props.advice.testo.length > 0 && <hr />}
          { props.advice.testo && props.advice.testo.length > 0 && <p dangerouslySetInnerHTML={{ __html: props.advice.testo }} /> }
      </Alert>
    );
};

export default AdviceItem;