import { FC, Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PageParagraph from '../components/PageParagraph';
import PageSubtitle from '../components/PageSubtitle';
import PageTitle from '../components/PageTitle';
import ProfileForm from '../components/ProfileForm';
import Notifications from '../components/user/Notifications';
import { IUser } from '../entities/user.entity';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { UserRole } from '../enums/user-roles.enum';
import AdvicesReadonly from '../components/shared/AdvicesReadonly';

const Profile: FC = () => {
  
  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value || null);

  return (
    <Fragment>
      <PageTitle content="Profilo" />
      <PageSubtitle content="" />
      <PageParagraph content="" />
      { user && (user.role === UserRole.User || user.role === UserRole.Guest) && <AdvicesReadonly /> }
      { user && (user.role === UserRole.User || user.role === UserRole.Guest) && <Notifications /> }
      { user && (user.role === UserRole.Apia || user.role === UserRole.Apib) && <AdvicesReadonly /> }
      { user && (user.role === UserRole.Apia || user.role === UserRole.Apib) && <Notifications /> }
      <Container fluid as="section">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Body>
                <ProfileForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Profile;
