import { FC, Fragment, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import TeacherForm from '../../components/admin/TeacherForm';
import TeacherList from '../../components/admin/TeacherList';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ITeacherEntity } from '../../entities/teacher.entity';
import { postTeacher, putTeacher } from '../../redux/slices/teacher.slice';
import { getTeachers } from '../../redux/slices/teachers.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface ITeacherModalProps {
  edit: boolean;
  teacher?: ITeacherEntity;
}

const AdminTeachers: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const error = useSelector((s: RootState) => s.teacher.error as IRejectResponse);

  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [teacherModalProps, setTeacherModalProps] = useState<ITeacherModalProps | null>(null);
  const [teacherModalLoading, setTeacherModalLoading] = useState<boolean>(false);

  const handleOpenTeacherModal = (teacher?: ITeacherEntity) => {
    setTeacherModalProps({
      edit: !!teacher,
      teacher,
    });
    setShowTeacherModal(true);
  };

  const handleCloseTeacherModal = () => {
    setTeacherModalProps(null);
    setShowTeacherModal(false);
  };

  const handleSubmit = (teacher: ITeacherEntity) => {
    setTeacherModalLoading(true);

    if (teacherModalProps?.edit) {
      dispatch(putTeacher({ teacher }))
        .unwrap()
        .then(() => dispatch(getTeachers({})))
        .catch(() => console.error(error))
        .finally(() => setTeacherModalLoading(false));
    } else {
      dispatch(postTeacher({ teacher }))
        .unwrap()
        .then(() => dispatch(getTeachers({})))
        .catch(() => console.error(error))
        .finally(() => setTeacherModalLoading(false));
    }
  };

  return (
    <Fragment>
      <PageTitle content="Docenti" />
      <PageSubtitle content="Lista completa">
        <Button onClick={() => handleOpenTeacherModal()}>Nuovo docente</Button>
      </PageSubtitle>
      <PageParagraph content="" />
      <Container fluid as="section">
        <TeacherList onEdit={handleOpenTeacherModal} />
      </Container>
      {teacherModalProps && (
        <Modal centered show={showTeacherModal} onHide={handleCloseTeacherModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Inserisci docente</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TeacherForm
              loading={teacherModalLoading}
              edit={teacherModalProps.edit}
              teacher={teacherModalProps.teacher}
              onSubmit={handleSubmit}
            />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
};

export default AdminTeachers;
