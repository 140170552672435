import { FC, Fragment, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { AuthenticatedRoutesManager } from '../../enums/routes.enum';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const ManagerDashboard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  
  useEffect(() => {
    //dispatch(getCompany({}));
    //dispatch(getCompanies({}));
    //dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    //dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    //dispatch(getMetadataTeacher({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const handleClickGoToAttachments = () => {
    let route = null;
    route = AuthenticatedRoutesManager.Attachments.toString();
    navigate(route);
  };

  const handleClickGoToListProfessional = () => {
    let route = null;
    route = AuthenticatedRoutesManager.ListProfessionals.toString();
    navigate(route);
  };

  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto manager!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
      <Container fluid as="section">
        <Row className="d-flex align-items-stretch row my-5">
          <Col xs={6} md={6} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/list.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToAttachments}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-1 c-pointer" onClick={handleClickGoToAttachments}>Allegati</h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={6} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/users.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToListProfessional}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-2 c-pointer" onClick={handleClickGoToListProfessional}>Elenco iscritti</h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ManagerDashboard;
