import { FC, Fragment, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { IPostCoursesImportFulfilledPayload, IPostCoursesImportThunkPayload, postCoursesImport } from '../../redux/slices/courses.slice';
import { AppDispatch } from '../../redux/store';

interface IForm extends IPostCoursesImportThunkPayload { }

const CourseImportFromImateria: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IForm);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<IRejectResponse>();
    const [total, setTotal] = useState<number>(0);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };

            fileReader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //setHasError(false);
        setLoading(true);

        dispatch(postCoursesImport(fields as IPostCoursesImportThunkPayload))
            .unwrap()
            .then((e) => {
                const res = e as unknown as IPostCoursesImportFulfilledPayload;
                setSubmitted(true);
                setTotal(res.countCourses);
            })
            .catch((e) => {
                setSubmitted(false);
                const err = e as unknown as IRejectResponse;
                setError(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            <Card className="my-3">
                <Card.Body>
                    {submitted ? (
                        <div className="text-center">
                            <CheckCircleFill size={100} className="text-success m-5" />
                            <h3 className="text-success">Operazione completata!</h3>
                            <p>Inseriti {total} corsi.</p>
                        </div>
                    ) : (
                        <Form noValidate onSubmit={handleSubmit} >
                            <p><b>Si raccomanda di NON modificare il file ottenuto dalla piattaforma di esportazione.</b></p>
                            <Form.Group controlId="formFile" className="mb-3 w-75">
                                <Form.Label>File da importare</Form.Label>
                                <Form.Control type="file" name="file" onChange={handleFileUpload} />
                                <Form.Text className="text-danger">{error?.error ? error.error : ''}</Form.Text>
                            </Form.Group>
                            <Button type="submit" className="w-25" disabled={isLoading}>
                                {isLoading ? (
                                    <Fragment>
                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                        <span className="ms-1">Attendere</span>
                                        <span>...</span>
                                    </Fragment>
                                ) : (
                                    <span>Salva</span>
                                )}
                            </Button>
                        </Form>
                    )}
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default CourseImportFromImateria;