import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import ProfessionalAPI from "../../api/professional.api";
import { IProfessionalEmailEntity } from "../../entities/professional.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IProfessionalsEmailFulfilledPayload {
  professionalsEmail: Array<IProfessionalEmailEntity>;
  countTotalProfessionalsEmail: number;
}

export interface IProfessionalsEmailCountFulfilledPayload {
  inadempienti: number;
  emptyEmails: number;
}

export interface IProfessionalsEmailsFulfilledPayload { 
  professionalsEmail: Array<IProfessionalEmailEntity>;
  countTotalProfessionals: number;
}  

export interface IGetProfessionalsEmailFilter {
  text: string;
  statoIscrizione: string;
}

export interface IGetProfessionalsEmailSorting {
  orderby: Array<string>;
  ordertype: boolean;
}

export interface IGetProfessionalsEmailPagination {
  page: number;
  limit: number;
}

export interface IProfessionalsEmailThunkPayload {
  filter: IGetProfessionalsEmailFilter;
  pagination: IGetProfessionalsEmailPagination;
  sorting: IGetProfessionalsEmailSorting;
}

export interface IProfessionalsEmailSendThunkPayload {
  type: number;
}

export const getProfessionalsEmail = createAsyncThunk(
  'professionals/email/list',
  async (thunkPayload: IProfessionalsEmailThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalsEmail(thunkPayload);
      console.log(response);
      const successPayload = response.data as unknown as Array<IProfessionalEmailEntity>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalProfessionalsEmail = Number(recordHeaders ? recordHeaders : 0);
      const recordEmptyEmailsHeaders = response.headers["x-unconfigured-email-count"];
      const countTotalProfessionalsEmptyEmails = Number(recordEmptyEmailsHeaders ? recordEmptyEmailsHeaders : 0);
      const fulfillValue = {
        professionalsEmail: successPayload,
        countTotalProfessionalsEmail: countTotalProfessionalsEmail,
        countTotalProfessionalsEmailEmpty: countTotalProfessionalsEmptyEmails
      } as IProfessionalsEmailFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getSendEmails = createAsyncThunk(
  'professionals/email/send',
  async (thunkPayload: IProfessionalsEmailSendThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getSendEmails(thunkPayload);
      const successPayload = response.data as unknown as Array<IProfessionalEmailEntity>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalProfessionalsEmail = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        professionalsEmail: successPayload,
        countTotalProfessionalsEmail: countTotalProfessionalsEmail
      } as IProfessionalsEmailFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue); 

    } 
  }
); 

// export interface IProfessionalSearchFulfilledPayload {
//   professionals: Array<IProfessionalEmailEntity>;
// }

// export interface IProfessionalSearchThunkPayload {
//   text: string;
//   activetoday: number;
// }

// export const getProfessionalSearch = createAsyncThunk(
//   'professionals/search/get',
//   async (thunkPayload: IProfessionalSearchThunkPayload, thunkAPI) => {
//     try {
//       const response = await ProfessionalAPI.getProfessionalSearch(thunkPayload);
//       const successPayload = response.data as unknown as Array<IProfessionalEmailEntity>;
//       const fulfillValue = {
//         professionals: successPayload
//       } as IProfessionalSearchFulfilledPayload;
//       return thunkAPI.fulfillWithValue(fulfillValue);
//     } catch (e) {
//       const error = e as AxiosError;
//       if (!error.isAxiosError) throw e;
//       const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
//       return thunkAPI.rejectWithValue(rejectValue);
//     }
//   }
// );

export interface IProfessionalsEmailState {
  error: IRejectResponse | null;
  value: Array<IProfessionalEmailEntity>;
  total: number;
  empty: number;
  searchError: IRejectResponse | null;
  searchValue: Array<IProfessionalEmailEntity>;
}

const initialState: IProfessionalsEmailState = {
  error: null,
  value: new Array<IProfessionalEmailEntity>(),
  total: 0,
  empty: 0,
  searchError: null,
  searchValue: new Array<IProfessionalEmailEntity>(),
};

const emailSlice = createSlice({
  name: "emails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfessionalsEmail.pending, (state) => {      
      state.error = null;
      state.value = new Array<IProfessionalEmailEntity>();
    });
    builder.addCase(getProfessionalsEmail.fulfilled, (state, action) => {
      console.log("getProfessionalsEmail.fulfilled");
      console.log(action.payload);
      const payload = action.payload as unknown as IProfessionalsEmailFulfilledPayload;
      state.error = null;
      state.value = payload.professionalsEmail;
      state.total = payload.countTotalProfessionalsEmail;
    });
    builder.addCase(getProfessionalsEmail.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = new Array<IProfessionalEmailEntity>();
    });

    builder.addCase(getSendEmails.pending, (state) => {
      console.log("getSendEmails.pending");
      state.searchError = null;
      state.searchValue = new Array<IProfessionalEmailEntity>();
    });
    builder.addCase(getSendEmails.fulfilled, (state, action) => {
      console.log("getSendEmails.fulfilled");
      console.log(action.payload);
      const payload = action.payload as unknown as IProfessionalsEmailFulfilledPayload;
      state.searchError = null;
      state.searchValue = payload.professionalsEmail;
    });
    builder.addCase(getSendEmails.rejected, (state, action) => {
      console.log("getSendEmails.rejected");
      const payload = action.payload as unknown as IRejectResponse;
      state.searchError = payload;
      state.searchValue = new Array<IProfessionalEmailEntity>();
    });
  },
});

export default emailSlice.reducer;
