import { Formik } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { IRejectResponse } from '../api/api.interface';
import { IInspectDocumentFulfilledPayload, IInspectDocumentThunkPayload, inspectDocument } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';
import { b64toBlob } from '../utils/attachment-parser';

interface IFormValue extends IInspectDocumentThunkPayload { }

interface IProps {
    token: string;
    codiceFiscale: string;
} 

export const InspectDocumentForm: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<IFormValue>(props);

  const schema = yup.object().shape({
    token: yup.string().required("campo obbligatorio").min(1, "campo non valido"),
    codiceFiscale: yup.string().required("campo obbligatorio").min(16, "campo non valido").max(16, "campo non valido"),
  });

  useEffect(() => {
        setInitialValues(props);
    }, [props]);

  const handleSubmit = (value: IFormValue) => {
    setLoading(true);
    setError('');
    setSubmitted(false);

    dispatch(inspectDocument(value as IInspectDocumentThunkPayload))
      .unwrap()
      .then((e) => {
        setSubmitted(true);
        const payload = e as unknown as IInspectDocumentFulfilledPayload;
        const div = document.getElementById("divDownloadDoc");
        if (div) {
            b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                const url = window.URL.createObjectURL(blobresult);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.attachment.filename);
                div.appendChild(link);
                link.click();
                link.remove();
            });
        }
    })
    .catch((exc) => {
      const err = exc as unknown as IRejectResponse;
      setError(err?.error ? err.error : '');
    })
    .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="py-1">
              <Col className="text-center small">
                <small>
                  Sta per essere generato un nuovo documento per la <b>verifica</b> dell'<b>autenticit&agrave;</b> del documento originale. 
                  <b> Le informazioni presenti nei due documenti devono corrispondere</b> (sono dati archiviati al momento della prima stampa). 
                </small>
              </Col>
            </Row>
            <Row className="py-1">
              <Form.Group as={Col} controlId="token">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="text"
                  name="token"
                  placeholder="Codice documento"
                  disabled={isLoading}
                  value={values.token}
                  onChange={handleChange}
                  isValid={touched.token && !errors.token}
                  isInvalid={!!touched.token && !!errors.token}
                />
                <Form.Control.Feedback type="invalid">{errors.token}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="py-1">
              <Form.Group as={Col} controlId="codiceFiscale">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="text"
                  name="codiceFiscale"
                  placeholder="Codice fiscale"
                  disabled={isLoading}
                  value={values.codiceFiscale}
                  onChange={handleChange}
                  isValid={touched.codiceFiscale && !errors.codiceFiscale}
                  isInvalid={!!touched.codiceFiscale && !!errors.codiceFiscale}
                />
                <Form.Control.Feedback type="invalid">{errors.codiceFiscale}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="py-1">
              <Col className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading || isSubmitted}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">Attendere</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>verifica</span>
                  )}
                </Button>
              </Col>
            </Row>
            {
              error && error.length ? (
                <Row className="py-1">
                  <Col className="text-center">
                    <Alert variant="danger" className="p-1">{error}</Alert>
                  </Col>
                </Row>
              ) : (
                <Fragment />
              )
            }
            {
              isSubmitted ? (
                <Row className="py-1">
                  <Col className="text-center">
                    <Alert variant="success" className="p-1">Verifica completata! Il documento verrà scaricato tra qualche istante.</Alert>
                  </Col>
                </Row>
              ) : (
                <Fragment />
              )
            }
            <Row className="py-1">
              <Col className="text-center small">
                <small>
                  <b>L'aspetto del documento originale potrebbe essere differente</b> rispetto al documento ora generato, a causa di aggiornamenti grafici apportati nel tempo. 
                  Non &egrave; possibile verificare un documento successivamente alla sua data di scadenza (i dati archiviati sono soggetti a bonifica automatica). 
                </small>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <div id="divDownloadDoc" className="d-none"></div>
    </Fragment>
  );
};

export default InspectDocumentForm;
