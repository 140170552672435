import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { ITeacherEntity } from '../../entities/teacher.entity';

interface IFormTeacherEntity extends ITeacherEntity { }

interface IProps {
  loading: boolean;
  edit: boolean;
  teacher?: ITeacherEntity;
  onSubmit: (teacher: ITeacherEntity) => void;
}

const TeacherForm: FC<IProps> = (props) => {
  const teacherInit = {
    idDocente: props.teacher ? props.teacher.idDocente : 0,
    nome: props.teacher ? props.teacher.nome : '',
    cognome: props.teacher ? props.teacher.cognome : '',
    idQualifica: props.teacher ? props.teacher.idQualifica : 0,
    idSituazioneCv: props.teacher ? props.teacher.idSituazioneCv : 0,
  } as IFormTeacherEntity;

  const [isLoading, setLoading] = useState(props.loading);
  const [submitted, setSubmitted] = useState(false);

  const schema = yup.object().shape({
    nome: yup.string().required(),
    cognome: yup.string().required(),
    idQualifica: yup.number().required().moreThan(0),
    idSituazioneCv: yup.number().required().moreThan(0),
  });

  const handleSubmit = (value: IFormTeacherEntity) => {
    props.onSubmit(value as ITeacherEntity);
  };

  return (
    <Fragment>
      {submitted ? (
        <div className="text-center">
          <CheckCircleFill size={94} className="text-success mb-5" />
          <h3 className="text-success">Operazione completata!</h3>
        </div>
      ) : (
        <Formik initialValues={teacherInit} validationSchema={schema} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="nomeValidation">
                <Form.Label>
                  Nome <Asterisk size={8} className="mb-3" />
                </Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  onChange={handleChange}
                  disabled={isLoading}
                  name="nome"
                  value={values.nome}
                  placeholder="Nome"
                  isInvalid={!!touched.nome && !!errors.nome}
                />
                <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="cognomeValidation">
                <Form.Label>
                  Cognome <Asterisk size={8} className="mb-3" />
                </Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  onChange={handleChange}
                  disabled={isLoading}
                  name="cognome"
                  value={values.cognome}
                  placeholder="Cognome"
                  isInvalid={!!touched.cognome && !!errors.cognome}
                />
                <Form.Control.Feedback type="invalid">{errors.cognome}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="idQualificaValidation">
                <Form.Label>
                  Qualifica <Asterisk size={8} className="mb-3" />
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  disabled={isLoading}
                  name="idQualifica"
                  value={values.idQualifica}
                  isInvalid={!!touched.idQualifica && !!errors.idQualifica}
                >
                  <option value={0}>Nessuno</option>
                  <option value={1}>Qualifica 1</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.idQualifica}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="idSituazioneCvValidation">
                <Form.Label>
                  Situazione CV <Asterisk size={8} className="mb-3" />
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  disabled={isLoading}
                  name="idSituazioneCv"
                  value={values.idSituazioneCv}
                  isInvalid={!!touched.idSituazioneCv && !!errors.idSituazioneCv}
                >
                  <option value={0}>Nessuno</option>
                  <option value={1}>Situazione CV 1</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.idSituazioneCv}</Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? (
                  <Fragment>
                    <Spinner as="span" animation="border" size="sm" role="status" />
                    <span className="ms-1">Attendere</span>
                    <span>...</span>
                  </Fragment>
                ) : (
                  <span>Salva</span>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default TeacherForm;
