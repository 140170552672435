import { FC, Fragment, useEffect, useState } from 'react';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { IAdviceEntity } from '../../entities/advice.entity';
import { IDeleteAdviceThunkPayload, IPutAdviceThunkPayload, deleteAdvice, getAdvice, putAdvice } from '../../redux/slices/advice.slice';
import { AuthenticatedRoutesSupervisor } from '../../enums/routes.enum';
import { Badge, Button, Container, Modal, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import AdviceForm from '../../components/supervisor/AdviceForm';

const SupervisorAdvice: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const error: IRejectResponse | null = useSelector((s: RootState) => s.advice.error);

  const [delaySuccessToast, ] = useState<number>(4000);
  const [delayDangerToast, ] = useState<number>(20000);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showDangerToast, setShowDangerToast] = useState<boolean>(false);
  
  const [isLoadingGet, setIsLoadingGet] = useState<boolean>(false);
  const [isLoadingPut, setIsLoadingPut] = useState<boolean>(false);

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  
  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);

  const handleOpenDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const advice: IAdviceEntity | null = useSelector((s: RootState) => s.advice.value);

  useEffect(() => {
    const idAdvice : number = Number(id);
    setIsLoadingGet(true);
    dispatch(getAdvice({ id: idAdvice }))
      .unwrap()
      .catch(() => setShowDangerToast(true))
      .finally(() => setIsLoadingGet(false));
  }, [dispatch, id]);

  const handleSave = (value: IAdviceEntity) => {
    setIsLoadingPut(true);
    dispatch(putAdvice({ advice: value } as IPutAdviceThunkPayload))
      .unwrap()
      .then(() => setShowSuccessToast(true))
      .catch(() => setShowDangerToast(true))
      .finally(() => setIsLoadingPut(false));
  };

  const handleNavigateBackToList = () => {
    navigate(AuthenticatedRoutesSupervisor.AdviceList);
  };

  const handleSubmitDeleteModal = (e: React.MouseEvent) => {
    e.preventDefault();

    if (advice) {
      setIsLoadingDelete(true);
      dispatch(deleteAdvice({ id: advice.id } as IDeleteAdviceThunkPayload))
        .unwrap()
        .then(() => {
          setIsLoadingGet(true);
          handleCloseDeleteModal();
          setTimeout(handleNavigateBackToList, 3000);
        })
        .finally(() => setIsLoadingDelete(false));
    }
  };

  return (
    <Fragment>
      <PageTitle content="News" />
      <PageSubtitle content="Modifica">
        {(advice && !isLoadingGet && !isLoadingDelete && !isLoadingPut) ? (<Button variant="danger" onClick={handleOpenDeleteModal}>Cancella</Button>) : ""}
      </PageSubtitle>
      <PageParagraph content="" />
      <Container fluid as="section" className="bg-light py-3">
        {
          (advice && !isLoadingGet && !isLoadingDelete && !isLoadingPut) ? (
            <AdviceForm advice={advice} onSave={handleSave} isLoading={false} submitted={false} />
          ) : (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )
        }
      </Container>
      <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal} >
        <Modal.Header closeButton>
          <Modal.Title><h3>Confermi la cancellazione?</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cliccando sul bottone <Badge bg="danger">ELIMINA</Badge> verrà cancellata la news.</p>
          <p><small>Al completamento dell'operazione atterrerai alla pagina che lista le news.</small></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal} disabled={isLoadingDelete}>Annulla</Button>
          <Button variant="danger" onClick={handleSubmitDeleteModal} disabled={isLoadingDelete}>Elimina</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione completata!</strong></span>
          </Toast.Header>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione terminata con errori!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status}: {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default SupervisorAdvice;