import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ITeacherEntity } from '../../entities/teacher.entity';
import { deleteTeacher } from '../../redux/slices/teacher.slice';
import { getTeachers } from '../../redux/slices/teachers.slice';
import { AppDispatch, RootState } from '../../redux/store';

const Limit = [10, 25, 50, 100];

interface IProps {
  onEdit: (teacher: ITeacherEntity) => void;
}

const TeacherList: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({ page: 1, limit: 25 });

  const teachers: Array<ITeacherEntity> = useSelector((s: RootState) => s.teachers.value);

  const error = useSelector((s: RootState) => s.teachers.error as IRejectResponse);

  useEffect(() => {
    setLoading(true);
    dispatch(getTeachers({}))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch]);

  const handlePageSizeClick = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handleLoadMoreClick = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handleLoadMinusClick = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleDelete = (teacher: ITeacherEntity) => {
    dispatch(deleteTeacher({ teacher }))
      .unwrap()
      .then(() => dispatch(getTeachers({})))
      .catch(() => console.error('error'));
  };

  return (
    <Fragment>
      <Row className="mb-3">
        <Col>
          <section className="d-flex">
            <div className="flex-grow-1 d-flex align-items-center">
              <p className="mx-1">{teachers.length} Docenti visualizzati</p>
            </div>
            <div className="flex-shrink-1 d-flex align-items-center">
              <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-page-size">
                  {`Carica ${pagination.limit} docenti per pagina `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                  {Limit.map((size: number, index: number) => (
                    <Dropdown.Item key={index} onClick={() => handlePageSizeClick(size)}>
                      {size}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </section>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col className="text-center">
            <p className="text-danger">{error.error}</p>
          </Col>
        </Row>
      )}
      {teachers.length ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {teachers.map((teacher: ITeacherEntity, index: number) => (
              <tr key={index}>
                <td>{teacher.idDocente}</td>
                <td>{teacher.nome}</td>
                <td>{teacher.cognome}</td>
                <td className="text-end">
                  <Button variant="outline" onClick={() => props.onEdit(teacher)}>
                    <PencilSquare size={16} />
                  </Button>
                  <Button variant="outline" onClick={() => handleDelete(teacher)}>
                    <Trash size={16} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Fragment>
          {pagination.page === 1 ? (
            <Row className="py-3">
              <Col className="text-center">
                <p className="text-dark">Nessun docente presente</p>
              </Col>
            </Row>
          ) : (
            <Row className="py-3">
              <Col className="text-center">
                <p className="text-dark">Non ci sono altri docenti da visualizzare</p>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
      {isLoading ? (
        <Row>
          <Col className="text-center">
            <Spinner as="span" animation="border" size="sm" role="status" />
            <p>
              <span>Attendere</span>
              <span>...</span>
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="py-2">
          <Col className="text-center">
            <Button
              variant="primary"
              onClick={handleLoadMinusClick}
              className="mx-2"
              disabled={pagination.page - 1 === 0}
            >
              Precedenti
            </Button>
            <Button
              variant="primary"
              onClick={handleLoadMoreClick}
              className="mx-2"
              disabled={pagination.limit != teachers.length}
            >
              Successivi
            </Button>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default TeacherList;
