import { IGetNotificationsThunkPayload } from '../redux/slices/notifications.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getNotifications = (payload: IGetNotificationsThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.Notification.toString());
};

const NotificationAPI = {
    getNotifications,
};
 
export default NotificationAPI;