import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import CertificationAPI from "../../api/certification.api";
import CourseAPI from '../../api/course.api';
import TeacherAPI from "../../api/teacher.api";
import { IMetadataCertificationEntity, IMetadataCourseEntity, IMetadataOption, IMetadataOptionItem, IMetadataOptionNode, IMetadataTeacherEntity } from "../../entities/metadata.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import { RootState } from "../store";

export interface IMetadataCertificationThunkPayload { }

export interface IMetadataCertificationFulfilledPayload {
  metadata: IMetadataCertificationEntity;
}

export const getMetadataCertification = createAsyncThunk(
  'metadata/certification/get',
  async (thunkPayload: IMetadataCertificationThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.getMetadata(thunkPayload);
      const successPayload = response.data as unknown as IMetadataCertificationEntity;
      const fulfillValue = {
        metadata: successPayload
      } as IMetadataCertificationFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
  {
    condition: (_thunkPayload, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      if (state.metadata.certification.oggetto_certificazione.length === 0) {
        return true;
      }
      return false;
    },
  }
);

export interface IMetadataCourseThunkPayload { }

export interface IMetadataCourseFulfilledPayload {
  metadata: IMetadataCourseEntity;
}

export const getMetadataCourse = createAsyncThunk(
  'metadata/course/get',
  async (thunkPayload: IMetadataCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getMetadata(thunkPayload);
      const successPayload = response.data as unknown as IMetadataCourseEntity;
      // console.log(successPayload);
      const fulfillValue = {
        metadata: successPayload
      } as IMetadataCourseFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
  {
    condition: (_thunkPayload, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      if (state.metadata.course.area_formativa_corso.length === 0) {
        return true;
      }
      return false;
    },
  }
);

export interface IMetadataTeacherThunkPayload { }

export interface IMetadataTeacherFulfilledPayload {
  metadata: IMetadataTeacherEntity;
}

export const getMetadataTeacher = createAsyncThunk(
  'metadata/teacher/get',
  async (thunkPayload: IMetadataTeacherThunkPayload, thunkAPI) => {
    try {
      const response = await TeacherAPI.getMetadata(thunkPayload);
      const successPayload = response.data as unknown as IMetadataTeacherEntity;
      const fulfillValue = {
        metadata: successPayload
      } as IMetadataTeacherFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
  {
    condition: (_thunkPayload, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      if (state.metadata.teacher.qualifica_docente.length === 0) {
        return true;
      }
      return false;
    },
  }
);

const initialMetadataCertification: IMetadataCertificationEntity = {
  oggetto_certificazione: [],
  tipo_accredito_certificazione: [],
  tipo_esonero_certificazione: [],
  tipo_accredito_certificazione_all: [],
  tipo_esonero_certificazione_all: [],
  oggetto_certificazione_all: [],
  stato_certificazione: [],
};

const initialMetadataTeacher: IMetadataTeacherEntity = {
  qualifica_docente: [],
  situazione_cv_docente: [],
};

const initialMetadataCourse: IMetadataCourseEntity = {
  accessibilita_corso: [],
  area_formativa_corso: [],
  articolazione_corso: [],
  erogazione_corso: [],
  stato_corso: [],
  stato_pubblicazione_corso: [],
  tipo_corso_allegato: [],
  tipo_pagamento_corso: [],
  tipologia_corso: [],
  modalita_iscrizione_corso: [],
  trienni: [],
};

export interface IMetadataState {
  course: IMetadataCourseEntity;
  teacher: IMetadataTeacherEntity;
  certification: IMetadataCertificationEntity;
}

const initialState: IMetadataState = {
  course: initialMetadataCourse,
  teacher: initialMetadataTeacher,
  certification: initialMetadataCertification,
};

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMetadataCourse.pending, (state) => {
      state.course = initialMetadataCourse;
    });
    builder.addCase(getMetadataCourse.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IMetadataCourseFulfilledPayload;
      state.course = payload.metadata;
    });
    builder.addCase(getMetadataCourse.rejected, (state, action) => { });

    builder.addCase(getMetadataTeacher.pending, (state) => {
      state.teacher = initialMetadataTeacher;
    });
    builder.addCase(getMetadataTeacher.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IMetadataTeacherFulfilledPayload;
      state.teacher = payload.metadata;
    });
    builder.addCase(getMetadataTeacher.rejected, (state, action) => { });

    builder.addCase(getMetadataCertification.pending, (state) => {
      state.certification = initialMetadataCertification;
    });
    builder.addCase(getMetadataCertification.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IMetadataCertificationFulfilledPayload;
      state.certification.oggetto_certificazione = payload.metadata.oggetto_certificazione;
      state.certification.oggetto_certificazione_all = payload.metadata.oggetto_certificazione_all;
      // tipo_accredito_certificazione: [],
      // tipo_esonero_certificazione: [],
      // state.certification.tipologia_certificazione = payload.metadata.tipologia_certificazione;
      state.certification.tipo_accredito_certificazione = payload.metadata.tipo_accredito_certificazione;
      state.certification.tipo_esonero_certificazione = payload.metadata.tipo_esonero_certificazione;
      state.certification.tipo_accredito_certificazione_all = payload.metadata.tipo_accredito_certificazione_all;
      state.certification.tipo_esonero_certificazione_all = payload.metadata.tipo_esonero_certificazione_all;
      state.certification.stato_certificazione = payload.metadata.stato_certificazione;
      // state.certification.oggetto_tipologia = payload.metadata.oggetto_certificazione.map((value: IMetadataOptionNode, index: number) => {
      //   let item: IMetadataOptionItem = { id: value.id, nome: value.nome, descrizione: value.descrizione, children: Array<IMetadataOption>() } as IMetadataOptionItem;

      //   let itemchildren = payload.metadata.tipologia_certificazione.filter((val: IMetadataOptionNode) => val.extId === value.id);

      //   item.children = itemchildren;

      //   return item;
      // });
    });
    builder.addCase(getMetadataCertification.rejected, (state, action) => { });
  },
});

export default metadataSlice.reducer;
