import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import AttachmentAPI from "../../api/attachment.api";
import { IAttachmentEntity } from "../../entities/attachment.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetAttachmentsThunkPayload {
  idAzienda: number;
}

export interface IGetAttachmentsFulfilledPayload {
  attachments: Array<IAttachmentEntity>;
}

export const getAttachments = createAsyncThunk(
  'attachments/get',
  async (thunkPayload: IGetAttachmentsThunkPayload, thunkAPI) => {
    try {
      const response = await AttachmentAPI.getAttachments(thunkPayload);
      const successPayload = response.data as unknown as Array<IAttachmentEntity>;
      const fulfillValue = {
        attachments: successPayload
      } as IGetAttachmentsFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IAttachmentsState {
  error: IRejectResponse | null;
  value: Array<IAttachmentEntity>;
}

const initialState: IAttachmentsState = {
  error: null,
  value: new Array<IAttachmentEntity>(),
};

const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAttachments.pending, (state) => {
      state.error = null;
      state.value = new Array<IAttachmentEntity>();
    });
    builder.addCase(getAttachments.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetAttachmentsFulfilledPayload;
      state.error = null;
      state.value = payload.attachments;
    });
    builder.addCase(getAttachments.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = new Array<IAttachmentEntity>();
    });
  },
});

export default attachmentsSlice.reducer;