import { FC } from 'react';
import { Badge } from 'react-bootstrap';

interface IProps {
  min: number;
  max: number;
  ext: number;
  totPresenti: number | undefined;
}

export const CourseListItemSubscriptions: FC<IProps> = (props) => (
  <h6>
    <span>
      <span className="small">Min: <small>{props.min === 0 ? "--" : props.min}</small></span>
      <small> / </small>
      <span className="small">Max: <small>{props.max === 0 ? "--" : props.max}</small></span>
      <small> / </small>
      <span className="small">Ext: <small>{props.ext === 0 ? "--" : props.ext}</small></span>
      <small> / </small>
      <span className="small">Presenti: <small>{props.totPresenti === 0 ? "--" : props.totPresenti}</small></span>
    </span>
  </h6>
);

export default CourseListItemSubscriptions;
