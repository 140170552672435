import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse } from '../../api/api.interface';
import TeacherAPI from "../../api/teacher.api";
import { ITeacherEntity } from "../../entities/teacher.entity";
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface ITeacherThunkPayload {
  teacher: ITeacherEntity;
}

export interface ITeacherFulfilledPayload { }

export const postTeacher = createAsyncThunk(
  'teacher/post',
  async (thunkPayload: ITeacherThunkPayload, thunkAPI) => {
    try {
      const response = await TeacherAPI.postTeacher(thunkPayload);

      const payload = response.data as IServerResponse;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        return thunkAPI.fulfillWithValue({} as ITeacherFulfilledPayload);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const putTeacher = createAsyncThunk(
  'teacher/patch',
  async (thunkPayload: ITeacherThunkPayload, thunkAPI) => {
    try {
      const response = await TeacherAPI.putTeacher(thunkPayload);

      const payload = response.data as IServerResponse;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        return thunkAPI.fulfillWithValue({} as ITeacherFulfilledPayload);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const deleteTeacher = createAsyncThunk(
  'teacher/delete',
  async (thunkPayload: ITeacherThunkPayload, thunkAPI) => {
    try {
      const response = await TeacherAPI.deleteTeacher(thunkPayload);

      const payload = response.data as IServerResponse;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        return thunkAPI.fulfillWithValue({} as ITeacherFulfilledPayload);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ITeachersState {
  error: IRejectResponse | null;
  value: ITeacherEntity | null;
}

const initialState: ITeachersState = {
  error: null,
  value: null,
};

const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postTeacher.pending, (state) => {
      state.error = null;
    });
    builder.addCase(postTeacher.fulfilled, (state, action) => {
      const payload = action.meta.arg as unknown as ITeacherThunkPayload;

      state.value = payload.teacher as ITeacherEntity;
    });
    builder.addCase(postTeacher.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.value = null;
      state.error = payload;
    });
    builder.addCase(putTeacher.pending, (state) => {
      state.error = null;
    });
    builder.addCase(putTeacher.fulfilled, (state, action) => {
      const payload = action.meta.arg as unknown as ITeacherThunkPayload;

      state.value = payload.teacher as ITeacherEntity;
    });
    builder.addCase(putTeacher.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.value = null;
      state.error = payload;
    });
    builder.addCase(deleteTeacher.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteTeacher.fulfilled, (state, action) => {
      const payload = action.meta.arg as unknown as ITeacherThunkPayload;

      state.value = payload.teacher as ITeacherEntity;
    });
    builder.addCase(deleteTeacher.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.value = null;
      state.error = payload;
    });
  },
});

export default teacherSlice.reducer;
