import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import CourseList from '../../components/shared/CourseList';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';

const UserPublishedCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Corsi">
        <a href="/assets/tutorial/U_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista corsi pubblicati" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <CourseList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserPublishedCourses;