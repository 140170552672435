import { FC, Fragment } from 'react';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import Advices from '../../components/supervisor/Advices';

const SupervisorAdvices: FC = () => {

  return (
    <Fragment>
      <PageTitle content="News" />
      <PageSubtitle content="Lista news" />
      <PageParagraph content="" />
      <Advices />
    </Fragment>
  );
};

export default SupervisorAdvices;