import { FC, Fragment, useEffect } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany, getPartners } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const AdminDashboard: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const handleClickGoToCourses = () => {
    const route = AuthenticatedRoutesAdmin.PublishedCourses.toString();
    navigate(route);
  };

  const handleClickGoToListProfessionals = () => {
    const route = AuthenticatedRoutesAdmin.ListProfessionals.toString();
    navigate(route);
  };

  const handleClickGoToTrainingCreditManage = () => {
    const route = AuthenticatedRoutesAdmin.TrainingCreditsManage.toString();
    navigate(route);
  };

  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto amministratore!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
      <Container fluid as="section">
        <Row className="d-flex align-items-stretch my-5">
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/list.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToCourses}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-1 c-pointer" onClick={handleClickGoToCourses}>Corsi</h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/users.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToListProfessionals}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-2 c-pointer" onClick={handleClickGoToListProfessionals}>Elenco iscritti</h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/graduation-cap.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToTrainingCreditManage}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-3 c-pointer" onClick={handleClickGoToTrainingCreditManage}>Esoneri e Certificazioni</h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminDashboard;
