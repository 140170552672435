import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import MessageAPI from "../../api/message.api";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IPostMessageThunkPayload {
  subject: string;
  text: string;
}

export interface IPostMessageFulfilledPayload { }

export const postMessage = createAsyncThunk(
  'message/post',
  async (thunkPayload: IPostMessageThunkPayload, thunkAPI) => {
    try {
      await MessageAPI.postMessage(thunkPayload);
      //const response = await MessageAPI.postMessage(thunkPayload);
      //const successPayload = response.data as unknown as ISuccessResponse;
      const fulfillValue = { } as IPostMessageFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IMessageState {
  error: IRejectResponse | null;
  timestamp: string;
}

const initialState: IMessageState = {
  error: null,
  timestamp: '',
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postMessage.pending, (state) => {
      state.error = null;
      state.timestamp = '';
    });
    builder.addCase(postMessage.fulfilled, (state, action) => {
      //const payload = action.payload as unknown as IPostMessageFulfilledPayload;
      state.timestamp = new Date().toISOString();
    });
    builder.addCase(postMessage.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.timestamp = new Date().toISOString();
    });
  },
});

export default messageSlice.reducer;