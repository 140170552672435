import { FC, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SupportOtp from '../../components/manager/SupportOtp';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { AppDispatch } from '../../redux/store';

const ManagerSupportOtp: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getCompaniesOrder({}));
        dispatch(getCompaniesPartner({}));
    }, [dispatch]);

    return (
        <Fragment>
            <PageTitle content="Assistenza ordini/enti" />
            <PageSubtitle content="" />
            <PageParagraph content="Scegliendo dal dropdown l'ordine/ente a cui fare assistenza e verranno generate le credenziali temporanee." />
            <SupportOtp />
        </Fragment >
    );
};

export default ManagerSupportOtp;