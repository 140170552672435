import { FC, Fragment, useState } from 'react';
import { Button, Modal, Row, Col, Form, Card } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CertficationForm from '../../components/admin/CertficationForm';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import SearchProfessionalList from '../../components/shared/search/SearchProfessionalList';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { IPostSubscriberThunkPayload } from '../../redux/slices/course.slice';
import { AppDispatch } from '../../redux/store';
import { IGetProfessionalsFilter } from '../../redux/slices/professionals.slice';

interface IForm extends IPostSubscriberThunkPayload {
  professional: IProfessionalEntity | null;
}

const AdminNewCertification: FC = () => {
  // const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();

  const [professionalSelect, setProfessionalSelect] = useState<IProfessionalEntity>();
  const [showAddModal, setShowAddModal] = useState(false);
  const handleOpenAddModal = () => {
    setShowAddModal(true);
    setProfessionalSelect(undefined);
  };
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };


  /* const error: IRejectResponse | null = useSelector((s: RootState) => s.course.error);

  const [delaySuccessToast,] = useState(4000);
  const [delayDangerToast,] = useState(10000);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDangerToast, setShowDangerToast] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);

  const [fields, setFields] = useState({
    idCorso: 0,
    codiceFiscale: '',
    esterna: false,
    professional: null,
  } as IForm);
  */

  const handleSelect = (professional: IProfessionalEntity, ext: boolean, filter: IGetProfessionalsFilter) => {
    console.log("selezionato il professionista");
    setProfessionalSelect(professional);
    handleCloseAddModal();
  };

  const handleSubmitSuccess = () => {
    setProfessionalSelect(undefined);
  };

  return (
    <Fragment>
      <PageTitle content="Certificazione / esonero">
        {<a href="/assets/tutorial/O_NuovaCertificazione.pdf" target="_blank">
          <FaInfoCircle className="text-dark" size={32} />
        </a>}
      </PageTitle>
      <PageSubtitle content="Nuova" />
      <PageParagraph content="Per creare la certificazione selezionare un professionista e compilare i campi richiesti mostrati dopo la scelta." />
      <Card className="m-1 py-3 px-1">
        <Form.Group as={Row} className="m-1 ">
          <Form.Label as={Col} lg={2} md={4} sm={12}>Professionista</Form.Label>
          <Col lg={6} md={8} sm={12}>
            {professionalSelect ? (
              <span><b>{professionalSelect?.nome + ' ' + professionalSelect?.cognome}</b> {'(' + professionalSelect?.codiceFiscale + ') '}</span>
            ) : ""}
            <Button size="sm" onClick={handleOpenAddModal}>Cerca</Button>
          </Col>
        </Form.Group>
        {professionalSelect ? (
          <>
            <Row>
              <Col>
                <CertficationForm codiceFiscale={professionalSelect.codiceFiscale} onSubmitSuccess={handleSubmitSuccess} />
              </Col>
            </Row>
          </>
        ) : ""}
      </Card>

      <Modal show={showAddModal} onHide={handleCloseAddModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h3>Aggiungi iscrizione</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchProfessionalList showDetail={false} disableFilterByExt={true} onSelect={handleSelect} />
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default AdminNewCertification;
