import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IProps {
  content: string;
}

const PageParagraph: FC<IProps> = (props) => (
  <Container fluid as="section">
    <Row>
      <Col>
        <hr className="mt-2" />
        {props.content}
        {props.children && <p>{props.children}</p>}
      </Col>
    </Row>
  </Container>
);

export default PageParagraph;
