export enum Routes {
  NotFound = "/not-found",
  RedirectSSO = "/redirect-saml",
  LoginSSO = "/login-saml",
  Root = "/",
}

export enum PublicRoutes {
  Contact = "/contactus",
}

export enum UnauthenticatedRoutes {
  Register = "/register",
  VerifyAccount = "/verify-account",
  VerifyAccountToken = "/verify-account/:token",
  ForgotUsername = "/forgot-username",
  ForgotPassword = "/forgot-password",
  ResetPasswordToken = "/reset-password/:token",
  ResetPassword = "/reset-password",
  Login = "/login",
  Logout = "/logout",
  InspectDocument = "/inspect-document/:code",
  InspectDocumentNoCode = "/inspect-document",
}

export enum AuthenticatedRoutes {
  Me = "/me",
  Support = "/support",
}

export enum AuthenticatedRoutesMaster {
  Dashboard = "/master/dashboard",
  Attachments = "/master/attachments",
  Courses = "/master/courses",
  PublishedCourses = "/master/courses/published",
  Settings = "/master/settings",
  Awn = "/master/awn",
}

export enum AuthenticatedRoutesManager {
  Dashboard = "/manager/dashboard",
  Professionals = "/manager/professionals",
  ListProfessionals = "/manager/professionals/list",
  Professional = "/manager/professional/:cf",
  Attachments = "/manager/attachments",
  Tutorials = "/manager/tutorials",
  Otp = "/manager/otp",
  ReplaceCf = "/manager/replace-cf",
}

export enum AuthenticatedRoutesAdmin {
  Dashboard = "/admin/dashboard",
  Courses = "/admin/courses",
  PublishedCourses = "/admin/courses/published",
  UnpublishedCourses = "/admin/courses/unpublished",
  OtherCourses = "/admin/courses/other",
  ImportCourses = "/admin/courses/import",
  Course = "/admin/course/:id",
  CourseOther = "/admin/course-other/:id",
  NewCourse = "/admin/course",
  NewCertification = "/admin/professionals/certification",
  Professionals = "/admin/professionals",
  ListProfessionals = "/admin/professionals/list",
  ImportProfessionals = "/admin/professionals/import",
  Professional = "/admin/professional/:cf",
  Communications = "/admin/communications",
  MailInadempienti = "/admin/communications/mail-inadempienti",
  Teachers = "/admin/teachers",
  Teacher = "/admin/teacher/:id",
  TrainingCredits = '/admin/professionals/training-credits',
  TrainingCreditsManage = '/admin/professionals/training-credits-manage',
  ReplicaCourse = "/admin/course-replica/:id",
  DuplicaCourse = "/admin/course-duplica",
}

export enum AuthenticatedRoutesPartner {
  Dashboard = "/partner/dashboard",
  Courses = "/partner/courses",
  PublishedCourses = "/partner/courses/published",
  UnpublishedCourses = "/partner/courses/unpublished",
  ImportCourses = "/partner/courses/import",
  Course = "/partner/course/:id",
  NewCourse = "/partner/course",
  ReplicaCourse = "/partner/course-replica/:id",
  DuplicaCourse = "/partner/course-duplica",
}

export enum AuthenticatedRoutesApia {
  Dashboard = "/apia/dashboard",
  // Profile = "/profile",
}

export enum AuthenticatedRoutesUser {
  Dashboard = "/dashboard",
  Courses = "/courses",
  Course = "/course/:id",
  CourseAttemptPayment = "/course-payment/:id",
  FavoritesCourses = "/favorites",
  PublishedCourses = "/courses/published",
  MyCourses = "/courses/my",
  ScheduledCourses = "/courses/scheduled",
  TrainingCredits = '/training-credits',
  TrainingCreditsStatus = '/training-credits/status',
  TrainingCreditsRequests = '/training-credits/requests',
  TrainingCreditsNewRequest = '/training-credits/new-request',
  Faq = '/faq',
}

export enum AuthenticatedRoutesSupervisor {
  Dashboard = "/supervisor/dashboard",
  Advices = "/supervisor/advices",
  AdviceList = "/supervisor/advices/list",
  NewAdvice = "/supervisor/advice",
  Advice = "/supervisor/advice/:id",
}
