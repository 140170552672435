import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BsDownload, BsXCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { IAttachmentEntity } from '../../entities/attachment.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IUserEntity } from '../../entities/user.entity';
import { deleteAttachment, getAttachment, IDeleteAttachmentThunkPayload, IGetAttachmentFulfilledPayload, IGetAttachmentThunkPayload } from '../../redux/slices/attachment.slice';
import { getAttachments, IGetAttachmentsThunkPayload } from '../../redux/slices/attachments.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import { paginationComponentOptions } from '../../utils/list-default';
import OrdineProvinciale from './dropdown/OrdineProvinciale';


interface IProps {
    readOnly: boolean
}

interface IFilter extends IGetAttachmentsThunkPayload { }

const AttachmentList: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const company: ICompanyEntity | null = useSelector((s: RootState) => s.company.value);
    const attachments: Array<IAttachmentEntity> = useSelector((s: RootState) => s.attachments.value);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilter>({ idAzienda: company ? company.idAzienda : 0 } as IFilter);
    const [selectedAttachment, setSelectedAttachment] = useState<IAttachmentEntity | null>(null);

    const handleOpenDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const getFullName = (u: IUserEntity): string => {
        if (u.nome && u.cognome) {
            return u.nome + ' ' + u.cognome;
        }
        else if (u.nome) {
            return u.nome;
        }
        else if (u.cognome) {
            return u.cognome;
        }

        return '';
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getAttachments(filter as IGetAttachmentsThunkPayload))
            .unwrap()
            .finally(() => setIsLoading(false));
    }, [dispatch, filter]);

    const handleDeleteAttachment = (attach: IAttachmentEntity | null) => {
        if (attach) {
            setIsLoading(true);
            dispatch(deleteAttachment({ idAllegato: attach.idAllegato } as IDeleteAttachmentThunkPayload))
                .unwrap()
                .then(() => {
                    handleCloseDeleteModal();
                    setSelectedAttachment(null);
                    handleGetAttachments();
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleGetAttachments = () => {
        setIsLoading(true);
        dispatch(getAttachments(filter))
            .unwrap()
            .finally(() => setIsLoading(false));
    };

    const handleDownloadAttachment = (row: IAttachmentEntity) => {
        setIsLoading(true);
        dispatch(getAttachment({ idAllegato: row.idAllegato } as IGetAttachmentThunkPayload))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IGetAttachmentFulfilledPayload;
                const div = document.getElementById("divDownloadAttachment");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .catch((e) => {
                // console.log("KO", e);
            })
            .finally(() => setIsLoading(false));
    };

    const handleCompanyClick = (company: ICompanyEntity | null) => {
        let f: IFilter = { idAzienda: 0 };
        if (company) {
            f = { idAzienda: company.idAzienda };
        }
        setFilter(f);
    };

    const columns: TableColumn<IAttachmentEntity>[] = [
        {
            id: 'idAllegato',
            name: '#',
            sortable: true,
            selector: (row: IAttachmentEntity) => row.idAllegato,
        },
        {
            id: 'descrizione',
            name: 'Descrizione file',
            sortable: true,
            selector: (row: IAttachmentEntity) => row.descrizione ? row.descrizione.toString() : '',
        },
        {
            id: 'contentType',
            name: 'Tipo file',
            sortable: true,
            selector: (row: IAttachmentEntity) => row.contentType ? row.contentType.toString() : '',
        },
        {
            id: 'filename',
            name: 'Nome file',
            sortable: true,
            selector: (row: IAttachmentEntity) => row.filename ? row.filename.toString() : '',
        },
        {
            id: 'utenteCreazione',
            name: 'Utente',
            sortable: true,
            selector: (row: IAttachmentEntity) => row.utenteCreazione ? getFullName(row.utenteCreazione) : '',
        },
        {
            cell: (row, index, column, id) => {
                return (<BsDownload className="text-dark c-pointer" title="Scarica allegato" onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleDownloadAttachment(row);
                }} size={16} />);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row, index, column, id) => {
                return (<BsXCircle className="text-danger c-pointer" title="Cancella allegato" onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    setSelectedAttachment(row);
                    handleOpenDeleteModal();
                }} size={16} />);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            omit: props.readOnly,
        },
    ] as TableColumn<IAttachmentEntity>[];

    return (
        <Fragment>
            <Container fluid as="section">
                <Row>
                    <Col>
                        <section className="d-flex mb-3">
                            <div className="flex-grow-1 d-flex"></div>
                            <div className="flex-shrink-1 d-flex">
                                <Form.Check type="switch" className={`m-2 ${filter.idAzienda === 0 || company?.idAzienda === filter.idAzienda ? '' : 'd-none'}`}>
                                    <Form.Check.Input checked={company?.idAzienda === filter.idAzienda} onChange={(e: React.ChangeEvent) => {
                                        e.preventDefault();
                                        if (company?.idAzienda === filter.idAzienda) {
                                            handleCompanyClick(null);
                                        } else {
                                            handleCompanyClick(company);
                                        }
                                    }} />
                                    <Form.Check.Label>{company?.nome}</Form.Check.Label>
                                </Form.Check>
                                <OrdineProvinciale onSelect={handleCompanyClick} />
                            </div>
                        </section>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <DataTable columns={columns} data={attachments}
                            striped pagination responsive
                            noDataComponent="Nessun risultato" progressPending={isLoading}
                            paginationComponentOptions={paginationComponentOptions} />
                    </Col>
                </Row>
            </Container>
            <div id="divDownloadAttachment" className="d-none"></div>
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal} >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Confermi la cancellazione?</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>Cliccando sul bottone ELIMINA verrà cancellato l'allegato #{selectedAttachment?.idAllegato} {selectedAttachment?.filename}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal} disabled={isLoading}>Annulla</Button>
                    <Button variant="danger" onClick={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        handleDeleteAttachment(selectedAttachment);
                    }} disabled={isLoading}>Elimina</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default AttachmentList;