import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SubscriptionState } from '../../../../enums/subscription-state.enum';
import { BsFillCircleFill } from "react-icons/bs";

export const getStatus = (id: SubscriptionState): string => {
    if (id === SubscriptionState.Pending) return "Lista d'attesa (con iscrizioni alla lista d'attesa)";
    if (id === SubscriptionState.Ingoing) return "Pre-iscritti (in transizione da lista d'attesa a iscritti)";
    if (id === SubscriptionState.AttemptPayment) return "In pagamento (in fase di pagamento / manca ricevuta telematica)";
    if (id === SubscriptionState.Confirmed) return "Iscritti (a cui risulti iscritto)";
    if (id === SubscriptionState.Canceled) return "Annullati (con iscrizioni annullate)";
    if (id === SubscriptionState.Attendance) return "Presenti (con presenze confermate)";
    if (id === SubscriptionState.NonAttendance) return "Assenti (con presenze non confermate)";
    return "";
};

interface IProps {
    defaultSelectedId?: SubscriptionState | null;
    onSelect?: (option: SubscriptionState | null) => void;
}

const StatusEsteso: FC<IProps> = (props) => {

    const defaultSelected = (): SubscriptionState | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByStatus, setFilterByStatus] = useState<SubscriptionState | null>(defaultSelected());

    const handleFilterByStatus = (option: SubscriptionState | null) => {
        setFilterByStatus(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {filterByStatus != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByStatus == null ? 'Stato iscrizione' : getStatus(filterByStatus)}`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownStatusNone"} disabled={filterByStatus == null} onClick={() => handleFilterByStatus(null)}>Tutti</Dropdown.Item>
                    {
                        Object.entries(SubscriptionState).map(([key, value], index: number) => (
                            {
                                [SubscriptionState.Pending]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.Pending} onClick={() => handleFilterByStatus(SubscriptionState.Pending)}>{getStatus(SubscriptionState.Pending)}</Dropdown.Item>
                                ),
                                [SubscriptionState.Ingoing]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.Ingoing} onClick={() => handleFilterByStatus(SubscriptionState.Ingoing)}>{getStatus(SubscriptionState.Ingoing)}</Dropdown.Item>
                                ),
                                [SubscriptionState.AttemptPayment]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.AttemptPayment} onClick={() => handleFilterByStatus(SubscriptionState.AttemptPayment)}>{getStatus(SubscriptionState.AttemptPayment)}</Dropdown.Item>
                                ),
                                [SubscriptionState.Confirmed]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.Confirmed} onClick={() => handleFilterByStatus(SubscriptionState.Confirmed)}>{getStatus(SubscriptionState.Confirmed)}</Dropdown.Item>
                                ),
                                [SubscriptionState.Canceled]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.Canceled} onClick={() => handleFilterByStatus(SubscriptionState.Canceled)}>{getStatus(SubscriptionState.Canceled)}</Dropdown.Item>
                                ),
                                [SubscriptionState.Attendance]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.Attendance} onClick={() => handleFilterByStatus(SubscriptionState.Attendance)}>{getStatus(SubscriptionState.Attendance)}</Dropdown.Item>
                                ),
                                [SubscriptionState.NonAttendance]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === SubscriptionState.NonAttendance} onClick={() => handleFilterByStatus(SubscriptionState.NonAttendance)}>{getStatus(SubscriptionState.NonAttendance)}</Dropdown.Item>
                                ),
                            }[key]
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default StatusEsteso;
