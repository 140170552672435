import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getSendEmails } from '../../redux/slices/email.slice';
import { getProfessionalsEmailCount } from '../../redux/slices/emailscount.slice';

const EmailInvio: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);

    // const [t, setT] = useState<IProfessionalsEmailSendThunkPayload>({ type: 1 });

    const countTotalProfessionalsEmailEmpty: number = useSelector((s: RootState) => s.professionalsEmailsCount.emailNonValide);
    const countTotalProfessionalsInadempienti: number = useSelector((s: RootState) => s.professionalsEmailsCount.inadempienti);
    const countdaInviare: number = useSelector((s: RootState) => s.professionalsEmailsCount.daInviare);
    const invioInCorso: boolean = useSelector((s: RootState) => s.professionalsEmailsCount.invioInCorso);
    const dataUltimoInvio: string = useSelector((s: RootState) => s.professionalsEmailsCount.dataUltimoInvio);

    useEffect(() => {
        setLoading(true);
        dispatch(getProfessionalsEmailCount({})).unwrap().finally(() => setLoading(false));
    }, [dispatch]);

    const handleRefresh = () => {
        setLoading(true);
        setSubmitted(false)
        dispatch(getProfessionalsEmailCount({})).unwrap().finally(() => setLoading(false));
    }

    const handleSubmit = () => {
        setShowConfirmSendModal(true);
    };

    const handleConfirmSend = () => {
        //setHasError(false);
        setShowConfirmSendModal(false);
        setLoading(true);
        console.log("ma rivo qua?");

        // dispatch(getProfessionalsEmailCount({}))
        dispatch(getSendEmails({type : 1}))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => setSubmitted(true))
            .finally(() => setLoading(false));
    }

    const handleCloseConfirmSendModal = () => setShowConfirmSendModal(false);

    return (
        <Fragment>
            {/* <h4 className="mb-3">Riepilogo</h4> */}
            <Container fluid as="section">
                <Card className="mt-3">
                    <Card.Body>
                        {submitted ? (
                            <div className="text-center">
                                <CheckCircleFill size={100} className="text-success m-5" />
                                <h3 className="text-success">Operazione completata!</h3>
                                <Button variant="primary" onClick={handleRefresh}>OK</Button>
                                {/* <Button variant="primary" onClick={() => setSubmitted(false)}>OK</Button> */}
                            </div>
                        ) : (
                            <Form noValidate>
                                <Form.Group as={Row} controlId="formEmail" className="mb-3">
                                    <Col lg={1} md={1} sm={1} xs={1} />

                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        {invioInCorso ? (
                                            <Fragment>
                                                {countdaInviare === 0 ? (<Fragment />) : (<p><b>{("Invio in corso, mancano ancora " + countdaInviare + " email")}</b></p>)}                                                
                                            </Fragment>) : (
                                            <Fragment>
                                                <p><b>{("professionisti inadempienti TOTALI: " + (countTotalProfessionalsInadempienti + countTotalProfessionalsEmailEmpty))}</b></p>
                                                {(dataUltimoInvio !== "") ? (<p>{("Data ultimo invio: " + dataUltimoInvio)}</p>) : (<p>Nessun invio effettuato finora</p>)}
                                                <p>{("Premendo Invia, verranno spedite " + countTotalProfessionalsInadempienti + " email")}</p>
                                                <br />
                                                <p>{(countTotalProfessionalsEmailEmpty > 0) ? ("un totale di " + countTotalProfessionalsEmailEmpty + " professionisti non hanno l'email configurata") : (<Fragment />)}</p>
                                            </Fragment>)}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formEmail" className="mb-3">
                                    <Col lg={1} md={1} sm={1} xs={1} />
                                    <Col lg={3} md={3} sm={3} xs={3} >
                                        {invioInCorso ? (
                                            <Button variant="danger" onClick={handleRefresh} className="w-100" >
                                                <span>Aggiorna</span>
                                            </Button>

                                        ) : (
                                            <Button variant="primary" onClick={handleSubmit} className="w-100">
                                                {/* <Button type="submit" className="w-25 float-right"> */}
                                                {isLoading ? (
                                                    <Fragment>
                                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                                        <span className="ms-1">Attendere</span>
                                                        <span>...</span>
                                                    </Fragment>
                                                ) : (
                                                    <span>Invia</span>
                                                )}
                                            </Button>
                                        )}
                                    </Col>

                                </Form.Group>

                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showConfirmSendModal} onHide={handleCloseConfirmSendModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Import iscrizioni</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Confermi l'invio delle email?</p>
                    <Button variant="primary" onClick={handleConfirmSend}>Conferma</Button>
                    {/* <SubscriberImportForm idCorso={props.idCorso} /> */}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default EmailInvio;