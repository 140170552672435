import { FC, Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { IMetadataOption } from '../../entities/metadata.entity';
import { ImateriaHistoryProfessionalStatusEntity } from '../../entities/training-credits.entity';
import { getImateriaHistoryProfessionalStatus } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import Triennio from './dropdown/course/Triennio';

interface IProps {
    codiceFiscale: string;
}

const ProfessionalTrainingCreditsImateria: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState(false);
    const [trainingCreditsTOT, setTrainingCreditsTOT] = useState<number>(0);

    const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
    const trainingCredits: Array<ImateriaHistoryProfessionalStatusEntity> = useSelector((s: RootState) => s.professional.trainingCreditsImateria);

    const [filterByThreeYears, setFilterByThreeYears] = useState<IMetadataOption | null>(trienni.length ? trienni[trienni.length - 1] : null);
    const [filterByCF, setFilterByCF] = useState<string>(props.codiceFiscale);

    const handleThreeYearsClick = (option: IMetadataOption | null) => {
        setFilterByThreeYears(option);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getImateriaHistoryProfessionalStatus({ codiceFiscale: filterByCF, filter: { triennio: filterByThreeYears ? filterByThreeYears.id : 0 } }))
            .finally(() => setIsLoading(false));
    }, [dispatch, filterByCF, filterByThreeYears]);

    const htmlDecode = (input: string) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    };

    const columns: TableColumn<ImateriaHistoryProfessionalStatusEntity>[] = [
        {
            id: 'codiceFiscale',
            name: 'Codice fiscale',
            selector: (row) => row.codiceFiscale,
            omit: true,
        },
        {
            id: 'siglaAzienda',
            name: 'Ordine',
            selector: (row) => row.siglaAzienda,
            sortable: true,
            grow: 0,
        },
        {
            id: 'anno',
            name: 'Anno',
            selector: (row) => Number(row.anno) ? 'A' + row.anno : 'T' + row.anno,
            cell: (row) => (
                <span>{Number(row.anno) ? row.anno : (<b>{row.anno}</b>)}</span>
            ),
            sortable: true,
            grow: 0,
        },
        {
            id: 'log',
            name: 'Status',
            selector: (row) => row.log,
            cell: (row) => (
                <span>{Number(row.anno) ? <div dangerouslySetInnerHTML={{ __html: row.log }} /> : (<b><div dangerouslySetInnerHTML={{ __html: row.log }} /></b>)}</span>

            ),
            wrap: true,
        },
    ] as TableColumn<ImateriaHistoryProfessionalStatusEntity>[];

    return (
        <Fragment>
            <Container as="section" fluid className="bg-light p-2">
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Triennio defaultSelectedId={filterByThreeYears ? filterByThreeYears.id : 0} onSelect={handleThreeYearsClick} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            columns={columns}
                            data={trainingCredits}
                            striped
                            responsive
                            noDataComponent="Nessun risultato"
                            progressPending={isLoading} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ProfessionalTrainingCreditsImateria;