import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from "react-icons/bs";


const endYear = 2013;
const startYear = (new Date()).getFullYear();
const monthsLabel = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

interface IProps {
    onlyYears?: boolean;
    defaultYear?: number;
    defaultMonth?: number;
    onSelectYear?: (year: number | null) => void;
    onSelectMonth?: (month: number | null) => void;
    initYears?: Array<number>;
}

const MeseAnno: FC<IProps> = (props) => {
    const monthsList = (): Array<number> => {
        let list = Array<number>();
        const n = 12;
        for (let i = 1; i <= n; i++) {
            list.push(i);
        }
        return list;
    }

    const months = monthsList();

    const yearsList = (): Array<number> => {
        let list = Array<number>();
        const n = startYear - endYear;
        for (let i = 0; i <= n; i++) {
            list.push(startYear - i);
        }
        return list;
    }

    const years = props?.initYears && props.initYears.length > 0 ? props.initYears : yearsList();

    const [year, setYear] = useState<number | null>(props.defaultYear ? props.defaultYear : null);
    const [month, setMonth] = useState<number | null>(props.defaultMonth ? props.defaultMonth : null);
    const [onlyYears, setOnlyYears] = useState<boolean>((props.onlyYears === undefined || props.onlyYears === null) ? false : props.onlyYears);

    const handleSelectedYear = (year: number | null) => {
        setYear(year);
        if (props?.onSelectYear) {
            props.onSelectYear(year);
        }
    };

    const handleSelectedMonth = (month: number | null) => {
        setMonth(month);
        if (props?.onSelectMonth) {
            props.onSelectMonth(month);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {year != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`Anno ${year ? year : ''} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownYearNone"} disabled={year === null} onClick={() => handleSelectedYear(null)}>Tutti</Dropdown.Item>
                    {
                        years.map((y: number, index: number) => (
                            <Dropdown.Item key={"dropdownYear" + y} onClick={() => handleSelectedYear(y)} disabled={year === y}>
                                {y}
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                year && !onlyYears ? (
                    <Dropdown className="mx-1">
                        <Dropdown.Toggle variant="outline-primary" id="dropdown-month">
                            {month != null ? (
                                <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                                    <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                                </span>
                            ) : ""}
                            {`Mese ${month ? month : ''} `}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="px-1 border border-primary">
                            <Dropdown.Item key={"dropdownMonthNone"} disabled={month === null} onClick={() => handleSelectedMonth(null)}>Tutti</Dropdown.Item>
                            {
                                months.map((m: number, index: number) => (
                                    <Dropdown.Item key={"dropdownMonth" + m} onClick={() => handleSelectedMonth(m)} disabled={month === m}>
                                        {m} - {monthsLabel[m - 1]}
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <Fragment />
                )
            }
        </Fragment>
    );
};

export default MeseAnno;