import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import { ICourseFavoritesEntityRaw } from "../../entities/course.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import FavoriteAPI from "../../api/favorite.api";

export interface IGetFavoritesThunkPayload {
}

export interface IGetFavoritesFulfilledPayload {
  favorites: Array<ICourseFavoritesEntityRaw>;
  // totalList: number;
}

export const getFavorites = createAsyncThunk(
  'favorites/get',
  async (thunkPayload: IGetFavoritesThunkPayload, thunkAPI) => {
    try {
      const response = await FavoriteAPI.getFavorites(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseFavoritesEntityRaw>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalCoursesList = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        favorites: successPayload,
        totalList: countTotalCoursesList,
      } as unknown as IGetFavoritesFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IFavoritesState {
  error: IRejectResponse | null; //error
  value: Array<ICourseFavoritesEntityRaw>; //favoriti
  // totalList: number;
}

const initialState: IFavoritesState = {
  error: null,
  value: [],
  // totalList: 0,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFavorites.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getFavorites.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetFavoritesFulfilledPayload;
      state.value = payload.favorites;
      // state.totalList = payload.totalList;
    });
    builder.addCase(getFavorites.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = [];
    });
  },
});

export default favoritesSlice.reducer;