import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, FormControl, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ICourse, ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { IUser } from '../../entities/user.entity';
import { deleteSubscriptionAndPendingPayment, getCoursePaymentReceipt, IPutUnsubscribeCourseThunkPayload, putUnsubscribeCourse } from '../../redux/slices/course.slice';
import { getCoursesMe } from '../../redux/slices/courses.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseRawToICourse } from '../../utils/course-parser';
import CourseDetail from '../shared/CourseDetail';
import AreaFormativa from '../shared/dropdown/course/AreaFormativa';
import Erogazione from '../shared/dropdown/course/Erogazione';
import Tipologia from '../shared/dropdown/course/Tipologia';
import PaginationLimit from '../shared/dropdown/list/PaginationLimit';
import ScheduledCoursesItem from './ScheduledCoursesItem';
import { IRejectResponse } from '../../api/api.interface';
import PageSelect from '../shared/dropdown/list/PageSelect';

interface IPagination {
    page: number;
    limit: number;
}

const ScheduledCourses: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);
    
    const [errorUnsubscribe, setErrorUnsubscribe] = useState<IRejectResponse>();
    const [errorDeletePendingPayment, setErrorDeletePendingPayment] = useState<IRejectResponse>();
    const [errorGetPaymentReceipt, setErrorGetPaymentReceipt] = useState<IRejectResponse>();
    const [showGetPaymentReceiptErrorModal, setShowGetPaymentReceiptErrorModal] = useState(false);

    const [showUnsubscribeUser, setShowUnsubscribeUser] = useState(false);
    const [idCorsoSelected, setIdCorsoSelected] = useState<number>(0);
    const [isLoading, setLoading] = useState(true);
    const [countRefresh, setCountRefresh] = useState<number>(0);
    const [filterByMetaErogazione, setFilterByMetaErogazione] = useState<IMetadataOption | null>(null);
    const [filterByMetaTipologia, setFilterByMetaTipologia] = useState<IMetadataOption | null>(null);
    const [filterByMetaAreaFormativa, setFilterByMetaAreaFormativa] = useState<IMetadataOption | null>(null);
    const [filterByFreeText, setFilterByFreeText] = useState<string | null>(null);

    const [pagination, setPagination] = useState<IPagination>({ page: 0, limit: 25 });

    const courses: Array<ICourse> = useSelector((s: RootState) => Object.keys(s.courses.myvalue).map((key: string) => parseRawToICourse(s.courses.myvalue[key])));
    const course: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.detail);
    const countTotalCoursesList: number = useSelector((s: RootState) => s.courses.totalList);

    const getPagination = (courses: Array<ICourse>, pagination: IPagination): Array<ICourse> => {
        return courses.slice((pagination.page - 1) * pagination.limit, (pagination.page - 1) * pagination.limit + pagination.limit);
    };

    const handlePagination = (courses: Array<ICourse>, pagination: IPagination) => {
        const paginated = getPagination(courses, pagination);
        setPaginatedCourses(paginated);
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getCoursesMe({}))
            .unwrap()
            .finally(() => {
                setPagination({ ...pagination, page: 1 });
                setLoading(false);
            });
    }, [dispatch, countRefresh]);

    const [hasFilter, setHasFilter] = useState<boolean>(false);
    const [filteredCourses, setFilteredCourses] = useState<Array<ICourse>>(courses);
    const [paginatedCourses, setPaginatedCourses] = useState<Array<ICourse>>(getPagination(courses, pagination));

    const handleFilter = (filterByMetaErogazione: IMetadataOption | null,
        filterByMetaTipologia: IMetadataOption | null, filterByMetaAreaFormativa: IMetadataOption | null,
        filterByFreeText: string | null): Array<ICourse> => {

        if (filterByMetaErogazione || filterByMetaTipologia || filterByMetaAreaFormativa || filterByFreeText) {
            setHasFilter(true);
        } else {
            setHasFilter(false);
        }

        const filtered = courses
            .filter((c: ICourse) => {
                // Erogazione
                if (!filterByMetaErogazione) return true;
                if (!c.idErogazione) return false;
                return c.idErogazione.toString() == filterByMetaErogazione.id.toString();
            })
            .filter((c: ICourse) => {
                // Tipologia
                if (!filterByMetaTipologia) return true;
                if (!c.idTipologia) return false;
                return c.idTipologia.toString() == filterByMetaTipologia.id.toString();
            })
            .filter((c: ICourse) => {
                // Area formativa
                if (!filterByMetaAreaFormativa) return true;
                if (!c.idAreaFormativa) return false;
                return c.idAreaFormativa.toString() == filterByMetaAreaFormativa.id.toString();
            })
            .filter((c: ICourse) => {
                // Testo libero
                if (!filterByFreeText) return true;
                if (!filterByFreeText.length) return true;
                if (!c.titolo) return false;
                let matchFilter = c.titolo.toLowerCase().includes(filterByFreeText.toLowerCase());
                if (matchFilter) return true;
                if (!c.codice) return false;
                return c.codice.toLowerCase().includes(filterByFreeText.toLowerCase());
            }).sort((a: ICourse, b: ICourse) => (a.dataInizio && b.dataInizio) ? b.dataInizio.getTime() - a.dataInizio.getTime() : 0);

        setFilteredCourses(filtered);
        return filtered;
    };

    useEffect(() => {
        handlePagination(handleFilter(filterByMetaErogazione, filterByMetaTipologia, filterByMetaAreaFormativa, filterByFreeText), pagination);
    }, [filterByMetaErogazione, filterByMetaTipologia, filterByMetaAreaFormativa, filterByFreeText, pagination]);

    const handleFreeTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination({ ...pagination, page: 1 });
        setFilterByFreeText(event.target.value);
    };

    const handleMetaErogazioneClick = (option: IMetadataOption | null) => {
        setPagination({ ...pagination, page: 1 });
        setFilterByMetaErogazione(option);
    };

    const handleMetaTipologiaClick = (option: IMetadataOption | null) => {
        setPagination({ ...pagination, page: 1 });
        setFilterByMetaTipologia(option);
    };

    const handleMetaAreaFormativaClick = (option: IMetadataOption | null) => {
        setPagination({ ...pagination, page: 1 });
        setFilterByMetaAreaFormativa(option);
    };

    const handlePageSizeClick = (limit: number) => {
        setPagination({ page: 1, limit: limit });
    };
    
    const handlePaginationLoad = (pag: number) => {
        setPagination({ ...pagination, page: pag });
    };
     
    const handlePaginationLoadFirst = () => {
        setPagination({ ...pagination, page: 1 });
    };

    const handlePaginationLoadLast = () => {
        setPagination({ ...pagination, page: Math.ceil(countTotalCoursesList / pagination.limit) });
    };

    const handleLoadMoreClick = () => {
        setPagination({ ...pagination, page: pagination.page + 1 });
    };

    const handleLoadMinusClick = () => {
        const previuosPage = pagination.page - 1;
        if (previuosPage === 0) return;
        setPagination({ ...pagination, page: pagination.page - 1 });
    };

    const handleClickCourseItemGetPaymentReceipt = (idCorso: number) => {
        setIdCorsoSelected(idCorso);
        handleOpenGetPaymentReceiptModal();
    };

    const handleClickGetPaymentReceipt = (idCorso: number) => {
        setErrorGetPaymentReceipt(undefined);
        setLoading(true);
        dispatch(getCoursePaymentReceipt({ idCorso: idCorso }))
        .unwrap()
        .then(() => { })
        .catch((err) => { 
            console.log("errore get ricevuta pagamento");
            const error = err as unknown as IRejectResponse;
            console.log(error);
            console.log(error.error);
            setErrorGetPaymentReceipt(error);
            setShowGetPaymentReceiptErrorModal(true);
        })
        .finally(() => {
            console.log("chiudo modal get ricevuta pagamento");
            handleCloseGetPaymentReceiptModal();
            setCountRefresh(countRefresh + 1);
        });
    };
        
    const [showGetPaymentReceiptModal, setShowGetPaymentReceiptModal] = useState(false);
    const handleOpenGetPaymentReceiptModal = () => {
        setShowGetPaymentReceiptModal(true);
        setErrorGetPaymentReceipt(undefined);
    }
    const handleCloseGetPaymentReceiptModal = () => {
        setShowGetPaymentReceiptModal(false);
        setIdCorsoSelected(0);
        // setErrorGetPaymentReceipt("");
    }

    const handleClickCourseItemDeletePendingPayment = (idCorso: number) => {
        setIdCorsoSelected(idCorso);
        handleOpenDeletePendingPaymentModal();
    };

    const handleCloseGetPaymentReceiptErrorModal = () => {
        console.log("chiudo errore get ricevuta pagamento");
        setShowGetPaymentReceiptErrorModal(false);
        setErrorGetPaymentReceipt(undefined);
    }

    const handleClickDeletePendingPayment = (idCorso: number) => {
        setErrorDeletePendingPayment(undefined);
        if (user != null) {
            setLoading(true);
            dispatch(deleteSubscriptionAndPendingPayment({ idCorso: idCorso, codiceFiscale: user.fiscalCode }))
            .unwrap()
            .then(() => { })
            .catch((err) => { 
                const error = err as unknown as IRejectResponse;
                setErrorDeletePendingPayment(error);
            })
            .finally(() => {
                handleCloseDeletePendingPaymentModal();
                setCountRefresh(countRefresh + 1);
            });
        }
    };

    const [showDeletePendingPaymentModal, setShowDeletePendingPaymentModal] = useState(false);
    const handleOpenDeletePendingPaymentModal = () => {
        setShowDeletePendingPaymentModal(true);
        setErrorDeletePendingPayment(undefined);
    }
    const handleCloseDeletePendingPaymentModal = () => {
        setShowDeletePendingPaymentModal(false);
        setIdCorsoSelected(0);
        setErrorDeletePendingPayment(undefined);
    }

    const [showCourseDetailModal, setShowCourseDetailModal] = useState(false);
    const handleOpenCourseDetailModal = () => setShowCourseDetailModal(true);
    const handleCloseCourseDetailModal = () => {
        setShowCourseDetailModal(false);
        setIdCorsoSelected(0);
    }

    const handleCloseUsubscribeUser = () => {
        setErrorUnsubscribe(undefined);
        setShowUnsubscribeUser(false);
        setIdCorsoSelected(0);
    }
    
    const handleShowUsubscribeUser = () => {
        setErrorUnsubscribe(undefined);
        setShowUnsubscribeUser(true); 
        setShowCourseDetailModal(false); 
    }

    const handleClickCourseItem = (idCorso: number) => {
        setIdCorsoSelected(idCorso);
        handleOpenCourseDetailModal();
    };
    
    const handleUnsubscribe = (idCorso: number) => {
        setErrorUnsubscribe(undefined);
        if (user != null) {
            setLoading(true);
            dispatch(putUnsubscribeCourse({ idCorso: idCorso, codiceFiscale: user.fiscalCode } as IPutUnsubscribeCourseThunkPayload))
                .unwrap()
                .then(() => { })
                .catch((err) => { 
                    const error = err as unknown as IRejectResponse;
                    setErrorUnsubscribe(error);
                })
                .finally(() => {
                    handleCloseUsubscribeUser();
                    setCountRefresh(countRefresh + 1);
                });
        }
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <section className="d-flex">
                        <div className="flex-grow-1">
                            <InputGroup className="my-2">
                                <FormControl placeholder="Cerca" onChange={handleFreeTextChange} />
                                <InputGroup.Text><Search /></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="flex-shrink-1 d-flex">
                            <Erogazione onSelect={handleMetaErogazioneClick} />
                            <Tipologia onSelect={handleMetaTipologiaClick} />
                            <AreaFormativa onSelect={handleMetaAreaFormativaClick} />
                        </div>
                    </section>
                </Col>
            </Row>
            <Row>
                <Col>
                    <section className="d-flex">
                        <div className="flex-grow-1 d-flex">
                            <p className="mx-1">
                                {(courses.length > 0 ? "corsi " + (1 + pagination.limit * (pagination.page-1)) + "-" + (courses.length + pagination.limit * (pagination.page-1)) + 
                                (courses.length >= countTotalCoursesList ? "" : " di " + countTotalCoursesList) : "0 corsi TOTALI")}
                               <br /><small>{hasFilter ? ' di cui ' + filteredCourses.length + ' corsi filtrati' : ' Nessun filtro applicato'}</small>
                            </p>
                        </div>
                        <div className="flex-shrink-1 d-flex">
                            <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCoursesList / pagination.limit)} onSelect={handlePaginationLoad} />
                            <PaginationLimit enableUnlimit={false} onSelect={handlePageSizeClick} />
                        </div>
                    </section>
                </Col>
            </Row>
            {
                isLoading ? (
                    <Row>
                        <Col className="text-center">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                            <p>
                                <span>Attendere</span>
                                <span>...</span>
                            </p>
                        </Col>
                    </Row>
                ) : (
                    <Fragment>
                        {
                            paginatedCourses.length ? (
                                <section className="d-flex flex-column">
                                    {
                                        paginatedCourses.map((c: ICourse, index: number) => (
                                            <div key={index + '_' + c.id} className="m-1">
                                                <ScheduledCoursesItem course={c} 
                                                    onClick={handleClickCourseItem} 
                                                    onClickDeletePayment={handleClickCourseItemDeletePendingPayment} 
                                                    onClickGetPaymentReceipt={handleClickCourseItemGetPaymentReceipt} />
                                            </div>
                                        ))
                                    }
                                </section>
                            ) : (
                                <Fragment>
                                    <Row className="py-3">
                                        <Col className="text-center">
                                            <p className="text-dark">Nessun risultato</p>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )
                        }
                        <Row className="py-2">
                            <Col className="text-center">
                                <Button variant="primary" onClick={handlePaginationLoadFirst} className="mx-2" disabled={isLoading || (pagination.page - 1) === 0}>
                                    Pagina iniziale
                                </Button>
                                <Button variant="primary" onClick={handleLoadMinusClick} className="mx-2" disabled={(pagination.page - 1) === 0}>
                                    Precedenti
                                </Button>
                                <span>pagina {pagination.page}</span>
                                <Button variant="primary" onClick={handleLoadMoreClick} className="mx-2" disabled={pagination.limit != paginatedCourses.length}>
                                    Successive
                                </Button>
                                <Button variant="primary" onClick={handlePaginationLoadLast} className="mx-2" disabled={isLoading || pagination.limit != courses.length}>
                                    Pagina finale
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                )
            }

            <Modal size="lg" show={showCourseDetailModal} onHide={handleCloseCourseDetailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Dettaglio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {   
                        idCorsoSelected > 0 ? (
                            <CourseDetail idCorso={idCorsoSelected} />
                        ) : ""
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleShowUsubscribeUser}>Annulla iscrizione</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUnsubscribeUser} onHide={handleCloseUsubscribeUser}>
                <Modal.Header closeButton>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Premi <b>ANNULLA ISCRIZIONE</b> per cancellare l'iscrizione al corso<br /><b>{course?.titolo}</b></p>
                    {
                        errorUnsubscribe ? (
                            <Alert variant="danger">ATTENZIONE! {errorUnsubscribe?.error}</Alert>
                        ): (<Fragment />)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleUnsubscribe(idCorsoSelected)} disabled={isLoading}>
                        Annulla iscrizione
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePendingPaymentModal} onHide={handleCloseDeletePendingPaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Sei sicuro?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Clicca su <b>ANNULLA ISCRIZIONE</b> per cancellare l'iscrizione al corso<br/><b className="small">{course?.titolo}</b></p>
                    {
                        errorDeletePendingPayment ? (
                            <Alert variant="danger">ATTENZIONE! {errorDeletePendingPayment?.error}</Alert>
                        ): (<Fragment />)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleClickDeletePendingPayment(idCorsoSelected)} disabled={isLoading}>
                        Annulla iscrizione
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showGetPaymentReceiptModal} onHide={handleCloseGetPaymentReceiptModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Verifica l'esito del pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Premi <b>CONFERMA</b> per procedere.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleClickGetPaymentReceipt(idCorsoSelected)} disabled={isLoading}>
                        Conferma
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showGetPaymentReceiptErrorModal} onHide={handleCloseGetPaymentReceiptErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENZIONE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Errore nella lettura della ricevuta:</p>
                    <p>{errorGetPaymentReceipt?.error || "Errore generico"}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleCloseGetPaymentReceiptErrorModal()} disabled={isLoading}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ScheduledCourses;