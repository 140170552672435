import { ChangeEvent, FC, Fragment, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IAttachmentEntity } from '../../entities/attachment.entity';
import { AttachmentType } from '../../enums/attachment-type.enum';
import { IPostCourseAttachmentThunkPayload, postCourseAttachment } from '../../redux/slices/course.slice';
import { AppDispatch } from '../../redux/store';


interface IFormAttachmentEntity extends IAttachmentEntity { };

interface IProps {
    idCorso: number;
}

const AttachmentForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [attachment, setAttachment] = useState({
        idCorso: props.idCorso,
        idAllegato: 0,
        filename: '',
        contentType: '',
        contentRaw: '',
        path: '',
        nome: '',
        descrizione: '',
        idTipoAllegato: AttachmentType.MaterialeDidattico,
    } as IFormAttachmentEntity);

    const [isLoading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        //const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setAttachment(previousState => ({
                        idCorso: previousState.idCorso,
                        idAllegato: previousState.idAllegato,
                        filename: file_name,
                        contentType: file_type,
                        contentRaw: res,
                        path: previousState.path,
                        nome: file_name,
                        descrizione: previousState.descrizione,
                        idTipoAllegato: previousState.idTipoAllegato,
                    } as IFormAttachmentEntity));
                }
            };

            fileReader.readAsDataURL(files[0]);
            setDisabled(false);
        }
    };

    const handleChangeIdTipoAllegato = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        let current = AttachmentType.MaterialeDidattico;

        if (target.value === AttachmentType.Logo.toString()) {
            current = AttachmentType.Logo;
        }
        else if (target.value === AttachmentType.Locandina.toString()) {
            current = AttachmentType.Locandina;
        }
        else if (target.value === AttachmentType.ContrattoDiIscrizione.toString()) {
            current = AttachmentType.ContrattoDiIscrizione;
        }

        setAttachment(previousState => ({
            idCorso: previousState.idCorso,
            idAllegato: previousState.idAllegato,
            filename: previousState.filename,
            contentType: previousState.contentType,
            contentRaw: previousState.contentRaw,
            path: previousState.path,
            nome: previousState.nome,
            descrizione: previousState.descrizione,
            idTipoAllegato: current,
        } as IFormAttachmentEntity));
    };

    const [showError, setShowError] = useState(false);

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSubmitted(false);
        setShowError(false);
        setLoading(true);
        //console.log(attachment);

        dispatch(postCourseAttachment(attachment as IPostCourseAttachmentThunkPayload))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => setShowError(true))
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            {submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={94} className="text-success mb-5" />
                    <h3 className="text-success">Operazione completata!</h3>
                </div>
            ) : (
                <Form noValidate onSubmit={handleSubmit} >

                    {Object.entries(AttachmentType).map(([key, value], index: number) => (

                        {
                            [AttachmentType.Logo]: (
                                <Form.Check key={key} inline disabled={isLoading} label="Logo" name="idTipoAllegato" type="radio" id={`attachmentType${key}`} value={AttachmentType.Logo.toString()} onInput={handleChangeIdTipoAllegato} />
                            ),
                            [AttachmentType.Locandina]: (
                                <Form.Check key={key} inline disabled={isLoading} label="Locandina" name="idTipoAllegato" type="radio" id={`attachmentType${key}`} value={AttachmentType.Locandina.toString()} onInput={handleChangeIdTipoAllegato} />
                            ),
                            [AttachmentType.ContrattoDiIscrizione]: (
                                <Form.Check key={key} inline disabled={isLoading} label="Contratto di iscrizione" name="idTipoAllegato" type="radio" id={`attachmentType${key}`} value={AttachmentType.ContrattoDiIscrizione.toString()} onInput={handleChangeIdTipoAllegato} />
                            ),
                            [AttachmentType.MaterialeDidattico]: (
                                <Form.Check key={key} inline disabled={isLoading} label="Materiale didattico" name="idTipoAllegato" type="radio" id={`attachmentType${key}`} value={AttachmentType.MaterialeDidattico.toString()} onInput={handleChangeIdTipoAllegato} defaultChecked={true} />
                            ),
                        }[key]

                    ))}

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label></Form.Label>
                        <Form.Control type="file" name="file" onInput={handleFileUpload} disabled={isLoading} />
                    </Form.Group>

                    <Button type="submit" className="w-100" disabled={isLoading || isDisabled}>
                        {isLoading ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendere</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <span>Salva</span>
                        )}
                    </Button>

                    <Alert variant="danger" show={showError} className="mt-3">
                        Salvataggio non riuscito!
                    </Alert>
                </Form>
            )}
        </Fragment>
    );
};

export default AttachmentForm;