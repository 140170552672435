import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import TeacherAPI from "../../api/teacher.api";
import { ITeacherEntity } from "../../entities/teacher.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface ITeachersThunkPayload { }

export interface ITeachersFulfilledPayload {
  teachers: Array<ITeacherEntity>;
}

export const getTeachers = createAsyncThunk(
  'teachers/get',
  async (thunkPayload: ITeachersThunkPayload, thunkAPI) => {
    try {
      const response = await TeacherAPI.getTeachers(thunkPayload);
      const successPayload = response.data as unknown as Array<ITeacherEntity>;
      const fulfillValue = {
        teachers: successPayload
      } as ITeachersFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ITeachersState {
  error: IRejectResponse | null;
  value: Array<ITeacherEntity>;
  search: string[];
}

const initialState: ITeachersState = {
  error: null,
  value: [],
  search: [],
};

const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeachers.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getTeachers.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ITeachersFulfilledPayload;

      state.value = payload.teachers;

      payload.teachers.forEach((x => {
        state.search[x.idDocente] = x.nome + ' ' + x.cognome;
      }));
    });
    builder.addCase(getTeachers.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.value = [];
      state.search = [];
      state.error = payload;
    });
  },
});

export default teachersSlice.reducer;