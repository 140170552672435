import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from "react-icons/bs";

export const getTrainingType = (id: boolean): string => {
    if (id === true) {
        return 'deontologici';
    }
    return 'ordinari';
}

export const getTipoFormazione = (id: null | boolean): string => {
    if (id === false) {
        return 'Formazione ordinaria';
    }
    else if (id === true) {
        return 'Deontologia e discipline ordinistiche';
    }
    return '';
}

interface IProps {
    defaultSelectedId?: boolean;
    onSelect?: (option: boolean | null) => void;
}

const TipoFormazione: FC<IProps> = (props) => {

    const defaultSelected = (): boolean | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByTipoFormazione, setFilterByTipoFormazione] = useState<boolean | null>(defaultSelected());

    const handleFilterByTipoFormazione = (option: boolean | null) => {
        setFilterByTipoFormazione(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {filterByTipoFormazione != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByTipoFormazione == null ? 'Tipologia formazione' : getTipoFormazione(filterByTipoFormazione)} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownTipoFormazioneNone"} disabled={filterByTipoFormazione == null} onClick={() => handleFilterByTipoFormazione(null)}>Tutte</Dropdown.Item>
                    <Dropdown.Item key={"dropdownTipoFormazioneTrue"} disabled={filterByTipoFormazione === true} onClick={() => handleFilterByTipoFormazione(true)}>{getTipoFormazione(true)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownTipoFormazioneFalse"} disabled={filterByTipoFormazione === false} onClick={() => handleFilterByTipoFormazione(false)}>{getTipoFormazione(false)}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default TipoFormazione;