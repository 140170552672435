import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

interface IProps {
    defaultSelectedId?: number;
    totPagine: number;    
    onSelect?: (limit: number) => void;
}

const PageSelect: FC<IProps> = (props) => {

    const defaultSelected = (): number => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        } else { return 1}
    };

    const [pageSelected, setPageSelected] = useState(defaultSelected());
    let listaPagine = [];
    for (let i=1; i<=props.totPagine; i++) {
        listaPagine.push(i);
    }

    const handlePageSelect = (item: number) => {
        setPageSelected(item);
        if (props?.onSelect) {
            props.onSelect(item);
        }
    }

    return (
        <Fragment>
            <Dropdown className="mx-2 my-2">
                <Dropdown.Toggle variant="outline-primary">
                    {pageSelected ? 'Seleziona la pagina' : 'Pagina ${pageSelected} '}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    {
                        listaPagine.map((item: number, index: number) => {
                            return (
                                <Dropdown.Item key={item} onClick={() => handlePageSelect(item)}>
                                    {item}
                                </Dropdown.Item>                                    
                            )
                        })                       
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default PageSelect;