import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import { IMetadataOption } from '../../../../entities/metadata.entity';

interface IProps {
    defaultOrderBy?: Array<IMetadataOption>;
    defaultOrderType?: boolean;
    onSelectOrderBy?: (options: Array<IMetadataOption> | null) => void;
    onSelectOrderType?: (option: boolean) => void;
    initSortingOptions: Array<IMetadataOption>;
}

const Sorting: FC<IProps> = (props) => {
    const options = props.initSortingOptions;

    const [orderBy, setOrderBy] = useState<Array<IMetadataOption>>(props.defaultOrderBy ? props.defaultOrderBy : Array<IMetadataOption>());
    const [orderType, setOrderType] = useState<boolean>(props.defaultOrderType !== undefined ? props.defaultOrderType : false);

    const handleSortingOrderBy = (by: IMetadataOption | null) => {
        let ordby = Array<IMetadataOption>();
        if (by) {
            orderBy.forEach((oby) => {
                ordby.push(oby);
            });
            ordby.push(by);
        }
        setOrderBy(ordby);
        if (props?.onSelectOrderBy) {
            props.onSelectOrderBy(ordby);
        }
    };

    const handleSortingOrderType = (type: boolean) => {
        setOrderType(type);
        if (props?.onSelectOrderType) {
            props.onSelectOrderType(type);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-2 my-2">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-orderby">
                    {`Ordina ${orderBy.length ? 'per ' + orderBy.map(oby => oby.descrizione).join(',') : ''} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownSortingOrderByNone"} disabled={orderBy.length === 0} onClick={() => handleSortingOrderBy(null)}>Non ordinata</Dropdown.Item>
                    {
                        options.map((o: IMetadataOption, index: number) => (
                            <Dropdown.Item key={"dropdownSortingOrderBy" + o.id} onClick={() => handleSortingOrderBy(o)} disabled={orderBy.map((oby) => oby.id).includes(o.id)}>
                                {o.descrizione}
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                orderBy.length ? (
                    <Dropdown className="mx-1">
                        <Dropdown.Toggle variant="outline-primary" id="dropdown-ordertype">
                            {
                                orderType ? (
                                    <ImSortAlphaDesc />
                                ) : (
                                    <ImSortAlphaAsc />
                                )
                            } &nbsp;
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="px-1 border border-primary">
                            <Dropdown.Item key={"dropdownSortingOrderType0"} disabled={orderType === false} onClick={() => handleSortingOrderType(false)}>Crescente <ImSortAlphaAsc /> </Dropdown.Item>
                            <Dropdown.Item key={"dropdownSortingOrderType1"} disabled={orderType === true} onClick={() => handleSortingOrderType(true)}>Decrescente <ImSortAlphaDesc /> </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <Fragment />
                )
            }
        </Fragment>
    );
};

export default Sorting;