import { FC, Fragment } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import Tutorials from '../../components/shared/Tutorials';

const ManagerTutorial: FC = () => {

    return (
        <Fragment>
            <PageTitle content="Faq" />
            <PageParagraph content="" />
            <Container fluid as="section">
             <Row>
               <Col className="mb-5">
                 <p className="mt-3">Questa pagina contiene documentazione sull'impiego dell'applicazione, manualistica che verrà progressivamente aggiornata per seguire le evoluzioni del sistema.</p>
                 <p>Informazioni sulle Linee guida e sulla normativa di riferimento che l'applicazione deve rispettare si trovano sul sito del CNAPPC nella pagina sulla <a href="https://www.awn.it/professione/aggiornamento/formazione-professionale-continua">Formazione Continua.</a></p>
               </Col>
             </Row>
            </Container>
            <PageSubtitle content="Manuali / Guide" />
            <Tutorials />
        </Fragment>
    );
};

export default ManagerTutorial;