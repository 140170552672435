import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { GoAlert } from "react-icons/go";
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { RootState } from '../../redux/store';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';

interface IProps {
    professional: IProfessionalEntity;
}

const ReplcaeCfProfessionalPersonalData: FC<IProps> = (props) => {
    const companies: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
    
    return (
        <Fragment>
            <Container as="section" fluid className="bg-light p-3">
                <Row>
                    <Col md={2}>Codice fiscale</Col>
                    <Col md={10}><b>{props.professional.codiceFiscale}</b></Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Cognome</Col>
                    <Col md={10}>{props.professional.cognome ? (<b>{props.professional.cognome}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Nome</Col>
                    <Col md={10}>{props.professional.nome ? (<b>{props.professional.nome}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Email</Col>
                    <Col md={10}>{props.professional.email ? (<b>{props.professional.email}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Data nascita</Col>
                    <Col md={10}>{props.professional.dataNascita ? (<b>{format(new Date(props.professional.dataNascita), 'dd/MM/yyyy')}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Data prima iscrizione</Col>
                    <Col md={10}>{props.professional.dataPrimaIscrizione ? (<b>{format(new Date(props.professional.dataPrimaIscrizione), 'dd/MM/yyyy')}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Stato</Col>
                    <Col md={10}>
                        {
                            props.professional.status.toLowerCase() === "iscritto" ? (
                                <Badge bg="success">{props.professional.status}</Badge>
                            ) : (
                                props.professional.status.toLowerCase() === "sospeso" ? (
                                    <Badge bg="warning">{props.professional.status}</Badge>
                                ) : (
                                    props.professional.status.toLowerCase() === "cancellato" ? (
                                        <Badge bg="danger">{props.professional.status}</Badge>
                                    ) : (
                                        <i>non disponibile</i>
                                    )
                                )
                            )
                        }
                    </Col>
                </Row>
                <hr className="my-2" />
                <Row>
                    <Col md={2}>Ordine</Col>
                    <Col md={10}>{props.professional.ordine ? (<b>{getOrdineProvinciale(props.professional.ordine, companies)}</b>) : (<i>non disponibile</i>)}</Col>
                </Row>
                <hr className="my-2" />
            </Container>
        </Fragment>
    );
};

export default ReplcaeCfProfessionalPersonalData;