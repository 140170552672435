import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { InterestCourseState } from '../../../../enums/subscription-state.enum';
import { BsFillCircleFill } from "react-icons/bs";

export const getStatus = (id: InterestCourseState): string => {
    if (id === InterestCourseState.Pending) return "In lista d'attesa";
    if (id === InterestCourseState.PreRegistered) return "Pre-registrato";
    return "";
};

interface IProps {
    defaultSelectedId?: InterestCourseState | null;
    onSelect?: (option: InterestCourseState | null) => void;
}

const StatusFavorites: FC<IProps> = (props) => {

    const defaultSelected = (): InterestCourseState | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByStatus, setFilterByStatus] = useState<InterestCourseState | null>(defaultSelected());

    const handleFilterByStatus = (option: InterestCourseState | null) => {
        setFilterByStatus(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary">
                    {filterByStatus != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByStatus == null ? 'Stato' : getStatus(filterByStatus)}`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownStatusNone"} disabled={filterByStatus == null} onClick={() => handleFilterByStatus(null)}>Tutti</Dropdown.Item>
                    {
                        Object.entries(InterestCourseState).map(([key, value], index: number) => (
                            {
                                [InterestCourseState.Pending]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === InterestCourseState.Pending} onClick={() => handleFilterByStatus(InterestCourseState.Pending)}>{getStatus(InterestCourseState.Pending)}</Dropdown.Item>
                                ),
                                [InterestCourseState.PreRegistered]: (
                                    <Dropdown.Item key={"dropdownStatus" + index + value} disabled={filterByStatus === InterestCourseState.PreRegistered} onClick={() => handleFilterByStatus(InterestCourseState.PreRegistered)}>{getStatus(InterestCourseState.PreRegistered)}</Dropdown.Item>
                                )
                            }[key]
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default StatusFavorites;
