import { FC, Fragment, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { postProfessionalsFromAwn } from '../../redux/slices/professionals.slice';
import { AppDispatch } from '../../redux/store';

const ProfessionalsFromAwn: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [data, setData] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setData(val ? val : '');
    }

    const handleSubmit = () => {
        setIsLoading(true);
        dispatch(postProfessionalsFromAwn({ data: data }))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => setHasError(true))
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            <Container fluid as="section">
                <Row>
                    <Col>
                        <Form.Control type="date" name="date" onChange={handleChange} />
                    </Col>
                    <Col>
                        <Button className='w-50' onClick={handleSubmit} disabled={isLoading || data.length === 0}>Alliena anagrafiche</Button>
                    </Col>
                    <Col>
                        {
                            isLoading ? (
                                <Spinner animation='border' />
                            ) : (
                                <Fragment />
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            submitted ? (
                                <Alert variant="success">
                                    Aggiornamento alla data {data} completato!
                                </Alert>
                            ) : (
                                <Fragment />
                            )
                        }
                        {
                            hasError ? (
                                <Alert variant="danger">
                                    Aggiornamento alla data {data} terminato con errori!
                                </Alert>
                            ) : (
                                <Fragment />
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ProfessionalsFromAwn;