import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import ProfessionalAPI from "../../api/professional.api";
import { IProfessionalsEmailsCountEntity } from "../../entities/professional.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IProfessionalsEmailsCountFulfilledPayload { 
  inadempienti: number;
  emailNonValide: number;
  daInviare: number;
  invioInCorso: boolean;
  dataUltimoInvio: string;
}  

export const getProfessionalsEmailCount = createAsyncThunk(
  'professionals/email/count',
  async (thunkPayload: {}, thunkAPI) => {  
    try {
      const response = await ProfessionalAPI.getProfessionalsEmailCount();
      console.log(response);
      const successPayload = response.data as unknown as IProfessionalsEmailsCountEntity;
      const fulfillValue = {
        inadempienti: successPayload.inadempienti,
        emailNonValide: successPayload.emailNonValide,
        daInviare: successPayload.daInviare,
        invioInCorso: successPayload.invioInCorso,
        dataUltimoInvio: successPayload.dataUltimoInvio,
      } as IProfessionalsEmailsCountFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalsEmailCountState {
  error: IRejectResponse | null;
  inadempienti: number;
  emailNonValide: number;
  daInviare: number;
  invioInCorso: boolean;
  dataUltimoInvio: string;
}

const initialState: IProfessionalsEmailCountState = {
  error: null,
  inadempienti: 0,
  emailNonValide: 0,
  daInviare: 0,
  invioInCorso: true,
  dataUltimoInvio: "",
};

const emailscountSlice = createSlice({
  name: "emailscount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getProfessionalsEmailCount.pending, (state) => {      
      state.error = null;
      state.inadempienti = 0;
      state.emailNonValide = 0;
      state.daInviare = 0;
      state.invioInCorso = true;
      state.dataUltimoInvio = "";
    });
    builder.addCase(getProfessionalsEmailCount.fulfilled, (state, action) => {
      console.log("getProfessionalsEmailCount.fulfilled");
      console.log(action.payload);
      const payload = action.payload as unknown as IProfessionalsEmailsCountFulfilledPayload;
      state.error = null;
      state.inadempienti = payload.inadempienti;
      state.emailNonValide = payload.emailNonValide;
      state.daInviare = payload.daInviare;
      state.invioInCorso = payload.invioInCorso;
      state.dataUltimoInvio = payload.dataUltimoInvio;
    });
    builder.addCase(getProfessionalsEmailCount.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.inadempienti = 0;
      state.emailNonValide = 0;
      state.daInviare = 0;
      state.invioInCorso = true;
      state.dataUltimoInvio = "";
    });

  },
});

export default emailscountSlice.reducer;