import React, { FC, Fragment } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourse, ICourseFavoritesEntityRaw } from '../../entities/course.entity';
import { RootState } from '../../redux/store';
import Subscribe from './Subscribe';
import { format } from 'date-fns';
import { getEnteTerzo } from '../shared/dropdown/EnteTerzo';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';

interface IProps {
  favorite: ICourseFavoritesEntityRaw;
  onClickItem?: (idCorso: number) => void;
  onClickButton?: (idCorso: number) => void;
}

const FavoritesCoursesItem: FC<IProps> = (props) => {
  
  const companies: ICompanyEntity[] = useSelector((s: RootState) => s.companies.orders);
  const partners: ICompanyEntity[] = useSelector((s: RootState) => s.companies.partners);

  const handleClickItem = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props?.onClickItem) {
      props.onClickItem(props.favorite.id);
    }
  }

  const handleClickButton = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props?.onClickButton) {
      props.onClickButton(props.favorite.id);
    }
  }

  return (
    <Fragment key={"favouritec_" + props.favorite.id}>
      <Badge  bg={`${props.favorite?.statoInteresse == 2 ? 'secondary' : 'warning'}` } className={`d-flex mt-3 p-2 ${props?.onClickItem ? 'c-pointer' : ''}` }>
        <div className="flex-shrink-1 d-flex flex-column px-2" onClick={handleClickItem} >
          <div className="d-flex flex-column align-self-center border-end border-3 pe-3">
            <h2 className="text-center text-light text-uppercase m-auto">{format(new Date(props.favorite.dataInizio), 'd')}</h2>
            <h6 className="text-center text-light text-uppercase m-auto">{format(new Date(props.favorite.dataInizio), 'MMM')}</h6>
            <h6 className="text-center text-light text-uppercase m-auto ">{format(new Date(props.favorite.dataInizio), 'yyyy')}</h6>
            <p className="text-center text-light text-uppercase m-auto small">data inizio</p>
          </div>
        </div>
        <div className="flex-grow-1 d-flex flex-column align-items-start" onClick={handleClickItem}>
          <h5 className="text-light text-wrap text-truncate">{props.favorite.titolo}</h5>
          <h6 className="text-light text-wrap text-truncate small">{props.favorite.codice}</h6>
          <h6 className={`text-light text-wrap text-truncate small ${props.favorite.nCorso && props.favorite.nCorso.length > 0 ? "" : "d-none"}`}>{props.favorite.nCorso}</h6>
          <p className="text-light text-wrap text-truncate small">{getOrdineProvinciale(props.favorite.idAziendaProponente, companies)}</p>
          <p className={`text-light text-wrap text-truncate small ${props.favorite.idAziendaProponente != props.favorite.idAzienda ? "" : "d-none"}`}><small><u>{getEnteTerzo(props.favorite.idAzienda, partners)}</u></small></p>
        </div>
        <div className="flex-shrink-1 d-flex flex-column justify-content-between px-2">
          {
            props.favorite.statoInteresse == 3 ? (
              <div className={`d-flex justify-content-end ${props.favorite.statoInteresse == 3 ? "" : "d-none"}`}>
                <Subscribe course={props.favorite as unknown as ICourse} noValidation={true} noPendingList={true} />
              </div>
            ) : (
              <Fragment />
            )
          }              
          <div className={`d-flex justify-content-end ${props?.onClickButton ? "" : "d-none"}`}>
            <Button className="w-100 border-1 border-light" variant="danger" onClick={handleClickButton}>Elimina</Button>
          </div>
        </div>
      </Badge>
    </Fragment>
  );
};

export default FavoritesCoursesItem;