import { Formik } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IUser, IUserEntity } from '../entities/user.entity';
import { UserRole } from '../enums/user-roles.enum';
import { getProfile, IGetProfileFulfilledPayload, IGetProfileThunkPayload, IPutProfileThunkPayload, putProfile } from '../redux/slices/auth.slice';
import { AppDispatch, RootState } from '../redux/store';
import UpdatePasswordForm from './UpdatePasswordForm';

export const ProfileForm: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);

  const [initialValues, setInitialValues] = useState<IUserEntity>();
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Campo obbligatorio")
      .email("Campo non valido"),
  });

  const [showChangePwdModal, setShowChangePwdhModal] = useState<boolean>(false);
  const handleOpenChangePwdModal = () => {
    // console.log(user);
    setShowChangePwdhModal(true);
  }
  const handleCloseChangePwdModal = () => {
    setShowChangePwdhModal(false);  
  };


  const handleSubmit = (value: IUserEntity) => {
    setSubmitted(false);
    setHasError(false);
    setLoading(true);

    const p: IPutProfileThunkPayload = {
      nome: value.nome,
      cognome: value.cognome,
      codiceFiscale: value.codiceFiscale,
      telefono: value.telefono,
      email: value.email,
    } as IPutProfileThunkPayload;

    dispatch(putProfile(p))
      .unwrap()
      .then(() => setSubmitted(true))
      .catch(() => setHasError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getProfile({} as IGetProfileThunkPayload))
      .then((res) => {
        const payload = res.payload as unknown as IGetProfileFulfilledPayload;
        setInitialValues(payload.profile);
      })
      .then((e) => {
        const init: IUserEntity = {
          nome: user.firstName,
          cognome: user.lastName,
          codiceFiscale: user.fiscalCode,
          email: user.email,
          telefono: user.phone,
        } as IUserEntity;
        setInitialValues(init);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <Fragment>
      {submitted ? (
        <div className="text-center">
          <CheckCircleFill size={100} className="text-success mb-5" />
          <h3 className="text-success">Ben fatto!</h3>
          <p className="mt-3">Operazione completata con successo.</p>
        </div>
      ) : (
        <Fragment>
          {initialValues ? (
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
              {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col lg={4} md={3} className="text-center">
                      {
                        {
                          [UserRole.Master]: <Image src="/assets/code-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Supervisor]: <Image src="/assets/cna-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Manager]: <Image src="/assets/support-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Admin]: <Image src="/assets/column-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Partner]: <Image src="/assets/building-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.User]: <Image src="/assets/graduate-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Guest]: <Image src="/assets/graduate-support-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Apia]: <Image src="/assets/graduate-support-light-theme.svg" className="w-100 mt-4" />,
                          [UserRole.Apib]: <Image src="/assets/graduate-support-light-theme.svg" className="w-100 mt-4" />,
                        }[user.role]
                      }
                    </Col>
                    <Col lg={8} md={9}>
                      <Form.Group controlId="firstNameLabelValidationFormik">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          size="lg"
                          name="nome"
                          placeholder="Nome"
                          disabled
                          value={values?.nome}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="lastNameLabelValidationFormik">
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          size="lg"
                          name="cognome"
                          placeholder="Cognome"
                          disabled
                          value={values?.cognome}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className={`${user && (user.role === UserRole.User || user.role === UserRole.Guest) ? '' : 'd-none'}`} controlId="codiceFiscaleLabelValidationFormik">
                        <Form.Label>Codice fiscale</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          size="lg"
                          name="codiceFiscale"
                          placeholder="Codice fiscale"
                          disabled
                          value={values?.codiceFiscale}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="emailValidationFormik">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          size="lg"
                          name="email"
                          placeholder="Email"
                          disabled={isLoading || user.role === UserRole.User || user.role === UserRole.Guest}
                          value={values?.email}
                          onChange={handleChange}
                          isInvalid={!!touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="phoneValidationFormik">
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          size="lg"
                          name="telefono"
                          placeholder="Telefono"
                          disabled={isLoading || user.role === UserRole.User || user.role === UserRole.Guest}
                          value={values?.telefono}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3" >
                    <Col lg={4} md={3}>                      
                      <Button onClick={handleOpenChangePwdModal} className={`${user && (user.role === UserRole.User || user.role === UserRole.Guest) ? 'd-none' : ''} w-100`} size="lg" variant="primary" disabled={isLoading || user.role === UserRole.User || user.role === UserRole.Guest}>
                        {isLoading ? (
                          <Fragment>
                            <Spinner as="span" animation="border" size="sm" role="status" />
                            <span className="ms-1">Cambia pwd</span>
                            <span>...</span>
                          </Fragment>
                        ) : (
                          <span>Cambia pwd</span>
                        )}
                      </Button>
                    </Col>
                    <Col lg={8} md={9}>
                      <Button type="submit" className={`${user && (user.role === UserRole.User || user.role === UserRole.Guest) ? 'd-none' : ''} w-100`} size="lg" variant="primary" disabled={isLoading || user.role === UserRole.User || user.role === UserRole.Guest}>
                        {isLoading ? (
                          <Fragment>
                            <Spinner as="span" animation="border" size="sm" role="status" />
                            <span className="ms-1">Attendi</span>
                            <span>...</span>
                          </Fragment>
                        ) : (
                          <span>Salva</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                  {hasError ? (
                    <Fragment>
                      <Alert variant="danger">
                        <Alert.Heading>Si è verificato un errore durante il salvataggio!</Alert.Heading>
                        <p></p>
                      </Alert>
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}
                </Form>
              )}
            </Formik>
          ) : (
            <Fragment>
              <Spinner as="span" animation="border" size="sm" role="status" />
            </Fragment>
          )}
        </Fragment>
      )}

      <Modal centered show={showChangePwdModal} onHide={handleCloseChangePwdModal} >
        <Modal.Header closeButton>
            <Modal.Title><p className="display-6 m-0 p-0">Cambio password</p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdatePasswordForm verticalSpacing={2} token={"tokenpercambiop"} />
        </Modal.Body>
      </Modal>


    </Fragment>
  );
};

export default ProfileForm;