import { FC, Fragment, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import { Link } from 'react-router-dom';
import Footer from '../layout/parts/Footer';
import { maintenanceModeUrl, webResourceExists } from '../utils/get-base-64.helper';
import { MdRefresh } from 'react-icons/md';
import { UnauthenticatedRoutes } from '../enums/routes.enum';

const RedirectSSO: FC = () => {

  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false);
  
  useEffect(() => {
    // Fetch the stop file to check if the website is in maintenance mode
    webResourceExists(maintenanceModeUrl)
      .then((res) => setIsMaintenanceMode(res))
      .catch(() => setIsMaintenanceMode(false))
  }, []);

  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card className="m-auto">
              <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                {
                  isMaintenanceMode ? (
                    <Fragment>
                      <h2>Benvenuto!</h2>
                      <p>Sito web momentamente offline per <u>interventi tecnici di MANUTENZIONE</u>.</p>
                      <p>Ci scusiamo per il disagio.</p>
                      <p>Lo staff</p>
                      <div className="my-3 text-center">
                        <button type="button" className="btn btn-dark text-light" onClick={() => window.location.reload()}>Ricarica <MdRefresh size={20} className="py-0 my-0" /></button>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2>Benvenuto!</h2>
                      <p>Accedi alla piattaforma in qualità di:</p>
                      <div className="my-3 text-center">
                        <a href="https://portaleservizi.cnappc.it/cfp/saml/authsso">
                          <button type="button" className="btn btn-primary w-75">
                            PROFESSIONISTA
                          </button>
                        </a>
                      </div>
                      <div className="my-3 text-center">
                        <Link to={UnauthenticatedRoutes.Login} className="btn btn-primary w-75">ENTE</Link>
                      </div>
                      <p>oppure</p>
                      <div className="my-3 text-center">
                        <Link to={UnauthenticatedRoutes.InspectDocumentNoCode} className="fw-bold">VERIFICA DOCUMENTO</Link>
                      </div>
                    </Fragment>
                  )
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer variant={"dark"} />
    </Fragment>
  );
};

export default RedirectSSO;