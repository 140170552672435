import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaHome, FaNewspaper } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from '../../entities/user.entity';
import { AuthenticatedRoutes as AuthR, AuthenticatedRoutesSupervisor as AuthRSupervisor, UnauthenticatedRoutes as UnauthR } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  user: IUser;
}

const SidebarSupervisor: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  const [selected, setSelected] = useState<string>(AuthRSupervisor.Dashboard);

  const isSelected = (key: string) => selected ? selected.startsWith(key) : false;
  const isSelectedItem = (key: string) => selected ? selected === key : false;

  const handleSelectParent = (e: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isSelectedItem(value) && expanded) return;
    if (!expanded) dispatch(openSidebar());
    if (isSelected(value)) return;
    let target: HTMLElement = e.target as HTMLElement;
    while (target.tagName.toLocaleLowerCase() !== 'div') {
      target = target.parentElement as HTMLElement;
    }
    setTimeout(() => target.click(), 200);
    setSelected(value);
  };

  const handleSelectItem = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = UnauthR.Login));
  };

  return (
    <SideNav
      style={{ zIndex: 1031 }}
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelectItem}
    >
      <SideNav.Toggle />
      <SideNav.Nav selected={selected}>
        {expanded && (
          <NavItem eventKey={AuthR.Me} className={['profile', isSelected(AuthR.Me) && 'selected']}>
            <div className="text-center my-1">
              <Link to={AuthR.Me} onClick={() => handleSelectItem(AuthR.Me)} className="text-decoration-none">
                <Image src="/assets/cna-dark-theme.svg" height={72} />
              </Link>
            </div>
            <div className="text-center my-1">
              <Link to={AuthR.Me} onClick={() => handleSelectItem(AuthR.Me)} className="text-decoration-none text-light text-uppercase small">
                {props.user.firstName} {props.user.lastName}
              </Link>
            </div>
          </NavItem>
        )}
        <NavItem eventKey={AuthRSupervisor.Dashboard} className={isSelected(AuthRSupervisor.Dashboard) && 'selected'}>
          <NavIcon>
            <Link to={AuthRSupervisor.Dashboard} onClick={() => handleSelectItem(AuthRSupervisor.Dashboard)} className="text-decoration-none">
              <FaHome size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link to={AuthRSupervisor.Dashboard} onClick={() => handleSelectItem(AuthRSupervisor.Dashboard)} className="text-decoration-none">
              Home
            </Link>
          </NavText>
        </NavItem>
        <NavItem eventKey={AuthRSupervisor.Advices} className={isSelected(AuthRSupervisor.Advices) && 'selected'}>
          <NavIcon onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthRSupervisor.Advices)}>
            <FaNewspaper size={32} />
          </NavIcon>
          <NavText onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthRSupervisor.Advices)}>
            News
          </NavText>
          <NavItem eventKey={AuthRSupervisor.AdviceList} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link to={AuthRSupervisor.AdviceList} onClick={() => handleSelectItem(AuthRSupervisor.AdviceList)} className="text-decoration-none text-light">
                Lista news
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthRSupervisor.NewAdvice} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link to={AuthRSupervisor.NewAdvice} onClick={() => handleSelectItem(AuthRSupervisor.NewAdvice)} className="text-decoration-none text-light">
                Crea nuova
              </Link>
            </NavText>
          </NavItem>
        </NavItem>
      </SideNav.Nav>
      <SideNav.Nav componentClass="footer">
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>Esci</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarSupervisor;