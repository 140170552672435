import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import AttachmentAPI from "../../api/attachment.api";
import { IAttachmentEntity } from "../../entities/attachment.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetAttachmentThunkPayload {
  idAllegato: number;
}

export interface IGetAttachmentFulfilledPayload {
  attachment: IAttachmentEntity;
}

export const getAttachment = createAsyncThunk(
  'attachment/get',
  async (thunkPayload: IGetAttachmentThunkPayload, thunkAPI) => {
    try {
      const response = await AttachmentAPI.getAttachment(thunkPayload);
      const successPayload = response.data as unknown as IAttachmentEntity;
      const fulfillValue = {
        attachment: successPayload
      } as IGetAttachmentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteAttachmentThunkPayload {
  idAllegato: number;
}

export interface IDeleteAttachmentFulfilledPayload { }

export const deleteAttachment = createAsyncThunk(
  'attachment/delete',
  async (thunkPayload: IDeleteAttachmentThunkPayload, thunkAPI) => {
    try {
      const response = await AttachmentAPI.deleteAttachment(thunkPayload);
      const fulfillValue = {
      } as IDeleteAttachmentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesState {
  error: IRejectResponse | null;
  value: number;
}

const initialState: ICoursesState = {
  error: null,
  value: 0,
};

const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAttachment.pending, (state) => { });
    builder.addCase(getAttachment.rejected, (state, action) => { });
    builder.addCase(getAttachment.fulfilled, (state, action) => { });

    builder.addCase(deleteAttachment.pending, (state) => { });
    builder.addCase(deleteAttachment.rejected, (state, action) => { });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => { });
  },
});

export default attachmentSlice.reducer;