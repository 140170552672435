import { FC, Fragment } from 'react';
import { IUser } from '../entities/user.entity';
import ExpireAlert from './parts/ExpireAlert';
import Footer from './parts/Footer';
import Header from './parts/Header';
import SidebarAdmin from './parts/SidebarAdmin';
import MaintenanceAlert from './parts/MaintenanceAlert';

interface IProp {
  expire: string | null;
  user: IUser;
  isMaintenanceMode: boolean;
}

const LayoutAdmin: FC<IProp> = (props) => (
  <Fragment>
    <Header user={props.user} />
    <SidebarAdmin user={props.user} />
    <main>{props.children}</main>
    <ExpireAlert expire={props.expire} />
    <MaintenanceAlert isMaintenanceMode={props.isMaintenanceMode} />
    <Footer />
  </Fragment>
);

export default LayoutAdmin;
