import { IGetLineeGuidaThunkPayload } from '../redux/slices/lineeguida.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getLineeGuida = (payload: IGetLineeGuidaThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.LineeGuida, payload);
};

const LineeGuidaAPI = {
  getLineeGuida,
};

export default LineeGuidaAPI;