import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import RequestCertificationForm from '../../components/user/RequestCertificationForm';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';

const UserCreditNewRequest: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Nuova Certificazione / Esonero">
        <a href="/assets/tutorial/U_Certificazione.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Crea una nuova richiesta" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <RequestCertificationForm codiceFiscale={''} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserCreditNewRequest;