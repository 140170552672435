import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Button, Col, Form, Modal, ModalFooter, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GiConfirmed } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UnauthenticatedRoutes } from '../enums/routes.enum';
import { forgotPassword, IForgotPasswordThunkPayload } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';

interface IFormValue extends IForgotPasswordThunkPayload { }

const initialValues: IFormValue = {
  username: ''
};

interface IProps {
  verticalSpacing: number;
}

export const ForgotPasswordForm: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showOkToast, setShowOkToast] = useState(false);
  const [showKoToast, setShowKoToast] = useState(false);
  const styleIcon = { color: "768820" };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true)


  const schema = yup.object().shape({
    username: yup
      .string()
      .required(t('forgot-password.form.errors.fieldRequired'))
  });

  const handleSubmit = (value: IFormValue) => {
    setLoading(true);

    dispatch(forgotPassword(value as IForgotPasswordThunkPayload))
      .unwrap()
      .then(() => {
        setShowForm(false);
        setShowOkToast(true);
        setLoading(false);
        setTimeout(() => navigate(UnauthenticatedRoutes.Login), 3200);
      })
      .catch(() => {
        setShowKoToast(true);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} show={showForm}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className={`py-${props.verticalSpacing}`}>
              <Form.Group as={Col} controlId="username">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="text"
                  name="username"
                  placeholder={"Username o Email"}
                  disabled={isLoading}
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Col className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading} onClick={handleShow}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">{t('forgot-password.form.submitting')}</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>{t('forgot-password.form.submit')}</span>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {/*
      <Toast onClose={() => setShowOkToast(false)} show={showOkToast} delay={3000} autohide bg="success">
        <Toast.Header>{t('forgot-password.card.successBody')}</Toast.Header>
      </Toast>
      <Toast onClose={() => setShowKoToast(false)} show={showKoToast} delay={3000} autohide bg="danger">
        <Toast.Header>{t('forgot-password.card.rejectBody')}</Toast.Header>
      </Toast>
      */}

      <Modal show={showModal} onHide={handleClose} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <GiConfirmed style={styleIcon} /> Password dimenticata
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Presto riceverai un'e-mail che ti consentirà di reimpostare la tua password. Assicurati di controllare la cartella spam e il cestino se non ricevi la nostra e-mail.</Modal.Body>
        <ModalFooter>
          <Button variant="primary" href="/login">Torna al login</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ForgotPasswordForm;
