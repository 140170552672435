import { FC, Fragment, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMetadataOption, IMetadataOptionNode } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";

export const getOggetto = (id: number, list: Array<IMetadataOptionNode>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getOggettoOption = (id: number, list: Array<IMetadataOptionNode>): IMetadataOptionNode | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  selectAll?: boolean;
  triennio?: number;
  anno?: number;
  onSelectOggetto?: (option: IMetadataOption | null) => void;
}

const Oggetto: FC<IProps> = (props) => {

  const oggetti: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification?.oggetto_certificazione ? s.metadata.certification.oggetto_certificazione : []);
  const oggettiAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification?.oggetto_certificazione_all ? s.metadata.certification.oggetto_certificazione_all : []);

  const elencoOggetti = ((props?.anno && props.anno>2023) ? oggetti : (props?.selectAll && props.selectAll) ? oggettiAll : oggetti);

  const [filterByOggetto, setFilterByOggetto] = useState<IMetadataOption | null>(null);

  const handleFilterByOggetto = (option: IMetadataOption | null) => {
    setFilterByOggetto(option);
    if (props?.onSelectOggetto) {
      props.onSelectOggetto(option);
    }
  };

  useEffect(() => {
    console.log("anno o triennio cambiato: ", props);
    setFilterByOggetto(null);
  },  [props.anno, props.triennio]);

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary">
          {filterByOggetto != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Oggetto ${filterByOggetto ? filterByOggetto.nome : ''}`}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"idOggettoNone"} disabled={filterByOggetto == null} onClick={() => handleFilterByOggetto(null)}>Tutti</Dropdown.Item>
          {
            elencoOggetti.length ? (
              elencoOggetti.map((item: IMetadataOptionNode, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownIdOggetto" + item.id} disabled={filterByOggetto !== null && filterByOggetto.id === item.id} onClick={() => handleFilterByOggetto(item)}>
                    {item.nome}
                    </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />  
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Oggetto;