import { IMetadataTeacherThunkPayload } from '../redux/slices/metadata.slice';
import { ITeacherThunkPayload } from '../redux/slices/teacher.slice';
import { ITeachersThunkPayload } from '../redux/slices/teachers.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getTeachers = (payload: ITeachersThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.Teachers);
};

export const getMetadata = (payload: IMetadataTeacherThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.TeacherMetadata);
};

export const postTeacher = (payload: ITeacherThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.Teachers, payload.teacher);
};

export const putTeacher = (payload: ITeacherThunkPayload) => {
  const url = ApiEndpoints.Teacher.replace(':id', payload.teacher.idDocente.toString());
  return AxiosService.instance.axios.put(url, payload.teacher);
};

export const deleteTeacher = (payload: ITeacherThunkPayload) => {
  const url = ApiEndpoints.Teacher.replace(':id', payload.teacher.idDocente.toString());
  return AxiosService.instance.axios.delete(url);
};

const TeacherAPI = {
  getTeachers,
  getMetadata,
  postTeacher,
  putTeacher,
  deleteTeacher,
};

export default TeacherAPI;
