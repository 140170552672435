import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IUser } from '../../entities/user.entity';
import { UserRole } from '../../enums/user-roles.enum';
import { RootState } from '../../redux/store';
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

const Tutorials: FC = () => {
    const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value ? s.auth.user.value : null);

    return (
        <Container fluid as="section" className="mt-3">
            {(user != null && (user.role === UserRole.Admin || user.role === UserRole.Manager) ? (
                <>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/O_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ordine - Elenco corsi
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/O_Corso.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ordine - Crea / modifica corso
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/O_ElencoProfessionisti.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ordine - Elenco professionisti
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/O_ElencoCertificazioni.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ordine - Elenco certificazioni / esoneri
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/O_NuovaCertificazione.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ordine - Crea nuova certificazione
                            </a>
                        </Col>
                    </Row>
                </>
            ) : "")}

            {(user != null && (user.role === UserRole.User || user.role === UserRole.Guest || user.role === UserRole.Manager) ? (
                <>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Lista corsi pubblicati
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_CorsiFuturi.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Corsi a cui parteciperò
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_CreditiStatus.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Crediti formativi e status
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_DettaglioCorso.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Dettaglio corso
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_ElencoCertificazioni.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Elenco certificazioni / esoneri
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_Certificazione.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Nuova certificazione / esonero
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/U_MieiCorsi.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Professionista - Tutti i miei corsi
                            </a>
                        </Col>
                    </Row>
                </>
            ) : "")}
            {(user != null && (user.role === UserRole.Partner || user.role === UserRole.Manager) ? (
                <>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/E_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ente terzo - Elenco corsi
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <a href="/assets/tutorial/E_Corso.pdf" target="_blank" rel="noreferrer">
                                <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                                Ente terzo - Crea / modifica corso
                            </a>
                        </Col>
                    </Row>
                </>
            ) : "")}
            {/* <Row className="mb-3">
                <Col>
                    <a href="/assets/tutorial/CNAPPC_linee-guida-formazione_2024_ALL2.pdf" target="_blank" rel="noreferrer">
                        <BsFillFileEarmarkPdfFill className="text-primary mx-1" size={22} />
                        Linee guida formazione 2024
                    </a>
                </Col>
            </Row> */}
        </Container>
    );
};

export default Tutorials;