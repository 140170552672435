import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IProps {
  content: string;
  childrenClasses?: string;
}

const PageTitle: FC<IProps> = (props) => (
  <Container fluid as="section">
    <Row className="py-2">
      <Col className="d-flex align-items-start flex-column">
        <h1 className="m-0 mt-auto page-title text-uppercase ">{props.content}</h1>
      </Col>
      {props.children && (
        <Col className={props.childrenClasses || 'd-flex align-items-end flex-column'}>{props.children}</Col>
      )}
    </Row>
  </Container>
);

export default PageTitle;
