import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { ISubscriptionEntity } from '../../entities/subscription.entity';
import { SubscriptionState } from '../../enums/subscription-state.enum';
import { getCourseDetail, IGetCourseDetailThunkPayload } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { linkMoodleBaseUrl } from '../../utils/course-default';
import { setSubscriptionState } from '../../utils/course-parser';
import { getMetadataNomeById } from '../../utils/metadata.helper';
import CourseDetailAttachmentList from './CourseDetailAttachmentList';
import { getAreaFormativa } from './dropdown/course/AreaFormativa';
import { getErogazione } from './dropdown/course/Erogazione';
import { getTipologia } from './dropdown/course/Tipologia';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';

interface IProps {
  idCorso: number;
}

const CourseDetail: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const erogazioni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.erogazione_corso);
  const tipologie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);
  const categorie: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);
  const staticorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.stato_corso);
  const articolazionicorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.articolazione_corso);
  const accessibilitacorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.accessibilita_corso);
  const course: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.detail);
  const iscr: Array<ISubscriptionEntity> | null = useSelector((s: RootState) => s.course?.detail?.iscritti ? s.course.detail.iscritti.map(itm => setSubscriptionState(itm)) : null);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getCourseDetail({ id: props.idCorso } as IGetCourseDetailThunkPayload))
      .unwrap().finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, props.idCorso]);

  return (
    <Fragment>
      {
        (course && !isLoading) ? (
          <Fragment>
            {
              iscr && iscr.length > 0 && iscr[0].state === SubscriptionState.Confirmed && iscr[0].inAttesaAcconto === 0 && 
                course.pubblicazione.areaRisDescrizione ? (
                <div className="mb-3" dangerouslySetInnerHTML={{ __html: course.pubblicazione.areaRisDescrizione }} />
              ) : (<div />)
            }
            <Tabs defaultActiveKey="generale">
              <Tab eventKey="generale" title="Generale">
                <Container fluid as="section">
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0 d-flex justify-content-between">
                      <div>
                        {course.codice}
                      </div>
                      {
                        (course.moodleCourseId != null && course.moodleCourseId.length > 0) ? (
                          <Button href={linkMoodleBaseUrl + course.moodleCourseId} target="_blank" rel="noreferrer">
                            partecipa al corso MOODLE
                          </Button>
                        ) : ''
                      }
                    </Col>
                    <Col sm={12} className="text-muted small m-0 p-0"><small><small>codice</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      <b>{course.titolo}</b>
                    </Col>
                    <Col sm={12} className="text-muted small m-0 p-0"><small><small>titolo</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}
                    </Col>
                    <Col sm={12} className="text-muted small m-0 p-0"><small><small>soggetto proponente</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {course.mailInformazioni && course.mailInformazioni.length ? course.mailInformazioni : (<i>--</i>)}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>mail informazioni</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {getAreaFormativa(course.idAreaFormativa, categorie)}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>area formativa / categoria</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {getErogazione(course.idErogazione, erogazioni)}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>erogazione</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {getTipologia(course.idTipologia, tipologie)}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>tipologia</small></small></Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="valutazione" title="Valutazione">
                <Container fluid as="section">

                  {course.dataInizio && course.dataInizio.length ? (
                    <Fragment>
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {format(new Date(course.dataInizio), 'dd/MM/yyyy')}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>data inizio svolgimento</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}

                  {course.dataFine && course.dataFine.length ? (
                    <Fragment>
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {format(new Date(course.dataFine), 'dd/MM/yyyy')}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>data fine svolgimento</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}

                  {course.valutazione ? (
                    <Fragment>
                      {course.valutazione.idArticolazioneCorso && course.valutazione.idArticolazioneCorso > 0 ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {getMetadataNomeById(course.valutazione.idArticolazioneCorso, articolazionicorso)}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>articolazione corso</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}
                      {course.valutazione.idStato && course.valutazione.idStato > 0 ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {getMetadataNomeById(course.valutazione.idStato, staticorso)}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>stato</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {course.valutazione.flagSvolgimentoInSede ? 'Svolgimento in sede' : ''} {course.valutazione.indirizzoSede ? course.valutazione.indirizzoSede : ''}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>indirizzo sede svolgimento corso</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                      {course.valutazione.idAccessibilita && course.valutazione.idAccessibilita > 0 ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {getMetadataNomeById(course.valutazione.idAccessibilita, accessibilitacorso)}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>accessibilità per persone con disabilità</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}
                      {course.valutazione.frequenzaMin && course.valutazione.frequenzaMin > 0 ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {course.valutazione.frequenzaMin} %
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>frequenza minima</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                    </Fragment>
                  ) : (
                    <Fragment />
                  )}

                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {course.nOre}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>ore</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />
                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {Math.abs(course.cpfAssegnati)}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>CFP assegnati</small></small></Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="modalita" title="Modalità">
                <Container fluid as="section">
                  {course.nPartecipantiMin > 0 || course.nPartecipantiMax > 0 || course.nPartecipantiExt > 0 ? (
                    <Fragment>
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {course.nPartecipantiMin ? course.nPartecipantiMin : ' -- '} /
                          {course.nPartecipantiMax ? course.nPartecipantiMax : ' -- '} /
                          {course.nPartecipantiExt ? course.nPartecipantiExt : ' -- '}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>numero partecipanti: minimi / massimi / esterni</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}

                  <Row className="m-2">
                    <Col sm={12} className="blockquote m-0 p-0">
                      {course.flagAccettaIscrizioni ? "online " : "offline (contattando l'ente) "} -
                      {course.flagChiudiIscrizioni ? " chiuse" : " aperte nelle date indicate"}
                    </Col>
                    <Col sm={12} className="text-muted m-0 p-0"><small><small>iscrizioni</small></small></Col>
                  </Row>
                  <hr className="text-muted m-0" />

                  {course.modalita ? (
                    <Fragment>
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {
                            course.modalita.dataIscrizioneDal && course.modalita.dataIscrizioneAl ?
                              "dal " + (format(new Date(course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')) + " al " + (format(new Date(course.modalita.dataIscrizioneAl), 'dd/MM/yyyy'))
                              : course.modalita.dataIscrizioneDal ?
                                "dal " + (format(new Date(course.modalita.dataIscrizioneDal), 'dd/MM/yyyy'))
                                : course.modalita.dataIscrizioneAl ?
                                  "fino al " + (format(new Date(course.modalita.dataIscrizioneAl), 'dd/MM/yyyy'))
                                  : course.dataInizio ?
                                    "fino al " + (format(new Date(course.dataInizio), 'dd/MM/yyyy'))
                                    :
                                    "fino ad esaurimento posti"
                          }
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>apertura iscrizioni</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                      {course.modalita.dettaglioModalitaIscrizione ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {course.modalita.dettaglioModalitaIscrizione}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>dettaglio modalità iscrizione</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {
                            (course.modalita.descIscrizioneA || course.modalita.accontoIscrizioneA || course.modalita.saldoIscrizioneA) ?
                              ((course.modalita.descIscrizioneA && course.modalita.descIscrizioneA.length > 0) ? course.modalita.descIscrizioneA : "") +
                              ((course.modalita.accontoIscrizioneA && course.modalita.accontoIscrizioneA > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneA.toLocaleString() + " €)" : "")
                              :
                              "Iscrizione GRATUITA"
                          }
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>Per gli appartenenti all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />

                      {course.modalita.flagIscrizioneIntB ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {
                                (course.modalita.descIscrizioneB || course.modalita.accontoIscrizioneB || course.modalita.saldoIscrizioneB) ?
                                  ((course.modalita.descIscrizioneB && course.modalita.descIscrizioneB.length > 0) ? course.modalita.descIscrizioneB : "") +
                                  ((course.modalita.accontoIscrizioneB && course.modalita.accontoIscrizioneB > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneB.toLocaleString() + " €)" : "")
                                  :
                                  "Iscrizione GRATUITA"
                              }
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Per gli NON appartenenti all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      {course.modalita.flagIscrizioneIntC ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {
                                (course.modalita.descIscrizioneC || course.modalita.accontoIscrizioneC || course.modalita.saldoIscrizioneC) ?
                                  ((course.modalita.descIscrizioneC && course.modalita.descIscrizioneC.length > 0) ? course.modalita.descIscrizioneC : "") +
                                  ((course.modalita.accontoIscrizioneC && course.modalita.accontoIscrizioneC > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneC.toLocaleString() + " €)" : "")
                                  :
                                  "Iscrizione GRATUITA"
                              }
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Per i NEOISCRITTI all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                          </Row>
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                    </Fragment>
                  ) : (
                    <Fragment />
                  )}

                </Container>
              </Tab>
              <Tab eventKey="pubblicazione" title="Pubblicazione">
                <Container fluid as="section">
                  <Row className="m-2">
                    <Col sm={12}>
                      {
                        course.pubblicazione.areaPubDescrizione ? (
                          <div dangerouslySetInnerHTML={{ __html: course.pubblicazione.areaPubDescrizione }} />
                        ) : ""
                      }
                    </Col>
                  </Row>
                  <Row className="m-2">
                    <Col sm={12}>
                      <CourseDetailAttachmentList idCorso={props.idCorso} />
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Fragment>
        ) : (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        )}
    </Fragment>
  );
};

export default CourseDetail;