import { FC, Fragment } from 'react';

const LoginBackgroundMobile: FC = () => {
  return (
    <Fragment>
      <section className="position-fixed min-vw-100 min-vh-100 login-background d-block d-md-none">
        <div className="logo position-relative">
          <img className="position-absolute top-100 start-50 translate-middle-x mt-5" src="/assets/logo.svg" alt="logo" />
        </div>
        <div className="d-flex align-items-end fixed-bottom justify-content-between">
          <img src="/assets/buildings-left.svg" height={190} alt="buildings" />
          <div></div>
          <img src="/assets/buildings-right.svg" height={190} alt="buildings" />
        </div>
      </section>
    </Fragment>
  );
};

export default LoginBackgroundMobile;
