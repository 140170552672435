import { Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IAttachment } from '../../entities/attachment.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPostCertificationThunkPayload, postCertification } from '../../redux/slices/certification.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';
import { getLineeGuida } from '../../redux/slices/lineeguida.slice';
import { ILineaGuidaEntity } from '../../entities/lineeguida.entity';

interface IFormValue extends IPostCertificationThunkPayload {
    responsabilita: boolean;
    triennio: number;
    dataRiferimento: Date | null;
    dataApprovazione: Date | null;
};

interface IProps {
    codiceFiscale: string
}

const RequestCertificationForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [tipoFileErrato, setTipoFileErrato] = useState<string>('');

    const oggettoCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
    // const tipologiaCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipologia_certificazione);
    const tipoAccreditoCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
    const tipoEsoneroCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
    const [tipologiaCertificazione, setTipologiaCertificazione] = useState<Array<IMetadataOptionNode>>([]);
    const [tipologiaCertificazioneLG, setTipologiaCertificazioneLG] = useState<Array<IMetadataOptionNode>>([]);

    const trienni: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.trienni);
    const [trienniSelezionabili, setTrienniSelezionabili] = useState<Array<IMetadataOptionNode>>([]);

    const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);
    const [lineaGuida, setlineaGuida] = useState<number>(0);
    const [dataRiferimentoInserita, setDataRiferimentoInserita] = useState<boolean>(false);
    const idLineaGuidaCorrente: number | null = useSelector((s: RootState) => s.lineeGuida.idLineaGuidaCorrente); 
    const lineaGuidaCorrente = lineeGuida.find((lg) => lg.id === idLineaGuidaCorrente);
    const [dataRiferimentoAbilitata, setDataRiferimentoAbilitata] = useState<boolean>(false);
    const [dataInizioTriennio, setDataInizioTriennio] = useState<Date | null>(null);

    const initialValues: IFormValue = {
        codiceFiscale: props.codiceFiscale,
        idOggetto: 0,
        idTipologia: 0,
        annoRiferimento: new Date().getFullYear(),
        dataRiferimentoDa: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        dataRiferimentoA: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 11, 31).toISOString()),
        dataApprovazione: null,
        dataRiferimento: null,
        titolo: '',
        organizzatore: '',
        codiceCorso: '',
        cfpDichiarati: 0,
        cfpAssegnati: 0,
        noteRichiedente: '',
        idStatoCertificazione: 1,
        responsabilita: false,
        triennio: 4,
        allegati: new Array<IAttachment>(),
    };

    const handleCloseTipoFileErrato = () => {
        setTipoFileErrato('');
    };

    useEffect(() => {
        if (lineaGuidaCorrente) {
            setDataRiferimentoAbilitata(true);
        }
    }, [lineaGuidaCorrente]);

    const today = new Date();
    useEffect(() => {
        if (trienni && trienni.length > 0) {
            setTrienniSelezionabili(trienni.filter((item) => new Date(item.data) > today));
        }
    }, [trienni]);

    useEffect(() => {
        if (trienniSelezionabili && trienniSelezionabili.length > 0) {
            const ultimoTriennio = trienniSelezionabili[trienniSelezionabili.length - 1]; 
            const dataInizio = new Date(ultimoTriennio.minimo.toString() + "-01-01T00:00:00");
            setDataInizioTriennio(dataInizio);        
        // console.log("trienni selezionabili: ", trienniSelezionabili);
        }
    }, [trienniSelezionabili]);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {
            // console.log("handleDateChange: ", e.target.value);
            // console.log("data inizio triennio selezionato: ", dataInizioTriennio);

            setFieldValue('cfpDichiarati', 0);
            setFieldValue('idTipologia', 0);
            setFieldValue('idOggetto', 0);
            setTipologiaCertificazioneLG([]);
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            const selectedDate = new Date(value + "T00:00:00");
            const dataInizio = new Date(lineaGuidaCorrente?.inizioValidita ? lineaGuidaCorrente.inizioValidita : new Date());
            if (name === "dataRiferimento" && dataInizioTriennio!= null && (dataInizioTriennio < selectedDate)) {
                setDataRiferimentoInserita(true);
            } else {
                setTipologiaCertificazioneLG([]);
                setDataRiferimentoInserita(false);
            }
            
            const lg = lineeGuida.filter((item) => (new Date(item.inizioValidita)) < selectedDate && 
            ((item.fineValidita === null) || (new Date(item.fineValidita)) > selectedDate)).pop()?.id || 0;

            // console.log("linea guida selezionata:", lg);
            setlineaGuida(lg);
    };

    const handleOggettoChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleOggettoChange custom, ", e.target.name, ": ", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            // console.log("scelto oggetto: ", e.target.value, " - linea guida:", lineaGuida);
            if (value === "1" && lineaGuida > 0) {
                // metaTipologiaCorso.filter(item => item.lg === lineaGuida);
                setTipologiaCertificazioneLG(tipoAccreditoCertificazione.filter(item => item.lg === lineaGuida));                
            } else if (value === "3" && lineaGuida > 0) {                
                setTipologiaCertificazioneLG(tipoEsoneroCertificazione.filter(item => item.lg === lineaGuida));
            } else {
                setTipologiaCertificazioneLG([]);
            }
            // console.log(tipologiaCertificazioneLG);
    }
    
    const handleGenericChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleGenericChange custom:", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
    }

    const getCfpValidationSchema = (lineaGuida: number, idTipologia: number) => {
        console.log("linea guida:", lineaGuida);
        console.log("id tipologia:", idTipologia);
        return yup.number().required("Campo obbligatorio")
        .when(['lineaGuida', 'idTipologia'], {
            is: (lineaG: number, id: number) => {
            console.log("linea guida:", lineaGuida, " id:", id);
            return tipologiaCertificazioneLG.some(
                (tip) => tip.id === id && tip.lg === lineaGuida
            );
            },
            then: schema => {
                const rule = tipologiaCertificazioneLG.find(
                    (tip) => tip.id === idTipologia && tip.lg === lineaGuida
                );
                // console.log("rule:", rule);

                return rule ? schema.min(rule.minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                .test('max-rule', 'Errore di validazione! Valore massimo consentito ${}', function(value) {
                    if (rule.massimo === 0) {
                        return true;
                    }
                    return (value == null || value <= rule.massimo) || this.createError({ message: `Errore di validazione! Valore massimo consentito ${rule.massimo}` });
                }) : schema;
            },
            otherwise: yup.number().max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`),
        });
    };

      // Function to build the dynamic validation schema for `cfpDichiarati`
//     const getCfpValidationSchema = (idOggetto: number, idTipologia: number) => {
//         return yup.number().required("Campo obbligatorio")
//         .when(['idOggetto', 'idTipologia'], {
//         is: (idOggetto: number, id: number) => {
//           // Check se esiste una coppia idOggetto / idTipologia valida come chiave di ricerca delle tipologie di certificazione
//           return tipologiaCertificazione.some(
//             (tip) => tip.id === id && tip.extId === idOggetto
//           );
//         },
//         then: schema => {
//             const rule = tipologiaCertificazione.find(
//                 (tip) => tip.id === idTipologia && tip.extId === idOggetto
//             );

//             return rule ? schema.min(rule.minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
//             .max(rule.massimo, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`) : schema;
//         },
//         otherwise: yup.number().max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`),
//       });
//   };

    // const schema = yup.object().shape({
    //     triennio: yup.number().required("Campo obbligatorio"),
    //     idOggetto: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
    //     idTipologia: yup.number().required("Campo obbligatorio"),
    //     titolo: yup.string().max(500, 'Errore di validazione! Valore massimo consentito di caratteri 500'),
    //     organizzatore: yup.string().max(250, 'Errore di validazione! Valore massimo consentito di caratteri 250'),
    //     codiceCorso: yup.string().max(50, 'Errore di validazione! Valore massimo consentito di caratteri 50'),
    //     cfpDichiarati: yup.lazy((value, {parent}) => getCfpValidationSchema(parent.idOggetto, parent.idTipologia)),
    //     responsabilita: yup.bool().oneOf([true], "Accetta l'assunzione di responsabilita per proseguire"),
    //     annoRiferimento: yup.number().min(2023, 'non si può selezionare un triennio chiuso').max(2025, 'non si può selezionare un triennio non ancora aperto').required("Selezionare anno di riferimento"),
    //     noteRichiedente: yup.string().max(300, 'Errore di validazione! Valore massimo consentito di caratteri 300')
    // });

    const schema = yup.object().shape({
        triennio: yup.number().required("Campo obbligatorio"),
        idOggetto: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        idTipologia: yup.number().required("Campo obbligatorio"),
        titolo: yup.string().max(500, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
        organizzatore: yup.string().max(250, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
        // codiceCorso: yup.string().max(50, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
        cfpDichiarati: yup.lazy((value, {parent}) => getCfpValidationSchema(lineaGuida, parent.idTipologia)),
        responsabilita: yup.bool().oneOf([true], "Accetta l'assunzione di responsabilita per proseguire"),
        dataRiferimento: yup.date()
            .min(dataInizioTriennio || new Date(), `non si può selezionare un triennio chiuso`)
            .required("La data di riferimento è obbligatoria"),
        noteRichiedente: yup.string().max(300, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
        // dataApprovazione: yup.date().max(new Date(), "la data non può essere nel futuro").required("Selezionare data approvazione"),
    });

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [submittedConfirmed, setSubmittedConfirmed] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmittedConfirmed(true);
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        dispatch(getMetadataCertification({}));
        dispatch(getLineeGuida({}));
        dispatch(getMetadataCourse({}));
    }, [dispatch]);

    // const [uploadAttachment, setUploadAttachment] = useState<IAttachment>({
    //     filename: '',
    //     contentType: '',
    //     contentRaw: '',
    // });

    const [uploadAttachment, setUploadAttachment] = useState<IAttachment[]>([]);

    const readFileContents = async (file: File) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(fileReader.result);
                if (e.target?.result) {
                    const res = e.target.result;

                    setUploadAttachment(oa => [...oa, {
                        filename: file.name,
                        contentType: file.type,
                        contentRaw: res.toString(),
                    }]);
                }
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(file);
        });
    };
    const readAllFiles = async (AllFiles: Array<File>) => {
        const results = await Promise.all(
            AllFiles.map(async file => {
                const fileContents = await readFileContents(file);
                return fileContents;
            })
        );
        // console.log(results, "resutls");
        setIsDisable(false);
        return results;
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;
        let AllFiles = [];

        if (files.length > 0 && files.length < 5) {
            //console.log("tot files: " + files.length);
            let tipiValidi = true;
            for (var i = 0; i < files.length; i++) {
                AllFiles.push(files[i]);

                if (!((files[i].type === "application/pdf") || (files[i].type === "application/x-zip-compressed"))) {
                    tipiValidi = false;
                }
            }

            if (tipiValidi) {
                readAllFiles(AllFiles)
                    .then(result => {
                        result.map(r => {
                            console.log(r);
                        });
                    });

                // setIsDisable(false);
            } else {
                setTipoFileErrato('tipo file errato, consentiti file zip e pdf');
                setUploadAttachment([]);
                event.currentTarget.value = "";
            }
        } else {
            setTipoFileErrato('il numero massimo di file consentito è 4');
            setUploadAttachment([]);
            event.currentTarget.value = "";
        }

        // if (files.length) {
        //     const file_name = files[0].name;
        //     const file_type = files[0].type;

        //     const fileReader = new FileReader();
        //     fileReader.onload = (e: ProgressEvent<FileReader>) => {
        //         if (e.target?.result) {
        //             const res = e.target.result;
        //             setUploadAttachment({
        //                 filename: file_name,
        //                 contentType: file_type,
        //                 contentRaw: res.toString(),
        //             });
        //         }
        //     };

        //     fileReader.readAsDataURL(files[0]);
        //     setIsDisable(false);
        // }
    };

    const handleSubmit = (value: IFormValue) => {
        console.log("submitting");
        //value.allegati.push(uploadAttachment);
        // const val: IFormValue = { ...value, cfpAssegnati: value.cfpDichiarati, allegati: [uploadAttachment] };

        // console.log(value.annoRiferimento);
        // value.dataRiferimentoDa = parseISOStringToStandardDate(new Date(value.annoRiferimento, 0, 1).toISOString());
        // value.dataRiferimentoA = parseISOStringToStandardDate(new Date(value.annoRiferimento, 11, 31).toISOString());
        // console.log(value.dataRiferimentoDa);
        // console.log(value.dataRiferimentoA);
        const val: IFormValue = { ...value, cfpAssegnati: value.cfpDichiarati, allegati: uploadAttachment };
        setError(false);
        setLoading(true);
        dispatch(postCertification(val))
            .unwrap()
            .then(() => {
                // console.log("post completata");
                setShow(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <div className={`${submittedConfirmed ? 'd-none' : ''}`}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue, setFieldTouched }) => (
                        <div className="mx-3">
                            <Form noValidate onSubmit={handleSubmit}>                                
                                
                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Triennio<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="triennio"
                                            value={values.triennio}
                                            // verificare se + l'handle giusto
                                            onChange={(e) => handleOggettoChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            disabled={isLoading || submittedConfirmed}
                                            isInvalid={!!touched.triennio && !!errors.triennio}
                                        >
                                            {trienniSelezionabili.map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idTriennio" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.triennio}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Data riferimento</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control type="date" 
                                        onChange={(e) => handleDateChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)} // Usa la funzione custom
                                            name="dataRiferimento" value={values?.dataRiferimento?.toString() || ""}
                                            isInvalid={!!touched.dataRiferimento && !!errors.dataRiferimento}
                                            disabled={isLoading || submittedConfirmed || !dataRiferimentoAbilitata} />
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimento}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Oggetto<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idOggetto"
                                            value={values.idOggetto}
                                            onChange={(e) => handleOggettoChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            // onChange={handleChange}
                                            disabled={isLoading|| !dataRiferimentoInserita || submittedConfirmed}
                                            isInvalid={!!touched.idOggetto && !!errors.idOggetto}
                                        >
                                            <option key={"idOggetto0"} value={0}>Seleziona oggetto</option>
                                            {oggettoCertificazione.map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idOggetto" + item.id + index} value={item.id}>{item.nome}</option>);
                                            // <option key={'idOggetto0000'} value={0}>Seleziona oggetto</option>
                                            // {oggettoCertificazione.filter((itm: IMetadataOptionNode) => itm.id === 1 || itm.id === 3).map((item: IMetadataOptionNode, index: number) => {
                                            //     return (<option key={"idOggetto" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.idOggetto}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idTipologia"
                                            onChange={handleChange}
                                            disabled={isLoading || !dataRiferimentoInserita || submittedConfirmed}
                                            isInvalid={!!touched.idTipologia && !!errors.idTipologia}
                                            value={values.idTipologia}>
                                            <option key={"idTipologia0"} value={0}></option>
                                            {tipologiaCertificazioneLG.length && (tipologiaCertificazioneLG.map((item: IMetadataOption, index: number) => {
                                                return (<option key={"idTipologia" + item.id} value={item.id}>{item.nome}</option>);
                                            }))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.idTipologia}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className={`my-3 ${values.idOggetto == 1 ? '' : 'd-none'}`} >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Titolo corso</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="titolo"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.titolo}
                                            onChange={handleChange}
                                            isInvalid={!!touched.titolo && !!errors.titolo}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className={`my-3 ${values.idOggetto == 1 ? '' : 'd-none'}`}>
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Organizzatore</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="organizzatore"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.organizzatore}
                                            onChange={handleChange}
                                            isInvalid={!!touched.organizzatore && !!errors.organizzatore}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.organizzatore}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>CFP dichiarati<Asterisk size={8} className="mb-3" />
                                        <a href="/assets/tutorial/CNAPPC_linee-guida-formazione_2024_ALL2.pdf" target="_blank" rel="noreferrer">
                                            <FaInfoCircle className="text-dark" title="Vedi la tabella di assegnazione cfp" size={22} />
                                        </a>
                                    </Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            // min={0}
                                            type="number"
                                            name="cfpDichiarati"
                                            disabled={isLoading || submittedConfirmed || !values.idTipologia || values.idTipologia == 0}
                                            value={values.cfpDichiarati}
                                            onChange={(e) => {handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}}
                                            isInvalid={!!touched.cfpDichiarati && !!errors.cfpDichiarati}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cfpDichiarati}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Carica allegato<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="file" multiple
                                            name="allegato"
                                            disabled={isLoading || submittedConfirmed}
                                            onChange={handleFileUpload}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Note</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="textarea"
                                            name="noteRichiedente"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.noteRichiedente}
                                            onChange={handleChange}
                                            isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Assunzione di responsabilità<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Check type="switch">
                                            <Form.Check.Input
                                                onChange={handleChange}
                                                name="responsabilita"
                                                defaultChecked={values.responsabilita}
                                                disabled={isLoading || submittedConfirmed}
                                                isInvalid={!!touched.responsabilita && !!errors.responsabilita}
                                            />
                                            <Form.Check.Label>
                                                <i>Tutte le informazioni sono soggette alla normativa vigente relativa alle <b>sanzioni penali</b>, nel caso di dichiarazioni mendaci, di formazione o uso di atti falsi, richiamate dall'art. 76 del D.P.R. 28/12/2000 n.445 in materia di Documentazione Amministrativa.</i>
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">{errors.responsabilita}</Form.Control.Feedback>
                                        </Form.Check>
                                    </Col>
                                </Form.Group>

                                {
                                    error ? (
                                        <Alert variant='danger' className="my-3">
                                            Si è verificato un errore durante il salvataggio della richiesta.
                                        </Alert>
                                    ) : (
                                        <Fragment />
                                    )
                                }

                                <Button type="submit" className="w-10" size="lg" variant="primary" disabled={isLoading || isDisable || submittedConfirmed}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendi</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Invia</span>
                                    )}
                                </Button>
                                <p className="my-1">Caricare almeno un allegato per abilitare il bottone</p>
                            </Form>
                        </div>
                    )
                    }
                </Formik>
            </div>

            <Modal show={show} centered>
                <Modal.Header >
                    <Modal.Title>Operazione completata con successo!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <CheckCircleFill size={100} className="text-success" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={tipoFileErrato.length > 0} centered>
                <Modal.Header >
                    <Modal.Title>Errore! </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p>{tipoFileErrato}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseTipoFileErrato} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RequestCertificationForm;

