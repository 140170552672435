import { FC, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import CourseImportFromImateria from '../../components/shared/CourseImportFromImateria';

const PartnerCourseImportFromImateria: FC = () => {
    return (
        <Fragment>
            <PageTitle content="Import" />
            <PageSubtitle content="Importa lista corsi" />
            <PageParagraph content="" />
            <Container fluid as="section">
                <CourseImportFromImateria />
            </Container>
        </Fragment>
    );
};

export default PartnerCourseImportFromImateria;