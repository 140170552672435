import { IDeleteCourseAttachmentThunkPayload, IDeleteCourseThunkPayload, IDeleteSubscriptionAndPendingPaymentThunkPayload, IDeleteSubscriptionAndPendingPaymentWithTimingThunkPayload, IDeleteSubscriptionsThunkPayload, IGetCourseDetailThunkPayload, IGetCoursePaymentReceiptThunkPayload, IGetCourseThunkPayload, IGetSubscriberExportThunkPayload, IGetSubscribersThunkPayload, IPostAddToPendingListThunkPayload, IPostCourseAttachmentThunkPayload, IpostCourseTeacherThunkPayload, IPostCourseThunkPayload, IPostSubscriberCustomImportThunkPayload, IPostSubscriberImportThunkPayload, IPostSubscriberThunkPayload, IPostSubscribeThunkPayload, IPutCheckMoodleCompletionThunkPayload, IPutCourseThunkPayload, IPutSubscriptionsStatusThunkPayload, IPutSubscriptionThunkPayload, IPutUnsubscribeCourseThunkPayload, IGetPaymentTReceiptThunkPayload } from '../redux/slices/course.slice';
import { IMyCoursesExportThunkPayload,ICoursesExportThunkPayload, ICoursesMeThunkPayload, ICoursesPublishedThunkPayload, IGetCoursesThunkPayload, IPostCoursesImportThunkPayload } from '../redux/slices/courses.slice';
import { IMetadataCourseThunkPayload } from '../redux/slices/metadata.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getCourses = (payload: IGetCoursesThunkPayload) => {
  let endpoint = ApiEndpoints.CoursesRich.toString();
  let querystring = Array<string>();
  if (payload.pagination.page && payload.pagination.page > 0) {
    querystring.push('page=' + payload.pagination.page.toString());
  }
  if (payload.pagination.limit && payload.pagination.limit > 0) {
    querystring.push('limit=' + payload.pagination.limit.toString());
  }
  if (payload.sorting.orderby && payload.sorting.orderby.length > 0) {
    querystring.push('orderby=' + payload.sorting.orderby.join(','));
    if (payload.sorting.ordertype) {
      querystring.push('ordertype=desc');
    }
    else {
      querystring.push('ordertype=asc');
    }
  }
  if (payload.filter.supplier && payload.filter.supplier > 0) {
    querystring.push('supplier=' + payload.filter.supplier.toString());
  }
  if (payload.filter.annoInizio && payload.filter.annoInizio > 0) {
    querystring.push('annoInizio=' + payload.filter.annoInizio.toString());
  }
  if (payload.filter.meseInizio && payload.filter.meseInizio > 0) {
    querystring.push('meseInizio=' + payload.filter.meseInizio.toString());
  }
  if (payload.filter.tipologia && payload.filter.tipologia > 0) {
    querystring.push('tipologia=' + payload.filter.tipologia.toString());
  }
  if (payload.filter.erogazione && payload.filter.erogazione > 0) {
    querystring.push('erogazione=' + payload.filter.erogazione.toString());
  }
  if (payload.filter.areaFormativa && payload.filter.areaFormativa > 0) {
    querystring.push('areaFormativa=' + payload.filter.areaFormativa.toString());
  }
  if (payload.filter.statoPubblicazione && payload.filter.statoPubblicazione.length > 0) {
    querystring.push('statoPubblicazione=' + payload.filter.statoPubblicazione.join(','));
  }
  if (payload.filter.triennio && payload.filter.triennio > 0) {
    querystring.push('triennio=' + payload.filter.triennio);
  }
  if (payload.filter.tipoAzienda > (-1)) {
    querystring.push('tipoAzienda=' + payload.filter.tipoAzienda.toString());
  }
  if (payload.filter.text && payload.filter.text.length > 0) {
    querystring.push('text=' + payload.filter.text);
  }
  if (payload.filter.full && payload.filter.full > 0) {
    querystring.push('full=' + payload.filter.full);
  } else {
    querystring.push('full=0');
  }
  if (payload.filter.eventoGratuito && payload.filter.eventoGratuito > 0) {
    querystring.push('eventoGratuito=' + payload.filter.eventoGratuito.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getCoursesPublished = (payload: ICoursesPublishedThunkPayload) => {
  let endpoint = ApiEndpoints.CoursesPublished.toString();
  let querystring = Array<string>();
  if (payload.pagination.page > 0) {
    querystring.push('page=' + payload.pagination.page.toString());
  }
  if (payload.pagination.limit > 0) {
    querystring.push('limit=' + payload.pagination.limit.toString());
  }
  if (payload.sorting.orderby.length > 0) {
    querystring.push('orderby=' + payload.sorting.orderby.join(','));
    if (payload.sorting.ordertype) {
      querystring.push('ordertype=desc');
    }
    else {
      querystring.push('ordertype=asc');
    }
  }
  if (payload.filter.tipoAzienda > 0) {
    if (payload.filter.tipoAzienda === 1) {
      querystring.push('tipoAzienda=1');
    }
    if (payload.filter.tipoAzienda === 2) {
      querystring.push('tipoAzienda=0');
    }
  }
  if (payload.filter.company > 0) {
    querystring.push('company=' + payload.filter.company.toString());
  }
  if (payload.filter.partner > 0) {
    querystring.push('supplier=' + payload.filter.partner.toString());
  }
  if (payload.filter.eventoGratuito > 0) {
    querystring.push('eventoGratuito=' + payload.filter.eventoGratuito.toString());
  }
  if (payload.filter.tipologia > 0) {
    querystring.push('tipologia=' + payload.filter.tipologia.toString());
  }
  if (payload.filter.erogazione > 0) {
    querystring.push('erogazione=' + payload.filter.erogazione.toString());
  }
  if (payload.filter.categoria > 0) {
    querystring.push('areaFormativa=' + payload.filter.categoria.toString());
  }
  if (payload.filter.text.length > 0) {
    querystring.push('text=' + payload.filter.text);
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getMetadata = (_payload: IMetadataCourseThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.CourseMetadata);
};

export const getCourse = (payload: IGetCourseThunkPayload) => {
  const url = ApiEndpoints.Course.replace(':id', payload.id.toString());
  return AxiosService.instance.axios.get(url);
};

export const checkMoodleCompletion = (payload: IPutCheckMoodleCompletionThunkPayload) => {
  const url = ApiEndpoints.CourseSubscriptionByMoodleInspect.replace(':id', payload.id.toString()).replace(':cf', payload.cf.toString());
  return AxiosService.instance.axios.put(url);
}

export const getCourseDetail = (payload: IGetCourseDetailThunkPayload) => {
  const url = ApiEndpoints.CourseDetail.replace(':id', payload.id.toString());
  return AxiosService.instance.axios.get(url);
};

export const postCourse = (payload: IPostCourseThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.AddCourse, payload.course);
};

export const putCourse = (payload: IPutCourseThunkPayload) => {
  const url = ApiEndpoints.EditCourse.replace(':id', payload.course.id.toString());
  return AxiosService.instance.axios.put(url, payload.course);
};

export const postSubscriberImport = (payload: IPostSubscriberImportThunkPayload) => {
  const url = ApiEndpoints.SubscriberImport.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.post(url, payload);
};

export const postSubscriber = (payload: IPostSubscriberThunkPayload) => {
  const url = ApiEndpoints.AddSubscriber.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.post(url, payload);
};

export const postSubscribe = (payload: IPostSubscribeThunkPayload) => {
  const url = ApiEndpoints.AddSubscriber.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.post(url, payload);
};

export const getSubscribers = (payload: IGetSubscribersThunkPayload) => {
  const url = ApiEndpoints.CourseSubscribers.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.get(url);
};

export const postCourseTeacher = (payload: IpostCourseTeacherThunkPayload) => {
  const url = ApiEndpoints.CourseTeacher.replace(':id', payload.idCorso.toString()).replace(':id', payload.idDocente.toString());
  return AxiosService.instance.axios.post(url, payload);
};

export const putSubscription = (payload: IPutSubscriptionThunkPayload) => {
  let url = ApiEndpoints.EditSubscription.toString();
  url = url.replace(':id', payload.subscription.idCorso.toString());
  url = url.replace(':cf', payload.subscription.codiceFiscale.toString());
  url = url.replace(':state', payload.subscription.state.toString());
  return AxiosService.instance.axios.put(url, payload.subscription);
};

export const putSubscriptionsStatus = (payload: IPutSubscriptionsStatusThunkPayload) => {
  let url = ApiEndpoints.EditSubscriptionsStatus.toString();
  url = url.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.put(url, payload.param);
};

export const getCoursesMe = (_payload: ICoursesMeThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.CoursesMe);
};

export const getCoursesExport = (payload: ICoursesExportThunkPayload) => {
  let endpoint = ApiEndpoints.CoursesExport.toString();
  let querystring = Array<string>();
  if (payload.statoPubblicazione != null) {
    querystring.push('statoPubblicazione=' + payload.statoPubblicazione.toString());
  }
  if (payload.own != null) {
    querystring.push('own=' + payload.own.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getMyCoursesExport = (payload: IMyCoursesExportThunkPayload) => {
  let endpoint = ApiEndpoints.MyCoursesExport.toString().replace(':codice_fiscale',payload.codice_fiscale);
  const url = endpoint;
  return AxiosService.instance.axios.get(url);
};

export const postSubscriberCustomImport = (payload: IPostSubscriberCustomImportThunkPayload) => {
  const url = ApiEndpoints.SubscriberCustomImport.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.post(url, payload);
};

export const getSubscriberExport = (payload: IGetSubscriberExportThunkPayload) => {
  const url = ApiEndpoints.SubscriberExport.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.get(url);
};

export const postCourseAttachment = (payload: IPostCourseAttachmentThunkPayload) => {
  let url = ApiEndpoints.CourseAttachment.toString();
  url = url.replace(':id', payload?.idCorso ? payload.idCorso.toString() : '');
  return AxiosService.instance.axios.post(url, payload);
};

export const deleteCourseAttachment = (payload: IDeleteCourseAttachmentThunkPayload) => {
  const url = ApiEndpoints.DeleteAttachment.replace(':id', payload.idAllegato.toString());
  return AxiosService.instance.axios.delete(url);
};

export const deleteCourse = (payload: IDeleteCourseThunkPayload) => {
  const url = ApiEndpoints.DeleteCourse.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.delete(url);
};

export const deleteSubscriptions = (payload: IDeleteSubscriptionsThunkPayload) => {
  const url = ApiEndpoints.DeleteSubscription.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.put(url, payload.ids);
};

export const getCoursePaymentReceipt = (payload: IGetCoursePaymentReceiptThunkPayload) => {
  const url = ApiEndpoints.CoursePaymentReceipt.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.get(url);
};

export const postCoursesImport = (payload: IPostCoursesImportThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.CoursesImport, payload);
};

export const putUnsubscribeCourse = (payload: IPutUnsubscribeCourseThunkPayload) => {
  let url = ApiEndpoints.UnsubscribeCourseUser.replace(':id_corso', payload.idCorso.toString());
  url = url.replace(':codiceFiscale', payload.codiceFiscale);
  return AxiosService.instance.axios.put(url);
};

export const deleteSubscriptionAndPendingPayment = (payload: IDeleteSubscriptionAndPendingPaymentThunkPayload) => {
  let url = ApiEndpoints.DeleteSubscriptionAndPendingPaymentUser.toString();
  url = url.replace(':id', payload.idCorso.toString());
  url = url.replace(':codiceFiscale', payload.codiceFiscale.toString());
  return AxiosService.instance.axios.delete(url);
};

export const deleteSubscriptionAndPendingPaymentWithTiming = (payload: IDeleteSubscriptionAndPendingPaymentWithTimingThunkPayload) => {
  let url = ApiEndpoints.DeleteSubscriptionAndPendingPaymentWithTimingUser.toString();
  url = url.replace(':id', payload.idCorso.toString());
  url = url.replace(':codiceFiscale', payload.codiceFiscale.toString());
  return AxiosService.instance.axios.delete(url);
};

export const postAddToPendingList = (payload: IPostAddToPendingListThunkPayload) => {
  let url = ApiEndpoints.AddToPendingList.toString();
  url = url.replace(':id', payload.idCorso.toString());
  return AxiosService.instance.axios.post(url);
};

export const getPaymentTReceipt = (payload: IGetPaymentTReceiptThunkPayload) => {
  let url = ApiEndpoints.GetPaymentTReceipt.toString();
  url = url.replace(':id', payload.idCorso.toString());
  url = url.replace(':cf', payload.codiceFiscale.toString());
  return AxiosService.instance.axios.get(url);
};

const CourseAPI = {
  getCourses,
  getCoursesPublished,
  getMetadata,
  getCourse,
  getCourseDetail,
  postCourse,
  putCourse,
  postSubscriberImport,
  postSubscriber,
  postSubscribe,
  getSubscribers,
  putSubscription,
  putSubscriptionsStatus,
  getCoursesMe,
  postCourseTeacher,
  getCoursesExport,
  getMyCoursesExport,
  postCourseAttachment,
  deleteCourseAttachment,
  deleteCourse,
  postSubscriberCustomImport,
  getSubscriberExport,
  deleteSubscriptions,
  getCoursePaymentReceipt,
  postCoursesImport,
  checkMoodleCompletion,
  putUnsubscribeCourse,
  deleteSubscriptionAndPendingPayment,
  deleteSubscriptionAndPendingPaymentWithTiming,
  postAddToPendingList,
  getPaymentTReceipt,
};

export default CourseAPI;