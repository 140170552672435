import { FC, Fragment, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IPostSubscriberImportThunkPayload, postSubscriberImport } from '../../redux/slices/course.slice';
import { AppDispatch } from '../../redux/store';

interface IProps {
    idCorso: number;
};

interface IFormSubscriberImport extends IPostSubscriberImportThunkPayload { }

const SubscriberImportFormIMateria: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        idCorso: props.idCorso,
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IFormSubscriberImport);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        //const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        idCorso: props.idCorso,
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };

            fileReader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        dispatch(postSubscriberImport(fields as IPostSubscriberImportThunkPayload))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => setSubmitted(false))
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            {submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={94} className="text-success mb-5" />
                    <h3 className="text-success">Operazione completata!</h3>
                </div>
            ) : (
                <Form noValidate onSubmit={handleSubmit} >
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                            <p>Si raccomanda di NON modificare il file ottenuto dalla piattaforma di esportazione.</p>
                            <p>Il formato file richiesto è CSV.</p>
                        </Form.Label>
                        <Form.Control type="file" name="file" onInput={handleFileUpload} />
                    </Form.Group>
                    <Button type="submit" className="w-100" disabled={isLoading}>
                        {isLoading ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendere</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <span>Salva</span>
                        )}
                    </Button>
                </Form>
            )}
        </Fragment>
    );
};

export default SubscriberImportFormIMateria;