import React, { FC, Fragment, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../../entities/company.entity';
import { RootState } from '../../../redux/store';

export const getOrdineProvinciale = (idAzienda: number, list: Array<ICompanyEntity>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getOrdineProvincialeItem = (idAzienda: number, list: Array<ICompanyEntity>): ICompanyEntity | null => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: ICompanyEntity | null) => void;
}

const OrdineProvinciale: FC<IProps> = (props) => {

  const companies: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);

  const defaultSelectedOption = (): ICompanyEntity | null => {
    if (props?.defaultSelectedId) {
      return getOrdineProvincialeItem(props.defaultSelectedId, companies);
    }
    return null;
  };

  const [filterByOrdineProvinciale, setFilterByOrdineProvinciale] = useState<ICompanyEntity | null>(defaultSelectedOption());
  const [searchItemsByText, setSearchItemsByText] = useState<string>('');

  const handleFilterByOrdineProvinciale = (item: ICompanyEntity | null) => {
    setFilterByOrdineProvinciale(item);
    setSearchItemsByText('');
    if (props?.onSelect) {
      props.onSelect(item);
    }
  };

  const handleSearchItemsByTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchItemsByText(text);
  };

  return (
    <Fragment>
      <Dropdown className="mx-2 my-2">
        <Dropdown.Toggle variant="outline-primary" id="dropdown-ordineprovinciale">
          {filterByOrdineProvinciale != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`${filterByOrdineProvinciale ? filterByOrdineProvinciale.nome.substring(0, 10) : 'Ordine provinciale'} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <FormControl placeholder="Cerca" aria-label="Cerca" onChange={handleSearchItemsByTextChange} aria-describedby="basic-search-option-item" />
          <Dropdown.Item key={"dropdownOrdineProvincialeNone"} disabled={filterByOrdineProvinciale === null} onClick={() => handleFilterByOrdineProvinciale(null)}>Tutti</Dropdown.Item>
          {
            companies.length ? (
              companies.filter((itm: ICompanyEntity) => {
                if (searchItemsByText.length === 0) return true;
                return itm.nome.toLowerCase().startsWith(searchItemsByText.toLowerCase());
              }).map((item: ICompanyEntity, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownOrdineProvinciale" + item.idAzienda} disabled={filterByOrdineProvinciale !== null && item.idAzienda === filterByOrdineProvinciale.idAzienda} onClick={() => handleFilterByOrdineProvinciale(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default OrdineProvinciale;