import { FC, Fragment } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import Footer from '../layout/parts/Footer';

export const ForgotPasswordPage: FC = () => {
    return (
        <Fragment>
            <LoginBackgroundMobile />
            <LoginBackgroundTablet />
            <LoginBackground />
            <Container fluid as="section" className="login-content d-flex">
                <Row className="flex-grow-1">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                        <Card className="m-auto">
                            <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                                <Card.Title className="mb-3">Password dimenticata:</Card.Title>
                                <p>Hai dimenticato la password? Nessun problema. Inserisci il tuo <b>username </b>oppure l' <b>e-mail </b>che hai utilizzato per registrarti e ti invieremo un link per reimpostarla.</p>
                                <h6 className="my-3 fw-bold">
                                    <ForgotPasswordForm verticalSpacing={2} />
                                </h6>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer variant={"dark"} />
        </Fragment>
    );
};

export default ForgotPasswordPage;