import { IPostMessageThunkPayload } from "../redux/slices/message.slice";
import AxiosService from "../services/axios.service";
import { ApiEndpoints } from "./api.config";

export const postMessage = (payload: IPostMessageThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.Message, payload);
};

const MessageAPI = {
  postMessage
};

export default MessageAPI;