import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';

interface IProps {
  date: Date | null;
}

export const CourseListItemDate: FC<IProps> = (props) => {
  let [date, setDate] = useState<Date | null>(null);

  //let [date, setDate] = useState<Date | null>(null);
  useEffect(() => {
    if (props.date) {
      //const dt = new Date(props.date.split('/').reverse().join('/')); 
      //const dt = new Date(props.date); 
      setDate(props.date);
    } else {
      setDate(null);
    }
  }, [props.date]);

  return (
    date && (
      <div className="d-flex flex-column align-self-center border-end border-3 pe-3">
        <h2 className="text-center text-muted text-uppercase m-auto">{format(date, 'd')}</h2>
        <h6 className="text-center text-muted text-uppercase m-auto">{format(date, 'MMM')}</h6>
        <h6 className="text-center text-muted text-uppercase m-auto ">{format(date, 'yyyy')}</h6>
        <p className="text-center text-muted text-uppercase m-auto small">data inizio</p>
      </div>
    )
  );
};

export default CourseListItemDate;
