import { FC, Fragment, useState } from 'react';
import { Button, Col, Row, Modal, Container, Spinner, InputGroup, Form } from 'react-bootstrap';
import { AiOutlineCopy } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { IPostAppuserOtpThunkPayload, postAppuserOtp, IPostAppuserOtpFulfilledPayload } from '../../redux/slices/appuser.slice';
import { AppDispatch } from '../../redux/store';
import { logout } from '../../redux/slices/auth.slice';

interface IProps {
    idAzienda: number;
    codiceFiscale: string;
    role: number;
}

const SupportOtpBuilder: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [otp, setOtp] = useState<IPostAppuserOtpFulfilledPayload>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCopyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
    };

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleLogout = () => {
        handleCopyToClipboard(props.codiceFiscale + '@' + otp?.appendCode + '|' + otp?.otp)
        dispatch(logout());
    };

    const handleSubmit = () => {
        if (otp == null) {
            setIsLoading(true);
            dispatch(postAppuserOtp({ idAzienda: props.idAzienda, codiceFiscale: props.codiceFiscale, role: props.role } as IPostAppuserOtpThunkPayload))
                .unwrap()
                .then((e) => {
                    const payload = e as unknown as IPostAppuserOtpFulfilledPayload;
                    setOtp(payload);
                    setShowModal(true);
                })
                .catch()
                .finally(() => setIsLoading(false));
        }
        else {
            setShowModal(true);
        }
    }

    return (
        <Fragment>
            <Container as="section" fluid className="bg-light p-3">
                <Row>
                    <Col>
                        {
                            isLoading ? (
                                <div className="d-flex">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                    <span className="ms-1">Attendere</span>
                                    <span>...</span>
                                </div>
                            ) : (
                                <Button onClick={handleSubmit} size="lg">Genera credenziali utente {props.codiceFiscale}</Button>
                            )
                        }
                        <p className="mt-3">Clicca <a href="/assets/tutorial/S_AccessoProfessionista.webm" target="_blank" rel="noreferrer"><b>QUI</b></a> per guardare una dimostrazione della funzionalità.</p>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Credenziali temporanee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        otp ? (
                            <div>
                                <h6>username:</h6>
                                <InputGroup>
                                    <Button variant="outline-primary" onClick={() => handleCopyToClipboard(props.codiceFiscale + '@' + otp?.appendCode)}>
                                        <AiOutlineCopy size={22} />
                                    </Button>
                                    <Form.Control value={props.codiceFiscale + '@' + otp?.appendCode} readOnly={true} />
                                </InputGroup>
                                <br />
                                <h6>password:</h6>
                                <InputGroup>
                                    <Button variant="outline-primary" onClick={() => handleCopyToClipboard(otp?.otp)}>
                                        <AiOutlineCopy size={22} />
                                    </Button>
                                    <Form.Control value={otp?.otp} readOnly={true} />
                                </InputGroup>
                            </div>
                        ) : (
                            <p>Generazione non riuscita. Riprovare in un secondo momento.</p>
                        )
                    }
                    <p><small>Cliccando sul bottone sottostante verrà copiato negli appunti "username|password" ed eseguito il logout.</small></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleLogout}>
                        inizia nuova sessione
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    );
};

export default SupportOtpBuilder;