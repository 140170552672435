import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { getCourseDetail, IGetCourseDetailThunkPayload } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { linkMoodleBaseUrl } from '../../utils/course-default';
import { parseRawToICourse } from '../../utils/course-parser';
import { getMetadataNomeById } from '../../utils/metadata.helper';
import Subscribe from '../user/Subscribe';
import CourseDetailAttachmentList from './CourseDetailAttachmentList';
import { getAreaFormativa } from './dropdown/course/AreaFormativa';
import { getErogazione } from './dropdown/course/Erogazione';
import { getTipologia } from './dropdown/course/Tipologia';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';

interface IProps {
  idCorso: number;
}

const UserCourseDetail: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const erogazioni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.erogazione_corso);
  const tipologie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);
  const categorie: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);
  const staticorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.stato_corso);
  const articolazionicorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.articolazione_corso);
  const accessibilitacorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.accessibilita_corso);
  const course: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.detail);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getCourseDetail({ id: props.idCorso } as IGetCourseDetailThunkPayload))
      .finally(() => setIsLoading(false));
  }, [dispatch, props.idCorso]);

  return (
    <Fragment>
      {
        (course && !isLoading) ? (
          <Row className="m-1">
            <Col md={9} sm={12}>
              <Card>
                <Card.Header><h3><b>{course.titolo.toUpperCase()}</b></h3><span>{course.codice}</span></Card.Header>
                <Card.Body>
                  {
                    course.pubblicazione.areaPubDescrizione ? (
                      <div dangerouslySetInnerHTML={{ __html: course.pubblicazione.areaPubDescrizione }} />
                    ) : ""
                  }
                  <CourseDetailAttachmentList idCorso={props.idCorso} />
                </Card.Body>
              </Card>
              <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0" id="generale">
                  <Accordion.Header><h5>GENERALI</h5></Accordion.Header>
                  <Accordion.Body>
                    <Container fluid as="section" className="m-0 p-0">
                      <Row className="m-2">
                        <Col className="m-0 p-0">
                          <span className="blockquote">{getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</span>
                          <p className="text-muted m-0 p-0"><small><small>Soggetto proponente</small></small></p>
                        </Col>
                        <Col className="m-0 p-0">
                          <span className="blockquote">{course.mailInformazioni && course.mailInformazioni.length ? course.mailInformazioni : (<i>--</i>)}</span>
                          <p className="text-muted m-0 p-0"><small><small>Mail informazioni</small></small></p>
                        </Col>
                      </Row>
                      <hr className="text-muted m-0" />
                      <Row className="m-2">
                        <Col className="m-0 p-0">
                          <span className="blockquote">{getAreaFormativa(course.idAreaFormativa, categorie)}</span>
                          <p className="text-muted m-0 p-0"><small><small>Area formativa / categoria</small></small></p>
                        </Col>
                        <Col className="m-0 p-0">
                          <span className="blockquote">{getErogazione(course.idErogazione, erogazioni)}</span>
                          <p className="text-muted m-0 p-0"><small><small>Erogazione</small></small></p>
                        </Col>
                      </Row>
                      <hr className="text-muted m-0" />
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {getTipologia(course.idTipologia, tipologie)}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>Tipologia</small></small></Col>
                      </Row>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" id="valutazione">
                  <Accordion.Header><h5>VALUTAZIONE</h5></Accordion.Header>
                  <Accordion.Body>
                    <Container fluid as="section" className="m-0 p-0">

                      {course.dataInizio && course.dataInizio.length ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {format(new Date(course.dataInizio), 'dd/MM/yyyy')}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Data inizio svolgimento</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      {course.dataFine && course.dataFine.length ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {format(new Date(course.dataFine), 'dd/MM/yyyy')}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Data fine svolgimento</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      {course.valutazione ? (
                        <Fragment>
                          {course.valutazione.idArticolazioneCorso && course.valutazione.idArticolazioneCorso > 0 ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {getMetadataNomeById(course.valutazione.idArticolazioneCorso, articolazionicorso)}
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Articolazione corso</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}
                          {course.valutazione.idStato && course.valutazione.idStato > 0 ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {getMetadataNomeById(course.valutazione.idStato, staticorso)}
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Stato</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {course.valutazione.flagSvolgimentoInSede ? 'Svolgimento in sede' : ''} {course.valutazione.indirizzoSede ? course.valutazione.indirizzoSede : ''}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Indirizzo sede svolgimento corso</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                          {course.valutazione.idAccessibilita && course.valutazione.idAccessibilita > 0 ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {getMetadataNomeById(course.valutazione.idAccessibilita, accessibilitacorso)}
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Accessibilità per persone con disabilità</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}
                          {course.valutazione.frequenzaMin && course.valutazione.frequenzaMin > 0 ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {course.valutazione.frequenzaMin} %
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Frequenza minima</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}

                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {course.nOre}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>Ore</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {Math.abs(course.cpfAssegnati)}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>CFP assegnati</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" id="modalita">
                  <Accordion.Header><h5>MODALITA'</h5></Accordion.Header>
                  <Accordion.Body>
                    <Container fluid as="section" className="m-0 p-0">
                      {course.nPartecipantiMin > 0 || course.nPartecipantiMax > 0 || course.nPartecipantiExt > 0 ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {course.nPartecipantiMin ? course.nPartecipantiMin : ' -- '} /
                              {course.nPartecipantiMax ? course.nPartecipantiMax : ' -- '} /
                              {course.nPartecipantiExt ? course.nPartecipantiExt : ' -- '}
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Nr. partecipanti: minimi / massimi / esterni</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      <Row className="m-2">
                        <Col sm={12} className="blockquote m-0 p-0">
                          {course.flagAccettaIscrizioni ? "online " : "offline (contattando l'ente) "} -
                          {course.flagChiudiIscrizioni ? " chiuse" : " aperte nelle date indicate"}
                        </Col>
                        <Col sm={12} className="text-muted m-0 p-0"><small><small>Iscrizioni</small></small></Col>
                      </Row>
                      <hr className="text-muted m-0" />

                      {course.modalita ? (
                        <Fragment>
                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {
                                course.modalita.dataIscrizioneDal && course.modalita.dataIscrizioneAl ?
                                  "dal " + (format(new Date(course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')) + " al " + (format(new Date(course.modalita.dataIscrizioneAl), 'dd/MM/yyyy'))
                                  : course.modalita.dataIscrizioneDal ?
                                    "dal " + (format(new Date(course.modalita.dataIscrizioneDal), 'dd/MM/yyyy'))
                                    : course.modalita.dataIscrizioneAl ?
                                      "fino al " + (format(new Date(course.modalita.dataIscrizioneAl), 'dd/MM/yyyy'))
                                      : course.dataInizio ?
                                        "fino al " + (format(new Date(course.dataInizio), 'dd/MM/yyyy'))
                                        :
                                        "fino ad esaurimento posti"
                              }
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Apertura iscrizioni</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />
                          {course.modalita.dettaglioModalitaIscrizione ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {course.modalita.dettaglioModalitaIscrizione}
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Dettaglio modalità iscrizione</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}

                          <Row className="m-2">
                            <Col sm={12} className="blockquote m-0 p-0">
                              {
                                (course.modalita.descIscrizioneA || course.modalita.accontoIscrizioneA) ?
                                  ((course.modalita.descIscrizioneA && course.modalita.descIscrizioneA.length > 0) ? course.modalita.descIscrizioneA : "") +
                                  ((course.modalita.accontoIscrizioneA && course.modalita.accontoIscrizioneA > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneA.toLocaleString() + " €)" : "")
                                  :
                                  "Iscrizione GRATUITA"
                              }
                            </Col>
                            <Col sm={12} className="text-muted m-0 p-0"><small><small>Per gli appartenenti all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                          </Row>
                          <hr className="text-muted m-0" />

                          {course.modalita.flagIscrizioneIntB ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {
                                    (course.modalita.descIscrizioneB || course.modalita.accontoIscrizioneB) ?
                                      ((course.modalita.descIscrizioneB && course.modalita.descIscrizioneB.length > 0) ? course.modalita.descIscrizioneB : "") +
                                      ((course.modalita.accontoIscrizioneB && course.modalita.accontoIscrizioneB > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneB.toLocaleString() + " €)" : "")
                                      :
                                      "Iscrizione GRATUITA"
                                  }
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Per gli NON appartenenti all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}

                          {course.modalita.flagIscrizioneIntC ? (
                            <Fragment>
                              <Row className="m-2">
                                <Col sm={12} className="blockquote m-0 p-0">
                                  {
                                    (course.modalita.descIscrizioneC || course.modalita.accontoIscrizioneC) ?
                                      ((course.modalita.descIscrizioneC && course.modalita.descIscrizioneC.length > 0) ? course.modalita.descIscrizioneC : "") +
                                      ((course.modalita.accontoIscrizioneC && course.modalita.accontoIscrizioneC > 0) ? " (quota richiesta " + course.modalita.accontoIscrizioneC.toLocaleString() + " €)" : "")
                                      :
                                      "Iscrizione GRATUITA"
                                  }
                                </Col>
                                <Col sm={12} className="text-muted m-0 p-0"><small><small>Per i NEOISCRITTI all'ORDINE DI {getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</small></small></Col>
                              </Row>
                              <hr className="text-muted m-0" />
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}

                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={3} sm={12}>
              <Card className="sticky-top">
                <Card.Body>
                  <Subscribe course={parseRawToICourse(course)} noValidation={false} noPendingList={false} />
                  {
                    (course.moodleCourseId != null && course.moodleCourseId.length > 0) ? (
                      <Button href={linkMoodleBaseUrl + course.moodleCourseId} target="_blank" rel="noreferrer" className="w-100 my-2">
                        partecipa al corso MOODLE
                      </Button>
                    ) : ''
                  }
                  <Card.Text>
                    <Table striped>
                      <tbody>
                        <tr>
                          <td className="small"><strong>CFP Assegnati</strong></td>
                          <td>{Math.abs(course.cpfAssegnati)}</td>
                        </tr>
                        <tr>
                          <td className="small"><strong>Inizio</strong></td>
                          <td>{format(new Date(course.dataInizio), 'dd/MM/yyyy')}</td>
                        </tr>
                        <tr>
                          <td className="small"><strong>Ore</strong></td>
                          <td>{course.nOre} </td>
                        </tr>
                        <tr>
                          <td className="small"><strong>Luogo</strong></td>
                          <td>{course.valutazione.flagSvolgimentoInSede ? 'Svolgimento in sede' : ''} {course.valutazione.indirizzoSede ? course.valutazione.indirizzoSede : ''}</td>
                        </tr>
                        <tr>
                          <td className="small"><strong>Soggetto Proponente</strong></td>
                          <td>{getOrdineProvinciale(course.idAziendaProponente, companiesOrders)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Card.Img variant="bottom" src="/assets/buildings-left.svg" />
                </Card.Footer>
              </Card>
            </Col>
          </Row >

        ) : (
          <Fragment />
        )}

    </Fragment >
  );
};

export default UserCourseDetail;