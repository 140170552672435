import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, Fragment, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaDownload, FaFileAlt, FaHome } from 'react-icons/fa';
import { MdHelp, MdLogout, MdSettings } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from '../../entities/user.entity';
import { AuthenticatedRoutes, AuthenticatedRoutesMaster, UnauthenticatedRoutes } from '../../enums/routes.enum';
import { UserRole } from '../../enums/user-roles.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  user: IUser;
}

const SidebarMaster: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  const [selected, setSelected] = useState<string>(AuthenticatedRoutesMaster.Dashboard);

  const isSelected = (key: string) => selected ? selected.startsWith(key) : false;
  const isSelectedItem = (key: string) => selected ? selected === key : false;

  const handleSelectParent = (e: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isSelectedItem(value) && expanded) return;
    if (!expanded) dispatch(openSidebar());
    if (isSelected(value)) return;
    let target: HTMLElement = e.target as HTMLElement;
    while (target.tagName.toLocaleLowerCase() !== 'div') {
      target = target.parentElement as HTMLElement;
    }
    setTimeout(() => target.click(), 200);
    setSelected(value);
  };

  const handleSelectItem = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = UnauthenticatedRoutes.Login));
  };

  return (
    <SideNav
      style={{ zIndex: 1031 }}
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelectItem}
    >
      <SideNav.Toggle />
      <SideNav.Nav selected={selected}>
        {expanded && (
          <NavItem
            eventKey={AuthenticatedRoutes.Me}
            className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
          >
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none"
              >
                <Image src="/assets/code-dark-theme.svg" height={72} />
              </Link>
            </div>
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none text-light text-uppercase small"
              >
                {props.user.firstName} {props.user.lastName}
              </Link>
            </div>
          </NavItem>
        )}
        <NavItem
          eventKey={AuthenticatedRoutesMaster.Dashboard}
          className={isSelected(AuthenticatedRoutesMaster.Dashboard) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesMaster.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Dashboard)}
              className="text-decoration-none"
            >
              <FaHome size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesMaster.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Dashboard)}
              className="text-decoration-none"
            >
              Home
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesMaster.Attachments}
          className={isSelected(AuthenticatedRoutesMaster.Attachments) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesMaster.Attachments}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Attachments)}
              className="text-decoration-none"
            >
              <FaFileAlt size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesMaster.Attachments}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Attachments)}
              className="text-decoration-none"
            >
              Allegati
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesMaster.Awn}
          className={isSelected(AuthenticatedRoutesMaster.Awn) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesMaster.Awn}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Awn)}
              className="text-decoration-nome"
            >
              <FaDownload size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesMaster.Awn}
              onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Awn)}
              className="text-decoration-none"
            >
              integrazione Awn
            </Link>
          </NavText>
        </NavItem>
      </SideNav.Nav>
      <SideNav.Nav componentClass="footer">
        {props.user.role === UserRole.Master && (
          <Fragment>
            <NavItem
              eventKey={AuthenticatedRoutesMaster.Settings}
              className={isSelected(AuthenticatedRoutesMaster.Settings) && 'selected'}
            >
              <NavIcon>
                <Link
                  to={AuthenticatedRoutesMaster.Settings}
                  onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Settings)}
                  className="text-decoration-none"
                >
                  <MdSettings size={32} />
                </Link>
              </NavIcon>
              <NavText>
                <Link
                  to={AuthenticatedRoutesMaster.Settings}
                  onClick={() => handleSelectItem(AuthenticatedRoutesMaster.Settings)}
                  className="text-decoration-none"
                >
                  Opzioni
                </Link>
              </NavText>
            </NavItem>
          </Fragment>
        )}
        <NavItem
          eventKey={AuthenticatedRoutes.Support}
          className={isSelected(AuthenticatedRoutes.Support) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutes.Support}
              onClick={() => handleSelectItem(AuthenticatedRoutes.Support)}
              className="text-decoration-none"
            >
              <MdHelp size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutes.Support}
              onClick={() => handleSelectItem(AuthenticatedRoutes.Support)}
              className="text-decoration-none"
            >
              Supporto
            </Link>
          </NavText>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>Esci</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarMaster;
