import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Col, Container, Dropdown, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import OwnCourseList from '../../components/admin/OwnCourseList';
import PartnershipCourseList from '../../components/admin/PartnershipCourseList';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ICompanyEntity } from '../../entities/company.entity';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany, getPartners } from '../../redux/slices/company.slice';
import { getCoursesExport, ICoursesExportFulfilledPayload } from '../../redux/slices/courses.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';

const AdminUnpublishedCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    //dispatch(getMetadataTeacher({}));
    //dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const joinpartners: Array<ICompanyEntity> = useSelector((s: RootState) => s.company.joinpartners);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleTab, setToggleTab] = useState<boolean>(true);

  const handleCoursesExport = (own: number) => {
    setIsLoading(true);
    dispatch(getCoursesExport({ statoPubblicazione: 2, own: own }))
      .unwrap()
      .then((e) => {
        const payload = e as unknown as ICoursesExportFulfilledPayload;
        const div = document.getElementById("divExportUnpublishedAttachmentCSV");
        if (div) {
          b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
            const url = window.URL.createObjectURL(blobresult);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
            div.appendChild(link);
            link.click();
            link.remove();
          });
        }
      })
      .catch((e) => {
        // console.log("KO", e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      <PageTitle content="Corsi">
        <a href="/assets/tutorial/O_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista corsi in attesa di pubblicazione">
        {isLoading ? (
          <div className="d-flex">
            <Spinner as="span" animation="border" size="sm" role="status" />
            <span className="ms-1">Attendere</span>
            <span>...</span>
          </div>
        ) : (
          <Dropdown title="Esporta corsi in attesa di pubblicazione">
            <Dropdown.Toggle variant="primary">
              Esporta in .CSV
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleCoursesExport(1)}>Corsi</Dropdown.Item>
              <Dropdown.Item onClick={() => handleCoursesExport(0)}>Corsi Enti Terzi</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div id="divExportUnpublishedAttachmentCSV" className="d-none"></div>
      </PageSubtitle>
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="own" onSelect={() => setToggleTab(!toggleTab)}>
              <Tab eventKey="own" title="Corsi">
                <OwnCourseList full={false} statoPubblicazione={[1, 2]} active={toggleTab} />
              </Tab>
              <Tab eventKey="partnership" title="Corsi Enti Terzi">
                {
                  joinpartners.length === 0 ? (
                    <Container fluid as="section" className="bg-light">
                      <Row>
                        <Col className="m-3">
                          <Alert variant="secondary">
                            <h4>Questa sezione mostra i corsi inseriti dagli enti terzi in collaborazione con l'ordine.</h4>
                            <hr />
                            <h6>Funzionalità non attiva.</h6>
                            <p>Non ci sono enti terzi abbinati a questo ordine.<br />Verrà automaticamente attivata quest'area quando saranno associati enti terzi in collaborazione con l'ordine.</p>
                          </Alert>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    <PartnershipCourseList full={false} statoPubblicazione={[2]} active={!toggleTab} />
                  )
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminUnpublishedCourses;