import { FC, Fragment, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { IMetadataOption, IMetadataOptionNode } from '../../../../entities/metadata.entity';
import { RootState, AppDispatch } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";
import { getLineeGuida } from '../../../../redux/slices/lineeguida.slice';
import { ILineaGuidaEntity } from '../../../../entities/lineeguida.entity';

export const getEsonero = (extId: number, id: number, list: Array<IMetadataOptionNode>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id && elem.extId === extId);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getEsoneroOption = (extId: number, id: number, list: Array<IMetadataOptionNode>): IMetadataOptionNode | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id && elem.extId === extId);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  selectAll?: boolean;
  anno?: number;
  triennio? : number;
  onSelectEsonero?: (option: IMetadataOptionNode | null) => void;
}

const Esonero: FC<IProps> = (props) => {

  const dispatch = useDispatch<AppDispatch>();

  const tipologieEsoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification?.tipo_esonero_certificazione ? s.metadata.certification.tipo_esonero_certificazione : []);
  const tipologieEsoneriAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification?.tipo_esonero_certificazione_all ? s.metadata.certification.tipo_esonero_certificazione_all : []);
  const [tipologieEsoneriLG, setTipologieEsoneriLG] = useState<Array<IMetadataOptionNode>>([]);

  const elencoTipologie = (props?.selectAll && props.selectAll) ? tipologieEsoneriAll : tipologieEsoneri;
  
  const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
  const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);

  const [filterByEsonero, setFilterByEsonero] = useState<IMetadataOptionNode | null>(null);

  // const [searchItemsByText, setSearchItemsByText] = useState<string>('');

  const handleFilterByEsonero = (option: IMetadataOptionNode | null) => {
    setFilterByEsonero(option);
    if (props?.onSelectEsonero) {
      props.onSelectEsonero(option);
    }
  };

  // const handleSearchItemsByTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const text = event.target.value;
  //   setSearchItemsByText(text);
  // };

   // estrarre le sole tipologie relative agli anni in questione
  useEffect(() => {
    
    // console.log("triennio: ", props.triennio);
    // console.log("anno: ", props.anno);
    // console.log("trienni: ", trienni?.length ? trienni : "vuoto");
    // console.log("lineeGuida: ", lineeGuida?.length ? lineeGuida : "vuoto");
    setTipologieEsoneriLG([]);
    if (trienni.length && lineeGuida.length) {
      const anniInEsame: number[] = [];
      if (props?.anno && props.anno > 0) {
        anniInEsame.push(props.anno);
      } else if (props.triennio && props.triennio > 0) {
        const triennio = trienni.find((elem) => elem.id === props.triennio);
        anniInEsame.push(...(triennio?.descrizione ? triennio.descrizione : "").split('-').map(Number));
      } else {
        setTipologieEsoneriLG(elencoTipologie)
      }

      if (anniInEsame.length > 0) {
      // estrarre linee guida relative all'anno ( props.anno compreso tra inizio e fine validità della linea guida) -> tipologie relative        
        const lineeGuidaProcessed = new Set<number>();

        anniInEsame.forEach((annoInEsame) => {
          const lineeGuidaFilter = lineeGuida.filter((elem) => {
            const compreso = annoInEsame >= new Date(elem.inizioValidita).getFullYear() && 
              annoInEsame <= new Date(elem.fineValidita).getFullYear();
            const duplicato = lineeGuidaProcessed.has(elem.id);
            if (compreso && !duplicato) {
              lineeGuidaProcessed.add(elem.id);
              return true;
            }
            return false;
          });
          lineeGuidaFilter.forEach((elem) => {
            setTipologieEsoneriLG((prev) => [ ...prev, ...elencoTipologie.filter((tip) => tip.lg === elem.id)]);
          });
        });
      }

    }
  }, [trienni, lineeGuida, props.anno, props.triennio]);

  useEffect(() => {
    dispatch(getLineeGuida({}));
}, [dispatch]); 

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary">
          {filterByEsonero != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Esonero ${filterByEsonero ? filterByEsonero.nome : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          {/* <FormControl placeholder="Cerca" onChange={handleSearchItemsByTextChange} /> */}
          <Dropdown.Item key={"idEsoneroNone"} disabled={filterByEsonero == null} onClick={() => handleFilterByEsonero(null)}>Tutti</Dropdown.Item>
          {
            tipologieEsoneriLG.length ? (
              tipologieEsoneriLG.map((item: IMetadataOptionNode, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownIdEsonero" + item.id} disabled={filterByEsonero !== null && filterByEsonero.id === item.id} onClick={() => handleFilterByEsonero(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Esonero;