import { Fragment, useState } from 'react';
import { FC } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from '../../entities/user.entity';
import { logout, policyConsent } from '../../redux/slices/auth.slice';
import { AppDispatch, RootState } from '../../redux/store';
import PrivacyPolicyText from './PrivacyPolicyText';

const PrivacyPolicyAgreement: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const user: IUser = useSelector((s: RootState) => s.auth.user?.value as IUser);

    const [show, setShow] = useState(user && !user.idPolicyConsent);

    const handleClose = () => setShow(false);
    const handleDecline = () => dispatch(logout());
    const handleAccept = () => dispatch(policyConsent()).then(() => setShow(false));

    return (
        <Fragment>
            <Container fluid as="section">
                <Modal
                    size="xl"
                    centered
                    scrollable
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    onHide={handleClose}
                >
                    <Modal.Header>
                        <Modal.Title className="text-center">
                            <h2><span className="privacyStyle">INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI</span></h2>
                            <h5 className="text-muted">degli utenti che accedono al portale web di formazione continua del Consiglio Nazionale degli Architetti, Pianificatori, Paesaggisti e Conservatori (di seguito anche &ldquo;CNAPPC&rdquo;) ai sensi dell'articolo 13 del Regolamento (UE) 2016/679</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PrivacyPolicyText />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-secondary w-25" onClick={handleDecline}>Rifiuto</Button>
                        <Button className="btn-primary w-25" onClick={handleAccept}>Accetto</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </Fragment>
    );
}

export default PrivacyPolicyAgreement;