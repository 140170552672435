import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse, getMetadataTeacher } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const MasterDashboard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto SUPER ADMIN!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
    </Fragment>
  );
};

export default MasterDashboard;