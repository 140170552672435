import { IGetCompaniesOrderThunkPayload, IGetCompaniesPartnerThunkPayload } from '../redux/slices/companies.slice';
import { IGetCompaniesThunkPayload, IGetCompanyThunkPayload, IGetPartnersThunkPayload } from '../redux/slices/company.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getCompany = (_payload: IGetCompanyThunkPayload) => {
  const url = ApiEndpoints.Company.toString();
  return AxiosService.instance.axios.get(url);
};

export const getCompanies = (_payload: IGetCompaniesThunkPayload) => {
  const url = ApiEndpoints.Companies.toString();
  return AxiosService.instance.axios.get(url);
};

export const getPartners = (_payload: IGetPartnersThunkPayload) => {
  const url = ApiEndpoints.Partners.toString();
  return AxiosService.instance.axios.get(url);
};

export const getCompaniesOrder = (_payload: IGetCompaniesOrderThunkPayload) => {
  const url = ApiEndpoints.CompaniesOrder.toString();
  return AxiosService.instance.axios.get(url);
};

export const getCompaniesPartner = (_payload: IGetCompaniesPartnerThunkPayload) => {
  const url = ApiEndpoints.CompaniesPartner.toString();
  return AxiosService.instance.axios.get(url);
};

export const CompanyAPI = {
  getCompany,
  getCompanies,
  getPartners,
  getCompaniesOrder,
  getCompaniesPartner,
};

export default CompanyAPI;