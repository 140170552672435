import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { IUser } from '../entities/user.entity';
import { UnauthenticatedRoutes } from '../enums/routes.enum';
import { UserRole } from '../enums/user-roles.enum';

interface IProps {
  user: IUser | null;
  component: FC;
}

export const AuthenticatedRouteAdmin: FC<IProps> = ({ user, component: Component }) => {
  return user?.role === UserRole.Admin ? <Component /> : <Navigate to={UnauthenticatedRoutes.Login} />;
};

export default AuthenticatedRouteAdmin;
