import { FC, Fragment } from 'react';
import ProfessionalsFromAwn from '../../components/master/ProfessionalsFromAwn';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';

const MasterAwn: FC = () => {
  return (
    <Fragment>
      <PageTitle content="Allineamento anagrafiche con AWN" />
      <PageSubtitle content="" />
      <PageParagraph content="" />
      <ProfessionalsFromAwn />
    </Fragment>
  );
};

export default MasterAwn;