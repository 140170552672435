import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Modal, Row, Toast, ToastContainer } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BsCheckCircle } from 'react-icons/bs';
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { checkMoodleCourseCompletion, IPutCheckMoodleCompletionThunkPayload } from '../../redux/slices/course.slice';
import { getCoursesProfessional, ICoursesProfessionalFilter, ICoursesProfessionalThunkPayload } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { paginationComponentOptions } from '../../utils/list-default';
import CourseDetail from './CourseDetail';
import { getAreaFormativa } from './dropdown/course/AreaFormativa';
import { getStatusSingol } from './dropdown/course/Status';
import TipoFormazione from './dropdown/course/TipoFormazione';
import MeseAnno from './dropdown/MeseAnno';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';
import Triennio from './dropdown/course/Triennio';
import StatusEsteso from './dropdown/course/StatusEsteso';
import { b64toBlob } from '../../utils/attachment-parser';
import { IPostExportCourseProfessionalCertificateFulfilledPayload, postExportCourseProfessionalCertificate } from '../../redux/slices/documents.slice';
import { FaRegFilePdf } from 'react-icons/fa';

interface IProps {
  codiceFiscale: string;
  disableButtonExportPdf: boolean;
  disableButtonMoodle: boolean;
}

const ProfessionalCourses: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const companyOrder: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const categorie: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<IRejectResponse | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  const [filter, setFilter] = useState<ICoursesProfessionalFilter>({ anno: 0, confermato: (-1), deontologico: (-1), statoIscrizione: 0, triennio: 0 });
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDangerToast, setShowDangerToast] = useState(false);
  const [delayDangerToast, setDelayDangerToast] = useState(40000);
  const [delaySuccessToast, setDelaySuccessToast] = useState(20000);

  const courses: Array<ICourseEntityRaw> = useSelector((s: RootState) => s.professional.courses);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const handleOpenDetailModal = () => setShowDetailModal(true);
  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getCoursesProfessional({ codiceFiscale: props.codiceFiscale, filter: filter } as ICoursesProfessionalThunkPayload))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch, props.codiceFiscale, filter, refresh]);

  const handleClickItem = (idCorso: number) => {
    setSelectedItem(idCorso);
    handleOpenDetailModal();
  };

  const handleCheckMoodleSubscription = (row: ICourseEntityRaw) => {
    setLoading(true);
    dispatch(checkMoodleCourseCompletion({ id: row.id, cf: props.codiceFiscale } as IPutCheckMoodleCompletionThunkPayload))
      .unwrap()
      .then((res: any) => {
        const nRefresh : number = refresh;
        setShowSuccessToast(true);
        setRefresh(nRefresh + 1);
      })
      .catch((err: any) => {
        setError(err);
        setLoading(false);
        setShowDangerToast(true);
      });
  }

  const handleSelectTipoFormazione = (option: boolean | null) => {
    if (option === true) {
      setFilter({ ...filter, deontologico: 1 });
    }
    else if (option === false) {
      setFilter({ ...filter, deontologico: 0 });
    }
    else {
      setFilter({ ...filter, deontologico: (-1) });
    }
  };

  const handleSelectStatoIscrizione = (option: number | null) => {
    setFilter({ ...filter, statoIscrizione: option });
  };

  const handleExportCourseProfessionalCertificate = (codiceFiscale: string, idCorso: number) => {
    setLoading(true);
    dispatch(postExportCourseProfessionalCertificate({ codiceFiscale: codiceFiscale, idCorso: idCorso}))
      .unwrap()
      .then((e) => {
          const payload = e as unknown as IPostExportCourseProfessionalCertificateFulfilledPayload;
          const div = document.getElementById("divUserExportPDF");
          if (div) {
            b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
              const url = window.URL.createObjectURL(blobresult);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', payload.attachment.filename);
              div.appendChild(link);
              link.click();
              link.remove();
            });
          }
      })
      .catch(() => {
        //console.log("KO", e);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectAnno = (option: number | null) => {
    if (option) {
      setFilter({ ...filter, anno: option });
    }
    else {
      setFilter({ ...filter, anno: 0 });
    }
  };

  const handleThreeYearsClick = (option: IMetadataOption | null) => {
    if (option)
      setFilter({ ...filter, triennio: option.id });
    else  
      setFilter({ ...filter, triennio: 0 });
  };

  const columns: TableColumn<ICourseEntityRaw>[] = [
    {
      name: 'Codice',
      sortable: true,
      selector: (row: ICourseEntityRaw) => row.codice,
      cell: (row: ICourseEntityRaw) => {
        return (<span title={row.codice}>{row.codice}</span>);
      },
      grow: 1,
    },
    {
      name: 'Ordine',
      sortable: true,
      selector: (row: ICourseEntityRaw) => getOrdineProvinciale(row.idAziendaProponente, companyOrder),
      cell: (row: ICourseEntityRaw) => {
        return (<span title={getOrdineProvinciale(row.idAziendaProponente, companyOrder)}>{getOrdineProvinciale(row.idAziendaProponente, companyOrder)}</span>);
      },
      grow: 1,
    },
    {
      name: 'Titolo',
      sortable: true,
      selector: (row: ICourseEntityRaw) => row.titolo,
      cell: (row: ICourseEntityRaw) => {
        return (<span title={row.titolo}>{row.titolo}</span>);
      },
      grow: 3,
    },
    {
      name: 'Area formativa',
      sortable: true,
      selector: (row: ICourseEntityRaw) => getAreaFormativa(row.idAreaFormativa, categorie),
      cell: (row: ICourseEntityRaw) => {
        return (<span title={getAreaFormativa(row.idAreaFormativa, categorie)}>{getAreaFormativa(row.idAreaFormativa, categorie)}</span>);
      },
      grow: 1,
    },
    {
      name: 'Data inizio corso',
      sortable: true,
      selector: (row: ICourseEntityRaw) => {
        if (!row.dataInizio) return '';
        return format(new Date(row.dataInizio), 'dd/MM/yyyy');
      },
      sortFunction: (a: ICourseEntityRaw, b: ICourseEntityRaw) => {
        return new Date(b.dataInizio).getTime() - new Date(a.dataInizio).getTime();
      },
      grow: 1,
    },
    {
      name: 'Data presenza',
      sortable: true,
      selector: (row: ICourseEntityRaw) => {
        if (row.iscritti != null && row.iscritti.length > 0 && row.iscritti[0]?.dtConfPres) return format(new Date(row.iscritti[0].dtConfPres), 'dd/MM/yyyy');
        return '';
      },
      sortFunction: (a: ICourseEntityRaw, b: ICourseEntityRaw) => {
        let atime = 0;
        if(a.iscritti != null && a.iscritti.length > 0 && a.iscritti[0]?.dtConfPres) {
          atime = new Date(a.iscritti[0].dtConfPres).getTime();
        }
        let btime = 0;
        if(b.iscritti != null && b.iscritti.length > 0 && b.iscritti[0]?.dtConfPres) {
          btime = new Date(b.iscritti[0].dtConfPres).getTime();
        }
        return atime - btime;
      },
      grow: 1,
    },
    {
      name: 'Stato',
      sortable: true,
      selector: (row: ICourseEntityRaw) => {
        return getStatusSingol(row.iscritti[0].state);
      },
      grow: 0,
    },
    {
      name: 'Crediti',
      selector: (row: ICourseEntityRaw) => row.cpfAssegnati,
      sortable: true,
      grow: 0,
    },
    {
      cell: (row: ICourseEntityRaw) => {
        if ((row.moodleCourseId == null || row.moodleCourseId.length === 0)) return;
        if (row?.partecipazioneConfermata != null && row.partecipazioneConfermata === 1) {
          return (
            <BsCheckCircle size={22} className="text-success" title="Presenza Moodle confermata" />
          );
        }

        return (<Button title="Verifica/registra presenza all'evento" size="sm" className="m-1 py-1"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleCheckMoodleSubscription(row);
          }}>verifica presenza</Button>);
      },
      name: <Image src="/assets/moodle.svg" width={70} className="m-0 p-0" />,
      ignoreRowClick: true,
      button: true,
      grow: 1,
      omit: props.disableButtonMoodle,
    },
    {
      name: '',
      cell: (row: ICourseEntityRaw) => {
        return (<FaRegFilePdf className="text-dark c-pointer m-0 p-0" title="pdf" size={20} onClick={(e: React.MouseEvent<SVGElement>) => {
          e.preventDefault();
          handleExportCourseProfessionalCertificate(props.codiceFiscale, row.id);
        }} />);
      },
      ignoreRowClick: true,
      button: true,
      grow: 1,
      omit: props.disableButtonExportPdf,
    },
    {
      name: '',
      cell: (row: ICourseEntityRaw) => {
        return (<IoEllipsisVerticalCircle className="text-dark c-pointer m-0 p-0" title="dettagli" size={22} onClick={(e: React.MouseEvent<SVGElement>) => {
          e.preventDefault();
          handleClickItem(row.id);
        }} />);
      },
      ignoreRowClick: true,
      button: true,
      grow: 1,
    },
  ] as TableColumn<ICourseEntityRaw>[];

  return (
    <Fragment>
      <Container as="section" fluid className="bg-light p-2">
        <Row className='mb-3'>
          <Col>
            <div className="d-flex justify-content-end">
              <StatusEsteso onSelect={handleSelectStatoIscrizione} />
              <TipoFormazione onSelect={handleSelectTipoFormazione} />
              <Triennio  onSelect={handleThreeYearsClick} />
              {
                filter.confermato === 1 ? (
                  <MeseAnno onlyYears={true} onSelectYear={handleSelectAnno} />
                ) : (
                  <Fragment />
                )
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable columns={columns} data={courses}
              pagination striped progressPending={isLoading}
              noDataComponent="Nessun risultato"
              paginationComponentOptions={paginationComponentOptions} />
          </Col>
        </Row>
      </Container>
      <div id="divUserExportPDF" className="d-none"></div> 
      <Modal size="lg" show={showDetailModal} onHide={handleCloseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dettaglio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <CourseDetail idCorso={selectedItem} />
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione completata!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">Comunicazione con MOODLE effettuata!</Toast.Body>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione terminata con errori!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status}: {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default ProfessionalCourses;