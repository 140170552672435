import { FC, Fragment, useState } from 'react';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { IRejectResponse } from '../../api/api.interface';
import { adviceInit } from '../../utils/advice-default';
import { IAdviceEntity } from '../../entities/advice.entity';
import { AuthenticatedRoutesSupervisor } from '../../enums/routes.enum';
import { useNavigate } from 'react-router';
import { IPostAdviceFulfilledPayload, IPostAdviceThunkPayload, postAdvice } from '../../redux/slices/advice.slice';
import { Container, Toast, ToastContainer } from 'react-bootstrap';
import AdviceForm from '../../components/supervisor/AdviceForm';

const SupervisorNewAdvice: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const error: IRejectResponse | null = useSelector((s: RootState) => s.advice.error);

  const [delaySuccessToast, ] = useState<number>(4000);
  const [delayDangerToast, ] = useState<number>(10000);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showDangerToast, setShowDangerToast] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);

  const [advice, ] = useState<IAdviceEntity>(adviceInit);

  const handleNavigateToEditPage = (idAdvice: number) => {
    navigate(AuthenticatedRoutesSupervisor.Advice.replace(':id', idAdvice.toString()));
  };

  const handleSave = (value: IAdviceEntity) => {
    setLoading(true);
    dispatch(postAdvice({ advice: value } as IPostAdviceThunkPayload))
      .unwrap()
      .then((payload) => {
        setShowSuccessToast(true);
        setSubmitted(true);
        const fulfilledPayload = payload as unknown as IPostAdviceFulfilledPayload;
        if (fulfilledPayload) {
          setTimeout(() => handleNavigateToEditPage(fulfilledPayload.id), 2000);
        }
      })
      .catch(() => {
        setShowDangerToast(true);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <PageTitle content="News" />
      <PageSubtitle content="Nuova" />
      <PageParagraph content="" />
      <Container fluid as="section" className="bg-light py-3">
        <AdviceForm advice={advice} onSave={handleSave} isLoading={isLoading} submitted={submitted} />
      </Container>
      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide id="toastSuccess">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio completato!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light"></Toast.Body>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide id="toastDanger">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio non riuscito!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status} {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default SupervisorNewAdvice;