import { parseISO } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { MdLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Routes } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch } from '../../redux/store';

interface IProp {
  expire: string | null;
}

const ExpireAlert: FC<IProp> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [stopCountdown, setStopCountdown] = useState<boolean>(false);

  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
    handleLogout();
  };

  const handleShow = () => {
    setShow(true);
    setStopCountdown(true);
  };

  useEffect(() => {
    const stop = stopCountdown;
    const expire = props.expire;
    if (expire != null && expire.length > 0 && !stop) {
      const dt = parseISO(expire);
      const now = new Date();
      const diff = dt.getTime() - now.getTime();
      const time = diff > 0 ? diff : 1;

      const timer = setTimeout(() => {
        handleShow();
      }, time);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [props.expire, stopCountdown, show]);

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = Routes.Root));
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom" className="bg-danger text-light" backdrop="static">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title><h1><span className="me-3">Sessione scaduta! </span><Button onClick={handleClose} variant="dark"><MdLogout size={20} /> Esci</Button></h1></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h5>Uscire dall'applicazione ed effettuare nuovamente l'accesso.</h5>
      </Offcanvas.Body>
    </Offcanvas>
  )
};

export default ExpireAlert;