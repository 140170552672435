import { FC, Fragment, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { SubscriptionState } from '../../enums/subscription-state.enum';

interface IProps {
    initialState: SubscriptionState;
    onSubmit: (state: SubscriptionState, data: string) => void;
}

const SubscriberEditStatusForm: FC<IProps> = (props) => {
    const [initState, setInitState] = useState<SubscriptionState>(props.initialState);
    const [data, setData] = useState<string | null>(null);
    const [state, setState] = useState<SubscriptionState | null>(null);

    const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        //console.log(val);
        setData(val ? val : null);
    }

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        //console.log(val);
        if (val === SubscriptionState.NonAttendance.toString()) {
            setState(SubscriptionState.NonAttendance);
        }
        else if (val === SubscriptionState.Attendance.toString()) {
            setState(SubscriptionState.Attendance);
        }
        else if (val === SubscriptionState.Canceled.toString()) {
            setState(SubscriptionState.Canceled);
        }
        else if (val === SubscriptionState.Confirmed.toString()) {
            setState(SubscriptionState.Confirmed);
        }
        else if (val === SubscriptionState.AttemptPayment.toString()) {
            setState(SubscriptionState.AttemptPayment);
        }
        else if (val === SubscriptionState.Ingoing.toString()) {
            setState(SubscriptionState.Ingoing);
        }
        else if (val === SubscriptionState.Pending.toString()) {
            setState(SubscriptionState.Pending);
        }
        else {
            setState(null);
        }
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        //console.log(event);
        if (state != null && data != null) {
            props.onSubmit(state, data);
        }
    }

    return (
        <Fragment>
            <Form.Control as="select" onChange={handleChangeState} name="idSubscriptionState">
                <option key={"idSubscriptionState00"} value={(-1)}>{"Scegli un nuovo stato"}</option>
                {
                    Object.entries(SubscriptionState)
                        .filter(([key, value], index: number) => {
                            if (initState === SubscriptionState.Confirmed) {
                                return (key === SubscriptionState.Attendance.toString() || key === SubscriptionState.NonAttendance.toString());
                            }
                            else if (initState === SubscriptionState.AttemptPayment) {
                                return (key === SubscriptionState.Canceled.toString());
                            }
                            else if (initState === SubscriptionState.Ingoing) {
                                return (key === SubscriptionState.Confirmed.toString() || key === SubscriptionState.Canceled.toString());
                            }
                            else if (initState === SubscriptionState.Pending) {
                                return (key === SubscriptionState.Confirmed.toString() || key === SubscriptionState.Canceled.toString());
                            }
                            //NonAttendance || Attendance || Canceled 
                            return key === SubscriptionState.Pending.toString();
                        })
                        .map(([key, value], index: number) => (
                            {
                                [SubscriptionState.Pending]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Torna in lista d'attesa"}</option>
                                ),
                                [SubscriptionState.Ingoing]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Pre-iscrizione"}</option>
                                ),
                                [SubscriptionState.Confirmed]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Conferma iscrizione"}</option>
                                ),
                                [SubscriptionState.Canceled]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Annulla iscrizione"}</option>
                                ),
                                [SubscriptionState.Attendance]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Assegna la presenza"}</option>
                                ),
                                [SubscriptionState.NonAttendance]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"Assegna l'assenza"}</option>
                                ),
                                [SubscriptionState.AttemptPayment]: (
                                    <option key={"idSubscriptionState" + index + value} value={key}>{"In pagamento"}</option>
                                ),
                            }[key]
                        ))
                }
            </Form.Control>
            <Form.Control type="date" name="date" onChange={handleChangeData} />
            <Button onClick={handleSubmit} disabled={data === null || state === null}>Assegna</Button>
        </Fragment>
    );
};

export default SubscriberEditStatusForm;