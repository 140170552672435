import { IDeleteAttachmentThunkPayload, IGetAttachmentThunkPayload } from '../redux/slices/attachment.slice';
import { IGetAttachmentsThunkPayload } from '../redux/slices/attachments.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getAttachment = (payload: IGetAttachmentThunkPayload) => {
  const url = ApiEndpoints.Attachment.replace(':id', payload.idAllegato.toString());
  return AxiosService.instance.axios.get(url);
};

export const deleteAttachment = (payload: IDeleteAttachmentThunkPayload) => {
  const url = ApiEndpoints.DeleteAttachment.replace(':id', payload.idAllegato.toString());
  return AxiosService.instance.axios.delete(url);
};

export const getAttachments = (payload: IGetAttachmentsThunkPayload) => {
  let endpoint = ApiEndpoints.Attachments.toString();
  let querystring = Array<string>();
  if (payload.idAzienda && payload.idAzienda > 0) {
    querystring.push('idAzienda=' + payload.idAzienda.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

const AttachmentAPI = {
  getAttachment,
  deleteAttachment,
  getAttachments,
};

export default AttachmentAPI;