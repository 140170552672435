import { FC, Fragment, useEffect, useState } from 'react';
import { Container, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { IRejectResponse } from '../../api/api.interface';
import CourseForm from '../../components/admin/CourseForm';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getEnteTerzoAbbinato } from '../../components/shared/dropdown/EnteTerzoAbbinato';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { getCourseDetail } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';

const AdminCourseOther: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setLoading] = useState(false);
  const [delayDangerToast, setDelayDangerToast] = useState(20000);
  const [showDangerToast, setShowDangerToast] = useState(false);

  const hideDangerToast = () => setShowDangerToast(false);

  const partners: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.partners);
  const error: IRejectResponse | null = useSelector((s: RootState) => s.course.error);
  const course: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.detail);
  
  useEffect(() => {
    dispatch(getCourseDetail({ id: Number(id) }))
      .then((e) => {
        //console.log("eeeeepayload", e.payload);
      })
      .catch(() => setShowDangerToast(true));
  }, [dispatch]);

  const handleSave = (value: ICourseEntityRaw) => {
    //console.log(value);
    //setLoading(true);
  };
  
  const handleClickBackToCourses = () => {
    const route = AuthenticatedRoutesAdmin.OtherCourses.toString();
    navigate(route);
  };
  
  return (
    <Fragment>
      <PageTitle content={`Corso ${(course && course?.idCorsoRiferimento && course.idCorsoRiferimento > 0) ? 'replica' : ''} `} />
      <PageSubtitle subContent={course?.titolo} content={`${(course && course?.codice && course.codice.length) ? course.codice : 'dettaglio'} ${(course && course.idAzienda != course.idAziendaProponente) ? (" - " + getEnteTerzoAbbinato(course.idAzienda, partners)) : ""}`} />
      <PageParagraph content="" />

      <Container fluid as="section">
        {
          (course && !isLoading) ? (
            <CourseForm course={course} onSave={handleSave} isLoading={true} submitted={false} readonly={true} />
          ) : (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )
        }
      </Container>

      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione terminata con errori!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status}: {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default AdminCourseOther;