import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IProps {
  content: string;
  subContent?: string;
}

const PageSubtitle: FC<IProps> = (props) => (
  <Container fluid as="section">
    <Row>
      <Col className="d-flex align-items-start flex-column m-0">
        <h3 className="mt-auto page-subtitle p-0">{props.content}</h3>
      </Col>
      {props.children && <Col className="d-flex align-items-end flex-column m-0">{props.children}</Col>}
    </Row>
    {props?.subContent && <Row className="p-0 m-0"><Col className="d-flex align-items-start flex-colum m-0 p-0"><h6 className="mt-auto p-0">{props.subContent}</h6></Col></Row>}
  </Container>
);

export default PageSubtitle;
