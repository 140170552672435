import React, { FC, Fragment, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../../entities/company.entity';
import { RootState } from '../../../redux/store';

export const getEnteTerzoAbbinato = (idAzienda: number, list: Array<ICompanyEntity>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getEnteTerzoAbbinatoItem = (idAzienda: number, list: Array<ICompanyEntity>): ICompanyEntity | null => {
  if (list.length) {
    const option = list.find((elem) => elem.idAzienda === idAzienda);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: ICompanyEntity | null) => void;
}

const EnteTerzoAbbinato: FC<IProps> = (props) => {

  const joinpartners: Array<ICompanyEntity> = useSelector((s: RootState) => s.company.joinpartners);

  const defaultSelectedItem = (): ICompanyEntity | null => {
    if (props?.defaultSelectedId) {
      return getEnteTerzoAbbinatoItem(props.defaultSelectedId, joinpartners);
    }
    return null;
  };

  const [filterByEnteTerzoAbbianto, setFilterByEnteTerzoAbbianto] = useState<ICompanyEntity | null>(defaultSelectedItem());
  const [searchItemsByText, setSearchItemsByText] = useState<string>('');

  const handleFilterByEnteTerzoAbbianto = (item: ICompanyEntity | null) => {
    setFilterByEnteTerzoAbbianto(item);
    if (props.onSelect) {
      props.onSelect(item);
    }
  };

  const handleSearchItemsByTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchItemsByText(text);
  };

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary">
          {`${filterByEnteTerzoAbbianto ? filterByEnteTerzoAbbianto.nome : 'Ente terzo autorizzato'} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <FormControl placeholder="Cerca" aria-label="Cerca" onChange={handleSearchItemsByTextChange} aria-describedby="basic-search-option-item" />
          <Dropdown.Item key={"dropdownEnteTerzoAbbiantoNone"} disabled={filterByEnteTerzoAbbianto === null} onClick={() => handleFilterByEnteTerzoAbbianto(null)}>Tutti</Dropdown.Item>
          {
            joinpartners.length ? (
              joinpartners.filter((itm: ICompanyEntity) => {
                if (searchItemsByText.length === 0) return true;
                return itm.nome.toLowerCase().startsWith(searchItemsByText.toLowerCase());
              }).map((item: ICompanyEntity, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownEnteTerzoAbbianto" + item.idAzienda} disabled={filterByEnteTerzoAbbianto !== null && item.idAzienda === filterByEnteTerzoAbbianto.idAzienda} onClick={() => handleFilterByEnteTerzoAbbianto(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default EnteTerzoAbbinato;