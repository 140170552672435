import React, { FC, Fragment, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BsDownload, BsXCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { IAttachmentEntity } from '../../entities/attachment.entity';
import { AttachmentType } from '../../enums/attachment-type.enum';
import { getAttachment, IGetAttachmentFulfilledPayload, IGetAttachmentThunkPayload } from '../../redux/slices/attachment.slice';
import { deleteCourseAttachment, IDeleteCourseAttachmentThunkPayload } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';

interface IProps {
    idCorso: number;
}

const CourseAttachmentList: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDeleteAttachment = (row: IAttachmentEntity) => {
        setIsLoading(true);
        dispatch(deleteCourseAttachment({ idAllegato: row.idAllegato } as IDeleteCourseAttachmentThunkPayload))
            .unwrap()
            .finally(() => setIsLoading(false));
    };

    const handleDownloadAttachment = (row: IAttachmentEntity) => {
        setIsLoading(true);
        dispatch(getAttachment({ idAllegato: row.idAllegato } as IGetAttachmentThunkPayload))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IGetAttachmentFulfilledPayload;
                const div = document.getElementById("divDownloadCourseAttachment");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .catch((e) => {
                // console.log("KO", e);
            })
            .finally(() => setIsLoading(false));
    };

    const attachments: Array<IAttachmentEntity> = useSelector((s: RootState) => s.course.value?.allegati ? s.course.value.allegati : new Array<IAttachmentEntity>());

    const columns: TableColumn<IAttachmentEntity>[] = [
        {
            id: 'idTipoAllegato',
            name: 'Tipo',
            selector: (row: IAttachmentEntity) => {
                if (row.idTipoAllegato === AttachmentType.Logo) {
                    return "Logo";
                }
                else if (row.idTipoAllegato === AttachmentType.Locandina) {
                    return "Locandina";
                }
                else if (row.idTipoAllegato === AttachmentType.ContrattoDiIscrizione) {
                    return "Contratto di iscrizione";
                }
                else if (row.idTipoAllegato === AttachmentType.MaterialeDidattico) {
                    return "Materiale didattico";
                }

                return "Materiale didattico";
            },
        },
        {
            id: 'filename',
            name: 'Nome',
            selector: (row: IAttachmentEntity) => row.filename.toString(),
        },
        {
            cell: (row, index, column, id) => {
                return (<BsDownload className="text-dark c-pointer" title="Scarica allegato" onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleDownloadAttachment(row);
                }} size={16} />);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row, index, column, id) => {
                return (<BsXCircle className="text-danger c-pointer" title="Cancella allegato" onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleDeleteAttachment(row);
                }} size={16} />);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ] as TableColumn<IAttachmentEntity>[];

    return (
        <Fragment>
            <DataTable
                columns={columns}
                data={attachments}
                responsive
                striped
                dense={true}
                noDataComponent="Nessun risultato"
                progressPending={isLoading} />
            <div id="divDownloadCourseAttachment" className="d-none"></div>
        </Fragment>
    );
};

export default CourseAttachmentList;