import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import { useNavigate } from 'react-router-dom';
import { loginSso } from '../redux/slices/auth.slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { AuthenticatedRoutes } from '../enums/routes.enum';
import Footer from '../layout/parts/Footer';

const LoginSSO: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loginSso({}))
      .unwrap()
      .then(() => navigate(AuthenticatedRoutes.Me));
  }, [dispatch]);

  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <div className="m-auto text-center">
              <Spinner animation='border' />
              <h1 className="text-dark">autenticazione in corso</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer variant={"dark"} />
    </Fragment>
  );
};

export default LoginSSO;