import { IGetFavoritesThunkPayload } from '../redux/slices/favorites.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getFavorites = (payload: IGetFavoritesThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.Favorites.toString());
};

const FavoriteAPI = {
  getFavorites,
};

export default FavoriteAPI;