import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import { CompanyAPI } from "../../api/company.api";
import { ICompanyEntity } from "../../entities/company.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetCompaniesThunkPayload { }

export interface IGetCompaniesFulfilledPayload {
  companies: ICompanyEntity[] | [];
}

export const getCompanies = createAsyncThunk(
  'company/join/orders/get',
  async (thunkPayload: IGetCompaniesThunkPayload, thunkAPI) => {
    try {
      const response = await CompanyAPI.getCompanies(thunkPayload);
      const successPayload = response.data as unknown as ICompanyEntity[];
      const fulfillValue = {
        companies: successPayload
      } as IGetCompaniesFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetPartnersThunkPayload { }

export interface IGetPartnersFulfilledPayload {
  partners: ICompanyEntity[] | [];
}

export const getPartners = createAsyncThunk(
  'company/join/partners/get',
  async (thunkPayload: IGetPartnersThunkPayload, thunkAPI) => {
    try {
      const response = await CompanyAPI.getPartners(thunkPayload);
      const successPayload = response.data as unknown as ICompanyEntity[];
      const fulfillValue = {
        partners: successPayload
      } as IGetPartnersFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetCompanyThunkPayload { }

export interface IGetCompanyFulfilledPayload {
  company: ICompanyEntity;
}

export const getCompany = createAsyncThunk(
  'company/me/get',
  async (thunkPayload: IGetCompanyThunkPayload, thunkAPI) => {
    try {
      const response = await CompanyAPI.getCompany(thunkPayload);
      const successPayload = response.data as unknown as ICompanyEntity;
      const fulfillValue = {
        company: successPayload
      } as IGetCompanyFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICompanyState {
  value: ICompanyEntity | null;
  joinorders: ICompanyEntity[] | [];
  joinpartners: ICompanyEntity[] | [];
}

const initialState: ICompanyState = {
  value: null,
  joinorders: [],
  joinpartners: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.value = null;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCompanyFulfilledPayload;
      state.value = payload.company;
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.value = null;
    });

    builder.addCase(getCompanies.pending, (state) => {
      state.joinorders = [];
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCompaniesFulfilledPayload;
      state.joinorders = payload.companies;
    });
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.joinorders = [];
    });

    builder.addCase(getPartners.pending, (state) => {
      state.joinpartners = [];
    });
    builder.addCase(getPartners.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetPartnersFulfilledPayload;
      state.joinpartners = payload.partners;
    });
    builder.addCase(getPartners.rejected, (state, action) => {
      state.joinpartners = [];
    });
  },
});

export default companySlice.reducer;