export enum SubscriptionState {
    Pending = 1, /* Lista d'attesa */
    Ingoing = 2, /* Pre-iscritti */
    Confirmed = 3, /* Iscritti */
    Canceled = 4, /* Annullati */
    Attendance = 5, /* Presenti */
    NonAttendance = 6, /* Assenti */
    AttemptPayment = 7, /* In Pagamento */ 
}

export enum InterestCourseState {
    Favorite = 1, 
    Pending = 2, 
    PreRegistered = 3,
}