import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import OwnCourseList from '../../components/partner/OwnCourseList';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany } from '../../redux/slices/company.slice';
import { getCoursesExport, ICoursesExportFulfilledPayload } from '../../redux/slices/courses.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';

const PartnerUnpublishedCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  const handleExport = () => {
    setIsLoading(true);
    dispatch(getCoursesExport({ statoPubblicazione: 2, own: 1 }))
      .unwrap()
      .then((e) => {
        const payload = e as unknown as ICoursesExportFulfilledPayload;
        const div = document.getElementById("divPartnerExportCoursesUnpublishedAttachmentCSV");
        if (div) {
          b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
            const url = window.URL.createObjectURL(blobresult);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
            div.appendChild(link);
            link.click();
            link.remove();
          });
        }
      })
      .catch((e) => {
        // console.log("KO", e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      <PageTitle content="Corsi">
        <a href="/assets/tutorial/E_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista corsi in attesa di pubblicazione">
        <Button onClick={handleExport}>
          {isLoading ? (
            <Fragment>
              <Spinner as="span" animation="border" size="sm" role="status" />
              <span className="ms-1">Attendere</span>
              <span>...</span>
            </Fragment>
          ) : (
            <span>Esporta in .CSV</span>
          )}
        </Button>
        <div id="divPartnerExportCoursesUnpublishedAttachmentCSV" className="d-none"></div>
      </PageSubtitle>
      <PageParagraph content="" />
      <OwnCourseList statoPubblicazione={[1, 2]} />
    </Fragment>
  );
};

export default PartnerUnpublishedCourses;