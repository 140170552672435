import React, { FC, Fragment, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BsDownload, BsXCircle, BsUpload } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { IAttachmentDetail } from '../../entities/attachment.entity';
import { getAttachment, IGetAttachmentFulfilledPayload, IGetAttachmentThunkPayload, deleteAttachment, IDeleteAttachmentThunkPayload, IDeleteAttachmentFulfilledPayload } from '../../redux/slices/attachment.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import { Button, Modal } from 'react-bootstrap';
import { IUser } from '../../entities/user.entity';

interface IProps {
    stato: number;
    allegatiAggiornati: IAttachmentDetail[];
    setAllegatiAggiornati: (newType: IAttachmentDetail[]) => void;
}

const ShowDetailAttachment: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<IAttachmentDetail[]>(props.allegatiAggiornati);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState<IAttachmentDetail | null>(null);
    const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value ? s.auth.user.value : null);

    useEffect( () => {
        setInitialValues(props.allegatiAggiornati);
    }, [props.allegatiAggiornati]);

    const handleDeleteAttachment = (row: IAttachmentDetail) => {
        setSelectedAttachment(row);
        setShowConfirmDelete(true);   
    };
    const handleDelete = () => {
        const newValues = initialValues.filter((riga) => riga !== selectedAttachment);        
        setInitialValues(newValues);
        props.setAllegatiAggiornati(newValues);
        // console.log(newValues);
        // console.log(selectedAttachment?.idAllegato);
        
        dispatch(deleteAttachment({ idAllegato: selectedAttachment?.idAllegato } as IDeleteAttachmentThunkPayload))
            .unwrap()
            .finally(() => setIsLoading(false));
        
        setShowConfirmDelete(false); 
    };
    const handleCloseDelete = () => {
        setShowConfirmDelete(false); 
    };

    const handleDownloadAttachment = (row: IAttachmentDetail) => {
        setIsLoading(true);
        dispatch(getAttachment({ idAllegato: row.idAllegato } as IGetAttachmentThunkPayload))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IGetAttachmentFulfilledPayload;
                const div = document.getElementById("divDownloadCourseAttachmentsList");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .catch((e) => {
                // console.log("KO", e);
            })
            .finally(() => setIsLoading(false));
    };

    const columns: TableColumn<IAttachmentDetail>[] = [
        {
            id: 'filename',
            name: 'Allegati',
            selector: (row: IAttachmentDetail) => row.filename.toString(),
        },
        {
            cell: (row, index, column, id) => {
                if (((user?.role === 3 || user?.role === 4 || user?.role === 6) && props.stato === 1) ||
                    ((user?.role === 1 || user?.role === 2 || user?.role === 5) && props.stato < 3)) {     
                    return (<BsXCircle className="text-danger c-pointer" title="Cancella allegato" onClick={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        handleDeleteAttachment(row);
                    }} size={16} />);
                }                
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row, index, column, id) => {
                return (<BsDownload className="text-dark c-pointer" title="Scarica allegato" onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleDownloadAttachment(row);
                }} size={16} />);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ] as TableColumn<IAttachmentDetail>[];

    return (
        <Fragment>
            <DataTable
                columns={columns}
                data={initialValues}
                // data={props.attachmentsList}
                responsive
                striped
                dense={true}
                noDataComponent=""
                progressPending={isLoading} />
            <div id="divDownloadCourseAttachmentsList" className="d-none" />

            <Modal centered show={showConfirmDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title><p className="display-6 m-0 p-0">Cancella allegato</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Confermi la cancellazione?<br /><small>Cliccando sul bottone ELIMINA l'allegato verrà cancellato.</small></p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete} disabled={isLoading}>Annulla</Button>
                    <Button variant="danger" onClick={handleDelete} disabled={isLoading}>Elimina</Button>
                </Modal.Footer>
            </Modal>          
        </Fragment>
    );
};

export default ShowDetailAttachment;