import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';

export const getEventoGratuito = (id: number | null): string => {
    if (id === 10) {
        return 'GRATUITI per gli appartenenti all\'ordine proponente';
    }
    if (id === 11) {
        return 'A PAGAMENTO per gli appartenenti all\'ordine proponente';
    }
    if (id === 20) {
        return 'GRATUITI per gli esterni all\'ordine proponente';
    }
    if (id === 21) {
        return 'A PAGAMENTO per gli esterni all\'ordine proponente';
    }
    if (id === 30) {
        return 'GRATUITI per gli interni ed esterni all\'ordine proponente';
    }
    if (id === 31) {
        return 'A PAGAMENTO per gli interni o esterni all\'ordine proponente';
    }
    return 'Tutti';
}

interface IProps {
    defaultSelectedId?: number | null;
    onSelect?: (option: number | null) => void;
}

const EventoGratuito: FC<IProps> = (props) => {

    const defaultSelected = () => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId > 0 ? props.defaultSelectedId : null;
        }
        return null;
    };

    const [filterByOption, setFilterByOption] = useState<number | null>(defaultSelected());

    const handleFilterByOption = (option: number | null) => {
        setFilterByOption(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-2 my-2">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-eventogratuito">
                    {filterByOption != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByOption !== null ? getEventoGratuito(filterByOption) : "Eventi gratuiti / a pagamento"} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownEventoGratuitoNull"} disabled={filterByOption === null} onClick={() => handleFilterByOption(null)}>Tutti</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoGratuitoInterni"} disabled={filterByOption === 10} onClick={() => handleFilterByOption(10)}>{getEventoGratuito(10)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoGratuitoEsterni"} disabled={filterByOption === 20} onClick={() => handleFilterByOption(20)}>{getEventoGratuito(20)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoGratuitoInterniEdEsterni"} disabled={filterByOption === 30} onClick={() => handleFilterByOption(30)}>{getEventoGratuito(30)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoAPagamentoInterni"} disabled={filterByOption === 11} onClick={() => handleFilterByOption(11)}>{getEventoGratuito(11)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoAPagamentoEsterni"} disabled={filterByOption === 21} onClick={() => handleFilterByOption(21)}>{getEventoGratuito(21)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEventoAPagamentoInterniOppureEsterni"} disabled={filterByOption === 31} onClick={() => handleFilterByOption(31)}>{getEventoGratuito(31)}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default EventoGratuito;