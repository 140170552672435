import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse, IServerResponse } from '../../api/api.interface';
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import AdviceAPI from "../../api/advice.api";
import { IAdviceEntity } from "../../entities/advice.entity";
import { parseISOStringToStandardDate } from "../../utils/course-parser";

export interface IGetAdviceThunkPayload {
  id: number;
}

export interface IGetAdviceFulfilledPayload {
  advice: IAdviceEntity;
}

export const getAdvice = createAsyncThunk(
  'advice/get',
  async (thunkPayload: IGetAdviceThunkPayload, thunkAPI) => {
    try {
      const response = await AdviceAPI.getAdvice(thunkPayload);
      const successPayload = response.data as unknown as IAdviceEntity;
      const fulfillValue = {
        advice: successPayload,
      } as IGetAdviceFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostAdviceThunkPayload {
  advice: IAdviceEntity;
}
  
export interface IPostAdviceFulfilledPayload {
  id: number;
}

export const postAdvice = createAsyncThunk(
  'advice/post',
  async (thunkPayload: IPostAdviceThunkPayload, thunkAPI) => {
    try {
      const response = await AdviceAPI.postAdvice(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<number>;
      const idAdvice: number = successPayload.data as number;
      const fulfillValue = {
        id: idAdvice
      } as IPostAdviceFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutAdviceThunkPayload {
  advice: IAdviceEntity;
}

export interface IPutAdviceFulfilledPayload {
  advice: IAdviceEntity;
}

export const putAdvice = createAsyncThunk(
  'advice/put',
  async (thunkPayload: IPutAdviceThunkPayload, thunkAPI) => {
    try {
      const response = await AdviceAPI.putAdvice(thunkPayload);
      const successPayload = response.data as IServerResponse<IAdviceEntity>;
      const fulfillValue = {
        advice: successPayload.data
      } as IPutAdviceFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteAdviceThunkPayload {
  id: number;
}
  
export interface IDeleteAdviceFulfilledPayload { }
  
export const deleteAdvice = createAsyncThunk(
  'advice/delete',
  async (thunkPayload: IDeleteAdviceThunkPayload, thunkAPI) => {
    try {
      //const response = await AdviceAPI.deleteAdvice(thunkPayload);
      await AdviceAPI.deleteAdvice(thunkPayload);
      const fulfillValue = { } as IDeleteAdviceFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IAdviceState {
  error: IRejectResponse | null;
  value: IAdviceEntity | null;
}

const initialState: IAdviceState = {
  error: null,
  value: null,
};

const adviceSlice = createSlice({
  name: "advice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdvice.pending, (state) => {
      state.error = null;
      state.value = null;
    });
    builder.addCase(getAdvice.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetAdviceFulfilledPayload;

      const dataI: string = parseISOStringToStandardDate(payload.advice.dataInizioValidita);
      const dataF: string = parseISOStringToStandardDate(payload.advice.dataFineValidita);

      const advice = {
        ...payload.advice,
        dataInizioValidita: dataI,
        dataFineValidita: dataF,
      } as IAdviceEntity;

      state.value = advice;
    });
    builder.addCase(getAdvice.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(postAdvice.pending, (state) => {
      state.error = null;
    });
    builder.addCase(postAdvice.fulfilled, (_state, _action) => { });
    builder.addCase(postAdvice.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(putAdvice.pending, (state) => {
      state.error = null;
    });
    builder.addCase(putAdvice.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPutAdviceFulfilledPayload;
      
      const dataI: string = parseISOStringToStandardDate(payload.advice.dataInizioValidita);
      const dataF: string = parseISOStringToStandardDate(payload.advice.dataFineValidita);

      const advice = {
        ...payload.advice,
        dataInizioValidita: dataI,
        dataFineValidita: dataF,
      } as IAdviceEntity;

      state.value = advice;
    });
    builder.addCase(putAdvice.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(deleteAdvice.pending, (_state) => { });
    builder.addCase(deleteAdvice.rejected, (_state, _action) => { });
    builder.addCase(deleteAdvice.fulfilled, (_state, _action) => { });
  },
});

export default adviceSlice.reducer;