import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { IRejectResponse } from '../api/api.interface';
import { ILoginMockSsoThunkPayload, ILoginThunkPayload, login, loginMockSso } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';

interface IFormValue extends ILoginThunkPayload { }

const initialValues: IFormValue = {
  username: '',
  password: '',
};

const masterData: IFormValue = { ...initialValues };
const supervisorData: IFormValue = { ...initialValues };
const managerData: IFormValue = { ...initialValues };
const adminData: IFormValue = { ...initialValues };
const partnerData: IFormValue = { ...initialValues };
const guestData: IFormValue = { ...initialValues };

if (process.env.NODE_ENV === 'development') {
  masterData.username = 'aspmaster';
  masterData.password = 'Prova123!';
  supervisorData.username = 'supervisortest';
  supervisorData.password = 'supervisortest';
  managerData.username = 'managertest';
  managerData.password = 'managertest';
  adminData.username = 'ordinetest';
  adminData.password = 'ordinetest';
  partnerData.username = 'partnertest';
  partnerData.password = 'partnertest';
  guestData.username = 'utentetest';
  guestData.password = 'utentetest';
}

interface IProps {
  verticalSpacing: number;
  isMaintenanceMode: boolean;
}

export const LoginForm: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const schema = yup.object().shape({
    username: yup.string().required(t('login.form.errors.fieldRequired')),
    password: yup.string().required(t('login.form.errors.fieldRequired')),
  });

  const handleSubmit = (value: IFormValue) => {
    setLoading(true);
    setError('');

    dispatch(login(value as ILoginThunkPayload))
      .unwrap()
      .then()
      .catch((exc) => {
        const err = exc as unknown as IRejectResponse;
        setError(err?.error ? err.error : '');
        setLoading(false);
      });
  };

  const handleSubmitMockUser = () => {
    if (process.env.NODE_ENV === 'development') {
      setLoading(true);
      setError('');

      dispatch(loginMockSso({} as ILoginMockSsoThunkPayload))
        .unwrap()
        .then()
        .catch((exc) => {
          const err = exc as unknown as IRejectResponse;
          setError(err?.error ? err.error : '');
          setLoading(false);
        });
    }
  }

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className={`py-${props.verticalSpacing}`}>
              <Form.Group as={Col} controlId="username">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="text"
                  name="username"
                  placeholder={t('login.form.usernamePlaceholder')}
                  disabled={isLoading}
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Form.Group as={Col} controlId="password">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="password"
                  name="password"
                  placeholder={t('login.form.passwordPlaceholder')}
                  disabled={isLoading}
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Col className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">{t('login.form.submitting')}</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>Login</span>
                  )}
                </Button>
              </Col>
            </Row>
            {
              error && error.length ? (
                <Row className={`py-${props.verticalSpacing}`}>
                  <Col className="text-center">
                    <Alert variant="danger" className="p-1">{error}</Alert>
                  </Col>
                </Row>
              ) : (
                <Fragment />
              )
            }
          </Form>
        )}
      </Formik>
      {props.isMaintenanceMode && (
        <div>
          <Alert variant="warning" className={`my-${props.verticalSpacing} py-3 text-center`}><h4>Sito web in <u><b>MANUTENZIONE</b></u>!</h4><h6>Si consiglia di non tentare l'accesso.</h6></Alert>
        </div>
      )}
      {process.env.NODE_ENV === 'development' && (
        <div>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmit(supervisorData)}>
            Nazionale
          </Button>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmit(managerData)}>
            Assistenza
          </Button>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmit(adminData)}>
            Ordine
          </Button>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmit(partnerData)}>
            Ente Terzo
          </Button>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmitMockUser()}>
            Professionista (utenti_esterni)
          </Button>
          <Button variant="outline-primary" className="w-50 mb-1" onClick={() => handleSubmit(guestData)}>
            Professionista (utenti)
          </Button>
          <Button variant="outline-primary" className="w-100 mb-1" onClick={() => handleSubmit(masterData)}>
            Didanet
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default LoginForm;
