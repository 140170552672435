import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LineeGuidaAPI from "../../api/lineeguida.api";

// da fare
import { IRejectResponse, IServerResponse } from '../../api/api.interface';
import { ILineaGuidaEntity } from '../../entities/lineeguida.entity';


import { AxiosError } from 'axios';
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

// interfaccia oggetto con tipi
interface LineeGuidaState {
  lineeGuida: Array<ILineaGuidaEntity>;
  idTriennioCorrente: number | null;
  idLineaGuidaCorrente: number | null;
  loading: boolean;
  error: string | null;
}

// stato iniziale dell'oggetto con valori
const initialState: LineeGuidaState = {
  lineeGuida: [],
  idTriennioCorrente: null,
  idLineaGuidaCorrente: null,
  loading: false,
  error: null,
};

export interface IGetLineeGuidaThunkPayload { }

export interface IGetLineeGuidaFulfilledPayload {
  lineeGuida: Array<ILineaGuidaEntity>;
  idTriennioCorrente: number;
  idLineaGuidaCorrente: number;
}

export const getLineeGuida = createAsyncThunk(
  'lineeguida/get',
  async (thunkPayload: IGetLineeGuidaThunkPayload, thunkAPI) => {
    try {
      const response = await LineeGuidaAPI.getLineeGuida(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<IGetLineeGuidaFulfilledPayload>;
      // console.log("linee guida payload", successPayload);
      // const fulfillValue = successPayload.data as IGetLineeGuidaFulfilledPayload;
      return thunkAPI.fulfillWithValue(successPayload);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

const lineeguidaSlice = createSlice({
  name: 'lineeguida',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(getLineeGuida.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(getLineeGuida.fulfilled, (state, action) => {
            const payload = action.payload as unknown as IGetLineeGuidaFulfilledPayload;
            state.lineeGuida = payload.lineeGuida;
            state.idTriennioCorrente = payload.idTriennioCorrente;
            state.idLineaGuidaCorrente = payload.idLineaGuidaCorrente;
            state.loading = false;
          })
          .addCase(getLineeGuida.rejected, (state, action) => {
            state.lineeGuida = [];
            state.idTriennioCorrente = 0;
            state.idLineaGuidaCorrente = 0;
            state.loading = false;
            state.error = action.payload as string;
          });
  },
});

export default lineeguidaSlice.reducer;
