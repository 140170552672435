import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import CertificationList from '../../components/user/CertificationList';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';
import RequestCertificationForm from '../../components/user/RequestCertificationForm';

const UserCreditRequest: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Certificazioni / Esoneri">
        <a href="/assets/tutorial/U_ElencoCertificazioni.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Cronologia delle richieste" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="elenco">
              <Tab eventKey="elenco" title="Elenco certificazioni richieste" className="py-3 bg-light">
                <CertificationList />
              </Tab>
              <Tab eventKey="newrequest" title="Inserisci nuova richiesta" className="py-3 bg-light">
                <RequestCertificationForm codiceFiscale={''} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserCreditRequest;