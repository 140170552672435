import { FC } from "react";
import { IAdviceEntity } from "../../entities/advice.entity";
import { Container } from "react-bootstrap";
import { isVisibleNow, whichScope } from "../../utils/advice-default";
import AdviceItem from "../shared/AdviceItem";

interface IProps {
    advice: IAdviceEntity;
}

const Advice: FC<IProps> = (props) => {
    return (
      <Container as="section" fluid>
        <AdviceItem advice={props.advice} />
        <h6>Adesso la news <u>{isVisibleNow(props.advice) ? "viene mostrata" : "NON viene mostrata"}</u> {isVisibleNow(props.advice) && " nella dashboard di "} <b>{isVisibleNow(props.advice) && whichScope(props.advice)}</b></h6>
      </Container>
    );
};

export default Advice;