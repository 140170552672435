import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaFileAlt, FaGraduationCap, FaHome, FaRegIdCard } from 'react-icons/fa';
import { MdHelp, MdLogout, MdSupportAgent } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from '../../entities/user.entity';
import { AuthenticatedRoutes, AuthenticatedRoutesManager, UnauthenticatedRoutes } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  user: IUser;
}

const SidebarManager: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  let [selected, setSelected] = useState<string>(AuthenticatedRoutesManager.Dashboard);

  const isSelected = (key: string) => selected ? selected.startsWith(key) : false;
  const isSelectedItem = (key: string) => selected ? selected === key : false;

  const handleSelectParent = (e: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isSelectedItem(value) && expanded) return;
    if (!expanded) dispatch(openSidebar());
    if (isSelected(value)) return;
    let target: HTMLElement = e.target as HTMLElement;
    while (target.tagName.toLocaleLowerCase() !== 'div') {
      target = target.parentElement as HTMLElement;
    }
    setTimeout(() => target.click(), 200);
    setSelected(value);
  };

  const handleSelectItem = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = UnauthenticatedRoutes.Login));
  };

  return (
    <SideNav
      style={{ zIndex: 1031 }}
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelectItem}
    >
      <SideNav.Toggle />
      <SideNav.Nav selected={selected}>
        {expanded && (
          <NavItem
            eventKey={AuthenticatedRoutes.Me}
            className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
          >
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none"
              >
                <Image src="/assets/support-dark-theme.svg" height={72} />
              </Link>
            </div>
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none text-light text-uppercase small"
              >
                {props.user.firstName} {props.user.lastName}
              </Link>
            </div>
          </NavItem>
        )}
        <NavItem
          eventKey={AuthenticatedRoutesManager.Dashboard}
          className={isSelected(AuthenticatedRoutesManager.Dashboard) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Dashboard)}
              className="text-decoration-none"
            >
              <FaHome size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesManager.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Dashboard)}
              className="text-decoration-none"
            >
              Home
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesManager.Attachments}
          className={isSelected(AuthenticatedRoutesManager.Attachments) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.Attachments}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Attachments)}
              className="text-decoration-none"
            >
              <FaFileAlt size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesManager.Attachments}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Attachments)}
              className="text-decoration-none"
            >
              Allegati
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesManager.ListProfessionals}
          className={isSelected(AuthenticatedRoutesManager.ListProfessionals) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.ListProfessionals}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.ListProfessionals)}
              className="text-decoration-none"
            >
              <FaGraduationCap size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesManager.ListProfessionals}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.ListProfessionals)}
              className="text-decoration-none"
            >
              Professionisti
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesManager.Otp}
          className={isSelected(AuthenticatedRoutesManager.Otp) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.Otp}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Otp)}
              className="text-decoration-none"
            >
              <MdSupportAgent size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesManager.Otp}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Otp)}
              className="text-decoration-none"
            >
              Assistenza Ordini / Enti
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesManager.ReplaceCf}
          className={isSelected(AuthenticatedRoutesManager.ReplaceCf) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.ReplaceCf}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.ReplaceCf)}
              className="text-decoration-none"
            >
              <FaRegIdCard size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesManager.ReplaceCf}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.ReplaceCf)}
              className="text-decoration-none"
            >
              Sostituzione codice fiscale
            </Link>
          </NavText>
        </NavItem>
      </SideNav.Nav>
      <SideNav.Nav componentClass="footer">
        <NavItem
          eventKey={AuthenticatedRoutesManager.Tutorials}
          className={isSelected(AuthenticatedRoutesManager.Tutorials) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesManager.Tutorials}
              onClick={() => handleSelectItem(AuthenticatedRoutesManager.Tutorials)}
              className="text-decoration-none"
            >
              <MdHelp size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutes.Support}
              onClick={() => handleSelectItem(AuthenticatedRoutes.Support)}
              className="text-decoration-none"
            >
              Guide
            </Link>
          </NavText>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>Esci</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarManager;
