import { FC, Fragment, useEffect, useState } from 'react';
import { Container, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IRejectResponse } from '../../api/api.interface';
import CourseForm from '../../components/admin/CourseForm';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { IPostCourseFulfilledPayload, IPostCourseThunkPayload, postCourse } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { courseInit } from '../../utils/course-default';
import { FaInfoCircle } from 'react-icons/fa';
import { ICompanyEntity } from '../../entities/company.entity';
import { IGetCompaniesFulfilledPayload, getCompanies } from '../../redux/slices/company.slice';

const AdminNewCourse: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const error: IRejectResponse | null = useSelector((s: RootState) => s.course.error);

  const [delaySuccessToast, ] = useState(4000);
  const [delayDangerToast, ] = useState(10000);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDangerToast, setShowDangerToast] = useState(false);
  const [isLoadingInit, setIsLoadingInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);
  
  const handleGetInitCourse = (arr : ICompanyEntity[]) : ICourseEntityRaw => {
    const azid : number = arr.length === 0 ? 0 : arr[0].idAzienda;
    const cinit : ICourseEntityRaw = { ...courseInit, idAziendaProponente: azid };
    return cinit;
  }

  const [course, setCourse] = useState<ICourseEntityRaw>();
  
  useEffect(() => {
      setIsLoadingInit(true);
      dispatch(getCompanies({}))
        .unwrap()
        .then((payload) => {
          const p = payload as unknown as IGetCompaniesFulfilledPayload;
          setCourse(handleGetInitCourse(p.companies));
        })
        .finally(() => setIsLoadingInit(false));
    }, [dispatch]);
  
  const handleClickGoToEditCourse = (idCorso: number) => {
    let route = null;

    route = AuthenticatedRoutesAdmin.Course.toString();
    route = route.replace(':id', idCorso.toString());

    navigate(route);
  };

  const handleSave = (value: ICourseEntityRaw) => {
    setIsLoading(true);
    dispatch(postCourse({ course: value } as IPostCourseThunkPayload))
      .unwrap()
      .then((fulfilledPayload) => {
        setShowSuccessToast(true);
        setSubmitted(true);
        const payload = fulfilledPayload as unknown as IPostCourseFulfilledPayload;
        if (payload) {
          setTimeout(() => handleClickGoToEditCourse(payload.id), 2000);
        }
      })
      .catch(() => {
        setShowDangerToast(true);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Fragment>
      <PageTitle content="Corso">
        <a href="/assets/tutorial/O_Corso.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Nuovo" />
      <PageParagraph content="" />
      <Container fluid as="section">
        { 
          course && !isLoadingInit ? (
            <CourseForm course={course} onSave={handleSave} isLoading={isLoading} submitted={submitted} />
          ) : (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )
        }
      </Container>

      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide id="toastSuccess">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio completato!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light"></Toast.Body>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide id="toastDanger">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio non riuscito!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status} {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default AdminNewCourse;
