import { FC, Fragment } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import PrivacyPolicyText from './PrivacyPolicyText';

interface IProps {
    show: boolean;
    handleClose: () => void;
}

const PrivacyPolicyView: FC<IProps> = (props) => {
    return (
        <Fragment>
            <Container fluid as="section">
                <Modal
                    size="xl"
                    centered
                    scrollable
                    show={props.show}
                    backdrop="static"
                    keyboard={false}
                    onHide={props.handleClose}
                >
                    <Modal.Header>
                        <Modal.Title className="text-center">
                            <h2><span className="privacyStyle">INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI</span></h2>
                            <h5 className="text-muted">degli utenti che accedono al portale web di formazione continua del Consiglio Nazionale degli Architetti, Pianificatori, Paesaggisti e Conservatori (di seguito anche &ldquo;CNAPPC&rdquo;) ai sensi dell'articolo 13 del Regolamento (UE) 2016/679</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PrivacyPolicyText />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-primary w-25" onClick={props.handleClose}>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </Fragment>
    );
}

export default PrivacyPolicyView;

