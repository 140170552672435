import { IPostAppuserOtpThunkPayload } from '../redux/slices/appuser.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const postAppuserOtp = (payload: IPostAppuserOtpThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.AppuserOtp, payload);
};

const AppuserAPI = {
  postAppuserOtp,
};

export default AppuserAPI;