import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import SuspenseFallback from './components/SuspenseFallback';
import './i18n';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';

ReactDOM.render(
  <React.Suspense fallback={<SuspenseFallback />}>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Suspense>,
  document.getElementById('root')
);

reportWebVitals();
