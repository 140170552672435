import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';

export const getStatusCfpOk = (id: boolean | null): string => {
    if (id === false) {
        return 'KO';
    }
    if (id === true) {
        return 'OK';
    }
    return '';
}

interface IProps {
    defaultSelectedId?: boolean | null;
    onSelect?: (option: boolean | null) => void;
}

const StatusCfpOk: FC<IProps> = (props) => {

    const defaultSelected = (): boolean | null => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByStatusCfpOk, setFilterByStatusCfpOk] = useState<boolean | null>(defaultSelected());

    const handleFilterByStatusCfpOk = (option: boolean | null) => {
        setFilterByStatusCfpOk(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-statuscfpok">
                    {filterByStatusCfpOk != null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`Status ${getStatusCfpOk(filterByStatusCfpOk)} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownStatusCfpOkNull"} disabled={filterByStatusCfpOk === null} onClick={() => handleFilterByStatusCfpOk(null)}>Tutti</Dropdown.Item>
                    <Dropdown.Item key={"dropdownStatusCfpOkTrue"} disabled={filterByStatusCfpOk === true} onClick={() => handleFilterByStatusCfpOk(true)}>{getStatusCfpOk(true)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownStatusCfpOkFalse"} disabled={filterByStatusCfpOk === false} onClick={() => handleFilterByStatusCfpOk(false)}>{getStatusCfpOk(false)}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default StatusCfpOk;