import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import CourseAPI from '../../api/course.api';
import { IAttachment, IAttachmentEntity } from "../../entities/attachment.entity";
import { ICourseEntityRaw } from "../../entities/course.entity";
import { ISubscriptionEntity } from "../../entities/subscription.entity";
import { ITeacherEntity } from "../../entities/teacher.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import { formatICourseEntityRawDate, setSubscriptionState } from '../../utils/course-parser';

export interface IGetCoursePaymentReceiptThunkPayload {
  idCorso: number;
}

export interface IGetCoursePaymentReceiptFulfilledPayload {
  id: number;
  idCorso: number;
  codiceFiscale: string;
  transactionid: string;
  positionid: string;
  rt: string;
}

export const getCoursePaymentReceipt = createAsyncThunk(
  'course/payment/receipt/get',
  async (thunkPayload: IGetCoursePaymentReceiptThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCoursePaymentReceipt(thunkPayload);
      const fulfillValue = response.data as unknown as IGetCoursePaymentReceiptFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetPaymentTReceiptThunkPayload {
  idCorso: number;
  codiceFiscale: string;
}

export interface IGetPaymentTReceiptFulfilledPayload { }

export const getPaymentTReceipt = createAsyncThunk(
  'course/payment/telematicreceipt/get',
  async (thunkPayload: IGetPaymentTReceiptThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getPaymentTReceipt(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse;
      const fulfillValue = { } as IGetPaymentTReceiptFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteSubscriptionAndPendingPaymentThunkPayload {
  idCorso: number;
  codiceFiscale: string;
}

export interface IDeleteSubscriptionAndPendingPaymentFulfilledPayload { }

export const deleteSubscriptionAndPendingPayment = createAsyncThunk(
  'course/subscription/payment/delete',
  async (thunkPayload: IDeleteSubscriptionAndPendingPaymentThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.deleteSubscriptionAndPendingPayment(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse;
      const fulfillValue = { } as IDeleteSubscriptionAndPendingPaymentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteSubscriptionAndPendingPaymentWithTimingThunkPayload {
  idCorso: number;
  codiceFiscale: string;
}

export interface IDeleteSubscriptionAndPendingPaymentWithTimingFulfilledPayload { }

export const deleteSubscriptionAndPendingPaymentWithTiming = createAsyncThunk(
  'course/subscription/payment-with-timing/delete',
  async (thunkPayload: IDeleteSubscriptionAndPendingPaymentWithTimingThunkPayload, thunkAPI) => {
    try {
      await CourseAPI.deleteSubscriptionAndPendingPaymentWithTiming(thunkPayload);
      // const successPayload = response.data as unknown as IServerResponse;
      const fulfillValue = { } as IDeleteSubscriptionAndPendingPaymentWithTimingFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetCourseThunkPayload {
  id: number;
}

export interface IGetCourseFulfilledPayload {
  course: ICourseEntityRaw;
}

export const getCourse = createAsyncThunk(
  'course/get',
  async (thunkPayload: IGetCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCourse(thunkPayload);
      const successPayload = response.data as unknown as ICourseEntityRaw;

      const fulfillValue = {
        course: successPayload
      } as IGetCourseFulfilledPayload;

      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetCourseDetailThunkPayload {
  id: number;
}

export interface IGetCourseDetailFulfilledPayload {
  course: ICourseEntityRaw;
}

export const getCourseDetail = createAsyncThunk(
  'course/detail/get',
  async (thunkPayload: IGetCourseDetailThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCourseDetail(thunkPayload);
      const successPayload = response.data as unknown as ICourseEntityRaw;

      const fulfillValue = {
        course: successPayload
      } as IGetCourseDetailFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostCourseThunkPayload {
  course: ICourseEntityRaw;
}

export interface IPostCourseFulfilledPayload {
  id: number;
}

export const postCourse = createAsyncThunk(
  'course/post',
  async (thunkPayload: IPostCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postCourse(thunkPayload);
      const successPayload = response.data as unknown as ISuccessResponse<number>;
      const idCorso: number = successPayload.data as number;
      const fulfillValue = {
        id: idCorso
      } as IPostCourseFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutUnsubscribeCourseThunkPayload {
  codiceFiscale: string;
  idCorso: number;
}

export interface IPutUnsubscribeCourseFullfilledPayload {
};

export const putUnsubscribeCourse = createAsyncThunk(
  'course/unsubscribe/put',
  async (thunkPayload: IPutUnsubscribeCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.putUnsubscribeCourse(thunkPayload);
      /* const successPayload = response.data as ISuccessResponse<ICourseEntityRaw>; */
      const fullfillValue: IPutUnsubscribeCourseFullfilledPayload = {

      } as IPutUnsubscribeCourseFullfilledPayload;
      return thunkAPI.fulfillWithValue(fullfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutCourseThunkPayload {
  course: ICourseEntityRaw;
}

export interface IPutCourseFulfilledPayload {
  course: ICourseEntityRaw;
}

export const putCourse = createAsyncThunk(
  'course/put',
  async (thunkPayload: IPutCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.putCourse(thunkPayload);
      const successPayload = response.data as ISuccessResponse<ICourseEntityRaw>;
      const fulfillValue: IPutCourseFulfilledPayload = {
        course: successPayload.data
      } as IPutCourseFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostSubscriberImportThunkPayload {
  idCorso: number;
  fileName: string;
  contentType: string;
  contentRaw: string | ArrayBuffer;
}

export interface IPostSubscriberImportFulfilledPayload {
  //subscriptions: ISubscriptionEntity[];
}

export const postSubscriberImport = createAsyncThunk(
  'course/subscriber/import/post',
  async (thunkPayload: IPostSubscriberImportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postSubscriberImport(thunkPayload);
      //const successPayload = response.data as unknown as ISubscriptionEntity[];
      const fulfillValue = {
        //subscriptions: successPayload
      } as IPostSubscriberImportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IpostCourseTeacherThunkPayload {
  idCorso: number;
  idDocente: number;
}

export interface IpostCourseTeacherFulfilledPayload {
  teacher: ITeacherEntity;
}

export const postCourseTeacher = createAsyncThunk(
  'course/teacher/post',
  async (thunkPayload: IpostCourseTeacherThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postCourseTeacher(thunkPayload);
      const successPayload = response.data as unknown as ITeacherEntity;
      const fulfillValue = {
        teacher: successPayload
      } as IpostCourseTeacherFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostSubscriberThunkPayload {
  idCorso: number;
  codiceFiscale: string;
  esterna: boolean;
  dtConfReg: Date | null;
  dtConfPres: Date | null;
}

export interface IPostSubscriberFulfilledPayload {
  subscriptions: Array<ISubscriptionEntity>;
}

export const postSubscriber = createAsyncThunk(
  'course/subscriber/add/post',
  async (thunkPayload: IPostSubscriberThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postSubscriber(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<Array<ISubscriptionEntity>>;
      const fulfillValue = {
        subscriptions: successPayload.data
      } as IPostSubscriberFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostAddToPendingListThunkPayload {
  idCorso: number;
}

export interface IPostAddToPendingListFulfilledPayload { }

export const postAddToPendingList = createAsyncThunk(
  'course/pending-list/add/post',
  async (thunkPayload: IPostAddToPendingListThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postAddToPendingList(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<ISubscriptionEntity>;
      const fulfillValue = { } as IPostAddToPendingListFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostSubscribeThunkPayload {
  idCorso: number;
}

export interface IPostSubscribeFulfilledPayload {
  pay: boolean;
  url: string;
}

export const postSubscribe = createAsyncThunk(
  'course/subscribe/post',
  async (thunkPayload: IPostSubscribeThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postSubscribe(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<string>;
      
      if (successPayload.status > 999) {
        const paymenturl: string = successPayload?.data?.toString() as unknown as string;
        const fulfillValue: IPostSubscribeFulfilledPayload = {
          pay: true,
          url: paymenturl
        } as IPostSubscribeFulfilledPayload;
        return thunkAPI.fulfillWithValue(fulfillValue);
      }
      
        const fulfillVal: IPostSubscribeFulfilledPayload = {
          pay: false,
          url: ""
        } as IPostSubscribeFulfilledPayload;
        return thunkAPI.fulfillWithValue(fulfillVal);
      
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetSubscriberFulfilledPayload {
  subscription: ISubscriptionEntity;
}

export interface IPutCheckMoodleCompletionThunkPayload {
  id: number,
  cf: string
}

export const checkMoodleCourseCompletion = createAsyncThunk(
  'course/subscription-by-moodle/put',
  async (thunkPayload: IPutCheckMoodleCompletionThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.checkMoodleCompletion(thunkPayload);
      const successPayload = response.data as unknown as ISubscriptionEntity;
      const fulfillValue = {
        subscription: successPayload
      } as IGetSubscriberFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetSubscribersThunkPayload {
  idCorso: number;
}

export interface IGetSubscribersFulfilledPayload {
  subscriptions: Array<ISubscriptionEntity>;
}

export const getSubscribers = createAsyncThunk(
  'course/subscribers/get',
  async (thunkPayload: IGetSubscribersThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getSubscribers(thunkPayload);
      const successPayload = response.data as unknown as Array<ISubscriptionEntity>;
      const fulfillValue = {
        subscriptions: successPayload
      } as IGetSubscribersFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutSubscriptionThunkPayload {
  subscription: ISubscriptionEntity;
}

export interface IPutSubscriptionFulfilledPayload {
  subscription: ISubscriptionEntity;
}

export const putSubscription = createAsyncThunk(
  'course/subscription/put',
  async (thunkPayload: IPutSubscriptionThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.putSubscription(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<ISubscriptionEntity>;
      const fulfillValue = {
        subscription: successPayload.data
      } as IPutSubscriptionFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutSubscriptionsStatusParamThunkPayload {
  fiscalCodes: Array<string>;
  newStatus: number;
  dateRif: string;
}

export interface IPutSubscriptionsStatusThunkPayload {
  idCorso: number;
  param: IPutSubscriptionsStatusParamThunkPayload;
}

export interface IPutSubscriptionsStatusFulfilledPayload { }

export const putSubscriptionsStatus = createAsyncThunk(
  'course/subscription/status/put',
  async (thunkPayload: IPutSubscriptionsStatusThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.putSubscriptionsStatus(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<number>;
      const fulfillValue = {} as IPutSubscriptionsStatusFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteSubscriptionsThunkPayload {
  idCorso: number;
  ids: Array<number>;
}

export interface IDeleteSubscriptionsFulfilledPayload { }

export const deleteSubscriptions = createAsyncThunk(
  'course/subscription/delete',
  async (thunkPayload: IDeleteSubscriptionsThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.deleteSubscriptions(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<number>;
      const fulfillValue = {} as IDeleteSubscriptionsFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostCourseAttachmentThunkPayload extends IAttachmentEntity { }

export interface IPostCourseAttachmentFulfilledPayload {
  attachment: IAttachmentEntity;
}

export const postCourseAttachment = createAsyncThunk(
  'course/attachment/post',
  async (thunkPayload: IPostCourseAttachmentThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postCourseAttachment(thunkPayload);
      const successPayload = response.data as unknown as IAttachmentEntity;
      const fulfillValue = {
        attachment: successPayload
      } as IPostCourseAttachmentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetSubscriberExportThunkPayload {
  idCorso: number;
}

export interface IGetSubscriberExportFulfilledPayload {
  attachment: IAttachment;
}

export const getSubscriberExport = createAsyncThunk(
  'course/enrolled/export',
  async (thunkPayload: IGetSubscriberExportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getSubscriberExport(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload
      } as IGetSubscriberExportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostSubscriberCustomImportThunkPayload {
  idCorso: number;
  fileName: string;
  contentType: string;
  contentRaw: string | ArrayBuffer;
}

export interface IPostSubscriberCustomImportFulfilledPayload { }

export const postSubscriberCustomImport = createAsyncThunk(
  'course/subscriber/custom-import/post',
  async (thunkPayload: IPostSubscriberCustomImportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postSubscriberCustomImport(thunkPayload);
      //const successPayload = response.data as unknown as ISubscriptionEntity[];
      const fulfillValue = {
        //subscriptions: successPayload
      } as IPostSubscriberCustomImportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteCourseAttachmentThunkPayload {
  idAllegato: number;
}

export interface IDeleteCourseAttachmentFulfilledPayload { }

export const deleteCourseAttachment = createAsyncThunk(
  'course/attachment/delete',
  async (thunkPayload: IDeleteCourseAttachmentThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.deleteCourseAttachment(thunkPayload);
      const fulfillValue = {
      } as IDeleteCourseAttachmentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteCourseThunkPayload {
  idCorso: number;
}

export interface IDeleteCourseFulfilledPayload { }

export const deleteCourse = createAsyncThunk(
  'course/delete',
  async (thunkPayload: IDeleteCourseThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.deleteCourse(thunkPayload);
      const fulfillValue = {
      } as IDeleteCourseFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICourseState {
  value: ICourseEntityRaw | null;
  error: IRejectResponse | null;
  subscriberError: IRejectResponse | null;
  detail: ICourseEntityRaw | null;
}

const initialState: ICourseState = {
  value: null,
  error: null,
  subscriberError: null,
  detail: null,
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourse.pending, (state) => {
      state.value = null;
      state.error = null;
      state.subscriberError = null;
    });
    builder.addCase(getCourse.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCourseFulfilledPayload;
      state.value = formatICourseEntityRawDate(payload.course);
    });
    builder.addCase(getCourse.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(getCourseDetail.pending, (state) => {
      state.detail = null;
    });
    builder.addCase(getCourseDetail.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCourseDetailFulfilledPayload;
      state.detail = formatICourseEntityRawDate(payload.course);
    });
    builder.addCase(getCourseDetail.rejected, (state, action) => {
      state.detail = null;
    });

    builder.addCase(postCourse.pending, (state) => {
      state.error = null;
    });
    builder.addCase(postCourse.fulfilled, (state, action) => {
      //const payload = action.payload as unknown as IPostCourseFulfilledPayload;
    });
    builder.addCase(postCourse.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(putCourse.pending, (state) => {
      state.error = null;
    });
    builder.addCase(putCourse.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPutCourseFulfilledPayload;
      state.value = formatICourseEntityRawDate(payload.course);
      //state.value = action.meta.arg.course;
    });
    builder.addCase(putCourse.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(getSubscriberExport.pending, (state) => { });
    builder.addCase(getSubscriberExport.rejected, (state, action) => { });
    builder.addCase(getSubscriberExport.fulfilled, (state, action) => { });

    builder.addCase(postSubscriberImport.pending, (state) => { });
    builder.addCase(postSubscriberImport.rejected, (state, action) => { });
    builder.addCase(postSubscriberImport.fulfilled, (state, action) => { });

    builder.addCase(postSubscriberCustomImport.pending, (state) => { });
    builder.addCase(postSubscriberCustomImport.rejected, (state, action) => { });
    builder.addCase(postSubscriberCustomImport.fulfilled, (state, action) => { });

    builder.addCase(deleteSubscriptions.pending, (state) => { });
    builder.addCase(deleteSubscriptions.rejected, (state, action) => { });
    builder.addCase(deleteSubscriptions.fulfilled, (state, action) => { });

    builder.addCase(postAddToPendingList.pending, (state) => { });
    builder.addCase(postAddToPendingList.rejected, (state, action) => { });
    builder.addCase(postAddToPendingList.fulfilled, (state, action) => { });

    builder.addCase(getSubscribers.pending, (state) => {
      state.subscriberError = null;
    });
    builder.addCase(getSubscribers.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetSubscribersFulfilledPayload;
      if (state.value?.iscritti && payload.subscriptions) {
        state.value.iscritti.splice(0);
        payload.subscriptions.map((element: ISubscriptionEntity) => {
          state.value?.iscritti.push(setSubscriptionState(element));
        });
      }
    });
    builder.addCase(getSubscribers.rejected, (state, action) => {
      //const payload = action.payload as unknown as IRejectResponse;
      //state.subscriberError = payload;
    });

    builder.addCase(postSubscriber.pending, (state) => {
      state.subscriberError = null;
    });
    builder.addCase(postSubscriber.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPostSubscriberFulfilledPayload;
      if (state.value?.iscritti && payload.subscriptions) {
        state.value.iscritti.splice(0, state.value.iscritti.length);
        payload.subscriptions.map((element: ISubscriptionEntity) => {
          state.value?.iscritti.push(setSubscriptionState(element));
        });
      }
    });
    builder.addCase(postSubscriber.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.subscriberError = payload;
    });

    builder.addCase(postSubscribe.pending, (state) => { });
    builder.addCase(postSubscribe.fulfilled, (state, action) => { });
    builder.addCase(postSubscribe.rejected, (state, action) => { });

    builder.addCase(putSubscription.pending, (state) => { });
    builder.addCase(putSubscription.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPutSubscriptionFulfilledPayload;
      if (state.value?.iscritti && payload.subscription) {
        let i = 0;
        state.value.iscritti.forEach((elem: ISubscriptionEntity, index: number) => {
          if (elem.id == payload.subscription.id) i = index;
        })
        state.value.iscritti[i] = setSubscriptionState(payload.subscription);
      }
    });
    builder.addCase(putSubscription.rejected, (state, action) => { });

    builder.addCase(putSubscriptionsStatus.pending, (state) => { });
    builder.addCase(putSubscriptionsStatus.fulfilled, (state, action) => { });
    builder.addCase(putSubscriptionsStatus.rejected, (state, action) => { });

    builder.addCase(postCourseTeacher.pending, (state) => { });
    builder.addCase(postCourseTeacher.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IpostCourseTeacherFulfilledPayload;
      if (state.value?.docenti) {
        state.value.docenti.push(payload.teacher);
      }
    });
    builder.addCase(postCourseTeacher.rejected, (state, action) => { });

    builder.addCase(postCourseAttachment.pending, (state) => { });
    builder.addCase(postCourseAttachment.rejected, (state, action) => { });
    builder.addCase(postCourseAttachment.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPostCourseAttachmentFulfilledPayload;
      if (state.value?.allegati && payload.attachment) {
        state.value.allegati.push(payload.attachment);
      }
    });

    builder.addCase(deleteCourseAttachment.pending, (state) => { });
    builder.addCase(deleteCourseAttachment.rejected, (state, action) => { });
    builder.addCase(deleteCourseAttachment.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IDeleteCourseAttachmentFulfilledPayload;
      if (state.value?.allegati) {
        const idAllegato = action.meta.arg.idAllegato;
        let i = -1;

        state.value.allegati.forEach((el: IAttachmentEntity, index: number) => {
          if (el.idAllegato === idAllegato) {
            i = index;
          }
        });

        if (i >= 0) {
          state.value.allegati.splice(i, 1);
        }
      }
    });

    builder.addCase(deleteCourse.pending, (state) => { });
    builder.addCase(deleteCourse.rejected, (state, action) => { });
    builder.addCase(deleteCourse.fulfilled, (state, action) => { });

    builder.addCase(getCoursePaymentReceipt.pending, (state) => { });
    builder.addCase(getCoursePaymentReceipt.rejected, (state, action) => { });
    builder.addCase(getCoursePaymentReceipt.fulfilled, (state, action) => { });

    builder.addCase(getPaymentTReceipt.pending, (state) => { });
    builder.addCase(getPaymentTReceipt.rejected, (state, action) => { });
    builder.addCase(getPaymentTReceipt.fulfilled, (state, action) => { });

    builder.addCase(putUnsubscribeCourse.pending, (state) => { });
    builder.addCase(putUnsubscribeCourse.rejected, (state, action) => { });
    builder.addCase(putUnsubscribeCourse.fulfilled, (state, action) => { });

    builder.addCase(checkMoodleCourseCompletion.pending, (state) => { });
    builder.addCase(checkMoodleCourseCompletion.rejected, (state, action) => { });
    builder.addCase(checkMoodleCourseCompletion.fulfilled, (state, action) => { });

    builder.addCase(deleteSubscriptionAndPendingPayment.pending, (state) => { });
    builder.addCase(deleteSubscriptionAndPendingPayment.rejected, (state, action) => { });
    builder.addCase(deleteSubscriptionAndPendingPayment.fulfilled, (state, action) => { });

    builder.addCase(deleteSubscriptionAndPendingPaymentWithTiming.pending, (state) => { });
    builder.addCase(deleteSubscriptionAndPendingPaymentWithTiming.rejected, (state, action) => { });
    builder.addCase(deleteSubscriptionAndPendingPaymentWithTiming.fulfilled, (state, action) => { });

  },
});

export default courseSlice.reducer;
