import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import ProfessionalAPI from "../../api/professional.api";
import { IAttachment } from "../../entities/attachment.entity";
import { IProfessionalEntity } from "../../entities/professional.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IProfessionalsFulfilledPayload {
  professionals: Array<IProfessionalEntity>;
  countTotalProfessionals: number;
}

export interface IGetProfessionalsFilter {
  text: string;
  esterno: number;
  statoIscrizione: string;
}

export interface IGetProfessionalsSorting {
  orderby: Array<string>;
  ordertype: boolean;
}

export interface IGetProfessionalsPagination {
  page: number;
  limit: number;
}

export interface IProfessionalsThunkPayload {
  filter: IGetProfessionalsFilter;
  pagination: IGetProfessionalsPagination;
  sorting: IGetProfessionalsSorting;
}

export interface IProfessionalsEmailTSendThunkPayload {
  type: number
}

export const getProfessionals = createAsyncThunk(
  'professionals/get',
  async (thunkPayload: IProfessionalsThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionals(thunkPayload);
      const successPayload = response.data as unknown as Array<IProfessionalEntity>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalProfessionals = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        professionals: successPayload,
        countTotalProfessionals: countTotalProfessionals
      } as IProfessionalsFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalSearchFulfilledPayload {
  professionals: Array<IProfessionalEntity>;
}

export interface IProfessionalSearchThunkPayload {
  text: string;
  activetoday: number;
}

export const getProfessionalSearch = createAsyncThunk(
  'professionals/search/get',
  async (thunkPayload: IProfessionalSearchThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalSearch(thunkPayload);
      const successPayload = response.data as unknown as Array<IProfessionalEntity>;
      const fulfillValue = {
        professionals: successPayload
      } as IProfessionalSearchFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostProfessionalCertificateImportThunkPayload {
  fileName: string;
  contentType: string;
  contentRaw: string | ArrayBuffer;
}

export interface IPostProfessionalCertificateImportFulfilledPayload { }

export const postProfessionalCertificateImport = createAsyncThunk(
  'professionals/certificate/import/post',
  async (thunkPayload: IPostProfessionalCertificateImportThunkPayload, thunkAPI) => {
    try {
      await ProfessionalAPI.postProfessionalCertificateImport(thunkPayload);
      //const response = await ProfessionalAPI.postProfessionalCertificateImport(thunkPayload);
      //const successPayload = response.data;
      const fulfillValue = { } as IPostProfessionalCertificateImportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostProfessionalsFromAwnThunkPayload {
  data: string;
}

export interface IPostProfessionalsFromAwnFulfilledPayload { }

export const postProfessionalsFromAwn = createAsyncThunk(
  'professionals/awn/post',
  async (thunkPayload: IPostProfessionalsFromAwnThunkPayload, thunkAPI) => {
    try {
      await ProfessionalAPI.postProfessionalsFromAwn(thunkPayload);
      //const response = await ProfessionalAPI.postProfessionalsFromAwn(thunkPayload);
      //const successPayload = response.data as unknown as IServerResponse<number>;
      const fulfillValue = { } as IPostProfessionalsFromAwnFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalsExportThunkPayload { }

export interface IProfessionalsExportFulfilledPayload {
  attachment: IAttachment;
}

export const getProfessionalsExport = createAsyncThunk(
  'professionals/export/get',
  async (thunkPayload: IProfessionalsExportThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalsExport(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload,
      } as IProfessionalsExportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalsExportStatusCfpThunkPayload { 
  idTriennio: number | null;
  idStatoIscrizione: string | null;
  isStatusCfpOk: boolean | null;
}

export interface IProfessionalsExportStatusCfpFulfilledPayload {
  attachment: IAttachment;
}

export const getProfessionalsExportStatusCfp = createAsyncThunk(
  'professionals/export-status-cfp/get',
  async (thunkPayload: IProfessionalsExportStatusCfpThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalsExportStatusCfp(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload,
      } as IProfessionalsExportStatusCfpFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalsState {
  error: IRejectResponse | null;
  value: Array<IProfessionalEntity>;
  total: number;
  searchError: IRejectResponse | null;
  searchValue: Array<IProfessionalEntity>;
}

const initialState: IProfessionalsState = {
  error: null,
  value: new Array<IProfessionalEntity>(),
  total: 0,
  searchError: null,
  searchValue: new Array<IProfessionalEntity>(),
};

const professionalsSlice = createSlice({
  name: "professionals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfessionals.pending, (state) => {
      state.error = null;
      state.value = new Array<IProfessionalEntity>();
    });
    builder.addCase(getProfessionals.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IProfessionalsFulfilledPayload;
      state.error = null;
      state.value = payload.professionals;
      state.total = payload.countTotalProfessionals;
    });
    builder.addCase(getProfessionals.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = new Array<IProfessionalEntity>();
    });

    builder.addCase(getProfessionalSearch.pending, (state) => {
      state.searchError = null;
      state.searchValue = new Array<IProfessionalEntity>();
    });
    builder.addCase(getProfessionalSearch.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IProfessionalsFulfilledPayload;
      state.searchError = null;
      state.searchValue = payload.professionals;
    });
    builder.addCase(getProfessionalSearch.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.searchError = payload;
      state.searchValue = new Array<IProfessionalEntity>();
    });

    builder.addCase(postProfessionalCertificateImport.pending, (state) => { });
    builder.addCase(postProfessionalCertificateImport.fulfilled, (state, action) => { });
    builder.addCase(postProfessionalCertificateImport.rejected, (state, action) => { });

    builder.addCase(postProfessionalsFromAwn.pending, (state) => { });
    builder.addCase(postProfessionalsFromAwn.fulfilled, (state, action) => { });
    builder.addCase(postProfessionalsFromAwn.rejected, (state, action) => { });

    builder.addCase(getProfessionalsExport.pending, (state) => { });
    builder.addCase(getProfessionalsExport.fulfilled, (state, action) => { });
    builder.addCase(getProfessionalsExport.rejected, (state, action) => { });

    builder.addCase(getProfessionalsExportStatusCfp.pending, (state) => { });
    builder.addCase(getProfessionalsExportStatusCfp.fulfilled, (state, action) => { });
    builder.addCase(getProfessionalsExportStatusCfp.rejected, (state, action) => { });
  },
});

export default professionalsSlice.reducer;
