import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

export const Limit = [0, 10, 25, 50, 100];

interface IProps {
    defaultSelectedId?: number;
    onSelect?: (limit: number) => void;
    enableUnlimit: boolean;
    initPaginationLimit?: Array<number>;
}

const PaginationLimit: FC<IProps> = (props) => {

    const paginationlimit = props?.initPaginationLimit && props.initPaginationLimit.length > 0 ? props.initPaginationLimit : Limit;

    const defaultSelected = (): number => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        const index = Math.floor(paginationlimit.length / 2);
        return paginationlimit[index];
    };

    const [filterByPaginationLimit, setFilterByPaginationLimit] = useState<number>(defaultSelected());

    const handleFilterByPaginationLimit = (limit: number) => {
        setFilterByPaginationLimit(limit);
        if (props?.onSelect) {
            props.onSelect(limit);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-2 my-2">
                <Dropdown.Toggle variant="outline-primary">
                    {`Record per pagina ${filterByPaginationLimit ? filterByPaginationLimit : 'TUTTI'} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    {
                        paginationlimit.length ? (
                            paginationlimit.map((item: number, index: number) => {
                                return (
                                    (index === 0 && item === 0) ? (
                                        (props.enableUnlimit) ? (
                                            <Dropdown.Item key={"dropdownPagLimitUnLimit" + item} disabled={filterByPaginationLimit === item} onClick={() => handleFilterByPaginationLimit(item)}>
                                                Tutti
                                            </Dropdown.Item>
                                        ) : (
                                            <Fragment key={"dropdownPagLimitUnLimit"} />
                                        )
                                    ) : (
                                        <Dropdown.Item key={"dropdownPagLimit" + item} disabled={filterByPaginationLimit === item} onClick={() => handleFilterByPaginationLimit(item)}>
                                            {item}
                                        </Dropdown.Item>
                                    )
                                );
                            })
                        ) : (
                            <Fragment />
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default PaginationLimit;