import { FC } from 'react';
import { Badge } from 'react-bootstrap';

interface IProps {
  cfp: number;
}

const CourseListItemCFP: FC<IProps> = (props) => (
  <h5>
    <Badge bg="primary" className="text-light">
      <span>{props.cfp}</span>
      <span>&nbsp;</span>
      <span>CFP</span>
    </Badge>
  </h5>
);

export default CourseListItemCFP;
