import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import ProfessionalAPI from "../../api/professional.api";
import { IProfessionalStatusFormativoEntity } from "../../entities/professional.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';;

export interface IProfessionalStatusFormativoFilter {
  codiceFiscale: string;
}

export interface IProfessionalStatusFormativoThunkPayload {
  filter: IProfessionalStatusFormativoFilter;
}

export interface IProfessionalStatusFormativoFulfilledPayload {
  statusFormativo: IProfessionalStatusFormativoEntity;
  // statusFormativo: Array<IProfessionalStatusFormativoEntity>;
}

export const getProfessionalStatusFormativo = createAsyncThunk(
  'professional/statusformativo/get',
  async (thunkPayload: IProfessionalStatusFormativoThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalStatusFormativo(thunkPayload);
      const successPayload = response.data as unknown as IProfessionalStatusFormativoEntity;
      // const successPayload = response.data as unknown as Array<IProfessionalStatusFormativoEntity>;

      const fulfillValue = {
        statusFormativo: successPayload
      } as IProfessionalStatusFormativoFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalStatusFormativoState {
  statusFormativo: IProfessionalStatusFormativoEntity;  
}

const initialState: IProfessionalStatusFormativoState = {
  statusFormativo: {} as IProfessionalStatusFormativoEntity,
  // statusFormativo: new Array<IProfessionalStatusFormativoEntity>(),
};

const professionalStatusFormativoSlice = createSlice({
  name: "professional",
  initialState,
  reducers: {},
  extraReducers: (builder) => {   
    builder.addCase(getProfessionalStatusFormativo.pending, (state) => {      
      state.statusFormativo = {} as IProfessionalStatusFormativoEntity;
      // state.statusFormativo = new Array<IProfessionalStatusFormativoEntity>();
    });
    builder.addCase(getProfessionalStatusFormativo.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IProfessionalStatusFormativoFulfilledPayload;
      state.statusFormativo = payload.statusFormativo;
    });
    builder.addCase(getProfessionalStatusFormativo.rejected, (state, action) => {
      //const payload = action.payload as unknown as IRejectResponse;
    });
  },
});

export default professionalStatusFormativoSlice.reducer;