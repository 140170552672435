import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';

export const getOrganizzatore = (id: number | null): string => {
    if (id === 2) {
        return 'Solo ordini provinciali';
    }
    if (id === 1) {
        return 'Solo enti terzi';
    }
    return 'Tutti';
}

interface IProps {
    defaultSelectedId?: number;
    onSelect?: (option: number | null) => void;
}

const Organizzatore: FC<IProps> = (props) => {

    const defaultSelected = () => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return null;
    };

    const [filterByOption, setFilterByOption] = useState<number | null>(defaultSelected());

    const handleFilterByOption = (option: number | null) => {
        setFilterByOption(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-2 my-2">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-organizzatore">
                    {filterByOption !== null ? (
                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
                            <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
                        </span>
                    ) : ""}
                    {`${filterByOption === null ? "Organizzatore" : getOrganizzatore(filterByOption)} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownOrganizzatoreNull"} disabled={filterByOption === null} onClick={() => handleFilterByOption(null)}>{getOrganizzatore(null)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownOrganizzatore2"} disabled={filterByOption === 2} onClick={() => handleFilterByOption(2)}>{getOrganizzatore(2)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownOrganizzaotre1"} disabled={filterByOption === 1} onClick={() => handleFilterByOption(1)}>{getOrganizzatore(1)}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default Organizzatore;