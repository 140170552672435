import { Formik } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, ModalFooter, Row, Spinner, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UnauthenticatedRoutes } from '../enums/routes.enum';
import { IUpdateFulfilledPayload, IUpdatePasswordThunkPayload, changePassword } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';

interface IFormValue extends IUpdatePasswordThunkPayload {
  passwordConfirmation: string;
 }

const initialValues: IFormValue = {
  password: '',
  passwordConfirmation: '',
  token: '',
};

interface IProps {
  verticalSpacing: number;
  token?: string;
  utente?: string;  
}

export const UpdatePasswordForm: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [toastBody, setToastBody] = useState("");
  const [showOkToast, setShowOkToast] = useState(false);
  const [showKoToast, setShowKoToast] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('forgot-password.form.errors.fieldRequired'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Deve contenere almeno 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale")
      .max(15, "Lunghezza massima 15 caratteri")
      .min(8, "Lunghezza minima 8 caratteri"),
    passwordConfirmation: yup
      .string()
      .required(t('forgot-password.form.errors.fieldRequired'))
      .oneOf([yup.ref("password"), null], "Le password scelte non coincidono")
  });

  const handleBackToLogin = () => {navigate(UnauthenticatedRoutes.Login)}

  const handleSubmit = (value: IFormValue) => {
    console.log(value.password);
    setLoading(true);

    const val = { password: value.password, token: props?.token || '' } as IUpdatePasswordThunkPayload;

      dispatch(changePassword(val as IUpdatePasswordThunkPayload))
      .unwrap()
      .then(response => {
        console.log(response);
        const payload = response as unknown as IUpdateFulfilledPayload;
        console.log(payload.messaggio);
        setShowForm(false);
        setLoading(false); 
        if (payload.stato == "OK") {
          setShowOkToast(true);
        } else {
          setShowKoToast(true);
        }
        setToastBody(payload?.messaggio ? payload.messaggio.toString() : "errore generico");
        // setTimeout(() => navigate(UnauthenticatedRoutes.Login), 3000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} show={showForm}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className={`py-${props.verticalSpacing}`}>
              <FloatingLabel
                controlId="newPassword"
                label="Nuova password"
                className="px-1"
              >
               <Form.Control
                  name='password'
                  type="password"
                  placeholder="Nuova Password"
                  disabled={isLoading}
                  isInvalid={!!touched.password && !!errors.password}
                  value={values?.password}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </FloatingLabel>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Conferma password"
                className="px-1"
              >
                <Form.Control
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Conferma password"
                  disabled={isLoading}
                  isInvalid={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                  value={values?.passwordConfirmation}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
              </FloatingLabel>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Col lg={5} md={4} className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">{t('forgot-password.form.submitting')}</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>Salva</span>
                  )}
                </Button>
              </Col>
              <Col lg={7} md={8}>                      
                <Button onClick={handleBackToLogin} className={`w-100`} variant="primary" disabled={isLoading}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">Torna alla login</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>Torna alla login</span>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Toast onClose={() => setShowOkToast(false)} show={showOkToast} delay={3000} autohide bg="success">
        <Toast.Header>Password modificata</Toast.Header>
        <Toast.Body>{toastBody}</Toast.Body>
      </Toast>
      <Toast onClose={() => setShowKoToast(false)} show={showKoToast} delay={3000} autohide bg="danger">
        <Toast.Header>Password NON modificata</Toast.Header>
        <Toast.Body>{toastBody}</Toast.Body>
      </Toast>
    </Fragment>
  );
};

export default UpdatePasswordForm;
