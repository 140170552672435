import React, { FC, Fragment, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMetadataOption, IMetadataOptionItem, IMetadataOptionNode } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";

export const getNome = (id: number, list: Array<IMetadataOptionNode>): string => {
  if (list?.length && list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getOption = (id: number, list: Array<IMetadataOptionNode>): IMetadataOptionNode | null => {
  if (list?.length && list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  onSelectOggetto?: (option: IMetadataOption | null) => void;
  onSelectTipologia?: (option: IMetadataOptionNode | null) => void;
}

const OggettoTipologia: FC<IProps> = (props) => {

  const oggettitipologie: Array<IMetadataOptionItem> = useSelector((s: RootState) => s.metadata.certification?.oggetto_tipologia ? s.metadata.certification.oggetto_tipologia : []);

  console.log(oggettitipologie);

  const [filterByOggetto, setFilterByOggetto] = useState<IMetadataOption | null>(null);
  const [filterByTipologia, setFilterByTipologia] = useState<IMetadataOptionNode | null>(null);

  const [searchItemsByText, setSearchItemsByText] = useState<string>('');

  const handleFilterByOggetto = (option: IMetadataOption | null) => {
    setFilterByOggetto(option);
    setFilterByTipologia(null);
    if (props?.onSelectOggetto) {
      props.onSelectOggetto(option);
    }
  };

  const handleFilterByTipologia = (option: IMetadataOptionNode | null) => {
    setFilterByTipologia(option);
    if (props?.onSelectTipologia) {
      props.onSelectTipologia(option);
    }
    if (option) {
      setFilterByOggetto({ id: option.extId, nome: '', descrizione: '' } as IMetadataOption);
    }
    else {
      setFilterByOggetto(null);
    }
  };

  const handleSearchItemsByTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchItemsByText(text);
  };

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary">
          {filterByTipologia != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Oggetto ${filterByOggetto ? filterByOggetto.id : ''} / Tipologia ${filterByTipologia ? filterByTipologia.nome : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <FormControl placeholder="Cerca" onChange={handleSearchItemsByTextChange} />
          <Dropdown.Item key={"idOggettoNone"} disabled={filterByOggetto == null && filterByTipologia == null} onClick={() => handleFilterByTipologia(null)}>Tutti</Dropdown.Item>
          {
            oggettitipologie.map((item: IMetadataOptionItem, index: number) => {
              return (
                <Fragment key={"dropdownFragment" + index} >
                  <Dropdown.Divider key={"dropdownDivider" + index} />
                  <Dropdown.Item className="text-uppercase" key={"dropdownIdOggetto__" + item.id + index} disabled={filterByTipologia == null && filterByOggetto !== null && filterByOggetto.id === item.id} onClick={() => handleFilterByOggetto(item)}>{item.id}. {item.nome}</Dropdown.Item>
                  {
                    item.children.filter((it: IMetadataOptionNode) => {
                      return it.nome.toLowerCase().includes(searchItemsByText);
                    }).map((itm: IMetadataOptionNode, idx: number) => {
                      return (<Dropdown.Item key={"dropdownIdTipologia" + item.id + "_" + itm.id + "_" + idx} 
                      disabled={(filterByTipologia != null && filterByTipologia.extId === item.id && filterByTipologia.id === itm.id)} 
                      onClick={() => handleFilterByTipologia(itm)}>{item.id}.{itm.id} - {itm.nome}</Dropdown.Item>);
                    })
                  }
                </Fragment>
              );
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default OggettoTipologia;