import { format } from 'date-fns';
import { FC, Fragment, useState } from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourse } from '../../entities/course.entity';
import { IPostAddToPendingListThunkPayload, IPostSubscribeFulfilledPayload, IPostSubscribeThunkPayload, postAddToPendingList, postSubscribe } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';
import { IAttemptPayment, setAttemptPayment } from '../../redux/slices/ui.slice';
import { useNavigate } from 'react-router';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';

interface IProps {
    course: ICourse;
    noValidation: boolean;
    noPendingList: boolean;
}

const Subscribe: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const company: ICompanyEntity | null = useSelector((s: RootState) => s.company.value);
    const companies: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);

    const handleValidation = (c: ICourse, az: ICompanyEntity | null, doValidation: boolean): boolean => {
        const now: Date = new Date();
        if(doValidation) {
            if (c.idAziendaProponente != c.idAzienda) {
                return false;
            }

            if (c.idAziendaProponente !== 635 && !c.flagEventoAperto && az?.idAzienda != c.idAziendaProponente) {
                return false;
            }

            if (!c.flagAccettaIscrizioni) {
                return false;
            }
            
            if (c.flagChiudiIscrizioni) {
                return false;
            }

            let dal = c.modalita?.dataIscrizioneDal ? c.modalita.dataIscrizioneDal : null;
            let al = c.modalita?.dataIscrizioneAl ? c.modalita.dataIscrizioneAl : null;

            if (dal || al) {
                if (dal && now < dal) {
                    return false;
                }
                if (al && now > al) {
                    return false;
                }
            }
        }

        return true;
    };

    const [isLoading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [error, setError] = useState<IRejectResponse | null>(null);
    const [success, setSuccess] = useState<IPostSubscribeFulfilledPayload | null>(null);
    const [disableListadattesa, setDisableListadattesa] = useState<boolean>(true);
    const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);

    const handleCloseSubscribeModal = () => {
        setShowSubscribeModal(false);
    }

    const handleOpenSubscribeModal = () => {
        setShowSubscribeModal(true);
    }

    const [isLoadingListadattesa, setLoadingListadattesa] = useState<boolean>(false);
    const [submittedListadattesa, setSubmittedListadattesa] = useState<boolean>(false);
    const [hasErrorListadattesa, setHasErrorListadattesa] = useState<boolean>(false);
    const [showListadattesaModal, setShowListadattesaModal] = useState<boolean>(false);

    const handleCloseListadattesaModal = () => {
        setShowListadattesaModal(false);
    }

    const handleOpenListadattesaModal = () => {
        setShowSubscribeModal(false);
        setShowListadattesaModal(true);
    }

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
    }

    const handleOpenInfoModal = () => {
        setShowInfoModal(true);
    }



    const handleGoToAttemptPaymentPage = (idCorso: number, attemptUrl: string) => {
        const attemptpaymentpayload: IAttemptPayment = { idCorso: idCorso, attemptUrl: attemptUrl } as IAttemptPayment;
        dispatch(setAttemptPayment(attemptpaymentpayload));
        setTimeout(() => {
                navigate(AuthenticatedRoutesUser.CourseAttemptPayment.replace(':id', idCorso.toString()));
            }, 3000);
    }

    const handleSubmitSubscribeModal = () => {
        setSubmitted(false);
        setSuccess(null);
        setHasError(false);
        setError(null);
        setDisableListadattesa(true);
        setLoading(true);
        
        // const linkLabel = "aHrefPaymentLink" + props.course.id.toString();
        
        const thunkPayload: IPostSubscribeThunkPayload = {
            idCorso: props.course.id
        } as IPostSubscribeThunkPayload;

        dispatch(postSubscribe(thunkPayload))
            .unwrap()
            .then((pyl) => {
                const payload = pyl as unknown as IPostSubscribeFulfilledPayload;
                setSuccess(payload);
                setSubmitted(true);
                if (payload.pay) {
                    handleGoToAttemptPaymentPage(props.course.id, payload.url);
                    /*
                    setTimeout(() => {
                        const div = document.getElementById(linkLabel);
                        if (div) {
                            const link = document.createElement('a');
                            link.href = payload.url;
                            div.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    }, 5000);
                    */
                }
            })
            .catch((e) => {
                const err = e as unknown as IRejectResponse;
                setError(err);
                setHasError(true);
                if(!props.noPendingList && err.status === 1026) {
                    setDisableListadattesa(false);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleSubmitListadattesaModal = () => {
        setSubmittedListadattesa(false);
        setHasErrorListadattesa(false);
        setLoadingListadattesa(true);

        const thunkPayload: IPostAddToPendingListThunkPayload = {
            idCorso: props.course.id
        } as IPostAddToPendingListThunkPayload;

        dispatch(postAddToPendingList(thunkPayload))
            .unwrap()
            .then(() => setSubmittedListadattesa(true))
            .catch(() => setHasErrorListadattesa(true))
            .finally(() => setLoadingListadattesa(false));
    };

    return (
        <Fragment>
            {
                //faccio la validazione e verifico che il bottone non sia già stato cliccato
                handleValidation(props.course, company, !props.noValidation) ? (
                    <Button className="w-100 border-1 border-light" disabled={submitted} key={"courseSubscribeButtonAA" + props.course.id} onClick={handleOpenSubscribeModal}>
                        Iscriviti
                    </Button>
                ) : (
                    <Fragment>
                        {props.course.idAziendaProponente != props.course.idAzienda ? (
                            <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonBB" + props.course.id} onClick={handleOpenInfoModal}>
                                Informazioni
                            </Button>
                        ) : (
                            (props.course.idAziendaProponente !== 635 && !props.course.flagEventoAperto && ((company == null) || (company?.idAzienda != props.course.idAziendaProponente))) ? (
                                <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonCC" + props.course.id} onClick={handleOpenInfoModal}>
                                    Informazioni
                                </Button>
                            ) : (
                                //Iscrizioni online disattivate oppure iscrizioni chiuse
                                (!props.course.flagAccettaIscrizioni || props.course.flagChiudiIscrizioni) ? (
                                    <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonDD" + props.course.id} onClick={handleOpenInfoModal}>
                                        Informazioni
                                    </Button>
                                ) : (
                                    //Iscrizioni dal... al...
                                    (props.course.modalita?.dataIscrizioneDal && props.course.modalita?.dataIscrizioneAl) ? (
                                        <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonEE" + props.course.id} onClick={handleOpenInfoModal}>
                                            Iscrizioni dal {format(new Date(props.course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')} al {format(new Date(props.course.modalita.dataIscrizioneAl), 'dd/MM/yyyy')}
                                        </Button>
                                    ) : (
                                        (props.course.modalita?.dataIscrizioneDal) ? (
                                            <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonFF" + props.course.id} onClick={handleOpenInfoModal}>
                                                Iscrizioni dal {format(new Date(props.course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')}
                                            </Button>
                                        ) : (
                                            (props.course.modalita?.dataIscrizioneAl) ? (
                                                <Button className="w-100" variant="outline-secondary" key={"courseInfoButtonGG" + props.course.id} onClick={handleOpenInfoModal}>
                                                    Iscrizioni fino al {format(new Date(props.course.modalita.dataIscrizioneAl), 'dd/MM/yyyy')}
                                                </Button>
                                            ) : (
                                                <Fragment />
                                            )
                                        )
                                    )
                                )
                            )

                        )}
                    </Fragment>
                )
            }

            <Modal centered show={showSubscribeModal} key={"courseSubscribeModal" + props.course.id} onHide={handleCloseSubscribeModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>Confermi l'iscrizione al corso?</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Cliccando su ISCRIVITI verrai aggiunto tra i partecipanti del corso:<br /><b>{props.course.titolo}</b></p>
                    <p>Nel caso l'evento sia Moodle basterà accedere alla voce di menù "Tutti i miei corsi" e nel dettaglio troverai il link per partecipare.</p>
                    <p>Nel caso sia previsto il versamento di una quota prima di completare l'iscrizione atterreari nella piattaforma di pagamento.</p>
                    {
                        isLoading ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendi</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <Fragment />
                        )
                    }
                    {
                        submitted ? (
                            (success && success.pay) ? (
                                <Alert variant="warning" className="p-2 m-0">
                                    <p className='text-center'>L'evento prevede il PAGAMENTO per completare l'iscrizione.</p>
                                    <p className='text-center'><Spinner as="span" animation="grow" /></p>
                                    <h6 className='text-center'>Tra poco atterrerai sulla piattaforma di pagamento...</h6>
                                    <div id={`aHrefPaymentLink${props.course.id}`} className="d-none"></div>
                                </Alert>
                            ) : (
                                <Alert variant="success" className="p-2 m-0">
                                    Ora sei ISCRITTO! L'evento è GRATUITO.
                                </Alert>
                            )
                        ) : (
                            <Fragment />
                        )
                    }
                    {
                        hasError ? (
                            <Alert variant="danger" className="p-2 m-0">
                                Attenzione!<br />{error?.error}
                            </Alert>
                        ) : (
                            <Fragment />
                        )
                    }
                    {
                        disableListadattesa ?  (
                            <Fragment />
                        ) : (
                            <Alert variant="dark" className="p-2 my-1">
                                Per questo evento <b>è attiva la <span className="text-uppercase">lista d'attesa</span></b>.<br /><small>Clicca sul bottone in basso per farne parte.</small>
                            </Alert>
                        ) 
                    }
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button variant="secondary" onClick={handleCloseSubscribeModal} disabled={isLoading || submitted}>Annulla</Button>
                    <Button variant={`${disableListadattesa ? 'secondary' : 'dark'}`} onClick={handleOpenListadattesaModal} disabled={isLoading || submitted || disableListadattesa}>{"Lista d'attesa"}</Button>
                    <Button variant="primary" onClick={handleSubmitSubscribeModal} disabled={isLoading || submitted}>Iscriviti</Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showListadattesaModal} key={"courseListadattesaModal" + props.course.id} onHide={handleCloseListadattesaModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>Vuoi far parte della lista d'attesa?</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Stai per essere inserito nella lista d'attesa del corso:<br /><b>{props.course.titolo}</b></p>
                    <p>Se tornerà disponibile un posto ti verrà notificato nell'apposita funzionalità.</p>
                    {
                        isLoadingListadattesa ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendi</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <Fragment />
                        )
                    }
                    {
                        submittedListadattesa ? (
                            <Alert variant="success" className="p-2 m-0">Ora fai parte della lista d'attesa :)</Alert>
                        ) : (
                            <Fragment />
                        )
                    }
                    {
                        hasErrorListadattesa ? (
                            <Alert variant="danger" className="p-2 m-0">Si è verificato un errore! Riprova in un secondo momento.<br /><small>Verifica di non essere iscritto o di non far già parte della lista d'attesa.</small></Alert>
                        ) : (
                            <Fragment />
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseListadattesaModal} disabled={isLoadingListadattesa || submittedListadattesa}>Annulla</Button>
                    <Button variant="primary" onClick={handleSubmitListadattesaModal} disabled={isLoadingListadattesa || submittedListadattesa}>Conferma</Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showInfoModal} key={"courseInfoModal" + props.course.id} onHide={handleCloseInfoModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>{props.course.titolo}</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                    (props.course.idAziendaProponente != props.course.idAzienda) ? (
                        <p>
                            Per iscriversi al corso prendere contatto diretto con l'Ente
                            {props.course.mailInformazioni ? (<span> alla mail <a href={'mailto:' + props.course.mailInformazioni}>{props.course.mailInformazioni}</a></span>) : ""}
                        </p>
                    ) : (
                        (props.course.idAziendaProponente !== 635 && !props.course.flagEventoAperto && ((company == null) || (company?.idAzienda != props.course.idAziendaProponente))) ? (
                            <p>Corso aperto solo per gli appartenenti all'Ordine di {getOrdineProvinciale(props.course.idAziendaProponente, companies)}</p>
                        ) : (
                            (!props.course.flagAccettaIscrizioni) ? (
                                <p>La modalità di iscrizione è reperibile nella scheda corso o contattando la segreteria dell'ente erogante.</p>
                            ) : (
                                (props.course.flagChiudiIscrizioni) ? (
                                    <p>Le iscrizioni all'evento risultano chiuse.</p>
                                ) : (
                                    (props.course.modalita?.dataIscrizioneDal && props.course.modalita?.dataIscrizioneAl) ? (
                                        <p>Iscrizioni dal {format(new Date(props.course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')} al {format(new Date(props.course.modalita.dataIscrizioneAl), 'dd/MM/yyyy')}</p>
                                    ) : (
                                        (props.course.modalita?.dataIscrizioneDal) ? (
                                            <p>Iscrizioni dal {format(new Date(props.course.modalita.dataIscrizioneDal), 'dd/MM/yyyy')}</p>
                                        ) : (
                                            (props.course.modalita?.dataIscrizioneAl) ? (
                                                <p>Iscrizioni fino al {format(new Date(props.course.modalita.dataIscrizioneAl), 'dd/MM/yyyy')}</p>
                                            ) : (
                                                <p>Per iscriversi al corso prendere contatto diretto con il soggetto proponente.</p>
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInfoModal}>Chiudi</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Subscribe;