import { FC, Fragment, useState } from 'react';
import { Button, Modal, Card, Alert, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import ReplaceCf from '../../components/manager/ReplaceCf';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import SearchProfessional from '../../components/shared/search/SearchProfessional';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { IProfessionalCheckBeforeReplaceCfThunkPayload, postProfessionalCheckBeforeReplaceCf } from '../../redux/slices/professional.slice';
import { AppDispatch } from '../../redux/store';

const MangerReplaceCf: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<IRejectResponse>();
  const [detail, setDetail] = useState<boolean>(false);

  const handleBack = () => {
    setDetail(false);
    setSubmitted(false);
    setError(undefined);
  };

  const handleClean = () => {
    handleBack();
    setProfessionalOld(undefined);
    setProfessionalNew(undefined);
  };

  const [professionalOld, setProfessionalOld] = useState<IProfessionalEntity>();
  const [showSearchOldModal, setShowSearchOldModal] = useState<boolean>(false);
  const handleOpenSearchOldModal = () => {
    setShowSearchOldModal(true);
    setProfessionalOld(undefined);
    handleBack();
  };
  const handleCloseSearchOldModal = () => {
    setShowSearchOldModal(false);
  };
  const handleSelectProfessionalOld = (professional: IProfessionalEntity) => {
    setProfessionalOld(professional);
    handleCloseSearchOldModal();
  };

  const [professionalNew, setProfessionalNew] = useState<IProfessionalEntity>();
  const [showSearchNewModal, setShowSearchNewModal] = useState<boolean>(false);
  const handleOpenSearchNewModal = () => {
    setShowSearchNewModal(true);
    setProfessionalNew(undefined);
    handleBack();
  };
  const handleCloseSearchNewModal = () => {
    setShowSearchNewModal(false);
  };
  const handleSelectProfessionalNew = (professional: IProfessionalEntity) => {
    setProfessionalNew(professional);
    handleCloseSearchNewModal();
  };

  const handleCheckBeforeReplaceCf = () => {
    if(professionalOld?.codiceFiscale && professionalNew?.codiceFiscale) {
      setLoading(true);
      dispatch(postProfessionalCheckBeforeReplaceCf({ codiceFiscaleOld: professionalOld.codiceFiscale, codiceFiscaleNew: professionalNew.codiceFiscale } as IProfessionalCheckBeforeReplaceCfThunkPayload))
        .unwrap()
        .then(() => {
          setError(undefined);
          setTimeout(() => {
            setDetail(true);
          }, 2000);
        })
        .catch((err) => {
          const erro = err as unknown as IRejectResponse;
          setError(erro);
        })
        .finally(() => {
          setSubmitted(true);
          setLoading(false);
        });
    }
  };

  return (
    <Fragment>
      <PageTitle content="Sostituzione codice fiscale" />
      <PageSubtitle content="Nuova" />
      <PageParagraph content="">
        <span>
          In quest'area si può unire i dati relativi ad un iscritto con codice fiscale errato (<b>CF VECCHIO</b>) con quelli di un codice fiscale corretto (<b>CF NUOVO</b>).<br />
          Questa operazione è possibile se non sono attivi i seguenti casi:
          <ol className="m-0">
            <li>Vi sono <b>pagamenti</b> associati al vecchio codice fiscale.</li>
            <li>Nello storico del vecchio codice fiscale non sono mai stati associati <b>periodi di cancellazione e reiscrizione</b> all'albo.</li>
            <li>Se il vecchio codice ficale e il nuovo cf sono entrambi <b>iscritti al medesimo/i corso/i</b>.</li>
          </ol>
          <span>
            Nel primo e secondo caso è necessario aprire un <a href="mailto:cfp@archiword.it">ticket di assistenza</a>.<br />
            Nel terzo è necessario contattare direttamente l'ordine di appartenenza del corso in modo che venga eliminato il codice fiscale errato.
          </span>
        </span>
      </PageParagraph>

      <Card className="p-3 m-2 border border-3 border-secondary">
        <div className="d-flex">
          <Button size="sm" onClick={handleOpenSearchOldModal} variant="outline-dark" className="flex-shrink-1">Cerca</Button>
          <div className="w-100 mx-3 my-1">Professionista (<u>CF VECCHIO</u>): {professionalOld ? (<span><b>{professionalOld?.codiceFiscale}</b> {professionalOld?.nome} {professionalOld?.cognome}</span>) : (<span><i>nessuno</i></span>)}</div>
        </div>
      </Card>

      <Card className="p-3 m-2 border border-3 border-success">
        <div className="d-flex">
          <Button size="sm" onClick={handleOpenSearchNewModal} variant="outline-dark" className="flex-shrink-1">Cerca</Button>
          <div className="w-100 mx-3 my-1">Professionista (<u>CF NUOVO</u>): {professionalNew ? (<span><b>{professionalNew?.codiceFiscale}</b> {professionalNew?.nome} {professionalNew?.cognome}</span>) : (<span><i>nessuno</i></span>)}</div>
        </div>
      </Card>

      {
        professionalOld && professionalNew && !detail ? (
          <Card className="p-3 m-2 border border-3 border-light">
            <div className="d-flex">
              <Button onClick={handleCheckBeforeReplaceCf} variant="primary" disabled={loading || submitted} className="align-self-center"> 
                { 
                  loading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  ) : (
                    <span>Conferma</span>
                  )
                }
              </Button>
              <div className="w-100 mx-3">
                { 
                  loading ? (
                    <span className="ms-1">Attendere...</span>
                  ) : (
                    submitted ? (
                      error ? (
                        <Alert className="m-0" variant="danger">{error.error}<br /><Button onClick={handleClean} variant="link" className="m-0 p-0 text-dark">CLICCA QUI</Button> per ripetere questa operazione su altri codici fiscali.</Alert>
                      ) : (
                        <Alert className="m-0" variant="success"><b>Verifica completata!</b> Nessuna anomalia rilevata per i suddetti codici fiscali.<br /><Spinner as="span" animation="grow" size="sm" role="status" /> Tra poco atterrerai alla scheramta riepilogativa e potrai completare l'operazione di unione dei due codici fiscali.</Alert>
                      )
                    ) : ""
                  )
                }
              </div>
            </div>
          </Card>
        ) : (
          <Fragment />
        )
      }

      {
        professionalOld && professionalNew && detail ? (
          <Card className="p-3 m-2 border border-3 border-light">
            <ReplaceCf professionalOld={professionalOld} professionalNew={professionalNew} onSubmitSuccess={handleClean} />
          </Card>
        ) : (
          <Fragment />
        )
      }
      
      <Modal show={showSearchOldModal} onHide={handleCloseSearchOldModal} size="lg">
        <Modal.Header closeButton className="bg-color-palette-4">
          <Modal.Title><h3>Scegli professionista (CF VECCHIO)</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchProfessional disableManualInput={false} searchInActiveProfessionalList={2} onSelect={handleSelectProfessionalOld} />
        </Modal.Body>
      </Modal>

      <Modal show={showSearchNewModal} onHide={handleCloseSearchNewModal} size="lg">
        <Modal.Header closeButton className="bg-color-palette-3">
          <Modal.Title><h3>Scegli professionista (CF NUOVO)</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchProfessional disableManualInput={true} searchInActiveProfessionalList={1} onSelect={handleSelectProfessionalNew} />
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default MangerReplaceCf;