import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import { CompanyAPI } from "../../api/company.api";
import { ICompanyEntity } from "../../entities/company.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import { RootState } from "../store";

export interface IGetCompaniesOrderThunkPayload { }

export interface IGetCompaniesOrderFulfilledPayload {
  companies: Array<ICompanyEntity>;
}

export const getCompaniesOrder = createAsyncThunk(
  'companies/order/get',
  async (thunkPayload: IGetCompaniesOrderThunkPayload, thunkAPI) => {
    try {
      const response = await CompanyAPI.getCompaniesOrder(thunkPayload);
      const successPayload = response.data as unknown as ICompanyEntity[];
      const fulfillValue = {
        companies: successPayload
      } as IGetCompaniesOrderFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
  {
    condition: (_thunkPayload, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      if (state.companies.orders.length === 0) {
        return true;
      }
      return false;
    },
  }
);

export interface IGetCompaniesPartnerThunkPayload { }

export interface IGetCompaniesPartnerFulfilledPayload {
  companies: Array<ICompanyEntity>;
}

export const getCompaniesPartner = createAsyncThunk(
  'companies/partner/get',
  async (thunkPayload: IGetCompaniesPartnerThunkPayload, thunkAPI) => {
    try {
      const response = await CompanyAPI.getCompaniesPartner(thunkPayload);
      const successPayload = response.data as unknown as ICompanyEntity[];
      const fulfillValue = {
        companies: successPayload
      } as IGetCompaniesPartnerFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
  {
    condition: (_thunkPayload, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      if (state.companies.partners.length === 0) {
        return true;
      }
      return false;
    },
  }
);

export interface ICompaniesState {
  orders: Array<ICompanyEntity>;
  partners: Array<ICompanyEntity>;
}

const initialState: ICompaniesState = {
  orders: new Array<ICompanyEntity>(),
  partners: new Array<ICompanyEntity>(),
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompaniesOrder.pending, (state) => {
      state.orders = new Array<ICompanyEntity>();
    });
    builder.addCase(getCompaniesOrder.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCompaniesOrderFulfilledPayload;
      state.orders = payload.companies;
    });
    builder.addCase(getCompaniesOrder.rejected, (state, action) => {
      //const payload = action.payload as unknown as IRejectResponse;
      state.orders = new Array<ICompanyEntity>();
    });

    builder.addCase(getCompaniesPartner.pending, (state) => {
      state.partners = new Array<ICompanyEntity>();
    });
    builder.addCase(getCompaniesPartner.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCompaniesPartnerFulfilledPayload;
      state.partners = payload.companies;
    });
    builder.addCase(getCompaniesPartner.rejected, (state, action) => {
      //const payload = action.payload as unknown as IRejectResponse;
      state.partners = new Array<ICompanyEntity>();
    });
  },
});

export default companiesSlice.reducer;