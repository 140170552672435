import { FC, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const PartnerDashboard: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto Ente!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
    </Fragment>
  );
};

export default PartnerDashboard;
