import { IDeleteCertificationThunkPayload, IGetCertificationThunkPayload, IPostCertificationAttachmentThunkPayload, IPostCertificationThunkPayload, IPutCertificationThunkPayload } from "../redux/slices/certification.slice";
import { ICertificationsExportThunkPayload, ICertificationsRetroattiveThunkPayload, ICertificationsThunkPayload, IPutCertificationsStatusThunkPayload } from "../redux/slices/certifications.slice";
import { IMetadataCertificationThunkPayload } from "../redux/slices/metadata.slice";
import AxiosService from "../services/axios.service";
import { ApiEndpoints } from "./api.config";

export const getCertifications = (payload: ICertificationsThunkPayload) => {
  const endpoint = ApiEndpoints.Certifications.toString();
  let querystring = Array<string>();
  if (payload.pagination.page && payload.pagination.page > 0) {
    querystring.push('page=' + payload.pagination.page.toString());
  }
  if (payload.pagination.limit && payload.pagination.limit > 0) {
    querystring.push('limit=' + payload.pagination.limit.toString());
  }
  if (payload.sorting.orderby && payload.sorting.orderby.length > 0) {
    querystring.push('orderby=' + payload.sorting.orderby.join(','));
    if (payload.sorting.ordertype) {
      querystring.push('ordertype=desc');
    }
    else {
      querystring.push('ordertype=asc');
    }
  }
  if (payload.filter.oggetto && payload.filter.oggetto > 0) {
    querystring.push('oggetto=' + payload.filter.oggetto.toString());
    if (payload.filter.tipologia != null && payload.filter.tipologia > (-1)) {
      querystring.push('tipologia=' + payload.filter.tipologia.toString());
    }
  }
  if (payload.filter.stato && payload.filter.stato > 0) {
    querystring.push('statoCertificazione=' + payload.filter.stato.toString());
  }
  if (payload.filter.triennio && payload.filter.triennio > 0) {
    querystring.push('triennio=' + payload.filter.triennio.toString());
  }
  if (payload.filter.annoInizio && payload.filter.annoInizio > 0) {
    querystring.push('annoInizio=' + payload.filter.annoInizio.toString());
  }
  if (payload.filter.meseInizio && payload.filter.meseInizio > 0) {
    querystring.push('meseInizio=' + payload.filter.meseInizio.toString());
  }
  if (payload.filter.text && payload.filter.text.length > 0) {
    querystring.push('text=' + payload.filter.text);
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getCertificationsRetroattive = (payload: ICertificationsRetroattiveThunkPayload) => {
  const endpoint = ApiEndpoints.CertificationsRetroattive.toString();
  let querystring = Array<string>();
  if (payload.codiceFiscale ) {
    querystring.push('codiceFiscale=' + payload.codiceFiscale);
  }
  if (payload.pagination.page && payload.pagination.page > 0) {
    querystring.push('page=' + payload.pagination.page.toString());
  }
  if (payload.pagination.limit && payload.pagination.limit > 0) {
    querystring.push('limit=' + payload.pagination.limit.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getMetadata = (_payload: IMetadataCertificationThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.CertificationMetadata);
};

export const getCertification = (payload: IGetCertificationThunkPayload) => {
  const url = ApiEndpoints.Certification.replace(':id', payload.id.toString());
  return AxiosService.instance.axios.get(url);
};

export const postCertification = (payload: IPostCertificationThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.AddCertification, payload);
};

export const putCertification = (payload: IPutCertificationThunkPayload) => {
  const url = ApiEndpoints.EditCertification.replace(':id', payload.certification.id.toString());
  return AxiosService.instance.axios.put(url, payload.certification);
};

export const deleteCertification = (payload: IDeleteCertificationThunkPayload) => {
  const url = ApiEndpoints.DeleteCertification.replace(':id', payload.id.toString());
  return AxiosService.instance.axios.delete(url);
};

export const putCertificationsStatus = (payload: IPutCertificationsStatusThunkPayload) => {
  const url = ApiEndpoints.EditCertificationsStatus.replace(':state', payload.status.toString());
  return AxiosService.instance.axios.put(url, payload.ids);
};

export const getCertificationsExport = (_payload: ICertificationsExportThunkPayload) => {
  const url = ApiEndpoints.CertificationsExport.concat('?triennio=', _payload.triennio === null ? "" : _payload.triennio.toString()).concat('&stato=', _payload.stato === null ? "" : _payload.stato.toString());
  return AxiosService.instance.axios.get(url);
  // return AxiosService.instance.axios.get(ApiEndpoints.CertificationsExport.toString());
};

export const postCertificationAttachment = (payload: IPostCertificationAttachmentThunkPayload) => {
  const url = ApiEndpoints.AddCertificationAttachment.replace(':id', payload.idCertification.toString());
  return AxiosService.instance.axios.post(url, payload.attachment);
};

const CertificationAPI = {
  getCertifications,
  getCertificationsRetroattive,
  getMetadata,
  getCertification,
  postCertification,
  putCertification,
  deleteCertification,
  putCertificationsStatus,
  getCertificationsExport,
  postCertificationAttachment,
};

export default CertificationAPI;