import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse, IServerResponse } from '../../api/api.interface';
import CourseAPI from '../../api/course.api';
import { IAttachment } from "../../entities/attachment.entity";
import { ICourseRaw } from "../../entities/course.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IPostCoursesImportThunkPayload {
  fileName: string;
  contentType: string;
  contentRaw: string | ArrayBuffer;
}

export interface IPostCoursesImportFulfilledPayload {
  countCourses: number;
}

export const postCoursesImport = createAsyncThunk(
  'courses/import/post',
  async (thunkPayload: IPostCoursesImportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.postCoursesImport(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<number>;
      const fulfillValue = {
        countCourses: successPayload.data
      } as IPostCoursesImportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetCoursesFilter {
  supplier: number;
  erogazione: number;
  tipologia: number;
  areaFormativa: number;
  statoPubblicazione: Array<number>;
  text: string;
  meseInizio: number;
  annoInizio: number;
  tipoAzienda: number;
  full: number;
  eventoGratuito: number;
  triennio: number;
}

export interface IGetCoursesSorting {
  orderby: Array<string>;
  ordertype: boolean;
}

export interface IGetCoursesPagination {
  page: number;
  limit: number;
}

export interface IGetCoursesThunkPayload {
  filter: IGetCoursesFilter;
  sorting: IGetCoursesSorting;
  pagination: IGetCoursesPagination;
}

export interface IGetCoursesFulfilledPayload {
  courses: Array<ICourseRaw>;
  countTotalCourses: number;
}

export const getCourses = createAsyncThunk(
  'courses/get',
  async (thunkPayload: IGetCoursesThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCourses(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseRaw>;
      const counterTotalCourses = Number(response.headers["x-count-records"]);
      const fulfillValue = {
        courses: successPayload,
        countTotalCourses: counterTotalCourses
      } as unknown as IGetCoursesFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesPublishedFilter {
  tipoAzienda: number;
  company: number;
  partner: number;
  eventoGratuito: number;
  erogazione: number;
  tipologia: number;
  categoria: number;
  text: string;
}

export interface ICoursesPublishedSorting {
  orderby: Array<string>;
  ordertype: boolean;
}

export interface ICoursesPublishedPagination {
  page: number;
  limit: number;
}

export interface ICoursesPublishedThunkPayload {
  filter: ICoursesPublishedFilter;
  sorting: ICoursesPublishedSorting;
  pagination: ICoursesPublishedPagination;
}

export interface ICoursesPublishedFulfilledPayload {
  courses: Array<ICourseRaw>;
  totalList: number;
}

export const getCoursesPublished = createAsyncThunk(
  'courses/published/get',
  async (thunkPayload: ICoursesPublishedThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCoursesPublished(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseRaw>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalCoursesList = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        courses: successPayload,
        totalList: countTotalCoursesList,
      } as unknown as ICoursesPublishedFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesMeThunkPayload { }

export interface ICoursesMeFulfilledPayload {
  courses: Array<ICourseRaw>;
}

export const getCoursesMe = createAsyncThunk(
  'courses/me/get',
  async (thunkPayload: ICoursesMeThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCoursesMe(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseRaw>;

      const fulfillValue = {
        courses: successPayload
      } as ICoursesMeFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesExportThunkPayload {
  own: number;
  statoPubblicazione: number;
}

export interface IMyCoursesExportThunkPayload {
  codice_fiscale: string;
}

export interface ICoursesExportFulfilledPayload {
  attachment: IAttachment;
}

export const getCoursesExport = createAsyncThunk(
  'courses/export/get',
  async (thunkPayload: ICoursesExportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getCoursesExport(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload,
      } as ICoursesExportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getMyCoursesExport = createAsyncThunk(
  'courses/mycourses/export',
  async (thunkPayload: IMyCoursesExportThunkPayload, thunkAPI) => {
    try {
      const response = await CourseAPI.getMyCoursesExport(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload,
      } as ICoursesExportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesState {
  error: IRejectResponse | null; //error
  value: Array<ICourseRaw>; //corsi 
  myvalue: Record<string, ICourseRaw>;
  list: Array<ICourseRaw>;
  total: number;
  totalList: number;
}

const initialState: ICoursesState = {
  error: null,
  value: [],
  myvalue: {},
  list: [],
  total: 0,
  totalList: 0,
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourses.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCoursesFulfilledPayload;
      state.value = payload.courses;
      state.total = payload.countTotalCourses;
    });
    builder.addCase(getCourses.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = [];
    });

    builder.addCase(getCoursesPublished.pending, (state) => {
      state.list = [];
    });
    builder.addCase(getCoursesPublished.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICoursesPublishedFulfilledPayload;
      state.list = payload.courses;
      state.totalList = payload.totalList;
    });
    builder.addCase(getCoursesPublished.rejected, (state, action) => {
      state.list = [];
    });

    builder.addCase(getCoursesMe.pending, (state) => {
      state.error = null;
      state.myvalue = {};
    });
    builder.addCase(getCoursesMe.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICoursesMeFulfilledPayload;
      payload.courses.forEach((x) => {
        state.myvalue[x.id.toString()] = x;
      });
    });
    builder.addCase(getCoursesMe.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.myvalue = {};
    });

    builder.addCase(getCoursesExport.pending, (state) => { });
    builder.addCase(getCoursesExport.fulfilled, (state, action) => { });
    builder.addCase(getCoursesExport.rejected, (state, action) => { });

    builder.addCase(postCoursesImport.pending, (state) => { });
    builder.addCase(postCoursesImport.fulfilled, (state, action) => { });
    builder.addCase(postCoursesImport.rejected, (state, action) => { });
  },
});

export default coursesSlice.reducer;