import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMetadataOption } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";


export const getErogazione = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getErogazioneOption = (id: number, list: Array<IMetadataOption>): IMetadataOption | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: IMetadataOption | null) => void;
}

const Erogazione: FC<IProps> = (props) => {

  const erogazioni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.erogazione_corso);

  const defaultSelectedOption = (): IMetadataOption | null => {
    if (props?.defaultSelectedId) {
      return getErogazioneOption(props.defaultSelectedId, erogazioni);
    }
    return null;
  };

  const [filterByErogazione, setFilterByErogazione] = useState<IMetadataOption | null>(defaultSelectedOption());

  const handleFilterByErogazione = (option: IMetadataOption | null) => {
    setFilterByErogazione(option);
    if (props?.onSelect) {
      props.onSelect(option);
    }
  };

  return (
    <Fragment>
      <Dropdown className="mx-2 my-2">
        <Dropdown.Toggle variant="outline-primary">
          {filterByErogazione != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Erogazione ${filterByErogazione ? filterByErogazione.id.toString().substring(0, 10) : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"dropdownErogazioneNone"} disabled={filterByErogazione === null} onClick={() => handleFilterByErogazione(null)}>Tutte</Dropdown.Item>
          {
            erogazioni.length ? (
              erogazioni.map((item: IMetadataOption, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownErogazione" + item.id} disabled={filterByErogazione !== null && item.id === filterByErogazione.id} onClick={() => handleFilterByErogazione(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Erogazione;