import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Badge, Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { INotifyEntity } from '../../entities/notify.entity';
import { getNotifications } from '../../redux/slices/notifications.slice';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { useNavigate } from 'react-router-dom';

const Notifications: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [isLoadingNotifications, setLoading] = useState(false);
    
    const notifications: Array<INotifyEntity> = useSelector((s: RootState) => s.notifications.value);

    useEffect(() => {
        setLoading(true);
        dispatch(getNotifications({}))
            .finally(() => setLoading(false));
    }, [dispatch]);
    
    const handleClickItem2 = () => {
        navigate(AuthenticatedRoutesUser.FavoritesCourses.toString());
    };
    
    const handleClickItem3 = () => { 
        navigate(AuthenticatedRoutesUser.MyCourses.toString());
    };

    return (
        <Fragment>
           {
                isLoadingNotifications ? (
                    <div className="text-center">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <p>
                            <span>Attendere</span>
                            <span>...</span>
                        </p>
                    </div>
                ) : notifications.length ? (
                        <Container fluid as="section" className="flex-column">
                            {
                                notifications.filter(itm => itm.count > 0).map((not: INotifyEntity, index: number) => {
                                    if(not.idTipoNotifica === 2) {
                                        return (
                                            <Alert key={"not" + not.id} variant="warning" className="c-pointer text-lowercase" onClick={handleClickItem2}>
                                                <h5>{not.testo} in un totale di corsi <Badge bg="warning" pill className="ms-3">{not.count}</Badge></h5>
                                            </Alert>
                                        );
                                    }
                                    else if(not.idTipoNotifica === 3) {
                                        return (
                                            <Alert key={"not" + not.id} variant="dark" className="c-pointer text-lowercase" onClick={handleClickItem3}>
                                                <h5>{not.testo} in un totale di corsi <Badge bg="dark" pill className="ms-3">{not.count}</Badge></h5>
                                            </Alert>
                                        );
                                    }

                                    return (<Fragment />);
                                })
                            }
                        </Container>
                    ) : (
                        <Fragment />
                    )
            }
        </Fragment>
    );
};

export default Notifications;