import { FC, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { IUser } from '../entities/user.entity';
import { AuthenticatedRoutesMaster, AuthenticatedRoutesSupervisor, AuthenticatedRoutesManager, AuthenticatedRoutesAdmin, AuthenticatedRoutesPartner, AuthenticatedRoutesUser, AuthenticatedRoutesApia } from '../enums/routes.enum';
import { UserRole } from '../enums/user-roles.enum';

interface IProps {
  user: IUser | null;
  component: FC;
}

export const UnauthenticatedRoute: FC<IProps> = ({ user, component: Component }) => {
  return !user ? (
    <Component />
  ) : (
    <Fragment>
      {
        {
          [UserRole.Master]: <Navigate to={AuthenticatedRoutesMaster.Dashboard} />,
          [UserRole.Supervisor]: <Navigate to={AuthenticatedRoutesSupervisor.Dashboard} />,
          [UserRole.Manager]: <Navigate to={AuthenticatedRoutesManager.Dashboard} />,
          [UserRole.Admin]: <Navigate to={AuthenticatedRoutesAdmin.Dashboard} />,
          [UserRole.Partner]: <Navigate to={AuthenticatedRoutesPartner.PublishedCourses} />,
          [UserRole.User]: <Navigate to={AuthenticatedRoutesUser.Dashboard} />,
          [UserRole.Guest]: <Navigate to={AuthenticatedRoutesUser.Dashboard} />,
          [UserRole.Apia]: <Navigate to={AuthenticatedRoutesApia.Dashboard} />,          
          [UserRole.Apib]: <Navigate to={AuthenticatedRoutesApia.Dashboard} />,
        }[user.role]
      }
    </Fragment>
  );
};

export default UnauthenticatedRoute;
