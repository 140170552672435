export enum UserRole {
  Master = 1,
  Admin = 2,
  Partner = 3,
  User = 4,
  Manager = 5,
  Guest = 6,
  Supervisor = 7,
  Apia = 20,
  Apib = 21,
}
