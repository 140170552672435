import { FC, Fragment } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import Footer from '../layout/parts/Footer';
import { useParams } from 'react-router';

export const ResetPassword: FC = () => {
    const { token } = useParams<{ token: string }>();
    console.log(token);
  
    return (
        <Fragment>
            <LoginBackgroundMobile />
            <LoginBackgroundTablet />
            <LoginBackground />
            <Container fluid as="section" className="login-content d-flex">
                <Row className="flex-grow-1">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                        <Card className="m-auto">
                            <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                                <Card.Title className="mb-3">Reset password:</Card.Title>
                                <UpdatePasswordForm verticalSpacing={2} token={token}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer variant={"dark"} />
        </Fragment>
    );
};

export default ResetPassword;