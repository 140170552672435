import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Col, Container, Dropdown, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ICompanyEntity } from '../../entities/company.entity';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany, getPartners } from '../../redux/slices/company.slice';
import { getCoursesExport, ICoursesExportFulfilledPayload } from '../../redux/slices/courses.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import SearchProfessionalEmailList from '../../components/shared/search/SearchProfessionalEmailList';
import EmailInvio from '../../components/shared/EmailInvio';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { IProfessionalEmailEntity } from '../../entities/professional.entity';
import { IGetProfessionalsEmailFilter, IGetProfessionalsEmailPagination, IGetProfessionalsEmailSorting } from '../../redux/slices/email.slice';

const AdminMailInadempienti: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  const joinpartners: Array<ICompanyEntity> = useSelector((s: RootState) => s.company.joinpartners);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleTab, setToggleTab] = useState<boolean>(true);

  const handleClickItem = (professional: IProfessionalEmailEntity, filter: IGetProfessionalsEmailFilter, 
    pagination: IGetProfessionalsEmailPagination, sorting: IGetProfessionalsEmailSorting) => {
        let route = AuthenticatedRoutesAdmin.Professional.toString();
        route = route.replace(':cf', professional.codiceFiscale);
        navigate(route, { state: { filter, from: location.pathname, pagination: pagination, sorting: sorting } });
        // navigate(route);
};

  const handleExport = (own: number) => {
    setIsLoading(true);
    dispatch(getCoursesExport({ statoPubblicazione: 3, own: own }))
      .unwrap()
      .then((e) => {
        const payload = e as unknown as ICoursesExportFulfilledPayload;
        const div = document.getElementById("divExportAttachmentCSV");
        if (div) {
          b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
            const url = window.URL.createObjectURL(blobresult);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
            div.appendChild(link);
            link.click();
            link.remove();
          });
        }
      })
      .catch((e) => {
        // console.log("KO", e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      <PageTitle content="Inadempienti">
        <a href="/assets/tutorial/O_ElencoCorsi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Riepilogo comunicazioni verso inadempienti">
        <div id="divExportAttachmentCSV" className="d-none"></div>
      </PageSubtitle>
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="lista" onSelect={() => setToggleTab(!toggleTab)}>
              <Tab eventKey="lista" title="Lista inadempienti">
                <SearchProfessionalEmailList onSelect={handleClickItem} />
              </Tab>
              <Tab eventKey="invio" title="Riepilogo ed invio">
                <EmailInvio />
              </Tab>
              {/* <Tab eventKey="export" title="Export">
                <Container fluid as="section" className="bg-light">
                      <Row>
                        <Col className="m-3">
                          <Alert variant="secondary">
                            <h4>In questa sezione è possibile esportare la lista delle comunicazioni inviate e dei professionisti senza email impostata.</h4>
                            <hr />
                            <h6>Funzionalità non ancora attiva.</h6>
                            <p>IN COSTRUZIONE 2.</p>
                          </Alert>
                        </Col>
                      </Row>
                </Container>
              </Tab> */}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminMailInadempienti;