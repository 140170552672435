import { IAdviceEntity, IMetadataOptionAdvice } from "../entities/advice.entity";
import { parseISOStringToStandardDate } from "./course-parser";

export const adviceInit: IAdviceEntity = {
    id: 0,
    dataInizioValidita: parseISOStringToStandardDate((new Date()).toISOString()),
    dataFineValidita: parseISOStringToStandardDate((new Date()).toISOString()),
    flagAmbitoEntiTerzi: false,
    flagAmbitoGlobale: true,
    flagAmbitoOrdini: false,
    flagAmbitoProfessionisti: false,
    flagOnline: false,
    idAziendaInserimento: 0,
    idProfiloInserimento: 0,
    livelloNotifica: "info",
    testo: "",
    titolo: ""
} as IAdviceEntity;

export const isVisibleNow = (adv: IAdviceEntity): boolean => {
    const nowtime: number = new Date().getTime();
    const intime: number = new Date(adv.dataInizioValidita).getTime();
    const fitime: number = new Date(adv.dataFineValidita).getTime();
  
    if(fitime < intime) return false;
  
    if (adv.flagOnline && intime <= nowtime && fitime >= nowtime) return true;
    
    return false;
}
  
export const whichScope = (adv: IAdviceEntity): string => {
    let scope = new Array<string>();
  
    if (adv.flagAmbitoGlobale) {
      scope.push("tutti i profili");
    }
    else {
      if (adv.flagAmbitoOrdini) {
        scope.push("ordini");
      }
      if (adv.flagAmbitoEntiTerzi) {
        scope.push("enti terzi");
      }
      if (adv.flagAmbitoProfessionisti) {
        scope.push("professionisti");
      }
    }
  
    if(scope.length === 0) {
      return "alcun profilo";
    }
    
    return scope.join(", ");
}

export const whichStatus = (adv: IAdviceEntity): string => {
    const nowtime: number = new Date().getTime();
    const intime: number = new Date(adv.dataInizioValidita).getTime();
    const fitime: number = new Date(adv.dataFineValidita).getTime();
    
    if(fitime < intime) return "";

    if (adv.flagOnline) {
        if(nowtime < intime) {
            // prima dell'inizio del periodo di validità
            return "pronta";
        }
        else if(intime <= nowtime && fitime >= nowtime) {
            // entro il periodo di validità
            return "visibile";
        }
        else {
            // dopo la fine del periodo di validità
            return "scaduta";
        }
    }
    else {
        if(nowtime < intime) {
            // prima dell'inizio del periodo di validità
            return "in redazione";
        }
        else if(intime <= nowtime && fitime >= nowtime) {
            // entro il periodo di validità
            return "nascosta";
        }
        else {
            // dopo la fine del periodo di validità
            return "disattivata";
        }
    }
}

export const TheAdviceDefaultLevelId : number = 3;

export const TheAdviceLevels: Array<IMetadataOptionAdvice> = [
  { id: 1, nome: 'high-news', descrizione: 'Alto', hide: false, variant: 'warning' },
  { id: 2, nome: 'medium-news', descrizione: 'Medio', hide: false, variant: 'primary' },
  { id: 3, nome: 'low-news', descrizione: 'Basso', hide: false, variant: 'light' },
  { id: 4, nome: 'general-notification', descrizione: 'Notifica', hide: true, variant: 'light' },
  { id: 5, nome: 'linked-notification', descrizione: 'Notifica', hide: true, variant: 'light' },
];