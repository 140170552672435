import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import ScheduledCourses from '../../components/user/ScheduledCourses';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';

const UserScheduledCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  const location = useLocation();

  const [showEsitoPagamentoOkModal, setShowEsitoPagamentoOkModal] = useState<boolean>(false);
  const handleCloseEsitoPagamentoOkModal = () => {
    setShowEsitoPagamentoOkModal(false);
  };

  const [showEsitoPagamentoKoModal, setShowEsitoPagamentoKoModal] = useState<boolean>(false);
  const handleCloseEsitoPagamentoKoModal = () => {
    setShowEsitoPagamentoKoModal(false);
  };
  
  useEffect(() => {
      // Get the query parameter from the navigation object
      const queryParams = new URLSearchParams(location.search);
      const nameParam = queryParams.get('esitoPagamento');
      
      if (nameParam === 'OK') {
        setShowEsitoPagamentoOkModal(true);
      }
      else if (nameParam === 'KO') {
        setShowEsitoPagamentoKoModal(true);
      }
  }, [location]);

  return (
    <Fragment>
      <PageTitle content="Corsi">
        <a href="/assets/tutorial/U_CorsiFuturi.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista corsi a cui parteciperò" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <ScheduledCourses />
      </Container>

      <Modal show={showEsitoPagamentoOkModal} onHide={handleCloseEsitoPagamentoOkModal}>     
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="text-center">
            <CheckCircleFill size={100} className="text-success mb-5" />
            <h4 className="text-success">Ben fatto!</h4>
            <p className="mt-3">Pagamento effettuato.</p>
          </div>
          <Alert variant="info">
            <h6 className="text-dark">Perfeziona l'<i>iscrizione</i> cliccando su <b>Controlla esito</b>.<br />PagoPA impiega anche 1 ora per generare la ricevuta telematica.</h6>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="dark" onClick={handleCloseEsitoPagamentoOkModal} >
                Chiudi
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEsitoPagamentoKoModal} onHide={handleCloseEsitoPagamentoKoModal}>     
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="text-center">
            <ExclamationCircleFill size={100} className="text-danger mb-5" />
            <h4 className="text-danger">Attenzione!</h4>
            <p className="mt-3">Qualcosa è andato storto con il pagamento.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="dark" onClick={handleCloseEsitoPagamentoKoModal} >
                Chiudi
            </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default UserScheduledCourses;