import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Container, Dropdown, DropdownButton, Modal, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { IRejectResponse } from '../../api/api.interface';
import CourseForm from '../../components/admin/CourseForm';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { AuthenticatedRoutesPartner } from '../../enums/routes.enum';
import { deleteCourse, getCourse, IDeleteCourseThunkPayload, IPutCourseThunkPayload, putCourse } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';

const PartnerCourse: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const error: IRejectResponse | null = useSelector((s: RootState) => s.course.error);

  const [delaySuccessToast,] = useState(4000);
  const [delayDangerToast,] = useState(20000);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDangerToast, setShowDangerToast] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false);
  const [isLoadingDeleteCourse, setIsLoadingDeleteCourse] = useState(false);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);
  const handleOpenDeleteCourseModal = () => setShowDeleteCourseModal(true);
  const handleCloseDeleteCourseModal = () => setShowDeleteCourseModal(false);

  const course: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.value);

  useEffect(() => {
    dispatch(getCourse({ id: Number(id) }))
      .then((e) => {
        //console.log("eeeeepayload", e.payload);
      })
      .catch(() => setShowDangerToast(true));
  }, [dispatch]);

  const handleSave = (value: ICourseEntityRaw) => {
    //console.log(value);
    setLoading(true);
    dispatch(putCourse({ course: value } as IPutCourseThunkPayload))
      .unwrap()
      .then(() => setShowSuccessToast(true))
      .catch(() => setShowDangerToast(true))
      .finally(() => setLoading(false));
  };

  const handleClickBackToCourses = () => {
    const route = AuthenticatedRoutesPartner.PublishedCourses.toString();
    navigate(route);
  };

  const handleSubmitDeleteCourseModal = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (course?.id) {
      setIsLoadingDeleteCourse(true);
      dispatch(deleteCourse({ idCorso: course.id } as IDeleteCourseThunkPayload))
        .unwrap()
        .then(() => {
          setTimeout(handleClickBackToCourses, 3000);
          setLoading(true);
          handleCloseDeleteCourseModal();
        })
        .finally(() => setIsLoadingDeleteCourse(false));
    }
  };

  const handleClickGoToReplicaCourse = (idCorso: string | undefined) => {
    if (idCorso) {
      const route = AuthenticatedRoutesPartner.ReplicaCourse.toString().replace(':id', idCorso);
      navigate(route);
    }
  };

  const handleClickGoToDuplicaCourse = (idCorso: string | undefined) => {
    if (idCorso) {
      navigate(AuthenticatedRoutesPartner.DuplicaCourse.toString());
    }
  };

  return (
    <Fragment>
      <PageTitle content={`Corso ${(course && course?.idCorsoRiferimento && course.idCorsoRiferimento > 0) ? 'replica' : ''} `}>
        <a href="/assets/tutorial/E_Corso.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle subContent={course?.titolo} content={`${(course && course?.codice && course.codice.length) ? course.codice : 'modifica'} `} >
        {
          (course && !isLoading) ? (
            <DropdownButton title={`Opzioni ${course.codice} `}>
              <Dropdown.Item as="button" onClick={handleOpenDeleteCourseModal} disabled={course.idStatoPubblicazione > 1}>Cancella</Dropdown.Item>
                    
              <Dropdown.Item as="button" onClick={() => handleClickGoToReplicaCourse(id)} 
                disabled={(course.idCorsoRiferimento != null && course.idCorsoRiferimento > 0) || (course.dataIstanza == null || new Date(course.dataIstanza) < new Date('2024-01-01'))}>Replica</Dropdown.Item>
              
              {/*}
              <Dropdown.Item as="button" disabled={true}>Replica</Dropdown.Item>
              {*/}


              <Dropdown.Item as="button" onClick={() => handleClickGoToDuplicaCourse(id)} disabled={course.idCorsoRiferimento != null && course.idCorsoRiferimento > 0}>Duplica</Dropdown.Item>
            </DropdownButton>
          ) : (
            <Fragment />
          )
        }
      </PageSubtitle>
      <PageParagraph content="" />
      <Container fluid as="section">
        {
          (course && !isLoading) ? (
            <CourseForm course={course} onSave={handleSave} isLoading={false} submitted={false} />
          ) : (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )
        }
      </Container>

      <Modal centered show={showDeleteCourseModal} onHide={handleCloseDeleteCourseModal} >
        <Modal.Header closeButton>
          <Modal.Title><h3>Confermi la cancellazione?</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>Cliccando sul bottone ELIMINA verrà cancellato il corso {course?.codice}.<br />Al completamento dell'operazione atterrerai alla pagina che lista i corsi pubblicati.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteCourseModal} disabled={isLoadingDeleteCourse} >Annulla</Button>
          <Button variant="danger" onClick={handleSubmitDeleteCourseModal} disabled={isLoadingDeleteCourse} >Elimina</Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione completata!</strong></span>
          </Toast.Header>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide>
          <Toast.Header>
            <span className="me-auto"><strong>Operazione terminata con errori!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status}: {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default PartnerCourse;