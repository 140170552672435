import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageParagraph from '../components/PageParagraph';
import PageSubtitle from '../components/PageSubtitle';
import PageTitle from '../components/PageTitle';
import Tutorials from '../components/shared/Tutorials';

const Support: FC = () => {
  return (
    <Fragment>
      <PageTitle content="Assistenza" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col className="mb-5">
            <p className="mt-3">Questa pagina contiene documentazione sull'impiego dell'applicazione, manualistica che verrà progressivamente aggiornata per seguire le evoluzioni del sistema.</p>
            <p>Informazioni sulle Linee guida e sulla normativa di riferimento che l'applicazione deve rispettare si trovano sul sito del CNAPPC nella pagina sulla <a href="https://www.awn.it/professione/aggiornamento/formazione-professionale-continua">Formazione Continua.</a></p>
          </Col>
        </Row>
        <Row>
          <PageSubtitle content="Contatta il nostro supporto!" />
          <Col className="mb-5">
            <p><a href="mailto:cfp@archiworld.it"><strong>Clicca QUI per richiedere assistenza tramite posta elettronica.</strong></a></p>
            <p><i>Alla suddetta assistenza vanno indirizzate <b>esclusivamente</b> richieste relative all'<b>uso del portale</b></i>.</p>
          </Col>
        </Row>
        {/*
        <Row>
          <Col className="mb-3">
            <p>
              <span>Link delle giornate di avviamento / formazione: </span>
              <span><a href="https://drive.google.com/file/d/1Xvs0NckNxs9vEutEsFdBh0VFg4JRwd2a/view?usp=sharing" target="_blank">4 maggio 2022</a>, </span>
              <span><a href="https://drive.google.com/file/d/1sljSaSEjS3EsPhqolWkBjJ-ndfwIIABK/view?usp=sharing" target="_blank">5 maggio 2022</a> </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{span: 6, offset:3}}>
            <Card>
              <Card.Body>
                <SupportForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        */}        
      </Container>
      <PageSubtitle content="Manuali / Guide" />
      <Tutorials />
    </Fragment>
  );
};

export default Support;