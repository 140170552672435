import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse, IServerResponse } from '../../api/api.interface';
import AppuserAPI from "../../api/appuser.api";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IPostAppuserOtpThunkPayload {
  idAzienda: number;
  codiceFiscale: string;
  role: number;
}

export interface IPostAppuserOtpFulfilledPayload {
  otp: string;
  appendCode: string;
}

export const postAppuserOtp = createAsyncThunk(
  'appuser/otp/post',
  async (thunkPayload: IPostAppuserOtpThunkPayload, thunkAPI) => {
    try {
      const response = await AppuserAPI.postAppuserOtp(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<IPostAppuserOtpFulfilledPayload>;
      const fulfillValue = successPayload.data as IPostAppuserOtpFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IAppuserState {
  value: null;
  error: null;
}

const initialState: IAppuserState = {
  value: null,
  error: null,
};

const appuser = createSlice({
  name: "appuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postAppuserOtp.pending, (state) => { });
    builder.addCase(postAppuserOtp.rejected, (state, action) => { });
    builder.addCase(postAppuserOtp.fulfilled, (state, action) => { });
  },
});

export default appuser.reducer;