import { FC, useEffect, useState } from 'react';
import TrainingCreditsBarBuilder, { getCfpThreeYearsByTrainingType } from './TrainingCreditsBarBuilder';

interface IProps {
    cfpTriennioPrecDeo: number;
    cfpTriennioPrecOrd: number;
    cfpDeo: number;
    cfpOrd: number;
    cfpAcquisitiEsoneriDeo: number;
    cfpAcquisitiEsoneriOrd: number;
}

const TrainingCreditsBarSummary: FC<IProps> = (props) => {

    const [cfpTotDeo,] = useState<number>(getCfpThreeYearsByTrainingType(true));
    const [cfpTotOrd,] = useState<number>(getCfpThreeYearsByTrainingType(false));

    const [cfpOrdPrec, setCfpOrdPrec] = useState<number>(0);
    const [cfpDeoPrec, setCfpDeoPrec] = useState<number>(0);

    const [cfpOrd, setCfpOrd] = useState<number>(0);
    const [cfpDeo, setCfpDeo] = useState<number>(0);

    const [cfpDeoEccedenti, setCfpDeoEccedenti] = useState<number>(0);

    useEffect(() => {
        const cfpzDeoPrec = props.cfpTriennioPrecDeo < 0 ? props.cfpTriennioPrecDeo * (-1) : 0;
        const cfpzOrdPrec = props.cfpTriennioPrecOrd < 0 ? props.cfpTriennioPrecOrd * (-1) : 0;
        const cfpzDeo = props.cfpDeo;
        const cfpzOrd = props.cfpOrd + (props.cfpTriennioPrecOrd > 0 ? props.cfpTriennioPrecOrd : 0);
        const cfpzDeoEccedenti = cfpzDeo > (cfpTotDeo + cfpzDeoPrec) ? cfpzDeo - (cfpTotDeo + cfpzDeoPrec) : 0;
        const cfpzDeoObbligatori = cfpzDeo - cfpzDeoEccedenti;
        const cfpzOrdObbligatori = cfpzOrd + cfpzDeoEccedenti;
        setCfpDeo(cfpzDeoObbligatori);
        setCfpOrd(cfpzOrdObbligatori);
        setCfpDeoEccedenti(cfpzDeoEccedenti);
        setCfpDeoPrec(cfpzDeoPrec);
        setCfpOrdPrec(cfpzOrdPrec);
    }, [props.cfpDeo, props.cfpOrd, props.cfpTriennioPrecDeo, props.cfpTriennioPrecOrd]);

    return (
        <div className="m-2">
            <TrainingCreditsBarBuilder
                cfp={cfpDeo}
                cfpDecurtati={cfpDeoPrec}
                key={`progressbarcfpdeox${(new Date()).getTime()}`}
                cfpObbligatori={0}
                trainingType={true}
                appendText={cfpDeoEccedenti > 0 ? "*" : ""}
                cfpAcquisitiSoloEsoneri={props.cfpAcquisitiEsoneriDeo} />

            <TrainingCreditsBarBuilder
                cfp={cfpOrd}
                cfpDecurtati={cfpOrdPrec}
                key={`progressbarcfpordx${(new Date()).getTime()}`}
                cfpObbligatori={0}
                trainingType={false}
                appendText="" 
                cfpAcquisitiSoloEsoneri={props.cfpAcquisitiEsoneriOrd} />
            
            <p className="small">{cfpDeoEccedenti > 0 ? "(*) " + cfpDeoEccedenti + " CFP Deontologici in eccedenza vengono conteggiati nei CFP Ordinari - tc" : ""}</p>
        </div>
    );
};

export default TrainingCreditsBarSummary;