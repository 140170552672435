import { Fragment } from 'react';
import { FC } from 'react';

const PrivacyPolicyText: FC = () => {
    return (
        <Fragment>
            <h3><span className="privacyStyle">PERCH&Eacute; QUESTE INFORMAZIONI</span></h3>

            <p>Ai sensi&nbsp;del Regolamento (UE) 2016/679 (di seguito "GDPR"), questa pagina descrive le modalit&agrave; di trattamento dei dati personali degli utenti che accedono al portale web di formazione continua del Consiglio Nazionale degli Architetti, Pianificatori, Paesaggisti e Conservatori (di seguito &ldquo;Portale&rdquo;), disponibile all&rsquo;indirizzo <a href="https://portaleservizi.cnappc.it/">https://portaleservizi.cnappc.it</a>.</p>

            <p>Le presenti informazioni non riguardano altri siti, pagine o servizi online raggiungibili tramite link ipertestuali eventualmente pubblicati nel Portale anche se appartenenti allo stesso CNAPPC.</p>

            <p>Inoltre, la presente informativa riguarda il solo trattamento di dati personali effettuato dal CNAPPC attraverso la navigazione e l&rsquo;utilizzo del Portale. Per le informazioni complete su come il CNAPPC tratta i Vostri dati personali, comunque raccolti, ai fini dell&rsquo;adempimento delle proprie funzioni istituzionali, si rinvia alle informative specifiche che vi sono state rilasciate.</p>

            <h3><span className="privacyStyle">TITOLARE DEL TRATTAMENTO</span></h3>

            <p>Titolare del trattamento &egrave; il Consiglio Nazionale degli Architetti, Pianificatori, Paesaggisti e Conservatori, con sede in Roma, Via di Santa Maria dell&rsquo;Anima n. 10&nbsp;(Email:&nbsp;direzione@cnappc.it, PEC: direzione.cnappc@archiworldpec.it&nbsp;, centralino +39 06. 6889901).</p>

            <h3><span className="privacyStyle">RESPONSABILE DELLA PROTEZIONE DEI DATI</span></h3>

            <p>Il DPO ovvero Data Protection Officer (anche denominato Responsabile della Protezione dei Dati) nominato dal CNAPPC &egrave; raggiungibile al seguente indirizzo: Consiglio Nazionale degli Architetti, Pianificatori, Paesaggisti e Conservatori &ndash; Data Protection Officer, Via di Santa Maria dell&rsquo;Anima n. 10 &ndash; 00186 Roma; email:&nbsp;<a href="mailto:dpo@cnappc.it">dpo@cnappc.it</a>.</p>

            <h3><span className="privacyStyle">INTERESSATI</span></h3>

            <p>Le categorie di Interessati che, attraverso la procedura di autenticazione, possono avere accesso al Portale, oltre il personale del CNAPPC addetto al suo funzionamento ed aggiornamento, sono gli Iscritti all&rsquo;Albo (di seguito &ldquo;Iscritti&rdquo;), i referenti degli Ordini territoriali e i referenti di altri soggetti terzi organizzatori di eventi di formazione.</p>

            <h3><span className="privacyStyle">BASE GIURIDICA E FINALITA&rsquo; DEL TRATTAMENTO</span></h3>

            <p>I dati personali indicati in questa pagina sono trattati dal CNAPPC nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri (art. 6, par. 2 lett. e del GDPR), con particolare riferimento a quanto previsto nel &ldquo;Regolamento per l&rsquo;aggiornamento e sviluppo professionale continuo&rdquo; adottato dal CNAPPC il 31 maggio 2017 in attuazione dell&rsquo;art. 7 del D.P.R. 7 agosto 2012 n. 137.</p>

            <p>La finalit&agrave; &egrave; quella di permettere il funzionamento del Portale, inteso quale mezzo pi&ugrave; efficiente per consentire l&rsquo;assolvimento dell&rsquo;obbligo di aggiornamento da parte degli Iscritti e per la gestione e organizzazione dell&rsquo;attivit&agrave; di aggiornamento a cura degli Ordini Territoriali, delle associazioni professionali e dei terzi autorizzati, nonch&eacute; per l&rsquo;adempimento degli altri compiti del CNAPPC in materia di formazione continua e in materia disciplinare.</p>

            <p>Inoltre, i dati possono essere trattati per consentire la comunicazione tra gli Iscritti e il CNAPPC ai fini di eventuali richieste di informazioni o chiarimenti attinenti agli obblighi di formazione continua dei professionisti o per l&rsquo;invio di istanze e relativi documenti allegati dagli Iscritti al CNAPPC e/o agli Ordini Territoriali di appartenenza (ad esempio per richieste di certificazioni, istanze di riconoscimento crediti formativi con autocertificazione, istanze di esonero), nonch&eacute; ai fini dell&rsquo;emissione degli attestati di partecipazione ai corsi.</p>

            <p>In relazione al possibile trattamento di categorie particolari di dati personali, la base giuridica del trattamento &egrave; l&rsquo;interesse pubblico rilevante (art.9, par. 2, lett. g del GDPR)</p>

            <p>Eventuali finalit&agrave; specifiche possono essere indicate nei successivi paragrafi per particolari tipologie di dati trattati o di trattamenti eseguiti.</p>

            <h3><span className="privacyStyle">TIPI DI DATI TRATTATI PER CATEGORIE DI INTERESSATI</span></h3>

            <p>Per ogni utente del sito, indipendentemente dal profilo di utenza utilizzato e delle aree riservate alle quali &egrave; stato eseguito l&rsquo;accesso, la navigazione del Portale comporta il trattamento dei seguenti dati personali:</p>

            <p><em>Dati di navigazione</em>: dati personali la cui trasmissione &egrave; implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli utenti, gli indirizzi in notazione URI/URL (Uniform Resource Identifier/Locator) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Tali dati, sono necessari per la fruizione dei servizi web e vengono trattati anche allo scopo di:</p>

            <p>&nbsp;- controllare il corretto funzionamento dei servizi offerti;</p>

            <p>- consentire la prestazione di servizi di assistenza da Voi eventualmente richiesti.</p>

            <p><em>Dati comunicati dall'utente: </em>l'invio facoltativo, esplicito e volontario di messaggi al CNAPPC, al fine di ricevere assistenza o chiarimenti, tramite la compilazione e l'inoltro dei moduli presenti sul Portale, comporta il trattamento di tutti i dati personali inclusi nelle comunicazioni al fine di dare risposta. Il conferimento di tali dati &egrave; facoltativo, ma in certi casi il mancato conferimento potrebbe comportare l&rsquo;impossibilit&agrave; di ricevere una risposta o che essa riesca ad essere esaustiva.</p>

            <p><em>Dati di log in: </em>credenziali specifiche di accesso al Portale ovvero credenziali personali di accesso centralizzato ai servizi del CNAPPC. Tali informazioni vengono trattate solo per la durata della sessione di utilizzo del Portale. Il conferimento di tali dati &egrave; da intendersi obbligatorio per gli Iscritti e i referenti degli Ordini territoriali, nel senso che senza di essi non sarebbe possibile ottenere l&rsquo;accesso al Portale e quindi assolvere ai rispettivi obblighi legati alla formazione continua, mentre pu&ograve; considerarsi facoltativo per i soggetti terzi erogatori di attivit&agrave; formative, fermo restando che il mancato conferimento da parte di questi ultimi non consentirebbe loro la pubblicazione delle attivit&agrave; formative sul Portale.</p>

            <p>Nessun trattamento di dati personali avviene prima dell&rsquo;accesso al Portale tramite autenticazione.</p>

            <h3><span className="privacyStyle">ISCRITTI ALL&rsquo; ALBO</span></h3>

            <p>Se siete entrati nel Portale autenticandovi come <strong>Iscritti all&rsquo;Albo</strong>, gli ulteriori dati trattati per le finalit&agrave; indicate nella presente informativa sono i seguenti:</p>

            <p><em>Dati personali comuni e di contatto:</em> nome, cognome, titolo professionale, Ordine territoriale di appartenenza, codice fiscale, indirizzo email collegato al servizio di accesso centralizzato ai servizi del CNAPPC, data di iscrizione al primo Albo e data di iscrizione all&rsquo;Albo attuale. Tali dati personali sono raccolti tramite collegamento all&rsquo;Albo Unico Nazionale tenuto e gestito dal CNAPPC. Il trattamento di tali dati &egrave; obbligatorio per obbligo di legge derivante dall&rsquo;iscrizione all&rsquo;Albo professionale.</p>

            <p>&nbsp;<em>Status Formativo</em>: dati riguardanti lo status del Vostro aggiornamento professionale (anche importati dal precedente portale im@teria), comprendenti informazioni sulla Vostra professione, il numero di crediti formativi ottenuti anno per anno e per ogni triennio, eventuali deficit di crediti formativi, l&rsquo;esistenza di condizioni di sospensione dalla fruizione delle attivit&agrave; di aggiornamento o formazione, l&rsquo;esistenza di esoneri totali o parziali dall&rsquo;obbligo di formazione continua, le attivit&agrave; formative a cui vi siete iscritti e a cui avete partecipato, con le relative date, procedimenti disciplinari che producono effetti sui crediti formativi, valutazioni espresse sulla qualit&agrave; delle attivit&agrave; formative organizzate da terzi a cui avete partecipato. Il trattamento di tali dati &egrave; obbligatorio per obbligo di legge derivante dall&rsquo;iscrizione all&rsquo;albo professionale (obbligo di formazione continua).</p>

            <p><em>Dati</em> <em>particolari:</em> Alcuni dati particolari (ex dati sensibili) relativi alle Vostre condizioni di salute, allo stato di maternit&agrave;, paternit&agrave;, adozione, affidamento possono essere trattati poich&eacute; rilevabili dalle eventuali istanze di esenzione da Voi inviate agli Ordini territoriali di appartenenza utilizzando i moduli presenti sul Portale. Ci&ograve; comporta anche una comunicazione di tali dati agli Ordini Territoriali che devono deliberare sull&rsquo;istanza. Nello status formativo &egrave; registrato unicamente il dato relativo all&rsquo;esistenza/inesistenza dell&rsquo;esenzione, senza menzione delle motivazioni. Il conferimento di tali dati personali &egrave; da considerarsi facoltativo, ma l&rsquo;eventuale rifiuto a comunicarli comporter&agrave; l&rsquo;impossibilit&agrave; di ottenere i provvedimenti di esonero totale o parziale basati sulle suddette condizioni.</p>

            <p>Il trattamento di tali dati personali non ha scadenza, stante l&rsquo;obbligo di conservazione ex art. 10 comma 2 lett. b) del D.Lgs. 42/2004, essendo soggetto agli obblighi di conservazione previsti per gli enti pubblici, salvo minimizzazione degli stessi nel momento in cui dovesse cessare il Vostro obbligo formativo.</p>

            <p>I Vostri dati personali potranno essere comunicati, nei limiti di quanto strettamente indispensabile, agli Ordini territoriali o agli altri soggetti terzi organizzatori delle attivit&agrave; formative al fine di consentire la partecipazione all&rsquo;evento e di rilevare la Vostra presenza agli stessi. Le valutazioni espresse sulla qualit&agrave; delle attivit&agrave; formative organizzate da terzi a cui avete partecipato sono ad essi comunicate in forma anonima o aggregata.</p>

            <h3><span className="privacyStyle">SOGGETTI TERZI</span></h3>

            <p>Se siete entrati nel Portale autenticandovi come <strong>soggetti terzi (o come referenti di soggetti terzi) offerenti attivit&agrave; formative</strong>, gli ulteriori dati personali trattati per le finalit&agrave; indicate nella presente informativa sono i seguenti:</p>

            <p><em>Dati personali comuni e di contatto</em>: nome, cognome, indirizzo di posta elettronica, numero di telefono. Tali dati personali sono stati da Voi conferiti al momento della richiesta delle credenziali di accesso al Portale o in sede di procedura di accreditamento.</p>

            <p>I dati possono essere comunicati, oltre che agli Iscritti in sede di visualizzazione delle offerte formative e/o di iscrizione, anche ai soggetti coinvolti nella procedura di accreditamento degli eventi e/o di verifica degli stessi (Membri della Commissione prevista dall&rsquo;art. 2 del Regolamento, Ordini Territoriali, Ministero vigilante). I dati non saranno diffusi.</p>

            <p>Il conferimento di tali dati personali &egrave; da considerarsi obbligatorio nel senso che, in caso di rifiuto a comunicarli, non &egrave; possibile ottenere le credenziali di accesso al Portale, n&eacute; ottenere l&rsquo;accreditamento degli eventi formativi.</p>

            <p>Si evidenzia che i soggetti terzi organizzatori degli eventi formativi, sono titolari del trattamento degli eventuali dati personali di terzi (ad esempio docenti/relatori, organizzatori, referenti, ecc.) inseriti nelle richieste di accreditamento e, comunque, all&rsquo;interno del Portale e si assumono ogni responsabilit&agrave; in merito alla legittimit&agrave; di tale trattamento ai sensi del GDPR, anche in merito alle informazioni che devono essere state fornite agli interessati.</p>

            <h3><span className="privacyStyle">ORDINI TERRITORIALI</span></h3>

            <p>Se siete entrati nel Portale autenticandovi come <strong>referenti degli Ordini territoriali</strong>, gli ulteriori dati personali trattati per le finalit&agrave; indicate nella presente informativa sono i seguenti:</p>

            <p><em>Dati personali comuni e di contatto</em>: nome, cognome, indirizzo di posta elettronica. Tali dati personali sono stati da Voi conferiti al momento della richiesta delle credenziali di accesso al Portale.</p>

            <p>I dati possono essere comunicati, agli Iscritti in sede di visualizzazione delle offerte formative e/o di iscrizione. I dati non saranno diffusi.</p>

            <p>Il conferimento di tali dati personali &egrave; da considerarsi obbligatorio nel senso che, in caso di rifiuto a comunicarli, non &egrave; possibile ottenere le credenziali di accesso al Portale, n&eacute; svolgere all&rsquo;interno del Portale le funzioni che la legge ed il Regolamento attribuiscono agli Ordini territoriali i materia di formazione continua.</p>

            <p>Si evidenzia che gli Ordini territoriali sono titolari del trattamento degli eventuali dati personali di terzi (ad esempio relatori, organizzatori, ecc.) inseriti nelle offerte formative e, comunque, all&rsquo;interno del Portale e si assumono ogni responsabilit&agrave; in merito alla legittimit&agrave; di tale trattamento ai sensi del GDPR, anche in merito alle informazioni che devono essere state fornite agli interessati.</p>

            <h3><span className="privacyStyle">ALTRE INFORMAZIONI COMUNI A TUTTI I DATI PERSONALI TRATTATI ATTRAVERSO IL PORTALE</span></h3>

            <p>I dati personali sono trattati:</p>

            <ul>

                <li>in modo lecito e secondo correttezza, per il perseguimento delle finalit&agrave; sopra indicate e nel rispetto dei principi fondamentali stabiliti dalla normativa;</li>

                <li>mediante strumenti informatici e telematici, sotto il presidio di misure tecniche e organizzative idonee a garantirne la sicurezza, la disponibilit&agrave; e la riservatezza, soprattutto al fine di ridurre i rischi di distruzione o perdita, anche accidentale, dei dati, di accesso non autorizzato, o di trattamento non consentito o non conforme alle finalit&agrave; del trattamento;</li>

                <li>generalmente presso gli uffici del CNAPPC dal personale dello stesso, istruito e formato alla tutela della sicurezza e riservatezza dei dati e che opera in qualit&agrave; di soggetti autorizzati al trattamento (designati)</li>

            </ul>

            <p>I dati personali non saranno trasferiti verso paesi extra-UE.</p>

            <p>I dati personali non saranno utilizzati ai fini di alcun processo decisionale automatizzato n&eacute; saranno utilizzati in attivit&agrave; di profilazione degli interessati.</p>

            <h3><span className="privacyStyle">RESPONSABILI DEL TRATTAMENTO</span></h3>

            <p>Il CNAPPC ha nominato:</p>

            <p>- la Deda Digital s.r.l. (Via Toscanini 7/2 40055 Castenaso (BO) - www.deda.digital), societ&agrave; incaricata all&rsquo;assistenza di primo livello;</p>

            <p>- la Didanet s.r.l. (Piazza Europa 2 35010 Cadoneghe (PD) info@didanet.org), societ&agrave; incaricata allo sviluppo del software gestione della piattaforma e assistenza di secondo livello,</p>

            <p>quali Responsabili del trattamento ai sensi dell'articolo 28 del GDPR in ragione dell&rsquo;attivit&agrave; di controllo sul corretto funzionamento del Portale e di assistenza agli Utenti di primo o di secondo livello che essi sono incaricati di svolgere.</p>

            <p>Per l&rsquo;assolvimento di tali incarichi, i Responsabili del trattamento potrebbero dover trattare i Vostri dati personali, nei limiti delle istruzioni impartite dal titolare e con gli standard di sicurezza e riservatezza che si sono obbligati a garantire.</p>

            <h3><span className="privacyStyle">DIRITTI DEGLI INTERESSATI</span></h3>

            <p>Salvo che non ricorrano motivi di esclusione dipendenti dalle finalit&agrave; o dalle modalit&agrave; di trattamento dei dati personali, ad ogni Interessato &egrave; data la possibilit&agrave; di esercitare, in qualsiasi momento, semplicemente contattando il CNAPPC ai recapiti indicati nella presente informativa, una serie di diritti, tra cui quello:</p>

            <ol>

                <li>di accedere ai dati personali in possesso del Titolare, ottenendo evidenza delle finalit&agrave; del loro trattamento, delle categorie di dati coinvolti, dei destinatari a cui gli stessi possono essere comunicati, del periodo di conservazione applicabile, dell&rsquo;esistenza di processi decisionali automatizzati;</li>

                <li>di ottenere senza ritardo la rettifica dei dati personali inesatti che lo riguardano;</li>

                <li>di ottenere, laddove consentito, la cancellazione dei suoi dati personali;</li>

                <li>di ottenere, laddove consentito, la limitazione del trattamento;</li>

                <li>di opporsi, nei casi previsti, in tutto o in parte, al trattamento dei suoi dati personali;</li>

                <li>di proporre reclamo all&rsquo;Autorit&agrave; Garante per la Protezione dei Dati Personali ai sensi dell&rsquo;art. 77 del GDPR.</li>

            </ol>

            <h3><span className="privacyStyle">INFORMATIVA SUI COOKIE E ALTRI STRUMENTI DI TRACCIAMENTO</span></h3>

            <p>I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al browser) dove vengono memorizzati per poi essere ritrasmessi agli stessi siti alla successiva visita del medesimo utente.</p>

            <p>Il Portale fa uso solo di cookie &ldquo;tecnici&rdquo;, esclusivamente per la permanenza della sessione di navigazione: l&rsquo;operazione di login riceve come esito un cookie di sessione che verr&agrave; utilizzato per l&rsquo;accesso dello user agent del browser fino a sua scadenza/cancellazione. Tali cookie hanno una permanenza limitata a 4 ore.</p>

            <p>Non viene fatto uso di cookie &ldquo;di profilazione&rdquo; n&eacute; di &ldquo;<em>analytics</em>&rdquo;.</p>

            <p>I cookies "tecnici" possono essere utilizzati anche in assenza del consenso dell&rsquo;utente. Ad ogni modo, l&rsquo;utente pu&ograve; sempre scegliere di abilitare o disabilitare i cookie tecnici, intervenendo sulle impostazioni del proprio browser di navigazione secondo le istruzioni rese disponibili dai relativi fornitori.</p>

            <p>La disabilitazione dei cookie non garantisce la corretta fruizione di alcune funzioni dal Portale.</p>

        </Fragment>
    );
}

export default PrivacyPolicyText;