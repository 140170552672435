import { FC, Fragment, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { IPostSubscriberCustomImportThunkPayload, postSubscriberCustomImport } from '../../redux/slices/course.slice';
import { AppDispatch } from '../../redux/store';

interface IProps {
    idCorso: number;
};

interface IForm extends IPostSubscriberCustomImportThunkPayload { }

const SubscriberImportForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        idCorso: props.idCorso,
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IForm);

    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [error, setError] = useState<IRejectResponse | null>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;
            const fileReader = new FileReader();
            fileReader.onloadstart = () => {
                setIsUploading(true);
            };
            fileReader.onloadend = () => {
                setIsUploading(false);
            };
            fileReader.onabort = (e: ProgressEvent<FileReader>) => {
                if(e.target?.error?.message) {
                    const er = e.target?.error?.message;
                    setError({error: er, status: 500, success: false});
                }
                else {
                    setError({error: "Si è verificata una condizione imprevista durante il caricamento del file! Riprovare più tardi.", status: 500, success: false});
                }
                setIsUploading(false);
            };
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        ...fields,
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };
            
            fileReader.readAsDataURL(files[0]);
        }
        else {
            setError({error: "Il file che si tenta di caricare è vuoto!", status: 500, success: false});
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsLoading(true);
        dispatch(postSubscriberCustomImport(fields as IPostSubscriberCustomImportThunkPayload))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch((exc) => setError(exc))
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            {submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={94} className="text-success mb-5" />
                    <h3 className="text-success">Operazione completata!</h3>
                </div>
            ) : (
                <Form noValidate onSubmit={handleSubmit} >
                    <p><a href="/assets/documents/import-iscritti-esempio.csv" download={`import-iscritti-esempio_${new Date().getTime()}.csv`}>Clicca QUI per scaricare il file CSV di esempio</a></p>
                    <span>Accorgimenti utili per una corretta importazione:</span>
                    <ul>
                        <li>formato file richiesto: CSV</li>
                        <li>ci deve essere la prima riga di intestazione con i titoli delle colonne come nel file di esempio</li>
                        <li>il carattere <span className="h5">;</span> (punto e virgola) è il separatore delle colonne</li>
                        <li>le date devono essere nel formato gg/mm/aaaa</li>
                        <li>la colonna "esterno" accetta valori numerici:<br />0 = professionista iscritto all'ordine<br />1 = professionista esterno all'ordine</li>
                    </ul>
                    <Form.Control type="file" name="file" onChange={handleFileUpload} className="mb-3" disabled={isLoading} accept=".csv" />
                    { error ? (<Alert variant="danger" className="p-2 text-center">{ error.error }</Alert>) : (<Fragment />) }
                    <Button type="submit" className="w-100" disabled={isLoading || error !== null || isUploading || (fields.fileName.length === 0)}>
                        {isLoading ? (
                            <Fragment>
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ms-1">Attendere</span>
                                <span>...</span>
                            </Fragment>
                        ) : (
                            <span>Salva</span>
                        )}
                    </Button>
                </Form>
            )
            }
        </Fragment >
    );
};

export default SubscriberImportForm;