import { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import InspectDocumentForm from '../components/InspectDocumentForm';
import Footer from '../layout/parts/Footer';

const InspectDocument: FC = () => {
  const { code } = useParams<{ code: string }>();

  const [token, setToken] = useState<string>();

  useEffect(() => {
        const t : string = code ? code : "";
        setToken(t);
    }, [code]);

  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card className="m-auto">
              <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                <h2>Verifica documento</h2>
                { token ? (<InspectDocumentForm token={token} codiceFiscale=""/>) : (<Spinner animation="grow" />) }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer variant={"dark"} />
    </Fragment>
  );
};

export default InspectDocument;