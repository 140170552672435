import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import OwnCourseList from '../../components/admin/OwnCourseList';
import PartnershipCourseList from '../../components/admin/PartnershipCourseList';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompanies, getCompany, getPartners } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';

const AdminOtherCourses: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompanies({}));
    dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
  }, [dispatch]);

  const [toggleTab, setToggleTab] = useState<boolean>(true);

  return (
    <Fragment>
      <PageTitle content="Corsi" />
      <PageSubtitle content="Lista corsi pubblicati da tutti ordini / enti" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="ownother" onSelect={() => setToggleTab(!toggleTab)}>
              <Tab eventKey="ownother" title="Ordini">
                <OwnCourseList full={true} statoPubblicazione={[3]} active={toggleTab} />
              </Tab>
              <Tab eventKey="partnershipother" title="Enti Terzi">
                <PartnershipCourseList full={true} statoPubblicazione={[3]} active={!toggleTab} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminOtherCourses;