import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

export const getEsterno = (id: boolean): string => {
    if (id === true) {
        return 'Tutti i professionisti';
    }
    return 'Professionisti iscritti all\'ordine';
}

interface IProps {
    defaultSelectedId?: boolean;
    onSelect?: (option: boolean) => void;
}

const Esterno: FC<IProps> = (props) => {

    const defaultSelected = (): boolean => {
        if (props?.defaultSelectedId) {
            return props.defaultSelectedId;
        }
        return false;
    };

    const [filterByEsterno, setFilterByEsterno] = useState<boolean>(defaultSelected());

    const handleFilterByEsterno = (option: boolean) => {
        setFilterByEsterno(option);
        if (props?.onSelect) {
            props.onSelect(option);
        }
    };

    return (
        <Fragment>
            <Dropdown className="mx-1">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-esterno">
                    {`${getEsterno(filterByEsterno)} `}
                </Dropdown.Toggle>
                <Dropdown.Menu className="px-1 border border-primary">
                    <Dropdown.Item key={"dropdownEsternoTrue"} disabled={filterByEsterno === true} onClick={() => handleFilterByEsterno(true)}>{getEsterno(true)}</Dropdown.Item>
                    <Dropdown.Item key={"dropdownEsternoFalse"} disabled={filterByEsterno === false} onClick={() => handleFilterByEsterno(false)}>{getEsterno(false)}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default Esterno;