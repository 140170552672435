import { FC, Fragment, useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { IAdvice } from '../../entities/advice.entity';
import { getAdvicesReadonly } from '../../redux/slices/advices.slice';
import AdviceItem from './AdviceItem';

const AdvicesReadonly: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const advices: Array<IAdvice> = useSelector((s: RootState) => s.advices.readonlyvalue);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getAdvicesReadonly({ })).unwrap().finally(() => setIsLoading(false));
    }, [dispatch]);

    return (
        <Container fluid as="section">
           {
                isLoading && (
                    <div className="text-center">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <p>Attendere...</p>
                    </div>
                )
            }
            {
                !isLoading && advices.length > 0 && advices.map((adv: IAdvice) => <span key={"adv" + adv.id}><AdviceItem advice={adv} /></span>)
            }
        </Container>
    );
};

export default AdvicesReadonly;