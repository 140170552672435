import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FC, Fragment, useState } from 'react';
import { Editor, RawDraftContentState, SyntheticEvent } from 'react-draft-wysiwyg';

interface IProp {
    name: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const MyRichTextEditor: FC<IProp> = (props) => {
    const [content, setContent] = useState<string>(props?.value ? props.value : '');

    const [editorState, setEditorState] = useState<EditorState>(
        () => {
            if (content) {
                const html = content;
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    return EditorState.createWithContent(contentState);
                }
            }
            return EditorState.createEmpty();
        }
    );

    const handleEditorStateChange = (editorState: EditorState) => {
        //console.log("handleEditorStateChange");
        setEditorState(editorState);
        //setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        //triggerChange();
    };

    const handleBlur = (_event: SyntheticEvent) => {
        //console.log("handleBlur");
        triggerChange();
    };

    const handleContentChange = (contentState: RawDraftContentState) => {
        //console.log("handleContentChange");
        setContent(draftToHtml(contentState));
        triggerChange();
    };

    const triggerChange = () => {
        const input = document.getElementById(props.name);
        const event = new Event("change", { bubbles: true });
        if (input && props?.onChange) {
            input.dispatchEvent(event);
        }
    };

    return (
        <Fragment>
            <Editor editorState={editorState} onEditorStateChange={handleEditorStateChange} onBlur={handleBlur}
                onContentStateChange={handleContentChange} wrapperClassName="demo-wrapper border" editorClassName="demo-editor" />
            <input type="text" name={props.name} id={props.name} onChange={props?.onChange} className="d-none" value={content} />
        </Fragment>
    );
};

export default MyRichTextEditor;
