export const CertificationOrderBy = [
    { id: 1, nome: 'codiceFiscale', descrizione: 'Codice fiscale' }, 
    { id: 2, nome: 'idOggetto', descrizione: 'Oggetto' }, 
    { id: 3, nome: 'idTipologia', descrizione: 'Tipologia' },
    { id: 4, nome: 'dataRiferimentoDa', descrizione: 'Data riferimento inizio' },
    { id: 5, nome: 'dataRiferimentoA', descrizione: 'Data riferimento fine' },
    { id: 6, nome: 'titolo', descrizione: 'Titolo' },
    { id: 7, nome: 'organizzatore', descrizione: 'Organizzatore' },
    { id: 8, nome: 'codiceCorso', descrizione: 'Codice corso' },
    { id: 9, nome: 'cfpDichiarati', descrizione: 'CFP dichiarati' },
    { id: 10, nome: 'cfpAssegnati', descrizione: 'CFP assegnati' },
    { id: 11, nome: 'noteRichiedente', descrizione: 'Note richiedente' },
    { id: 12, nome: 'idStatoCertificazione', descrizione: 'Stato' },
];