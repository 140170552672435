import { FC, Fragment } from 'react';
import { ProgressBar } from 'react-bootstrap';

interface IProps {
    cfp: number;
    cfpTOT: number;
    textTitle: string;
    textSubtitle: string;
    variant50: string;
    variant100: string;
    hideIfEmpty: boolean;
}

const TrainingCreditsBar: FC<IProps> = (props) => {
    return (
        <div>
            <p className="m-0 p-0 mt-1 small"><b className="text-uppercase">{props.textTitle}&nbsp;</b><br /><small>{props.textSubtitle}&nbsp;</small></p>
            {
                props.cfpTOT > 0 ? (
                    <Fragment>
                    {
                        props.cfp > 0 ? (
                            props.cfp < props.cfpTOT ? (
                                <ProgressBar className="fw-bold" striped variant={props.variant50} now={props.cfp} label={`${props.cfp} / ${props.cfpTOT}`} max={props.cfpTOT} />
                            ) : (
                                <ProgressBar className="fw-bold" striped variant={props.variant100} now={props.cfp} label={`${!props.hideIfEmpty ? props.cfp + " /" : "+"} ${props.cfpTOT}`} max={props.cfpTOT} />    
                            )
                        ) : (      
                            <ProgressBar className="fw-bold bg-color-palette-4" now={props.cfpTOT} label={`0 / ${props.cfpTOT}`} max={props.cfpTOT} />
                        )
                    }
                    </Fragment>
                ) : (
                    !props.hideIfEmpty ? (
                        <ProgressBar className="fw-bold" striped variant="secondary" now={100} label={`0 / 0`} />
                    ) : (
                        <Fragment />
                    )
                )
            }
        </div>        
    );
};

export default TrainingCreditsBar;